import React, {useState} from 'react';
import './Garage.css';
import CarInfo from '../Detailcomponents/Garagedetailcomponent/Carinfo';
import Benefits from '../Detailcomponents/Garagedetailcomponent/Benefit';
import Appointments from '../Detailcomponents/Garagedetailcomponent/Appointments';
import ShopProtectionPlan from '../Shopcomponents/Shop_Protection_Plan';
import ShopServices from '../Shopcomponents/Shop_services';
import $ from 'jquery'
import Declined from './Declined';

const GarageCarDetail = ({ carId, carmodel }) => {
    const [carinfoopen, setcarinfoopen] = useState(false);
    const [benefitopen, setbenefitopen] = useState(false);
    
    const [services, setservices] = useState(true);
    const [protectionplans, setprotectionplans] = useState(false);
    const [appoinmentopen, setappoinmentopen] = useState(false);
    const [decline, setdecline] = useState(false);

    function tabClickservices(){
        setservices(true);
        setprotectionplans(false);
        setappoinmentopen(false);
        setdecline(false);
        $(".garage_car_detail_header").removeClass("colpse_header")
    }


    function tabClickprotectionplan(){
        setservices(false);
        setprotectionplans(true);
        setappoinmentopen(false);
        setdecline(false);
        $(".garage_car_detail_header").removeClass("colpse_header")
    }
    
    function tabClickAppointments(){
        setservices(false);
        setprotectionplans(false);
        setappoinmentopen(true);
        setdecline(false);
    }

    function tabClickDecline(){
        setservices(false);
        setprotectionplans(false);
        setappoinmentopen(false);
        setdecline(true);
        $(".garage_car_detail_header").removeClass("colpse_header")
    }

    function colpse() {
        $(".garage_car_detail_header").toggleClass("colpse_header")
    }

    return (
        <div className='garage_car_detail_section'>
            <div className='garage_car_detail_header'>
                {
                    appoinmentopen &&
                    <div className='colapse' onClick={colpse}></div>
                }
                <div className='garage_car_detail_header_left_right_wrapper'>
                    <div className='garage_car_detail_header_left'>
                        <img className='brokenimg' alt='img' src={carmodel.carimg} id='belliconid'></img>
                    </div>
                    <div className='garage_car_detail_header_right'>
                        <div className="flex">
                            <strong>{carmodel.dealername}</strong>
                        </div>
                        <h4 className="title_large">
                            {carmodel.carmakemodel}
                        </h4>
                    </div>
                </div>
                <div className='garage_car_detail_navigation'>
                    <span className={`garage_car_detail_navigation_items ${services ? 'active' : '' }`} onClick={tabClickservices} id="cardetailtabservice">Services</span>
                    <span className={`garage_car_detail_navigation_items ${protectionplans ? 'active' : '' }`} onClick={tabClickprotectionplan} id="cardetailtabpp">Protection Plans</span>
                    <span className={`garage_car_detail_navigation_items ${decline ? 'active' : '' }`} onClick={tabClickDecline} id="cardetailtabdecline">Decline</span>
                    <span className={`garage_car_detail_navigation_items ${appoinmentopen ? 'active' : '' }`} onClick={tabClickAppointments} id="cardetailtabappoinment">Appointments</span>
                    
                    {/* <span className={`garage_car_detail_navigation_items ${carinfoopen ? 'active' : '' }`} onClick={tabClickcarinfo}>Car Info</span>
                    <span className={`garage_car_detail_navigation_items ${benefitopen ? 'active' : '' }`} onClick={tabClickBenefits}>Benefits</span> */}
                </div>
            </div>
            <div className='garage_car_detail_content_wrapper'>
                
                {services && <ShopServices title='Services' carId={carId}/>}
                {protectionplans && <ShopProtectionPlan title='Protection Plans'  carId={carId}/>}
                {decline && <Declined title="Decline"  carId={carId}/>}
                {appoinmentopen && <Appointments title='Appointments' carId={carId}/>}
                {/* {carinfoopen && <CarInfo title='Car Info' carId={carId}/>}
                {benefitopen && <Benefits title='Benefits' carId={carId}/>} */}
            </div>
        </div>
    );
};
export default GarageCarDetail;