import React from 'react';
import Slider from "react-slick";
import { useContext } from 'react';
import AppContext from '../../AppContext';
import ShopViewMore from './Shop_view_more';
import loader from '../../images/loader.gif';
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}


const ShopNewVehicles = ({title}) => { 
    const myContext = useContext(AppContext);
    let base_url = myContext.Base_url;
    let endpoint = "Shop/ShopVehicleFilterSearch";
    let api_token = localStorage.getItem("token");

    const favoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };
      const response = await markAsFavorite(itemId, contextData);
      if (!response) {
          setIsLoading(false);
          myContext.setfavoriteitemloader(false);
      }
    };

    const unfavoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };
      
      const response = await removeFromFavorite(itemId, contextData);
      if (!response) {
        setIsLoading(false);
        myContext.setfavoriteitemloader(false);
      }
    };


     

    // API URL For  New Vehicles > New Member Ship
    let apiurlNewMember = base_url+endpoint+'?'+'New'+'='+true+'&'+'Used'+'='+false+'&'+'CarShopType'+'='+0+'&'+'Year'+'='+'&'+'Make'+'='+'&'+'Model'+'='+'&'+'LowPrice'+'='+0+'&'+'HighPrice'+'='+0+'&'+'MonthlyLowPrice'+'='+0+'&'+'MonthlyHighPrice'+'='+0+'&'+'SearchString'+'='+''+'&'+'PageIndex'+'='+1+'&'+'PageSize'+'='+8;

    // Check API Loading Status
    const [isLoading, setIsLoading] = React.useState(true);
    
    // Set API Response into model
    const [isdataloded, setisdataloded] = React.useState(false);
    
    // Set API Model
    const [data, setData] = React.useState([]);

    // API Status Result
    const [apistatus, setapistatus] = React.useState(false);
    const [apimessage, setapimessage] = React.useState(false);
    
    

    // Membership Discounts on New Cars
    const [dataformember, setdataformember] = React.useState([]);
    const [totalcountnewmember, settotalcountnewmember] = React.useState();
    
    // New Special
    const [datafornewspecial, setdatafornewspecial] = React.useState([]);
    const [totalcountnewspecial, settotalcountnewspecial] = React.useState();
    
    // New Lease Special
    const [dataforleasspecial, setdataforleasspecial] = React.useState([]);
    const [totalcountleasspecial, settotalcountleasspecial] = React.useState();
    
    //  New All
    const [datafornewall, setdatafornewall] = React.useState([]);
    const [totalcountnewall, settotalcountnewall] = React.useState();


    // Create our number formatter.
    var  formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });



    function callAPIForShop() {
        let api_url = apiurlNewMember;
        const url = api_url;
        axios(
            {
            method: 'get',
            url: url,
            headers: {'Authorization': api_token}
            }
        )
        .then(res => {
            setapistatus(res.data.status);
            setapimessage(res.data.message);
            setData(res.data.getAllCategorizedList);
            // console.log(res)
            
        })
        .catch((error) => {
          if (error.response.data.message == "AuthToken is Expired") {
            localStorage.setItem("token", "");
          }
          console.log(error);
        }) 
    } 
    if(myContext.favoriteitemloader) {
        callAPIForShop();
    }

    React.useEffect(() => {
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            callAPIForShop();
            
        }
       
    }, []);
    React.useEffect(() => { 
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            if(apistatus === false) {

            } else {
                if (data.length !== 0) {

                        setIsLoading(false);
                        setisdataloded(true);


                        setdataformember(data.newCars.shopModelNewMembership); 
                        settotalcountnewmember(data.newCars.shopModelNewMembershipCount);


                        setdatafornewspecial(data.newCars.shopModelNewSpecial); 
                        settotalcountnewspecial(data.newCars.shopModelNewSpecialCount);


                        setdataforleasspecial(data.newCars.shopModelNewLeaseSpecial); 
                        settotalcountleasspecial(data.newCars.shopModelNewLeaseSpecialCount);


                        setdatafornewall(data.newCars.shopModelNewAll);
                        settotalcountnewall(data.newCars.shopModelNewAllCount);
                        
                } else {
                }
            }
        }
    }, [data]);
    
    // Slider Setting
    const settings = {
        arrows: true,
        slidesToShow: 4, 
        slidesToScroll: 1,
        infinite: false,
        dot: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    $(".heart_icon").hover(function(){
        $('.shop__row__slider__items').css("pointer-events", "none");
    }, function(){
        $('.shop__row__slider__items').css("pointer-events", "auto");
    });

    if(api_token !== undefined && api_token !== null && api_token !== '') {    
        return (
          <div className="content_panel_controller dir_column">
            <ToastContainer hideProgressBar={true} />
            {myContext.shopcardetailsectionisopen === "NewVehicleNewCars" && (
              <ShopViewMore
                title="NewVehicleNewCar"
                totalCount={totalcountnewmember}
                CarShopType="1"
                from="new"
              />
            )}
            {myContext.shopcardetailsectionisopen ===
              "NewVehicleNewSpecial" && (
              <ShopViewMore
                title="NewVehicleNewSpecial"
                totalCount={totalcountnewspecial}
                CarShopType="2"
                from="new"
              />
            )}
            {myContext.shopcardetailsectionisopen ===
              "NewVehicleLeaseSpecial" && (
              <ShopViewMore
                title="NewVehicleLeaseSpecial"
                totalCount={totalcountleasspecial}
                CarShopType="3"
                from="new"
              />
            )}
            {myContext.shopcardetailsectionisopen === "NewVehicleNewAll" && (
              <ShopViewMore
                title="NewVehicleNewAll"
                totalCount={totalcountnewall}
                CarShopType="4"
                from="new"
              />
            )}
            {isLoading && (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            )}
            {apistatus === false ? (
              <h4>{apimessage}</h4>
            ) : (
              <div className="shop__row_wrapper">
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountnewmember != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountnewmember != 0 && (
                          <h4 className="title_medium">
                            Membership Discounts on New Cars
                          </h4>
                        )}
                        <span
                          id="newvehiclenewcar"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "NewVehicleNewCars"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            dataformember.map((user, id) => (
                              <div className="relative" key={id}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      className="brokenimg"
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>

                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carcutprice">
                                        <del>
                                          {formatter.format(user.sellingPrice)}
                                        </del>
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.netPrice)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountnewspecial != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountnewspecial != 0 && (
                          <h4 className="title_medium">New Special</h4>
                        )}
                        <span
                          id="newnewspecial"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "NewVehicleNewSpecial"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            datafornewspecial.map((user, id) => (
                              <div className="relative" key={id}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.sellingPrice)}
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year} {user.id}
                                      </span>
                                      <span className="carprice"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountleasspecial != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountleasspecial != 0 && (
                          <h4 className="title_medium">New Lease Special</h4>
                        )}
                        <span
                          id="newleasespecial"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "NewVehicleLeaseSpecial"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            dataforleasspecial.map((user, id) => (
                              <div className="relative" key={id}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.sellingPrice)}
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountnewall != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountnewall != 0 && (
                          <h4 className="title_medium">
                            All New Vehicles Inventory
                          </h4>
                        )}
                        <span
                          id="newallvahicleinventory"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "NewVehicleNewAll"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            datafornewall.map((user, id) => (
                              <div className="relative" key={id}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 1,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.sellingPrice)}
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        );
    } else {
        return(
            <h1>Token Expired</h1>
        )
    }
    
};

export default ShopNewVehicles;