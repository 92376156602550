import React, { Component } from 'react'
import AppContext from '../../AppContext';
import loader from '../../images/loader.gif';
import $ from 'jquery'


export default class ResetPassword extends Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            btnenabled: false,
            error: null, 
            isLoaded: false,
            APIHitstatus: false,
            items: [],
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
            api_token: localStorage.getItem('token'),
            openloginagainmodal: false
        };
        this.showpwd = this.showpwd;
        this.ResetPaswword = this.ResetPaswword;
        this.confirmChange = this.confirmChange;
        this.Validate = this.Validate.bind(this);
        this.confirmlogout = this.confirmlogout;
    }

    // Password Show Hide
    showpwd = e => {
        const ClickID = e.currentTarget.getAttribute("clickid");
        document.getElementById(ClickID);
        var x = document.getElementById(ClickID);
        if (x.type === "password") {
            e.currentTarget.innerHTML = "Hide";
            x.type = "text";
        } else {
            e.currentTarget.innerHTML = "Show";
            x.type = "password";
        }
    }
    // Password Show Hide


    // Data API Start
    ResetPaswword = (Op, Np, Cp) => {
        const data = {
            "oldPassword": Op,
            "newPassword": Np,
            "confirmNewPassword": Cp
        }
        let end_point = 'UserInfo/UpdateProfilePassword';
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            },
            body: JSON.stringify(data),
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    isLoaded: true,
                    apiStatus: result.status,
                    apiStatusMessage: result.message,
                    APIHitstatus: false,
                    oldpassword: '',
                    newpassword: '',
                    confirmpassword: '',
                    openloginagainmodal: true
                });
                $('.title_large').addClass('blur');
                $('.reset_password_wrapper2').addClass('blur');
                $('.Left__panel').addClass('blur');
                $('.top_iconbar').addClass('blur');
                
            } else {
                this.setState({
                  isLoaded: false,
                  apiStatus: result.status,
                  apiStatusMessage: result.message,
                  APIHitstatus: false,
                //   oldpassword: "",
                //   newpassword: "",
                //   confirmpassword: "",
                  btnenabled: false,
                });
                
                // var olpdpinp = document.getElementById("oldpwd");
                // var olpdpinp2 = document.getElementById("newpwd");
                // var olpdpinp3 = document.getElementById("cnfmpwd");
                // olpdpinp.value = "";
                // olpdpinp2.value = "";
                // olpdpinp3.value = "";
            }
        },
        (error) => {
            this.setState({
                isLoaded: false,
                error
            });
        })         
    }
    // Data API End


    // On Click Confirm Button
    confirmChange = e => {
        // debugger
        this.setState({
            APIHitstatus: true
        })
        if (this.state.btnenabled) {
            this.ResetPaswword(this.state.oldpassword, this.state.newpassword, this.state.confirmpassword);
            // this.setState({
            //   oldpassword: "",
            //   newpassword: "",
            //   confirmpassword: ""
            // });
        } else {
            alert('Empty Fields');
            this.setState({
                APIHitstatus: false
            })
        }
        if(this.state.apiStatus) {
            this.setState({
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
        }
    }
    // On Click Confirm Button


    // Validate Fields
    Validate = (Op, Np, Cp) => {

        let errorfeildlower = document.querySelector('.errorfeildlower');
        let errorfeildupper = document.querySelector('.errorfeildupper');
        let errorfeildnum = document.querySelector('.errorfeildnum');
        let errorfeildminchar = document.querySelector('.errorfeildminchar');
        let completeField = document.querySelector('.completeField');
        let matchpwdfild = document.querySelector('.matchpwdfild');
        
        matchpwdfild.style.display = 'none';
        
        var myInput = document.getElementById("newpwd");
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if(myInput.value.match(lowerCaseLetters)) {  
            errorfeildlower.style.color = "none";
        } else {
            errorfeildlower.style.color = "red";
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(myInput.value.match(upperCaseLetters)) { 
            errorfeildupper.style.color = "none";
        } else {
            errorfeildupper.style.color = "red";
        }
        
        
        // Validate numbers
        var numbers = /[0-9]/g;
        if(myInput.value.match(numbers)) {  
            errorfeildnum.style.color = "none";
        } else {
            errorfeildnum.style.color = "red";
        }


        // Validate length
        if(myInput.value.length >= 8) {
            errorfeildminchar.style.display = "none";
        } else {
            errorfeildminchar.style.color = "red";
        }







        if(myInput.value.match(numbers)){
            errorfeildnum.style.display = 'none'
            completeField.style.color = 'green'
            let ele = document.querySelector('.icclass');
            ele.classList.add('fa-check');
            ele.classList.remove("fa-xmark");
            
        }else{
            errorfeildnum.style.display = 'block'
            completeField.style.color = 'red'
            let ele = document.querySelector('.icclass');
            ele.classList.remove('fa-check');
            ele.classList.add("fa-xmark");

        }

        if(myInput.value.match(upperCaseLetters) ){
            errorfeildupper.style.display = 'none'
            completeField.style.color = 'green'
            let ele = document.querySelector('.icclass');
            ele.classList.add('fa-check');
            ele.classList.remove("fa-xmark");
            
        }else{
            errorfeildupper.style.display = 'block'
            completeField.style.color = 'red'
            let ele = document.querySelector('.icclass');
            ele.classList.remove('fa-check');
            ele.classList.add("fa-xmark");

        }

        if( myInput.value.match(lowerCaseLetters)){
            errorfeildlower.style.display = 'none'
            completeField.style.color = 'green'
            let ele = document.querySelector('.icclass');
            ele.classList.add('fa-check');
            ele.classList.remove("fa-xmark");
            
        }else{
            errorfeildlower.style.display = 'block'
            completeField.style.color = 'red'
            let ele = document.querySelector('.icclass');
            ele.classList.remove('fa-check');
            ele.classList.add("fa-xmark");

        }

        if(myInput.value.length >= 8){
            errorfeildminchar.style.display = 'none'
            completeField.style.color = 'green'
            let ele = document.querySelector('.icclass');
            ele.classList.add('fa-check');
            ele.classList.remove("fa-xmark");
            
        }else{
            errorfeildminchar.style.display = 'block'
            completeField.style.color = 'red'
            let ele = document.querySelector('.icclass');
            ele.classList.remove('fa-check');
            ele.classList.add("fa-xmark");

        }


        let Opstatus;
        let Npstatus;
        let Cpstatus;
        if (Op.length >= 1) {
            Opstatus = true;
        }
        if (Np.length >= 1) {
            Npstatus = true;
        }
        if (Cp.length >= 1) {
            Cpstatus = true;
        }
        if(Opstatus && Npstatus && Cpstatus) {
            if (Np === Cp) {
                this.setState({
                    btnenabled: true
                })
                matchpwdfild.style.display = 'none';
                let ele = document.querySelector('.icclass1');
                ele.classList.add('fa-check');
                ele.classList.remove("fa-xmark");
            } else {
                matchpwdfild.style.display = 'block';
                let ele = document.querySelector('.icclass1');
                ele.classList.remove('fa-check');
                ele.classList.add("fa-xmark");
                this.setState({
                    btnenabled: false
                })
            }
        } else {
            this.setState({
                btnenabled: false
            })
        }
    }
    // Validate Fields
    confirmlogout = () => {
        $('.title_large').removeClass('blur');
        $('.reset_password_wrapper2').removeClass('blur');
        $('.Left__panel').removeClass('blur');
        $('.top_iconbar').removeClass('blur');
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
        this.context.setsplashIsOpen(false);
        this.setState({
            openloginagainmodal: false
        });
      }
    render() {
        return (
            <div className="reset_password_wrapper">
                {/* <h4 className="title_large">Reset Password</h4> */}
                <div className='reset_password_form_wrapper  reset_password_wrapper2'>
                    <div className='form-wrapper'>
                        {
                            this.state.APIHitstatus &&
                            <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>

                        }
                        <form>
                            <div className='form-inner-wrap'>
                                
                                <div className="form_group">
                                    <label>
                                        Old Password
                                    </label>
                                    <div className="inputwrapper">
                                        <input type="password" id="oldpwd" onChange={e => {
                                            this.setState({oldpassword : e.target.value})
                                            this.Validate(e.target.value, this.state.newpassword, this.state.confirmpassword)
                                            }} 
                                            placeholder='Old Password' className='grey-bdr m-0' required/>
                                        <label id="vinValid" className='c-red'></label>
                                        <span className='show_hide' onClick={this.showpwd} clickid="oldpwd">Show</span>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label>
                                        New Password
                                    </label>
                                    <div className="inputwrapper">
                                        <input type="password" id="newpwd" onChange={e => {
                                            this.setState({newpassword : e.target.value})
                                            this.Validate(this.state.oldpassword, e.target.value, this.state.confirmpassword)
                                        }}  
                                        placeholder='New Password' className='grey-bdr m-0' required/>
                                        <label id="vinValid" className='c-red'></label>
                                        <span className='show_hide'  onClick={this.showpwd} clickid="newpwd">Show</span>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label>
                                        Confirm Password
                                    </label>
                                    <div className="inputwrapper">
                                        <input type="password"  id="cnfmpwd"  onChange={e => {
                                            this.setState({confirmpassword : e.target.value})
                                            this.Validate(this.state.oldpassword, this.state.newpassword, e.target.value)
                                            }}  
                                            placeholder='Confirm Password' className='grey-bdr m-0' required/>
                                        <label id="vinValid" className='c-red'></label>
                                        <span className='show_hide'  onClick={this.showpwd} clickid="cnfmpwd">Show</span>
                                    </div>
                                </div>
                                
                                <div className={`btn_theme fullwidth ${this.state.btnenabled ? '' : 'disable'}`} onClick={this.confirmChange}>Confirm</div>
                                <div className='apistatusmssg w-100 text-danger mb-1'
                                style={
                                this.state.apiStatus ?
                                {color: 'green'}
                                :
                                {color: 'red'}                                   
                                }>
                                {this.state.apiStatusMessage}
                                </div>
                                <div className='matchpwdfild w-100 text-danger mt-2 mb-1' style={{display: 'none'}}><i className="fa-solid fa-xmark icclass1"></i> Password Does not Match</div>
                                <div className='errorfeildlower w-100 text-danger mb-1'><i className="fa-solid fa-xmark"></i> A lowercase letter</div>
                                <div className='errorfeildupper w-100 text-danger mb-1'><i className="fa-solid fa-xmark"></i> A capital (uppercase) letter</div>
                                <div className='errorfeildnum w-100 text-danger mb-1'><i className="fa-solid fa-xmark"></i> A number</div>
                                <div className='errorfeildminchar w-100 text-danger mb-1'><i className="fa-solid fa-xmark"></i> Minimum 8 characters</div>
                                <div className='completeField w-100 text-danger mb-1'><i className="fa-solid fa-xmark icclass"></i> Strong Password</div>
                                
                            </div>
                        </form>
                    </div>
                </div>
                {
                this.state.openloginagainmodal &&     


                <div className='carinfo_cards_box_right_panel modalcenter garagemodal active'>
                    <h3 className='text-center'>{this.state.apiStatusMessage}</h3> 
                    <div className='flex justify-center mt-2'>
                    <div className='btn_theme mr-1' onClick={this.confirmlogout}>Login</div>
                    </div>
                </div>
                }
            </div>
        )
    }
}
