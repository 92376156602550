import React, { Component } from 'react';
import loader from '../../../images/loader.gif';
import AppContext from '../../../AppContext';
import Overlay from '../../Snippets/overlay';
import { v4 } from 'uuid';

class Services extends Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            AddItemToCart: {},
            additemapiisloaded: false,
            additemapimessage: false,
            items: [],
            additemapistatus: '',
            api_token: localStorage.getItem('token')
        };
        this.AddToCart = this.AddToCart;
        this.AddToCartApiFunction = this.AddToCartApiFunction;
    }

     // After Rendering
     componentDidMount() {
        let end_point = 'Garage/GetUserBenefits';
        let url_parameter = '?CarId=';
        let api_url = this.context.Base_url+end_point+url_parameter+this.props.carId; 

        // Main Benefit Services API Start
        fetch(api_url ,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: result.benfitsGarageModel
                    });
                } else {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
            
        )
        // Main Benefit Services API End
    }

    // Add To cart 
    AddToCart = e => {
        
        let itemID = e.currentTarget.getAttribute("itemId");
        let itemdealerShipID = e.currentTarget.getAttribute("itemdealerShipID");
        
        let CarID = this.props.carId;
        let DealershipID = itemdealerShipID;
        let PromoCode = "";
        let Notes = "";

         let val = [{   
            "CartItemType": 0,
            "ItemId": parseInt(itemID),
            "isBeneift": true,
            "Price" : 0
        }] 
        let data = {
            "DealerShipId": parseInt(DealershipID),
            "CarId": parseInt(CarID),
            "CartItemModels" : val,
            "PromoCode": PromoCode,
            "Notes": Notes
        }

        this.setState(
            { 
                AddItemToCart : data, 
                additemapiisloaded : true
            }, () => {

                // Add To Cart API Hit Function
                this.AddToCartApiFunction(this.state.AddItemToCart);
            }
        )
        
    }
    // Add To cart 


    // Add To Cart API Function
    AddToCartApiFunction = (ReqData) => {
        let end_point = 'Cart/AddItemToCart';
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            },
            body: JSON.stringify(ReqData),
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                }, () => {

                    this.context.setupdatecartcount(true);
                    setTimeout(() => {
                        this.setState({additemapiisloaded:false})
                    }, 2000);
                });
            } else {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                });
            }
        },
        (error) => {
            this.setState({
                additemapistatus:false,
                error
            });
        }) 

    }
    // Add To Cart API Function

    render() {
        return (
            <>
            {
                this.state.additemapiisloaded ?    
                    this.state.additemapistatus  &&     
                    <div className='loader-div'>
                        <Overlay title='additem' message={this.state.additemapimessage}/>
                    </div>
                :
                ''
            }
                <div className='table__wrapper'>
                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" width='25%'>Service Name</th>
                                <th scope="col" width='30%'>Service Type</th>
                                <th scope="col" width='15%'>Valid</th>
                                <th scope="col" width='20%'>Cost / Remaining</th>
                                <th scope="col" width='10%'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.apiStatus === false 
                                ?
                                <tr>
                                    <td>{this.state.apiStatusMessage}</td> 
                                    <td></td> 
                                    <td></td> 
                                    <td></td> 
                                    <td></td> 
                                </tr>
                                :
                                this.state.isLoaded === false ? (
                                    <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>
                                    ) : (              
                                        this.state.items.serviceList.map((data) => (
                                            <>
                                                <tr key={v4}>
                                                    <td className='flex_start_center'>
                                                        <div className='table_image_wrapper'>
                                                            <img className='brokenimg' alt='img' src={data.serviceCategoryImage} id='belliconid'></img>
                                                        </div>
                                                        <div className='ml-1'>
                                                            <span className='text-black'>{data.serviceCategoryName}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='flex_center_start dir_column'>
                                                            <span className='text-black'>{data.serviceName}</span>
                                                            <span className='text-gray'>{data.serviceCategoryName}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='flex_center_start dir_column'>
                                                            <span className='text-black'>{data.validDateString}</span>
                                                            <span className='text-gray'>{data.expire}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='flex_center_start dir_column'>
                                                            <span className='text-black'>{data.serviceCost === 0 ? 'Include In Membership' : '$'+data.serviceCost} (<span>{data.remainingCount}</span>)</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data.remainingCount === 0 ? <div className='btn_theme light ml-auto disable'>Redeem</div> : <div className='btn_theme light ml-auto' onClick={this.AddToCart} itemdealerShipID={data.dealerShipID} itemId={data.serviceCategoryId}>Redeem</div>}
                                                    </td>
                                                </tr>
                                                <tr className='table__row__seperator'></tr>
                                            </>
                                        )
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
};

export default Services;