import React, { Component } from 'react'
import AppContext from '../../AppContext';
import Slider from "react-slick";
import loader from '../../images/loader.gif';
import './Garage.css';
import TopNav from '../Snippets/top_nav_items';
import '../Snippets/carslider.css';
import { ToastContainer, toast } from 'react-toastify';
import GarageCarDetail from "../Maincomponents/Garage_car_detail";
import Progressbar from "./Progressbar"
import $ from "jquery";
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import Overlay from '../Snippets/overlay';
import { v4 } from 'uuid';
import axios from "axios";
import { async } from '@firebase/util';

async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
    //   toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}


export default class Garage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
      datadetail: [],
      isRighpanelOpen: false,
      api_token: localStorage.getItem("token"),
      carid: 0,
      garagecarcarddetaildialogisopen: false,
      garageopen: true,
      carModel: {},
      additemapiisloaded: false,
      checkBoxModelObject: [],
      checkBoxModelObjectPrice: [],
      checkBoxModelObjectisbene: [],
      checkBoxModelObjectisspecial: [],
      detailobject: {},
      isLoadedcardDetail: false,
      apiStatuscardDetail: false,
      apiStatusMessagecardDetail: "",
      carditemsDetail: [],
      radioButtonLoader: false,
      dealershiID: 0,
      boxID: 0,
      AddItemToCart: {},
      readyToGoCartModelStatus: false,
      checkedItems: new Map(),
      readyToGoCartModel: {},
      readyToGoCartModelpush: [],
      finalModel: {},
      isdeclinestate: 0,
      noticount: 0,
    };
    this.ClickMe = this.ClickMe;
    this.backfromcardetail = this.backfromcardetail;
    this.openDialog = this.openDialog;
    this.servicedetailapi = this.servicedetailapi;
    this.favoriteMe = this.favoriteMe;
    this.unfavoriteMe = this.unfavoriteMe;
    this.handleChangeCheckboxes = this.handleChangeCheckboxes.bind(this);
    this.AddToCart = this.AddToCart.bind(this);
    this.checkBoxModelObject = [];
    this.checkBoxModelObjectPrice = [];
    this.checkBoxModelObjectisbene = [];
    this.checkBoxModelObjectisspecial = [];
    this.readyToCart = [];
    this.readyToCartModel = [];
    this.readyToGoCartModel = {};
    this.AddToCartApiFunction = this.AddToCartApiFunction;
    this.functionReadtToAddToCart = this.functionReadtToAddToCart;
    this.EmptyCart = this.EmptyCart;
    // this.getNotiCount = this.getNotiCount;
  }

  favoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await markAsFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoadedcardDetail: false,
          radioButtonLoader: false,
          isRighpanelOpen: false,
        },
        () => {
          this.setState(
            {
              isRighpanelOpen: true,
              checkBoxModelObject: [],
            },
            () => {
              this.checkBoxModelObject = [];
              setTimeout(() => {
                this.servicedetailapi(this.state.carid, this.state.boxID);
              }, 1000);
            }
          );
        }
      );
    }
  };

  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoadedcardDetail: false,
          radioButtonLoader: false,
          isRighpanelOpen: false,
          checkBoxModelObject: [],
        },
        () => {
          this.setState(
            {
              isRighpanelOpen: true,
            },
            () => {
              this.checkBoxModelObject = [];
              setTimeout(() => {
                this.servicedetailapi(this.state.carid, this.state.boxID);
              }, 1000);
            }
          );
        }
      );
    }
  };

  componentWillUnmount() {
    // this.getNotiCount();
  }
  componentDidCatch() {}
  componentDidUpdate() {
    // this.getNotiCount();
  }

  // After Rendering
  componentDidMount() {
    let end_point = "Garage/UserCars";
    let api_url = this.context.Base_url + end_point;

    // Main CarInfo API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: false,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              data: result.carInfoDetailModels,
            });
          } else {
            if (result.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            }
            this.setState({
              isLoaded: false,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              data: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main CarInfo API End

    if (Object.keys(this.context.declineAddtoModel).length > 0) {
      this.context.setActive("Cart");
    }
    // // Check after Login
    // if (Object.keys(this.context.declineAddtoModel).length > 0) {
    //     let x = 0;
    //     if (x === 0) {
    //         this.AddToCartApiFunction(this.context.declineAddtoModel);
    //         setTimeout(() => {
    //           this.context.setActive("Cart");
    //         }, 1500);
    //         this.context.setdeclineAddtoModel([]);
    //         x = 1;
    //     }
    // }

    // // Check after Register
    // let chckdeclineisTrue = localStorage.getItem('isDeclined');
    // if (chckdeclineisTrue === "true") {
    //     let x = 0;
    //     if (x === 0) {
    //         this.context.setActive("Cart");
    //         this.context.setdeclineAddtoModel([]);
    //         localStorage.setItem('isDeclined', false);
    //         x = 1;
    //     }
    // }
    // else {
    //     localStorage.setItem('isDeclined', false);
    // }
  }

  servicedetailapi = (carid, id) => {
    let end_point_Shop_Services = "Shop/DealerShipServicesAndCategory";
    let url_parameter =
      "?" +
      "IsDetail" +
      "=" +
      true +
      "&" +
      "CarId" +
      "=" +
      carid +
      "&" +
      "ServiceCategoryId" +
      "=" +
      id +
      "&" +
      "ApplySearch" +
      "=" +
      false +
      "&" +
      "SearchString" +
      "=" +
      "Something";
    const url = this.context.Base_url + end_point_Shop_Services + url_parameter;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                isLoadedcardDetail: true,
                apiStatuscardDetail: result.status,
                apiStatusMessagecardDetail: result.message,
                carditemsDetail: result.serviceCategoryDetailModel,
                radioButtonLoader: true,
                carid: carid,
                dealershiID: result.serviceCategoryDetailModel.dealerShipId,
              },
              () => {}
            );
          } else {
            this.setState(
              {
                isLoadedcardDetail: true,
                apiStatuscardDetail: result.status,
                apiStatusMessagecardDetail: result.message,
                carditemsDetail: [],
                dealershiID: 0,
              },
              () => {}
            );
          }
        },
        (error) => {
          this.setState({
            isLoadedcardDetail: false,
            error,
          });
        }
      );
  };

  openDialog = (e) => {
    const servicename = e.currentTarget.getAttribute("servicename");
    const servicedesc = e.currentTarget.getAttribute("servicedescription");
    const serviceimage = e.currentTarget.getAttribute("serviceimg");
    const serviceid = e.currentTarget.getAttribute("serviceid");
    const carid = e.currentTarget.getAttribute("carid");

    this.servicedetailapi(carid, serviceid);

    this.setState({
      isModallOpen: true,
      carid: carid,
      boxID: serviceid,
      detailobject: {
        name: servicename,
        desc: servicedesc,
        img: serviceimage,
        id: serviceid,
      },
    });
    $(".garage").addClass("blur");
    $(".top_iconbar").addClass("blur");
    $(".Left__panel").addClass("blur");
  };

  handleChangeCheckboxes = (e) => {
    var item = e.target.value;
    var checkmodel = {
      CartItemModels: item,
    };
    var priceval = e.currentTarget.getAttribute("data");
    var isSpecial = e.currentTarget.getAttribute("isSpecial");
    let pr;
    let bene;
    let special;

    if (isSpecial == "true") {
      special = true;
    } else {
      special = false;
    }
    if (priceval == 0) {
      pr = 0;
      bene = true;
    } else {
      bene = false;
      pr = priceval;
    }

    if (this.state.checkBoxModelObject.includes(checkmodel.CartItemModels)) {
      let index = this.checkBoxModelObject.indexOf(checkmodel.CartItemModels);
      this.checkBoxModelObject.splice(index, 1);
    } else {
      this.checkBoxModelObject.push(checkmodel.CartItemModels);
    }
    this.checkBoxModelObjectPrice.push(pr);
    this.checkBoxModelObjectisbene.push(bene);
    this.checkBoxModelObjectisspecial.push(special);
    this.setState(
      {
        checkBoxModelObject: this.checkBoxModelObject,
        checkBoxModelObjectPrice: this.checkBoxModelObjectPrice,
        checkBoxModelObjectisbene: this.checkBoxModelObjectisbene,
        checkBoxModelObjectisspecial: this.checkBoxModelObjectisspecial,
      },
      () => {
        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
        } else {
          e.target.classList.add("active");
        }
        var val = {};
        var objeCt = [];
        for (let i = 0; i < this.state.checkBoxModelObject.length; i++) {
          val = {
            CartItemType: 0,
            ItemId: parseInt(this.state.checkBoxModelObject[i]),
            isBeneift: this.state.checkBoxModelObjectisbene[i],
            Price: parseFloat(this.state.checkBoxModelObjectPrice[i]),
            ItemTypeAddToCondition: this.state.checkBoxModelObjectisbene[i]
              ? 2
              : this.state.checkBoxModelObjectisspecial[i]
              ? 3
              : 1,
          };
          objeCt.push(val);
        }
        this.functionReadtToAddToCart(objeCt);
      }
    );
    e.preventDefault();
  };

  EmptyCart = (ReqData) => {
    this.setState({
      additemapiisloaded: true,
    });

    let end_point = "Cart/EmptyCartItems";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      // body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // debugger
          if (result.status) {
            this.AddToCartApiFunction(this.state.AddItemToCart);
          } else {
            
          }
        },
        (error) => {
          this.setState({
            additemapistatus: false,
            error,
          });
        }
      );
  };
  // Add To Cart API Function

  functionReadtToAddToCart = (model) => {
    let CarID = this.state.carid;
    let DealershipID = this.state.dealershiID;
    let CartItemModels = model;
    let PromoCode = "";
    let Notes = "";

    let data = {
      DealerShipId: DealershipID,
      CarId: parseInt(CarID),
      CartItemModels: CartItemModels,
      PromoCode: PromoCode,
      Notes: Notes,
    };
    this.setState({ AddItemToCart: data });
    return this.state.AddItemToCart;
  };

  // Add To Cart API Function
  AddToCartApiFunction = (ReqData) => {
    this.setState({
      additemapiisloaded: true,
    });

    let end_point = "Cart/AddItemToCart";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                additemapistatus: result.status,
                additemapimessage: result.message,
              },
              () => {
                this.checkBoxModelObjectPrice = [];
                this.checkBoxModelObjectisbene = [];
                this.context.setupdatecartcount(true);
                setTimeout(() => {
                  this.setState({ additemapiisloaded: false });
                }, 2000);
              }
            );
          } else {
            this.setState({
              additemapistatus: result.status,
              additemapimessage: result.message,
            });
          }
        },
        (error) => {
          this.setState({
            additemapistatus: false,
            error,
          });
        }
      );
  };
  // Add To Cart API Function

  // Add To cart
  AddToCart = (e) => {
    this.setState(
      {
        checkBoxModelObject: [],
        isLoadedcardDetail: true,
        additemapiisloaded: true,
        isModallOpen: false,
      },
      () => {
        this.checkBoxModelObject = [];
      }
    );
    // Add To Cart API Hit Function

    this.AddToCartApiFunction(this.state.AddItemToCart);

    $(".garage").removeClass("blur");
    $(".top_iconbar").removeClass("blur");
    $(".Left__panel").removeClass("blur");
  };

  render() {
    function getDate() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const DayNames = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      let date = new Date();
      var time =
        DayNames[date.getDay() - 1] +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getDate();

      return time;
    }
    function getTime() {
      let date = new Date();
      let hour = date.getHours();

      let NIGHT = 6;
      let NOON = 12;
      let SUNSET = 18;
      let MIDNIGHT = 24;

      var greetingText = "Hello"; // Default greeting text

      if (hour >= NIGHT && hour <= NOON) {
        greetingText = "Good Morning";
      } else if (hour >= NOON && hour <= SUNSET) {
        greetingText = "Good Afternoon";
      } else if (hour >= SUNSET && hour <= MIDNIGHT) {
        greetingText = "Good Evening";
      } else {
        greetingText = "Hello";
      }

      return greetingText;
    }

    const settings = {
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      lazyLoad: true,
      infinite: false,
      dot: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const ClickMe = (props) => {
      this.setState(
        {
          carid: props.currentTarget.getAttribute("car-id"),
          garagecarcarddetaildialogisopen: true,
          garageopen: false,
          carModel: {
            carimg: props.currentTarget.getAttribute("carimg"),
            carmakemodel: props.currentTarget.getAttribute("carmakemodel"),
            dealername: props.currentTarget.getAttribute("dealername"),
            dealarrid: props.currentTarget.getAttribute("dealarrid"),
          },
        },
        () => {}
      );
    };

    const backfromcardetail = (props) => {
      this.setState({
        garagecarcarddetaildialogisopen: false,
        garageopen: true,
      });
    };

    return (
      <>
        <div className="garage_frame_controller">
          {this.state.isLoaded ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : (
            ""
          )}
          <div className="top_iconbar">
            <span className="top_Left_column">
              {this.state.garagecarcarddetaildialogisopen && (
                <span className="backbutton" onClick={backfromcardetail}>
                  <i className="fa-solid fa-chevron-left"></i>
                </span>
              )}
              <h4 className="title_medium" id="top__title">
                {this.props.title}
              </h4>
            </span>
            <TopNav />
          </div>
          {this.state.garageopen && (
            <div className="garage">
              {/* <div className="garage__header">
                                    <div className='garage__header__content_left'>
                                        <h4 className='title_medium'>
                                            {getDate()}
                                        </h4>
                                        <h4 className='title_large'>
                                            <span>{getTime()} </span>
                                            {sessionStorage.getItem('usermodelfirstname')} {sessionStorage.getItem('usermodellastname')}
                                        </h4>
                                    </div>
                                </div> */}
              {
                <div className="garage__content" id="garageCarBox">
                  <Slider {...settings}>
                    {this.state.isLoaded ? (
                      <div className="loader-div">
                        <img alt="img" src={loader} id="belliconid"></img>
                      </div>
                    ) : (
                      this.state.data.map((car) => (
                        <div
                          className="garage__car__card garage__car__card_item"
                          key={car.id + v4}>
                          <div>
                            <div className="flex">
                              <strong> {car.dealerShipName}</strong>
                            </div>
                            <h4 className="title_large">
                              {car.make} {car.model}
                            </h4>
                          </div>
                          <div
                            className="garage__car__card_image_wrapper"
                            id="clickId"
                            onClick={ClickMe}
                            car-id={car.id}
                            carimg={car.images}
                            carmakemodel={car.make + " " + car.model}
                            dealername={car.dealerShipName}
                            dealarrid={car.dealerShipId}>
                            <img
                              className="brokenimg"
                              alt="img"
                              src={car.images}
                              id="belliconid"></img>
                          </div>

                          <div className="flex">
                            <h4 className="title_Medium">
                              <strong>Vehicle's Health</strong>
                            </h4>
                          </div>

                          <div className="circles_wrapper">
                            {car.carInfoModels.map((cardetail) => (
                              <div
                                className="circles_wrapper_items"
                                key={v4 + cardetail.serviceCategoryId}
                                onClick={this.openDialog}
                                servicename={cardetail.carInfoName}
                                servicedescription={cardetail.description}
                                serviceimg={cardetail.carSkeletonImage}
                                serviceid={cardetail.serviceCategoryId}
                                carid={car.id}>
                                <div className="circles_wrapper_items_img_wrapper">
                                  <img
                                    className="brokenimg"
                                    alt="img"
                                    src={cardetail.carInfoImage}
                                    id="belliconid"></img>
                                  <Progressbar
                                    val={
                                      cardetail.status < 2
                                        ? 10
                                        : cardetail.status
                                    }
                                    color={cardetail.color}
                                  />
                                </div>
                                <span>{cardetail.carInfoName}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    )}
                  </Slider>
                </div>
              }
            </div>
          )}
          {this.state.garagecarcarddetaildialogisopen && (
            <GarageCarDetail
              carId={this.state.carid}
              carmodel={this.state.carModel}
            />
          )}
          {this.state.isModallOpen && (
            <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
              <div
                className="closedetailsection"
                onClick={() => {
                  $(".garage").removeClass("blur");
                  $(".top_iconbar").removeClass("blur");
                  $(".Left__panel").removeClass("blur");
                  this.setState(
                    {
                      AddItemToCart: {},
                      isModallOpen: false,
                      checkBoxModelObject: [],
                    },
                    () => {
                      this.checkBoxModelObject = [];
                    }
                  );
                  // Add To Cart API Hit Function
                }}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              {
                <>
                  <div className="right-panel_image_wrapper">
                    <img
                      className="brokenimg"
                      alt="img"
                      src={this.state.detailobject.img}
                      id="belliconid"></img>
                  </div>
                  <div className="right-panel_title med-title mt-2">
                    <div>
                      <h3>{this.state.detailobject.name}</h3>
                    </div>
                  </div>
                  <div className="right-panel_title dir-col mt-1">
                    <h2>
                      <strong>Description</strong>
                    </h2>
                    <p>{this.state.detailobject.desc}</p>
                  </div>

                  {this.state.isLoadedcardDetail ? (
                    this.state.carditemsDetail.length != 0 ? (
                      <>
                        {this.state.carditemsDetail
                          .shopServiceCategoryServiceDetailModels !== null ? (
                          <div className="checkboxes">
                            {this.state.carditemsDetail.shopServiceCategoryServiceDetailModels.map(
                              (item) => (
                                <div className="flex-space-between-center">
                                  <label
                                    className="labelcon"
                                    key={item.dealerShipServiceName}>
                                    {item.dealerShipServiceIsCart ? (
                                      <i className="fa-solid fa-cart-shopping mr-1"></i>
                                    ) : (
                                      <input
                                        value={item.dealerShipServiceId}
                                        type="checkbox"
                                        aria-checked="false"
                                        data={
                                          item.isBenefit
                                            ? 0
                                            : item.dealerShipServiceIsSpecial
                                            ? item.dealerShipServiceSpecialPrice
                                            : item.dealerShipServiceOrignalPrice
                                        }
                                        isSpecial={
                                          item.dealerShipServiceIsSpecial
                                            ? "true"
                                            : "false"
                                        }
                                        onChange={this.handleChangeCheckboxes}
                                      />
                                    )}

                                    <span className="inputname">
                                      {item.dealerShipServiceName}
                                      {item.isBenefit && (
                                        <span className="tag3">
                                          <span>{item.isBenefitCount}x</span>
                                          <span>Included in the package</span>
                                        </span>
                                      )}
                                      {item.dealerShipServiceIsSpecial && (
                                        <span className="tag3">
                                          <span>Special</span>
                                        </span>
                                      )}
                                      {(() => {
                                        let span;
                                        if (
                                          item.isBenefit ||
                                          item.dealerShipServiceIsSpecial
                                        ) {
                                          span = (
                                            <span className="tag3">
                                              <span>
                                                Valid till {item.validTillDate}
                                              </span>
                                            </span>
                                          );
                                        }
                                        return span;
                                      })()}
                                    </span>
                                    <div className="flex dir-col alignstart">
                                      <div className="flex dir-col">
                                        <span
                                          className=""
                                          id="priceId"
                                          data={
                                            item.isBenefit
                                              ? 0
                                              : item.dealerShipServiceIsSpecial
                                              ? item.dealerShipServiceSpecialPrice
                                              : item.dealerShipServiceOrignalPrice
                                          }>
                                          {item.isBenefit === false && (
                                            <>
                                              <del>
                                                {item.dealerShipServiceIsSpecial && (
                                                  <>
                                                    $
                                                    {item.dealerShipServiceOrignalPrice.toLocaleString()}
                                                  </>
                                                )}
                                              </del>
                                              <strong className="sepra">
                                                {" "}
                                              </strong>
                                            </>
                                          )}
                                        </span>
                                        <span
                                          className=""
                                          id="priceId"
                                          data={
                                            item.isBenefit
                                              ? 0
                                              : item.dealerShipServiceIsSpecial
                                              ? item.dealerShipServiceSpecialPrice
                                              : item.dealerShipServiceOrignalPrice
                                          }>
                                          {item.isBenefit === false && (
                                            <>
                                              $
                                              {item.dealerShipServiceIsSpecial
                                                ? item.dealerShipServiceSpecialPrice.toLocaleString()
                                                : item.dealerShipServiceOrignalPrice.toLocaleString()}
                                              <strong> Price</strong>
                                              <strong className="sepra">
                                                {" "}
                                                |{" "}
                                              </strong>
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <span className="" id="PriceInPoints">
                                        {item.isBenefit === false && (
                                          <>
                                            {item.dealerShipServicePriceInPoints.toLocaleString()}
                                            <strong> Points</strong>
                                          </>
                                        )}
                                      </span>
                                    </div>

                                    <span className="check-bullet"></span>
                                  </label>
                                  <div className="heart_icon heart_icon_service">
                                    {(() => {
                                      switch (this.context.favoriteitemloader) {
                                        case item.dealerShipServiceId:
                                        // return <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>;
                                        default:
                                          return this.state
                                            .radioButtonLoader === false ? (
                                            <div className="loader-div">
                                              <img
                                                alt="img"
                                                src={loader}
                                                id="belliconid"></img>
                                            </div>
                                          ) : item.dealerShipServiceIsFavorites ? (
                                            <img
                                              alt="img"
                                              src={favIcon}
                                              id="belliconid"
                                              onClick={() => {
                                                this.unfavoriteMe({
                                                  Entity: 3,
                                                  tableId:
                                                    item.dealerShipServiceId,
                                                  CarId: this.state.carid,
                                                });
                                                this.context.setfavoriteitemloader(
                                                  item.dealerShipServiceId
                                                );
                                                this.context.setcarID(
                                                  this.state.carid
                                                );
                                              }}></img>
                                          ) : (
                                            <img
                                              alt="img"
                                              src={UnfavIcon}
                                              id="belliconid"
                                              onClick={() => {
                                                this.favoriteMe({
                                                  Entity: 3,
                                                  tableId:
                                                    item.dealerShipServiceId,
                                                  CarId: this.state.carid,
                                                });
                                                this.context.setfavoriteitemloader(
                                                  item.dealerShipServiceId
                                                );
                                                this.context.setcarID(
                                                  this.state.carid
                                                );
                                              }}></img>
                                          );
                                      }
                                    })()}
                                  </div>
                                  {item.dealerShipServiceIsCart ? (
                                    <span className="aic">Already in Cart</span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {(() => {
                          let ele;
                          if (this.state.checkBoxModelObject.length != 0) {
                            ele = (
                              <div
                                className="btn_theme fullwidth"
                                onClick={this.AddToCart}
                                serviceid={this.state.detailobject.id}>
                                Schedule Service
                              </div>
                            );
                          } else {
                            ele = "";
                          }
                          return ele;
                        })()}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  )}
                </>
              }
            </div>
          )}
          {this.state.additemapiisloaded
            ? this.state.additemapistatus && (
                <div className="loader-div succesmodal">
                  <Overlay
                    title="additem"
                    message={this.state.additemapimessage}
                  />
                </div>
              )
            : ""}
          {this.state.additemapiisloaded
            ? this.state.additemapistatus === false && (
                <div className="loader-div succesmodal">
                  <h3 className="title_medium"></h3>
                  <div className="confirm_dialog">
                    <h4>{this.state.additemapimessage}</h4>

                    <div className="flex">
                      {this.state.additemapimessage ===
                      "Item Already Exist in the Cart." ? (
                        <div
                          className={`btn_theme small`}
                          onClick={() => {
                            this.setState({
                              additemapiisloaded: false,
                            });
                          }}>
                          Okay
                        </div>
                      ) : (
                        <>
                          <div
                            className={`btn_theme small light`}
                            onClick={() => {
                              this.EmptyCart(this.state.AddItemToCart);
                            }}>
                            Empty Cart
                          </div>
                          <div
                            className={`btn_theme small`}
                            onClick={() => {
                              this.setState({
                                additemapiisloaded: false,
                              });
                            }}>
                            Cancel
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="confirm_dialog_layer"></div>
                </div>
              )
            : ""}
        </div>
      </>
    );
  }
}
