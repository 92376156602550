import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import favIcon from '../../images/heartico_colored.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';
import axios from "axios";


async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
        toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}
class FavoriteNewVehicles extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      isLoaded: false,
      apiStatus: false,
      apiStatusMessage: "",
      items: [],
      currentPage: 1,
      favloader: false,
      api_token: localStorage.getItem("token"),
    };
    this.itemsPerPage = 50;
    // this.apiUrl = this.context.Base_url + "Favorite/UserFavourites";
    // this.DataRendering = this.DataRendering;
    this.unfavoriteMe = this.unfavoriteMe;
    this.handleLoadMore = this.handleLoadMore;
  }

  

  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoaded: true,
          apiStatus: false,
          items: [],
        },
        // this.DataRendering(),
        // this.fetchPageData(),
        () => {
          // this.DataRendering();
          this.fetchPageData();
        }
      );
    }
  };

  // After Rendering
  componentDidMount() {
    // Data API
    this.fetchPageData();
    // this.DataRendering();
  }

  fetchData = async (page) => {
    try {
      const response = await fetch(
        `${this.context.Base_url}Favorite/UserFavourites?PageNumber=${page}&PageSize=${this.itemsPerPage}&timeSpan=0&favouriteSystemEntity=1`,
        {
          headers: { Authorization: this.state.api_token },
        }
      );
      if (!response.ok) {
        if (response.status == '403') {
          localStorage.setItem("token", "");
        } 
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data; // Assuming your API response is an object with a "data" property that holds the array of items.
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  fetchPageData = async () => {
    this.setState({
      isLoaded: false,
    });
    const { currentPage, items } = this.state;
    const pageData = await this.fetchData(currentPage);
    if (pageData.status) {
      this.setState({
        isLoaded: true,
        apiStatus: pageData.status,
        apiStatusMessage: pageData.message,
        items: [pageData.allFavoriteModel.favouriteCarModelsNewVehicle],
      });
    } else {
      this.setState({
        isLoaded: false,
        apiStatus: pageData.status,
        apiStatusMessage: pageData.message,
        items: [],
      });
    }
      
  };

  handleLoadMore = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
      }),
      this.fetchPageData
    );
  };

  // const fetchData = async (page) => {
  //   try {
  //     const response = await axios.get(base_url+endpoint+'?',
  //       {
  //         params: {
  //           New: newstate,
  //           Used: usedstate,
  //           CarShopType: CarShopType,
  //           Year: "",
  //           Make: "",
  //           Model: "",
  //           LowPrice: 0,
  //           HighPrice: 0,
  //           MonthlyLowPrice: 0,
  //           MonthlyHighPrice: 0,
  //           SearchString: "",
  //           PageIndex: page,
  //           PageSize: 32,
  //         },
  //         headers: { Authorization: api_token },
  //       }
  //     );
  //     return response.data; // Assuming your API response is an object with a "data" property that holds the array of items.
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     return [];
  //   }
  // };

  // const fetchPageData = async () => {
  //   const pageData = await fetchData(currentPage);
  //   setData(pageData.getAllCategorizedList);
  // };

  // Data API Start
  DataRendering = () => {
    this.setState({
      isLoaded: false,
    });
    let endpoint =
      "Favorite/UserFavourites?PageNumber=1&PageSize=50&timeSpan=0&favouriteSystemEntity=1";
    const url = this.context.Base_url + endpoint;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.allFavoriteModel.favouriteCarModelsNewVehicle,
            });
          } else {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: true,
            errorMessage: "There is an error",
          });
        }
      );
  };
  // Data API End

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
    // this.setState({
    //   isLoaded: false,
    //   items: [],
    // });
  }

  render() {
    const data  = this.state;

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });


    return (
      <div className="view_more_screen_frame_controller">
        <ToastContainer hideProgressBar={true} />
        <div className="flex">
          {this.state.isLoaded === false ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : data.items[0] === null ? (
            this.state.error ? (
              <h3>{this.state.errorMessage}</h3>
            ) : (
              <div className="emptywndow emptywndowfav">
                <i className="fa-solid fa-heart"></i>
                <h3 className="mb-0">No Favorite Found</h3>
                <p className="mt-0">
                  New Vehicles that you mark as favorite are show here.
                </p>
              </div>
            )
          ) : (
            <div className="view_more_items-wrapper">
              {this.state.isLoaded === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : data.items[0] != null ? (
                data.items[0].map((user, i) => (
                  <div className="relative" key={v4}>
                    <div className="heart_icon">
                      <img
                        alt="img"
                        src={favIcon}
                        id="belliconid"
                        onClick={() => {
                          this.unfavoriteMe({
                            Entity: 1,
                            tableId: user.id,
                            CarId: 0,
                          });
                          this.context.setfavoriteitemloader(true);
                          this.context.setsinglecardetailsectionisopen(false);
                          this.context.setcarID(user.id);
                        }}></img>
                    </div>
                    <div
                      className="shop__row__slider__items view-more-cards relative"
                      onClick={() => {
                        this.context.setsinglecardetailsectionisopen(true);
                        this.context.setcarID(user.id);
                      }}
                      key={user.id}>
                      <div className="shop__row__slider__items__image_wrapper">
                        <img alt="img" src={user.images} id="belliconid"></img>
                      </div>
                      <div className="shop__row__slider__items__content_wrapper">
                        <div className="flex-space-between-start">
                          <span className="cartitle">
                            {user.make} {user.model}
                          </span>
                          <span className="carprice">
                            {formatter.format(user.sellingPrice)}
                          </span>
                        </div>
                        <div className="flex-space-between-start">
                          <span className="carmodel">{user.year}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="emptywndow emptywndowfav">
                  <i className="fa-solid fa-heart"></i>
                  <h3 className="mb-0">No Favorite Found</h3>
                  <p className="mt-0">
                    New Vehicles that you mark as favorite are show here.
                  </p>
                </div>
              )}
              {/* <div className="Loadmorebtn">
                {(() => {
                  let button;
                  if (data.items[0] != null) {
                    if (data.items[0].length > 50) {
                      button = (
                        <button
                          onClick={this.handleLoadMore}
                          className="btn_theme">
                          Load More
                        </button>
                      );
                    } else {
                      button = "";
                    }
                  } else {
                    button = (
                      <button
                        onClick={() => {
                          this.setState(
                            (prevState) => ({
                              currentPage: 1,
                            }),
                            this.fetchPageData
                          );
                        }}
                        className="btn_theme">
                        Reload
                      </button>
                    );
                  }
                  return button;
                })()}
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default FavoriteNewVehicles ;
