import React, { Component } from 'react';
import TopNav from '../Snippets/top_nav_items';
import AppContext from '../../AppContext';
import loader from '../../images/loader.gif';
import 'react-toastify/dist/ReactToastify.css';
import { RadioGroup, RadioButton } from 'react-radio-buttons';


class Rewards extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error: null,
      answerModelListSave: [],
      answerModelListItemValue: "",
      isLoaded: false,
      QAResponseAPIIsLoaded: false,
      items: [],
      rewardPoints: 0,
      apistatus: false,
      apiStatusMessage: "",
      apiResponseitemList: [{}],
      AnswerModel: [],
      questionCount: 0,
      apiResponseicount: 0,
      api_token: localStorage.getItem("token"),
      questionAnsScreenIsOpen: this.props.addcarquestion ? true : false,
      continueBtnState: false,
      QAResponseMessage: "",
      apihitloaderstate: false,
      PreviousBtnState: false,
      thisQuestionSkipStatus: false,
      AnswerModelReadyToGo: {},
      setLength: 0,
      selected: "",
      carId: null,
      validationText: "",
    };

    this.GetRewardsPoint = this.GetRewardsPoint;
    this.EarnQuestion = this.EarnQuestion;
    this.CloseQuestion = this.CloseQuestion;
    this.GetQuestionAPI = this.GetQuestionAPI;
    this.GetQuestionAPIForCar = this.GetQuestionAPIForCar;
    this.QuesTionContinue = this.QuesTionContinue;
    this.Previous = this.Previous;
    this.OnChangeHandle = this.OnChangeHandle;
    this.SkipQuestion = this.SkipQuestion;
    this.GaragePostAnswersAPI = this.GaragePostAnswersAPI;
    this.AnswerModels = [];
    this.AnswerModelReadyToGo = {};
    this.onChange = this.onChange.bind(this);
    this.AnswerModelListSaveObject = [];
  }

  // Data API UserInfo/UserRewards Start
  GetRewardsPoint = () => {
    let end_point = "UserInfo/SetProfileSetting";
    const url = this.context.Base_url + end_point;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          debugger
          if (result.status) {
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              rewardPoints: result.userInfoSettingModel.rewardPoints,
            });
          } else {
            if (result.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            }
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              rewardPoints: 0,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
  };
  // Data API UserInfo/UserRewards End

  // Data API GaragePostAnswers Start
  // GaragePostAnswersAPI = ( valfrommodel ) => {
  //     let end_point = 'Garage/GaragePostAnswers';
  //     const url = this.context.Base_url + end_point;
  //     var Model = this.state.AnswerModelReadyToGo;
  //     // console.log('From AnswerModelReadyToGo ' + valfrommodel);
  //     // console.log('From GaragePostAnswersAPI ' + valfrommodel);

  //     // if(this.state.apihitloaderstate === false) {
  //     // }

  //     // fetch(url,{
  //     //     method: 'POST',
  //     //     headers: {
  //     //         'Content-Type': 'application/json',
  //     //         'Authorization' : this.state.api_token
  //     //     },
  //     //     body: JSON.stringify(Model)
  //     // })
  //     // .then(res => res.json())
  //     // .then(
  //     //     (result) => {
  //     //         if(result.status) {
  //     //             this.setState({
  //     //                 isLoaded: true,
  //     //                 apistatus: result.status,
  //     //                 apiStatusMessage: result.message,
  //     //                 rewardPoints: result.rewardPoints,
  //     //             });
  //     //         } else {
  //     //             this.setState({
  //     //                 isLoaded: true,
  //     //                 apistatus: result.status,
  //     //                 apiStatusMessage: result.message,
  //     //                 rewardPoints: 0
  //     //             });
  //     //         }
  //     //     },
  //     //     (error) => {
  //     //         this.setState({
  //     //             isLoaded: false,
  //     //             error
  //     //         });
  //     //     }

  //     // )

  // }
  // Data API GaragePostAnswers End

  // After Rendering
  componentDidMount() {
    // Data API
    if (this.props.addcarquestion) {
      this.GetRewardsPoint();
      this.GetQuestionAPIForCar();
    } else {
      this.GetRewardsPoint();
      this.GetQuestionAPI();
    }
  }

  GetQuestionAPI = () => {
    let end_point = "Garage/GarageQuestions";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
          });
          if (result.status) {
            this.setState({
              apiStatus: result.status,
              apiStatusMessage: result.message,
              apiResponseicount: result.responseCount,
              apiResponseitemList: result.questionModels,
            });
          } else {
            this.setState({
              apiStatus: result.status,
              apiStatusMessage: result.message,
              apiResponseicount: 0,
              apiResponseitemList: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
  };

  GetQuestionAPIForCar = () => {
    const body_user = {
      carId: this.props.newcarid,
    };
    let end_point = "Garage/AddNewCarQuestion";
    const url = this.context.Base_url + end_point;
    console.log(body_user);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(body_user),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
          });
          if (result.status) {
            this.setState({
              apiStatus: result.status,
              apiStatusMessage: result.message,
              apiResponseicount: result.responseCount,
              apiResponseitemList: result.questionModels,
            });
          } else {
            this.setState({
              apiStatus: result.status,
              apiStatusMessage: result.message,
              apiResponseicount: 0,
              apiResponseitemList: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );

    // return fetch(this.context.Base_url + "Garage/AddNewCarQuestion", {
    //   method: "POST",
    //   headers: {
    //     Authorization: addcarModel.api_token,
    //     Accept: "application/json, text/plain",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify(body_user),
    // })
    //   .then((response) => response.json())
    //   .then((json) => json);
  };

  EarnQuestion = (e) => {
    this.setState({
      questionAnsScreenIsOpen: true,
    });
    this.GetQuestionAPI();
  };

  CloseQuestion = () => {
    this.setState({
      questionAnsScreenIsOpen: false,
      isLoaded: false,
    });
  };

  QuesTionContinue = (e) => {
    var currntAnswer = this.state.answerModelListItemValue;
    var currntMCQs = this.state.selected;

    if (this.state.questionCount < this.state.apiResponseicount - 1) {
      this.setState({
        questionCount: this.state.questionCount + 1,
      });
    }
    if (this.state.questionCount < 1) {
      this.setState({
        PreviousBtnState: true,
      });
    }
    const QuestionID = e.currentTarget.getAttribute("qid");
    const QcarID = e.currentTarget.getAttribute("QcarId");
    const QuestionPoints = e.currentTarget.getAttribute("questionpoints");
    const UIControlTypeID = e.currentTarget.getAttribute("uicontroltypeid");
    const QuestionDataType = e.currentTarget.getAttribute("questiondatatype");
    const textareaElementvalue = document.querySelector("#AnswerText");

    if (UIControlTypeID == 1) {
      // Written
      var AnswerModel2;
      if (QuestionDataType == 1) {
        // Integer
        if (!this.props.addcarquestion) {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answerId: 0,
            carId: parseInt(QcarID),
            points: parseInt(QuestionPoints),
            isSkip: false,
            answerValue: textareaElementvalue.value,
          };
        } else {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answer: textareaElementvalue.value,
          };
        }
      } else if (QuestionDataType == 3) {
        // Date
        if (!this.props.addcarquestion) {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answerId: 0,
            carId: parseInt(QcarID),
            points: parseInt(QuestionPoints),
            isSkip: false,
            answerValue: textareaElementvalue.value,
          };
        } else {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answer: textareaElementvalue.value,
          };
        }
      } else {
        // String
        if (!this.props.addcarquestion) {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answerId: 0,
            carId: parseInt(QcarID),
            points: parseInt(QuestionPoints),
            isSkip: false,
            answerValue: textareaElementvalue.value,
          };
        } else {
          AnswerModel2 = {
            questionId: parseInt(QuestionID),
            answer: textareaElementvalue.value,
          };
        }
      }
    } else {
      // MCQS

      if (!this.props.addcarquestion) {
        AnswerModel2 = {
          questionId: parseInt(QuestionID),
          answerId: this.state.selected == "" ? 0 : this.state.selected,
          points: parseInt(QuestionPoints),
          carId: parseInt(QcarID),
          isSkip: false,
          answerValue: "",
        };
      } else {
        AnswerModel2 = {
          questionId: parseInt(QuestionID),
          answer: this.state.selected == "" ? "0" : `${this.state.selected}`,
        };
      }

      // console.log(AnswerModel2);
    }

    let textareaElementvalues;

    // console.log("Answer Length after Set " + this.AnswerModelListSaveObject.length);
    this.setState({
      setLength: this.AnswerModelListSaveObject.length,
    });

    if (UIControlTypeID == 1) {
      textareaElementvalue.value = "";
    }
    this.setState({
      continueBtnState: false,
    });

    if (this.AnswerModelListSaveObject.length >= this.state.questionCount + 1) {
      this.AnswerModelListSaveObject[this.state.questionCount].value =
        this.state.apiResponseitemList[this.state.questionCount]
          .uiControlType == 1
          ? currntAnswer
          : currntMCQs;

      var extractval;
      extractval =
        this.AnswerModelListSaveObject[this.state.questionCount + 1].value;
      if (
        this.state.apiResponseitemList[this.state.questionCount + 1]
          .questionDataType == 1
      ) {
        setTimeout(() => {
          const textareaElementValue = document.getElementById("AnswerText");
          textareaElementValue.value = extractval;
        }, 300); // 1000 milliseconds (1 seconds)

        this.setState({
          continueBtnState: true,
        });
      } else if (
        this.state.apiResponseitemList[this.state.questionCount + 1]
          .questionDataType == 3
      ) {
        setTimeout(() => {
          const textareaElementValue = document.getElementById("AnswerText");
          textareaElementValue.value = extractval;
        }, 200); // 200 milliseconds

        this.setState({
          continueBtnState: true,
        });
      } else {
        if (
          this.state.apiResponseitemList[this.state.questionCount + 1]
            .uiControlType == 1
        ) {
          setTimeout(() => {
            const textareaElementValue = document.getElementById("AnswerText");
            textareaElementValue.value = extractval;
          }, 200); // 200 milliseconds
        } else {
          this.setState({
            selected:
              this.AnswerModelListSaveObject[this.state.questionCount + 1]
                .value,
          });
        }
        this.setState({
          continueBtnState: true,
        });
      }
    } else {
      if (this.state.questionCount === this.AnswerModelListSaveObject.length) {
        this.AnswerModels.push(AnswerModel2);
      }
      for (let i = 0; i < this.AnswerModels.length; i++) {
        if (!this.props.addcarquestion) {
          var val = {
            value:
              UIControlTypeID == 1
                ? this.AnswerModels[i].answerValue
                : this.AnswerModels[i].answerId,
            index: i,
          };
        } else {
          var val = {
            value: this.AnswerModels[i].answer,
            index: i,
          };
        }
      }
      this.AnswerModelListSaveObject.push(val);
    }
    if (this.state.questionCount + 1 === this.state.apiResponseicount) {
      if (!this.props.addcarquestion) {
        this.AnswerModelReadyToGo = { questionAnswers: this.AnswerModels };
        this.setState(
          {
            apihitloaderstate: true,
            AnswerModelReadyToGo: { questionAnswers: this.AnswerModels },
          },
          () => {
            // Garage/GaragePostAnswers API
            let end_point = "Garage/GaragePostAnswers";
            const url = this.context.Base_url + end_point;
            const Model = this.state.AnswerModelReadyToGo;
            debugger;
            if (this.state.apihitloaderstate) {
              fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.state.api_token,
                },
                body: JSON.stringify(Model),
              })
                .then((res) => res.json())
                .then(
                  (result) => {
                    if (result.status) {
                      this.setState(
                        {
                          QAResponseAPIIsLoaded: true,
                          QAResponseMessage: result.points,
                          apihitloaderstate: false,
                        },
                        () => {
                          const interval = setTimeout(() => {
                            this.setState(
                              {
                                QAResponseAPIIsLoaded: false,
                                questionAnsScreenIsOpen: false,
                                apihitloaderstate: false,
                                isLoaded: false,
                                apistatus: false,
                                apiResponseitemList: [],
                                PreviousBtnState: false,
                                questionCount: 0,
                              },
                              () => {
                                this.GetRewardsPoint();
                                this.GetQuestionAPI();
                              }
                            );
                          }, 2000);
                          return () => clearInterval(interval);
                        }
                      );
                    } else {
                      this.setState({
                        QAResponseAPIIsLoaded: true,
                        QAResponseMessage: result.points,
                        apihitloaderstate: false,
                      });
                    }
                  },
                  (error) => {
                    this.setState({
                      QAResponseAPIIsLoaded: false,
                      error,
                    });
                  }
                );
            } else {
              console.log("Model");
            }
          }
        );
      } else {
        this.AnswerModelReadyToGo = {
          carId: this.props.carId,
          qaClassList: this.AnswerModels,
        };

        this.setState(
          {
            apihitloaderstate: true,
            AnswerModelReadyToGo: {
              carId: this.props.newcarid,
              qaClassList: this.AnswerModels,
            },
          },
          () => {
            // Garage/GaragePostAnswers API
            let end_point = "Garage/AddNewCarAnswer";
            const url = this.context.Base_url + end_point;
            const Model = this.state.AnswerModelReadyToGo;
            if (this.state.apihitloaderstate) {
              fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.state.api_token,
                },
                body: JSON.stringify(Model),
              })
                .then((res) => res.json())
                .then(
                  (result) => {
                    if (result.status) {
                      this.setState(
                        {
                          QAResponseAPIIsLoaded: true,
                          QAResponseMessage: result.points,
                          apihitloaderstate: false,
                        },
                        () => {
                          const interval = setTimeout(() => {
                            this.setState(
                              {
                                QAResponseAPIIsLoaded: false,
                                questionAnsScreenIsOpen: false,
                                apihitloaderstate: false,
                                isLoaded: false,
                                apistatus: false,
                                apiResponseitemList: [],
                                PreviousBtnState: false,
                                questionCount: 0,
                              },
                              () => {
                                this.GetRewardsPoint();
                                this.GetQuestionAPI();
                              }
                            );
                          }, 2000);
                          return () => clearInterval(interval);
                        }
                      );
                    } else {
                      this.setState({
                        QAResponseAPIIsLoaded: true,
                        QAResponseMessage: result.points,
                        apihitloaderstate: false,
                      });
                    }
                  },
                  (error) => {
                    this.setState({
                      QAResponseAPIIsLoaded: false,
                      error,
                    });
                  }
                );
            } else {
              console.log("Model");
            }
          }
        );
      }
    }
  };

  SkipQuestion = (e) => {
    var currntAnswer = this.state.answerModelListItemValue;
    var currntMCQs = this.state.selected;

    if (this.state.questionCount < this.state.apiResponseicount - 1) {
      this.setState({
        questionCount: this.state.questionCount + 1,
      });
    }
    if (this.state.questionCount < 1) {
      this.setState({
        PreviousBtnState: true,
      });
    }

    const QuestionID = e.currentTarget.getAttribute("qid");
    const QcarID = e.currentTarget.getAttribute("QcarId");
    const QuestionPoints = e.currentTarget.getAttribute("questionpoints");
    const UIControlTypeID = e.currentTarget.getAttribute("uicontroltypeid");
    const QuestionDataType = e.currentTarget.getAttribute("questiondatatype");
    const textareaElementvalue = document.querySelector("#AnswerText");

    if (UIControlTypeID == 1) {
      // Written
      var AnswerModel2;
      if (QuestionDataType == 1) {
        // Integer
        AnswerModel2 = {
          questionId: parseInt(QuestionID),
          answerId: 0,
          carId: parseInt(QcarID),
          points: parseInt(QuestionPoints),
          isSkip: true,
          answerValue: "",
        };
      } else {
        // String
        AnswerModel2 = {
          questionId: parseInt(QuestionID),
          answerId: 0,
          carId: parseInt(QcarID),
          points: parseInt(QuestionPoints),
          isSkip: true,
          answerValue: "",
        };
      }
    } else {
      // MCQS
      AnswerModel2 = {
        questionId: parseInt(QuestionID),
        answerId: this.state.selected == "" ? 0 : this.state.selected,
        carId: parseInt(QcarID),
        points: parseInt(QuestionPoints),
        isSkip: true,
        answerValue: "",
      };
    }

    let textareaElementvalues;

    // console.log("Answer Length after Set " + this.AnswerModelListSaveObject.length);
    this.setState({
      setLength: this.AnswerModelListSaveObject.length,
    });

    if (UIControlTypeID == 1) {
      textareaElementvalue.value = "";
    }
    this.setState({
      continueBtnState: false,
    });

    if (this.AnswerModelListSaveObject.length >= this.state.questionCount + 1) {
      this.AnswerModelListSaveObject[this.state.questionCount].value =
        this.state.apiResponseitemList[this.state.questionCount]
          .uiControlType == 1
          ? currntAnswer
          : currntMCQs;

      var extractval;
      extractval =
        this.AnswerModelListSaveObject[this.state.questionCount + 1].value;
      if (
        this.state.apiResponseitemList[this.state.questionCount + 1]
          .questionDataType == 1
      ) {
        setTimeout(() => {
          const textareaElementValue = document.getElementById("AnswerText");
          textareaElementValue.value = extractval;
        }, 300); // 1000 milliseconds (1 seconds)

        this.setState({
          continueBtnState: true,
        });
      } else if (
        this.state.apiResponseitemList[this.state.questionCount + 1]
          .questionDataType == 3
      ) {
        setTimeout(() => {
          const textareaElementValue = document.getElementById("AnswerText");
          textareaElementValue.value = extractval;
        }, 200); // 200 milliseconds

        this.setState({
          continueBtnState: true,
        });
      } else {
        if (
          this.state.apiResponseitemList[this.state.questionCount + 1]
            .uiControlType == 1
        ) {
          setTimeout(() => {
            const textareaElementValue = document.getElementById("AnswerText");
            textareaElementValue.value = extractval;
          }, 200); // 200 milliseconds
        } else {
          this.setState({
            selected:
              this.AnswerModelListSaveObject[this.state.questionCount + 1]
                .value,
          });
        }
        this.setState({
          continueBtnState: true,
        });
      }
    } else {
      if (this.state.questionCount === this.AnswerModelListSaveObject.length) {
        this.AnswerModels.push(AnswerModel2);
      }
      for (let i = 0; i < this.AnswerModels.length; i++) {
        var val = {
          value:
            UIControlTypeID == 1
              ? this.AnswerModels[i].answerValue
              : this.AnswerModels[i].answerId,
          index: i,
        };
      }
      this.AnswerModelListSaveObject.push(val);
    }
    if (this.state.questionCount + 1 === this.state.apiResponseicount) {
      this.AnswerModelReadyToGo = { questionAnswers: this.AnswerModels };
      this.setState(
        {
          apihitloaderstate: true,
          AnswerModelReadyToGo: { questionAnswers: this.AnswerModels },
        },
        () => {
          if (this.state.apihitloaderstate) {
            let end_point = "Garage/GaragePostAnswers";
            const url = this.context.Base_url + end_point;
            const Model = this.state.AnswerModelReadyToGo;
            console.log(Model);
            debugger;
            fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: this.state.api_token,
              },
              body: JSON.stringify(Model),
            })
              .then((res) => res.json())
              .then(
                (result) => {
                  console.log(result);
                  if (result.status) {
                    this.setState(
                      {
                        QAResponseAPIIsLoaded: true,
                        QAResponseMessage: result.points,
                        apihitloaderstate: false,
                      },
                      () => {
                        const interval = setTimeout(() => {
                          this.setState(
                            {
                              QAResponseAPIIsLoaded: false,
                              questionAnsScreenIsOpen: false,
                              apihitloaderstate: false,
                              isLoaded: false,
                              apistatus: false,
                              apiResponseitemList: [],
                              PreviousBtnState: false,
                              questionCount: 0,
                            },
                            () => {
                              this.GetRewardsPoint();
                              this.GetQuestionAPI();
                            }
                          );
                        }, 2000);
                        return () => clearInterval(interval);
                      }
                    );
                  } else {
                    this.setState({
                      QAResponseAPIIsLoaded: true,
                      QAResponseMessage: result.points,
                      apihitloaderstate: false,
                    });
                  }
                },
                (error) => {
                  this.setState({
                    QAResponseAPIIsLoaded: false,
                    error,
                  });
                }
              );
          } else {
            console.log("Model");
          }
        }
      );
    }
  };

  Previous = (event) => {
    var extractval;
    extractval =
      this.AnswerModelListSaveObject[this.state.questionCount - 1].value;
    var textareaElementvalue;
    if (
      this.state.apiResponseitemList[this.state.questionCount - 1]
        .questionDataType == 1
    ) {
      setTimeout(() => {
        const textareaElementValue = document.getElementById("AnswerText");
        textareaElementValue.value = extractval;
      }, 200); // 200 milliseconds

      this.setState({
        continueBtnState: true,
      });
    } else if (
      this.state.apiResponseitemList[this.state.questionCount - 1]
        .questionDataType == 3
    ) {
      setTimeout(() => {
        const textareaElementValue = document.getElementById("AnswerText");
        textareaElementValue.value = extractval;
      }, 200); // 200 milliseconds

      this.setState({
        continueBtnState: true,
      });
    } else {
      if (
        this.state.apiResponseitemList[this.state.questionCount - 1]
          .uiControlType == 1
      ) {
        setTimeout(() => {
          const textareaElementValue = document.getElementById("AnswerText");
          textareaElementValue.value = extractval;
        }, 200); // 200 milliseconds
      } else {
        this.setState({
          selected:
            this.AnswerModelListSaveObject[this.state.questionCount - 1].value,
        });
      }
      this.setState({
        continueBtnState: true,
      });
    }

    if (this.state.questionCount >= 1) {
      this.setState({
        questionCount: this.state.questionCount - 1,
      });
    }
    if (this.state.questionCount == 1) {
      this.setState({
        PreviousBtnState: false,
      });
    }
    // this.AnswerModels.pop();
    // this.AnswerModelListSaveObject.pop();
  };

  OnChangeHandle = (event) => {
    const crrntMillage = event.currentTarget.getAttribute("CurrntMillage");
    const isRequired = event.currentTarget.getAttribute("isRequiredd");

    let crrntmillage = parseInt(crrntMillage);
    let crrntmillageCheckIsRequired = isRequired;

    var textareaElementvalue = document.querySelector("#AnswerText");
    textareaElementvalue.value = textareaElementvalue.value.replace(
      /\s\s+/g,
      ""
    );

    if (crrntmillageCheckIsRequired == "true") {
      let valuu = parseInt(textareaElementvalue.value);
      if (valuu > crrntmillage) {
        if (textareaElementvalue.value.match(/[A-Za-z0-9]/i)) {
          if (textareaElementvalue.value.length < 1) {
            this.setState({
              continueBtnState: false,
            });
          } else {
            this.setState({
              continueBtnState: true,
            });
          }
        } else {
          textareaElementvalue.value = "";
          this.setState({
            continueBtnState: false,
          });
        }
        this.setState({
          answerModelListItemValue: textareaElementvalue.value,
          validationText: "",
        });
      } else {
        this.setState({
          continueBtnState: false,
          validationText:
            "In the last attempt, you have " +
            crrntmillage +
            " mileage against this vehicle. Please enter mileage greater than the previous mileage",
        });
      }
    } else {
      if (textareaElementvalue.value.match(/[A-Za-z0-9]/i)) {
        if (textareaElementvalue.value.length < 1) {
          this.setState({
            continueBtnState: false,
          });
        } else {
          this.setState({
            continueBtnState: true,
          });
        }
      } else {
        textareaElementvalue.value = "";
        this.setState({
          continueBtnState: false,
        });
      }
      this.setState({
        answerModelListItemValue: textareaElementvalue.value,
        validationText: "",
      });
    }
  };

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
    // this.GaragePostAnswersAPI();
  }


  onChange = (e) => {
    this.setState(
      {
        selected: e,
        continueBtnState: true,
      },
      () => {
        // console.log(this.state.selected);
      }
    );
  };

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    return (
      <>
        {this.state.apihitloaderstate && (
          <div className="loader-div z-index-6">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        )}
        {this.state.QAResponseAPIIsLoaded && (
          <div className="loader-div z-index-6">
            <h4 className="title_medium">
              Congratulations.! You have Earn {this.state.QAResponseMessage}{" "}
              Points
            </h4>
          </div>
        )}
        {!this.props.addcarquestion && (
          <div className="top_iconbar">
            <span className="top_Left_column">
              <h4
                className={`title_medium ${
                  this.state.questionAnsScreenIsOpen ? "spacetext" : ""
                }`}>
                {this.props.title}
              </h4>
            </span>
            <TopNav />
          </div>
        )}

        {this.state.questionAnsScreenIsOpen ? (
          <div className="Garage_Question_Screen_wrapper">
            {!this.props.addcarquestion && (
              <div className="closedetailsection" onClick={this.CloseQuestion}>
                <i className="fa-solid fa-chevron-left"></i>
              </div>
            )}

            <div className="Question_result flex-center-center">
              {/* {
                    console.log(this.state.apiResponseitemList)
                  } */}
              {this.state.isLoaded === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : this.state.apistatus ? (
                this.state.apiResponseitemList.length === 0 ? (
                  <h3>No Questions are found</h3>
                ) : // <h3>zshaikh. {this.state.apiStatusMessage}</h3>
                this.state.apiResponseitemList.length === 0 ? (
                  <h3>No Questions are found</h3>
                ) : (
                  <div className="questionAnswerWrapper">
                    {/* <span className={`btn_theme light small m-0 ${this.state.PreviousBtnState ? '' : 'disable'}`} onClick={this.Previous}><i className="fa-solid fa-chevron-left"></i> Previous</span> */}
                    <h3 className="mt-1">
                      Question No. {this.state.questionCount + 1} \{" "}
                      {this.state.apiResponseicount}
                    </h3>
                    <h2 className="text-black">
                      {
                        this.state.apiResponseitemList[this.state.questionCount]
                          .question
                      }
                    </h2>
                    <span className="text-danger">
                      {this.state.validationText}
                    </span>

                    {this.state.apiResponseitemList[this.state.questionCount]
                      .uiControlType === 1 ? (
                      this.state.apiResponseitemList[this.state.questionCount]
                        .questionDataType == 1 ? (
                        <>
                          {/* <textarea placeholder='Answer' className='AnswerTextTextArea' id='AnswerText' onChange={this.OnChangeHandle}></textarea> */}
                          <input
                            type="number"
                            className="AnswerTextInput"
                            placeholder="Answer"
                            id="AnswerText"
                            CurrntMillage={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].currentMileage
                            }
                            isRequiredd={`${
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].isRequired
                            }`}
                            onChange={this.OnChangeHandle}></input>
                          <span id="textReq" className="c-red"></span>
                        </>
                      ) : this.state.apiResponseitemList[
                          this.state.questionCount
                        ].questionDataType == 3 ? (
                        <>
                          <input
                            type="date"
                            className="AnswerTextInput"
                            placeholder="Answer"
                            id="AnswerText"
                            CurrntMillage={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].currentMileage
                            }
                            isRequiredd={`${
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].isRequired
                            }`}
                            onChange={this.OnChangeHandle}></input>
                          <span id="textReq" className="c-red"></span>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="AnswerTextInput"
                            placeholder="Answer"
                            id="AnswerText"
                            CurrntMillage={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].currentMileage
                            }
                            isRequiredd={`${
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].isRequired
                            }`}
                            onChange={this.OnChangeHandle}></input>
                          {/* <textarea placeholder='Answer' className='AnswerTextTextArea' id='AnswerText' onChange={this.OnChangeHandle}></textarea> */}
                          <span id="textReq" className="c-red"></span>
                        </>
                      )
                    ) : (
                      <>
                        <div className="w-100 mb-2">
                          <RadioGroup onChange={this.onChange} verticle>
                            {this.state.apiResponseitemList[
                              this.state.questionCount
                            ].answers.map((answ) => (
                              <RadioButton
                                rootColor="#757575"
                                pointColor="#121212"
                                padding="15px"
                                value={answ.answerId}
                                className="Radio"
                                checked={answ.answerId === this.state.selected} // Check based on the selected answerId
                              >
                                {answ.answer}
                              </RadioButton>
                            ))}
                          </RadioGroup>
                        </div>
                      </>
                    )}

                    <div className="btn_wrapper p-0 w-100">
                      <button
                        type="button"
                        className={`btn_theme mr-auto m-0 ${
                          this.state.PreviousBtnState ? "" : "disable"
                        }`}
                        onClick={this.Previous}>
                        <i className="fa-solid fa-chevron-left"></i> Previous
                      </button>

                      {!this.props.addcarquestion &&
                        !this.state.apiResponseitemList[
                          this.state.questionCount
                        ].isRequired && (
                          <button
                            type="button"
                            className="btn_theme mr-1 light"
                            QcarId={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].carId
                            }
                            qid={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].questionId
                            }
                            uicontroltypeid={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].uiControlType
                            }
                            questionpoints={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].questionPoints
                            }
                            questiondatatype={
                              this.state.apiResponseitemList[
                                this.state.questionCount
                              ].questionDataType
                            }
                            onClick={this.SkipQuestion}>
                            Skip
                          </button>
                        )}
                      <button
                        type="button"
                        className={`btn_theme ${
                          this.state.continueBtnState ? "" : "disable"
                        }`}
                        QcarId={
                          this.state.apiResponseitemList[
                            this.state.questionCount
                          ].carId
                        }
                        qid={
                          this.state.apiResponseitemList[
                            this.state.questionCount
                          ].questionId
                        }
                        uicontroltypeid={
                          this.state.apiResponseitemList[
                            this.state.questionCount
                          ].uiControlType
                        }
                        questionpoints={
                          this.state.apiResponseitemList[
                            this.state.questionCount
                          ].questionPoints
                        }
                        questiondatatype={
                          this.state.apiResponseitemList[
                            this.state.questionCount
                          ].questionDataType
                        }
                        onClick={this.QuesTionContinue}>
                        Continue
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <h4 className="title_medium">{this.state.apiStatusMessage}</h4>
              )}
            </div>
          </div>
        ) : this.state.apistatus === false ? (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        ) : (
          <div className="Rewards_wrapper overflow-auto">
            <div className="Rewards__inner__wrapper">
              <h2 className="title_large ">My Points</h2>
              <br></br>
              <h1 className="title_x_large">
                {formatter.format(this.state.rewardPoints)}
              </h1>

              <br></br>
              <p className="normal__text text-gray">Rewards Points Available</p>
              {/* <br></br>
                        <br></br> */}
              {/* <div className='btn_theme' onClick={() => {this.context.setActive("Shop")}}>Redeem Points</div> */}
              {/* <br></br>
                        <br></br> */}
              {/* <br></br> */}
              {/* <p className='normal__text text-gray'>via Services and Protection Plans.</p> */}
              <div className="list">
                <span className="btn_theme light" onClick={this.EarnQuestion}>
                  Answer the question and earn points
                </span>
                <span
                  className="btn_theme light"
                  onClick={() => {
                    this.context.setActive("Addvehicle");
                    this.context.setopenaddnewcarfromrewards(true);
                    this.context.setaddnewcarisopen(true);
                  }}>
                  Add new vehicle and get Points
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Rewards;
