import React, { useState, useEffect } from "react";
import "./main_layout.css";
import Logo from "../images/IBIZILogo.svg";
import $ from "jquery";

import garageicon from "../images/garageicon.png";
import marketplaceicon from "../images/marketplaceicon.png";
import connecticon from "../images/connecticon.png";
import specialicon from "../images/specialicon.png";
import carticon from "../images/carticon.png";
import favicon from "../images/favicon.png";
import appointmenticon from "../images/appointmenticon.png";
import paymenticon from "../images/paymenticon.png";
import rewardicon from "../images/rewardicon.png";
import logouticon from "../images/logouticon.png";
import addvehicles from "../images/addvehicles.png";

import Garage from "./Maincomponents/Garage";
import Shop from "./Maincomponents/Shop";
import Cart from "./Maincomponents/Cart";
import PaymentInfo from "./Maincomponents/PaymentInfo";
import Rewards from "./Maincomponents/Rewards";
import "./Leftpanel/leftpanel.css";
import "./Rightpanel/rightpanel.css";
import Favorites from "./Maincomponents/Favorites";
import AllAppointments from "./Maincomponents/Appointments";

import { useContext } from "react";
import AppContext from "../AppContext";
import ProfileSetting from "./Maincomponents/ProfileSetting";
import Specials from "./Maincomponents/Specials";
import Connects from "./Maincomponents/Connects";
import Garage_Add_New_Car from "./Maincomponents/Garage_Add_New_Car";

function Main_layout() {
  const myContext = useContext(AppContext);
  const [logoutmodal, setlogoutmodal] = React.useState(false);

  if (myContext.active !== "Shop") {
    myContext.setshopcardetailsectionisopen("");
  }

  function Logout() {
    setlogoutmodal(true);
    $(".garage").addClass("blur");
    $(".top_iconbar").addClass("blur");
    $(".Left__panel").addClass("blur");
  }
  function confirmlogout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
    myContext.setsplashIsOpen(false);
  }
  function opensidenav() {
    var sidebar = document.querySelector(".Left__panel");
    sidebar.classList.toggle("toggle");
    myContext.setsinglecardetailsectionisopen(false);
  }

  


  return (
    <div
      className="main__layout__controller"
      onClick={() => {
        myContext.setnotidropdowstatehide(false);
      }}>
      {/* Left Panel */}
      <div className="Left__panel" onClick={opensidenav}>
        <img
          alt="img"
          className="brokenimg"
          src={Logo}
          onClick={() => {
            myContext.setActive("Garage");
            myContext.setopenaddnewcarfromrewards(false);
            myContext.setaddnewcarisopen(false);
          }}
          id="Logoid"></img>
        <div className="Left__panel__nav__items__wrapper">
          <ul className="ul_top">
            <li
              className={`garageitem ${
                myContext.active === "Garage" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Garage");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
              }}>
              <img
                alt="img"
                className="brokenimg"
                src={garageicon}
                id="Logoid"></img>
              Garage
            </li>
            <li
              className={`garageitem ${
                myContext.active === "Shop" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Shop");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={marketplaceicon} id="Logoid"></img>
              Marketplace
            </li>
            {/* <li className={`garageitem ${myContext.active === "Decline" ? 'active' : '' }`} onClick={() => myContext.setActive("Decline")}>Decline</li> */}
            <li
              className={`garageitem ${
                myContext.active === "Connects" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Connects");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={connecticon} id="Logoid"></img>Connect
            </li>
            <li
              className={`garageitem ${
                myContext.active === "Special" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Special");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={specialicon} id="Logoid"></img>Specials
            </li>
            <li
              className={`garageitem ${
                myContext.active === "Cart" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Cart");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={carticon} id="Logoid"></img>Cart{" "}
              {myContext.countforcart === undefined ? (
                ""
              ) : (
                <span className="red-badge shape">
                  {myContext.countforcart}
                </span>
              )}{" "}
            </li>
            <li
              className={`garageitem ${
                myContext.active === "Favorites" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Favorites");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={favicon} id="Logoid"></img>Favorites
            </li>
            <li className="spacer"></li>
            <li
              className={`garageitem ${
                myContext.active === "Addvehicle" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Addvehicle");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={addvehicles} id="Logoid"></img>Add Vehicle
            </li>
            {/* <li className={`garageitem ${myContext.active === "Earnpoints" ? 'active' : '' }`} onClick={() => myContext.setActive("Earnpoints")}><img alt='img' src={earnpoints} id='Logoid'></img>Earn Points</li> */}

            <li
              className={`garageitem ${
                myContext.active === "Appointments" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Appointments");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={appointmenticon} id="Logoid"></img>Appointments{" "}
              {myContext.appoinmentcount === undefined ? (
                ""
              ) : (
                <span className="red-badge shape">
                  {myContext.appoinmentcount}
                </span>
              )}{" "}
            </li>
            {/* <li
              className={`garageitem ${
                myContext.active === "Payment" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Payment");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={paymenticon} id="Logoid"></img>Card Info
            </li> */}
            <li
              className={`garageitem ${
                myContext.active === "Rewards" ? "active" : ""
              }`}
              onClick={() => {
                myContext.setActive("Rewards");
                myContext.setopenaddnewcarfromrewards(false);
                myContext.setaddnewcarisopen(false);
                }}>
              <img alt="img" src={rewardicon} id="Logoid"></img>Rewards
            </li>
            <li
              className={`garageitem ${
                myContext.active === "Logout" ? "active" : ""
              }`}
              onClick={Logout}>
              <img alt="img" src={logouticon} id="Logoid"></img>Sign Out
            </li>
          </ul>
        </div>
      </div>

      {/* Right Panel */}
      <div className="Right__panel">
        {myContext.active === "Garage" && <Garage title="Garage" />}
        {myContext.active === "Shop" && <Shop title="Marketplace" />}
        {/* { myContext.active === "Decline" && <Declined title="Decline"/> } */}
        {myContext.active === "Favorites" && <Favorites title="Favorites" />}
        {myContext.active === "Connects" && <Connects title="Connect" />}
        {myContext.active === "Special" && <Specials title="Specials" />}
        {myContext.active === "Cart" && <Cart title="Cart" />}
        {myContext.active === "Appointments" && (
          <AllAppointments title="Appointments" carId="0" />
        )}
        {myContext.active === "Addvehicle" && (
          <Garage_Add_New_Car title="Add New Vehicle" />
        )}
        {myContext.active === "Earnpoints" && <Rewards title="Earn Points" />}
        {/* {myContext.active === "Payment" && <PaymentInfo title="Card Info" />} */}
        {myContext.active === "Rewards" && <Rewards title="Rewards" />}
        {myContext.active === "Logout" && (
          <h4 className="title_medium">Logout</h4>
        )}
        {myContext.active === "UserProfile" && (
          <ProfileSetting title="Edit Profile" />
        )}
      </div>

      {logoutmodal && (
        <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
          <div
            className="closedetailsection"
            onClick={() => {
              setlogoutmodal(false);
              $(".garage").removeClass("blur");
              $(".top_iconbar").removeClass("blur");
              $(".Left__panel").removeClass("blur");
            }}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <h3 className="text-center">Are you sure you want to Sign Out?</h3>
          <div className="flex justify-center mt-2">
            <div className="btn_theme mr-1" onClick={confirmlogout}>
              Yes
            </div>
            <div
              className="btn_theme light"
              onClick={() => {
                setlogoutmodal(false);
                $(".garage").removeClass("blur");
                $(".top_iconbar").removeClass("blur");
                $(".Left__panel").removeClass("blur");
              }}>
              No
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Main_layout;
