import React, { Component } from 'react';
import loader from '../../../images/loader.gif';
import AppContext from '../../../AppContext';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { v4 } from 'uuid';
import $ from 'jquery'

class Appointments extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      clickloader: false,
      upcomingstate: true,
      previousstate: false,
      cancelledstate: false,
      items: [],
      confirmDialogState: false,
      currentDeletedID: "",
      cnfirmbtdisabled: true,
      apistatus: false,
      confrmdelApistatus: false,
      GetAppointmentTypeEntity: 2,
      Selected: "",
      apimessage: "",
      datareadytogo: false,
      api_token: localStorage.getItem("token"),
    };
    this.confirmationDialog = this.confirmationDialog;
    this.closeDialog = this.closeDialog;
    this.doneDialog = this.doneDialog;
    this.confirmDelete = this.confirmDelete;
    this.onChange = this.onChange;
    this.onloadAPI = this.onloadAPI;
    this.upcoming = this.upcoming;
    this.previous = this.previous;
    this.cancelled = this.cancelled;
    this.showMap = this.showMap;
    this.dateFormate = this.dateFormate;
  }

  onloadAPI = (e) => {
    // this.context.setupdatenoticount(false);
    let end_point = "Appointment/GetUserAppointments";
    let url_parameter = "?carId=";
    let url_parameter2 = "&getAppointmentType=";
    let api_url =
      this.context.Base_url +
      end_point +
      url_parameter +
      this.props.carId +
      url_parameter2 +
      this.state.GetAppointmentTypeEntity;
    // Main Appointment API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // this.context.setupdatenoticount(true);
          if (result.status) {
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              items: result.appointmentDetails,
            });


          } else {
            if (result.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            } 
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage:
                result.message == "No Appointments are found."
                  ? "No Appointments found."
                  : result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main Appointment API End
  };


  // After Rendering
  componentDidMount() {
    this.onloadAPI();
  }

  confirmationDialog = (e) => {
    $(".garage_car_detail_header_left_right_wrapper").addClass("blur");
    $(".colapse").addClass("blur");
    $(".top_iconbar").addClass("blur");
    $(".Left__panel").addClass("blur");
    $(".garage_car_detail_navigation").addClass("blur");

    const ClickID = e.currentTarget.getAttribute("cancel-appoinment-id");
    this.setState({
      confirmDialogState: true,
      currentDeletedID: ClickID,
    });
  };

  closeDialog = (e) => {
    this.setState({
      confirmDialogState: false,
      currentDeletedID: "",
    });
    $(".garage_car_detail_header_left_right_wrapper").removeClass("blur");
    $(".colapse").removeClass("blur");
    $(".top_iconbar").removeClass("blur");
    $(".Left__panel").removeClass("blur");
    $(".garage_car_detail_navigation").removeClass("blur");
  };

  doneDialog = async (e) => {
    
    this.setState({
      confirmDialogState: false,
      currentDeletedID: "",
      cnfirmbtdisabled: true,
      isLoaded: false,
      confrmdelApistatus: false,
    });
    
    this.onloadAPI();

    let end_pointt = "Notifications/NotificationsList";
    let api_url = this.context.Base_url + end_pointt;

    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: token,
      };
      const response = await fetch(api_url, { headers });
      const data = await response.json();

      if (data.notificationBadgeCount != 0) {
        setTimeout(() => {
          // setnotificationBagdeCount(data.notificationBadgeCount);
          this.context.setnotificationBagdeCount(data.notificationBadgeCount);
        }, 500);
        
      }

      // setupdatecartcount(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setupdatecartcount(false);
    }
    
  };

  confirmDelete = (e) => {
    $(".garage_car_detail_header_left_right_wrapper").removeClass("blur");
    $(".colapse").removeClass("blur");
    $(".top_iconbar").removeClass("blur");
    $(".Left__panel").removeClass("blur");
    $(".garage_car_detail_navigation").removeClass("blur");
    if (this.state.cnfirmbtdisabled === false) {
      this.setState({
        datareadytogo: true,
      });
      const dataReq = {
        AppointmentId: this.state.currentDeletedID,
        CancelComments: this.state.Selected,
      };
      let endpointdelappoint = "Appointment/CancelAppointments";
      let apiurldelapp = this.context.Base_url + endpointdelappoint;
      const url = apiurldelapp;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.api_token,
        },
        body: JSON.stringify(dataReq),
      })
        .then((res) => res.json())
        .then((res) => {
          this.context.setupdatenoticount(false);
          this.setState(
            {
              datareadytogo: false,
              //   confirmDialogState: false,
              confrmdelApistatus: res.status,
              apimessage: res.message,
            },
            () => {
              this.context.setupdatenoticount(true);
            }
          );
        })
        .catch((error) => console.log(error));
    }
  };

  onChange = (e) => {
    this.setState({
      cnfirmbtdisabled: false,
      Selected: e,
    });
  };

  upcoming = (e) => {
    this.setState(
      {
        upcomingstate: true,
        previousstate: false,
        cancelledstate: false,
        GetAppointmentTypeEntity: 2,
        isLoaded: false,
        
      },
      () => {
        this.onloadAPI();
        this.setState({ clickloader: false });
      }
    );
  };

  previous = (e) => {
    this.setState(
      {
        upcomingstate: false,
        previousstate: true,
        cancelledstate: false,
        GetAppointmentTypeEntity: 3,
        isLoaded: false,
      },
      () => {
        this.onloadAPI();
        this.setState({ clickloader: false });
      }
    );
  };

  cancelled = (e) => {
    this.setState(
      {
        upcomingstate: false,
        previousstate: false,
        cancelledstate: true,
        GetAppointmentTypeEntity: 1,
        isLoaded: false,
      },
      () => {
        this.onloadAPI();
        this.setState({ clickloader: false });
      }
    );
  };

  showMap = (e) => {
    var lat = e.currentTarget.getAttribute("data-lat");
    var lng = e.currentTarget.getAttribute("data-lng");
    var loc = e.currentTarget.getAttribute("data-localation");
    // var url = "https://maps.google.com/?q=" + lat + "," + lng;
    var url = loc;
    window.open(url);
  };

  dateFormate = (value) => {
    let date = new Date(value);
    let options = { month: "long", day: "numeric", year: "numeric" };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  render() {
    return (
      <>
        <div className="content_panel_controller dir_column">
          <div className="round_tabs">
            <span
              className={`innertab__wrapper_items ${
                this.state.upcomingstate ? "active" : ""
              }`}
              onClick={() => {
                this.setState(
                  {
                    clickloader: true,
                  },
                  () => {
                    this.upcoming();
                  }
                );
              }}>
              Upcoming
            </span>
            <span
              className={`innertab__wrapper_items ${
                this.state.previousstate ? "active" : ""
              }`}
              onClick={() => {
                this.setState(
                  {
                    clickloader: true,
                  },
                  () => {
                    this.previous();
                  }
                );
              }}>
              Previous
            </span>
            <span
              className={`innertab__wrapper_items ${
                this.state.cancelledstate ? "active" : ""
              }`}
              onClick={() => {
                this.setState(
                  {
                    clickloader: true,
                  },
                  () => {
                    this.cancelled();
                  }
                );
              }}>
              Canceled
            </span>
          </div>
          {this.state.cancelledstate && this.state.items.length != 0 && (
            <div className="cancel_messg_section">
              You have successfully canceled the appointment.
               {/* You will get the
              refund within 10-15 working days.{" "}
              <span className="black">Contact Ibizi</span> for more information. */}
            </div>
          )}

          {this.state.confirmDialogState && (
            <>
              {this.state.confrmdelApistatus ? (
                <>
                  <div className="confirm_dialog">
                    <h4>{this.state.apimessage}</h4>
                    <div className="flex">
                      <div
                        className={`btn_theme small light`}
                        onClick={this.doneDialog}>
                        Done
                      </div>
                    </div>
                  </div>
                  <div className="confirm_dialog_layer"></div>
                </>
              ) : (
                <>
                  <div className="confirm_dialog">
                    {/* <p>Are you sure? You want to cancel this Appointment?</p> */}
                    <div className="w-100 mb-2 reasonofcancel">
                      <h4>Reason of Cancelation</h4>

                      <RadioGroup onChange={this.onChange} verticle>
                        <RadioButton
                          rootColor="#757575"
                          pointColor="#121212"
                          padding="10px"
                          value="The cost of this appointment is too high"
                          className="Radio">
                          The cost of this appointment is too high
                        </RadioButton>
                        <RadioButton
                          rootColor="#757575"
                          pointColor="#121212"
                          padding="10px"
                          value="The dealer's location is far away from my locality.">
                          The dealer's location is far away from my locality.
                        </RadioButton>
                        <RadioButton
                          rootColor="#757575"
                          pointColor="#121212"
                          padding="10px"
                          value="I have some other commitments in this time slot.">
                          I have some other commitments in this time slot.
                        </RadioButton>
                        <RadioButton
                          rootColor="#757575"
                          pointColor="#121212"
                          padding="10px"
                          value="I have already acquired this service.">
                          I have already acquired this service.
                        </RadioButton>
                        <RadioButton
                          rootColor="#757575"
                          pointColor="#121212"
                          padding="10px"
                          value="Other.">
                          Other.
                        </RadioButton>
                      </RadioGroup>
                    </div>
                    <div className="flex">
                      <div
                        className={`btn_theme small mr-1 ${
                          this.state.cnfirmbtdisabled && "disable"
                        }`}
                        onClick={this.confirmDelete}>
                        Confirm
                      </div>
                      <div
                        className={`btn_theme small light`}
                        onClick={this.closeDialog}>
                        Cancel
                      </div>
                    </div>
                  </div>
                  <div className="confirm_dialog_layer"></div>
                </>
              )}
            </>
          )}
          {this.state.isLoaded === false ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : (
            <div className="innertab__content_wrappers">
              {this.state.datareadytogo && (
                <div className="loader-div z-in-4">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              )}
              <div className="garage_car_detail_appointment_content_wrapper bg-white radius mt-5">
                {this.state.apistatus === false ? (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">{this.state.apiStatusMessage}</h3>
                  </div>
                ) : this.state.isLoaded === false ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : this.state.apiStatusMessage ===
                  "No Appointments are found against this car" ? (
                  <h3 className="title_medium">
                    {this.state.apiStatusMessage}
                  </h3>
                ) : this.state.isLoaded === false ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : this.state.clickloader ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : (
                  <>
                    {this.state.items.map((data1, i) => (
                      <div key={i}>
                        <div className="flex-space-between-center w-100 firsttr">
                          <div className="">
                            <span className="text-black">
                              {this.dateFormate(data1.appointmentDate)} |{" "}
                              {data1.appointmentTime} <br></br>{" "}
                              <small>{data1.appointmentNumber}</small>
                            </span>
                          </div>
                          <div className="flex">
                            {/* {this.state.cancelledstate ? (
                                data1.fandiAppointmentModel.length !== 0 ? (
                                  <>
                                    {data1.isPaymentRevert ? (
                                      <span className="refundbtn success">
                                        <i className="fa-solid fa-check"></i>
                                        Refunded
                                      </span>
                                    ) : (
                                      <span className="refundbtn">
                                        <i className="fa-regular fa-clock"></i>
                                        Refund pending
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )} */}

                            {this.state.upcomingstate && (
                              <span className="dirctn">
                                <a
                                  href="#"
                                  className="map-btn"
                                  onClick={this.showMap}
                                  data-lat={data1.dealershipLatitude}
                                  data-localation={data1.dealershipLocation}
                                  data-lng={data1.dealershipLongitude}>
                                  Get Direction
                                  <i className="fa-sharp fa-solid fa-diamond-turn-right"></i>
                                </a>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="carinfo_cards_box_wrapper">
                          {this.state.isLoaded === false ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : data1.serviceAppointmentModel.length === 0 ? (
                            ""
                          ) : (
                            (() => {
                              let td = [];
                              for (
                                let i = 0;
                                i < data1.serviceAppointmentModel.length;
                                i++
                              ) {
                                td.push(
                                  <div
                                    key={
                                      data1.serviceAppointmentModel[i]
                                        .dealershipServicePrice
                                    }
                                    className="carinfo_cards_box_wrapper_items">
                                    <div className="carinfo_cards_box_wrapper_items_frame_controller">
                                      <div className="carinfo_cards_box_wrapper_items_image_wrapper">
                                        <img
                                          alt="Failed to load"
                                          className="brokenimg"
                                          src={
                                            data1.serviceAppointmentModel[i]
                                              .dealershipServiceImage
                                          }></img>
                                      </div>
                                      <div className="carinfo_cards_box_wrapper_items_content_wrapper">
                                        <h4 className="carinfo_cards_box_wrapper_items_title">
                                          {data1.serviceAppointmentModel
                                            .length !== 0
                                            ? data1.serviceAppointmentModel[i]
                                                .dealershipServiceName
                                            : "-"}
                                        </h4>
                                        <div>
                                          {data1.serviceAppointmentModel
                                            .length !== 0
                                            ? data1.serviceAppointmentModel[i]
                                                .dealershipServiceParentName
                                            : "-"}
                                        </div>
                                        <span>
                                          {data1.serviceAppointmentModel
                                            .length !== 0 ? (
                                            data1.serviceAppointmentModel[i]
                                              .dealershipServicePrice ===
                                            "Free" ? (
                                              <strong>
                                                Included in the Package
                                              </strong>
                                            ) : data1.serviceAppointmentModel[i]
                                                .dealershipServicePrice ==
                                              null ? (
                                              "$0"
                                            ) : (
                                              "$" +
                                              [
                                                data1.serviceAppointmentModel[
                                                  i
                                                ].dealershipServicePrice.includes(
                                                  ".00"
                                                )
                                                  ? data1.serviceAppointmentModel[
                                                      i
                                                    ].dealershipServicePrice
                                                      .replace(".00", "")
                                                      .toLocaleString()
                                                  : data1.serviceAppointmentModel[
                                                      i
                                                    ].dealershipServicePrice.toLocaleString(),
                                              ]
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                        {this.state.previousstate ? (
                                          <>
                                            <br></br>
                                            {data1.serviceAppointmentModel[i]
                                              .isAbsent ? (
                                              <strong className="text-danger">
                                                Absent
                                              </strong>
                                            ) : (
                                              <strong className="text-green">
                                                Compelete
                                              </strong>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return td;
                            })()
                          )}
                          {this.state.isLoaded === false ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : data1.fandiAppointmentModel.length === 0 ? (
                            ""
                          ) : (
                            (() => {
                              let td = [];
                              for (
                                let i = 0;
                                i < data1.fandiAppointmentModel.length;
                                i++
                              ) {
                                td.push(
                                  <div
                                    key={
                                      data1.fandiAppointmentModel[i]
                                        .dealershipCarPartFandiPrice
                                    }
                                    className="carinfo_cards_box_wrapper_items">
                                    <div className="carinfo_cards_box_wrapper_items_frame_controller">
                                      <div className="carinfo_cards_box_wrapper_items_image_wrapper">
                                        <img
                                          alt="Failed to load"
                                          className="brokenimg"
                                          src={
                                            data1.fandiAppointmentModel[i]
                                              .dealershipCarPartFandiImage
                                          }></img>
                                      </div>
                                      <div className="carinfo_cards_box_wrapper_items_content_wrapper">
                                        <h4 className="carinfo_cards_box_wrapper_items_title">
                                          {data1.fandiAppointmentModel
                                            .length !== 0
                                            ? data1.fandiAppointmentModel[i]
                                                .dealershipCarPartFandiName
                                            : "-"}
                                        </h4>
                                        <div>
                                          {data1.fandiAppointmentModel
                                            .length !== 0
                                            ? "Protection"
                                            : "-"}
                                        </div>
                                        {/* <span>${data1.fandiAppointmentModel.length !== 0 ? data1.fandiAppointmentModel[i].dealershipCarPartFandiPrice : '-'}</span> */}
                                        <span>
                                          {data1.fandiAppointmentModel
                                            .length !== 0 ? (
                                            data1.fandiAppointmentModel[i]
                                              .dealershipCarPartFandiPrice ===
                                            "Free" ? (
                                              <strong>
                                                Included in the Package
                                              </strong>
                                            ) : data1.fandiAppointmentModel[i]
                                                .dealershipCarPartFandiPrice ==
                                              null ? (
                                              "$0"
                                            ) : (
                                              "$" +
                                              [
                                                data1.fandiAppointmentModel[
                                                  i
                                                ].dealershipCarPartFandiPrice.includes(
                                                  ".00"
                                                )
                                                  ? data1.fandiAppointmentModel[
                                                      i
                                                    ].dealershipCarPartFandiPrice
                                                      .replace(".00", "")
                                                      .toLocaleString()
                                                  : data1.fandiAppointmentModel[
                                                      i
                                                    ].dealershipCarPartFandiPrice.toLocaleString(),
                                              ]
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                        {this.state.previousstate ? (
                                          <>
                                            <br></br>
                                            {data1.fandiAppointmentModel[i]
                                              .isAbsent ? (
                                              <strong className="text-danger">
                                                Absent
                                              </strong>
                                            ) : (
                                              <strong className="text-green">
                                                Compelete
                                              </strong>
                                            )}
                                            <br></br>
                                            {data1.fandiAppointmentModel[i]
                                              .dealershipCarPartFandiPrice !=
                                            "Free" ? (
                                              data1.fandiAppointmentModel[i]
                                                .isPaymentRevert ? (
                                                <strong className="text-green">
                                                  Refunded
                                                </strong>
                                              ) : (
                                                <strong className="text-danger">
                                                  Refund Pending
                                                </strong>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {this.state.cancelledstate ? (
                                          <>
                                            <br></br>
                                            {data1.fandiAppointmentModel[i]
                                              .dealershipCarPartFandiPrice !=
                                            "Free" ? (
                                              data1.fandiAppointmentModel[i]
                                                .isPaymentRevert ? (
                                                <strong className="text-green">
                                                  Refunded
                                                </strong>
                                              ) : (
                                                <strong className="text-danger">
                                                  Refund Pending
                                                </strong>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return td;
                            })()
                          )}
                        </div>
                        <div className="flex flex-end mb-2">
                          {this.state.cancelledstate === true && (
                            <span className="flex subtotal_apoint text-danger">
                              <strong>SubTotal: </strong>
                              <span>${data1.totalPrice}</span>
                              {/* <span>{data1.totalPoint} Points</span> */}
                            </span>
                          )}
                          {this.state.previousstate === true && (
                            <span className="flex subtotal_apoint">
                              <strong>SubTotal: </strong>
                              <span>${data1.totalPrice}</span>
                              {/* <span>{data1.totalPoint} Points</span> */}
                            </span>
                          )}
                          {this.state.upcomingstate === true && (
                            <span className="flex subtotal_apoint">
                              <strong>SubTotal: </strong>
                              <span>${data1.totalPrice}</span>
                              {/* <span>{data1.totalPoint} Points</span> */}
                            </span>
                          )}
                          {this.state.upcomingstate && (
                            <span
                              className="text-black cancelbtn cursor-pointer"
                              onClick={this.confirmationDialog}
                              cancel-appoinment-id={data1.appointmentId}>
                              cancel
                            </span>
                          )}
                        </div>
                        <div className="sepratr"></div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default Appointments;