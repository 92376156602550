import React, { useEffect, useRef } from "react";
import Video from '../images/videoForSplash.mp4'


const Splash = ({title}) => {
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    return (
        <div className='splash_screen_wrapper'>
            <video
            style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
            playsInline
            loop
            muted
            alt="All the devices"
            src={Video}
            ref={videoEl}
            />
        </div>
    );
};

export default Splash;
