import React, {useState, useEffect } from 'react';
import AppContext from '../../AppContext';
import { useContext } from 'react';
import TopNav from '../Snippets/top_nav_items';
import loader from '../../images/loader.gif';
import QuestionAndAnswer from './QuestionAndAnswer';
import Select from "react-select";
import "./Garage_Add_New_Car.css";
import Rewards from "./Rewards";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./../../firebase";
import { v4 } from "uuid";
import { async } from '@firebase/util';
let environmentstatefrom = "Garage-Car/"


async function VerifyVINNumber(vinNO) {
      return fetch(vinNO.base_url+'Garage/VerifyVINNumber?VINNumber='+ vinNO.vinNumber, {
        method: 'GET',
        headers: {
          'Authorization' : vinNO.api_token
        }
      })
      .then((response) => response.json())
      .then((json) => json)
}

async function UserCarAddCar(addcarModel) {
  const body_user = 
  {
    "vinNumber": addcarModel.vinNumber,
    "year": addcarModel.year,
    "make": addcarModel.make,
    "model": addcarModel.model,
    "engine": addcarModel.Engine,
    "transmission": addcarModel.Transmission,
    "dealerShipId": addcarModel.selectedOption,
    "mileage": addcarModel.Odometer
  }
  
  return fetch(addcarModel.base_url + 'Garage/UserCarAddCar', {
    method: 'POST',
    headers: {
      'Authorization' : addcarModel.api_token,
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
}

async function AddNewCarQuestion(addcarModel) {
  const body_user = 
  {
    "carId": addcarModel.vss
  }
  return fetch(addcarModel.base_url + 'Garage/AddNewCarQuestion', {
    method: 'POST',
    headers: {
      'Authorization' : addcarModel.api_token,
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
}

const Garage_Add_New_Car = ({title}) => { 
    const myContext = useContext(AppContext);
    const [vinNumber, setvinNumber] = useState();
    const [carID, setcarID] = useState();
    const [year, setyear] = useState("Select Year");
    const [make, setmake] = useState("Select Make");
    const [model, setmodel] = useState("Select Model");
    const [Engine, setEngine] = useState("Select Engine");
    const [Trim, settrim] = useState("Select Trim");
    const [DealershipId, setDealershipId] = useState();
    const [datafordealership, setDatafordealership] = useState([]);
   
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");


    const [readytomapindd, setreadytomapindd] = React.useState(false);
    const [Manufacturer, setManufacturer] = useState("Select Manufacture");
    const [Transmission, settransmission] = useState("Select Transmission");
    const [Odometer, setodometer] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [QandAOpen, setQandAOpen] = useState(false);
    const [QandAArr, setQandAArr] = useState([]);
    const [QandACarId, setQandACarId] = useState(0);
    const [FeildsEnabled, setFeildsEnabled] = useState(false);

    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const objId = QandACarId;
    let base_url = myContext.Base_url;
    let api_token = localStorage.getItem('token'); 
    let vs = 0;


    function back() {
        myContext.setaddnewcarisopen(false);
    }

    const handleKeyDown = async (event) => {

        if (event.key === "Enter") {
          setFeildsEnabled(true);
          setIsLoading(true);
          document.getElementById("vinValid").innerHTML = "";

          if (vinNumber == "" || vinNumber == undefined) {
            setIsLoading(false);
            setFeildsEnabled(false);
            function sayHi() {
              document.getElementById("vinValid").innerHTML = "Required";
            }
            setTimeout(sayHi, 50);
          } else {
            event.preventDefault();
            const response = await VerifyVINNumber({
              vinNumber,
              api_token,
              base_url,
            });
            if (response.status) {
              setIsLoading(false);
              settrim(response.carResponse.trim);
              setyear(response.carResponse.year);
              setmake(response.carResponse.make);
              setmodel(response.carResponse.model);
              setEngine(response.carResponse.engine);
              settransmission(response.carResponse.transmission);
              setManufacturer(response.carResponse.manufacturer);
              setDatafordealership(response.dealerships);
              const options = response.dealerships.map((item) => ({
                value: item.dealershipId,
                label: item.dealershipName,
              }));
              setDropdownOptions(options);

              setreadytomapindd(true);
            } else {
              setIsLoading(false);
              setFeildsEnabled(false);
              function sayHi() {
                document.getElementById("vinValid").innerHTML =
                  response.message;
                  setvinNumber("");
              }
              setTimeout(sayHi, 50);
            }
          }
        } else {
          document.getElementById("vinValid").innerHTML = "";
          setFeildsEnabled(false);
          setIsLoading(false);
        }
    }

    
    // const imagesListRef = ref(storage, myContext.environmentstate+environmentstatefrom);
    
    // useEffect(() => {
    //   listAll(imagesListRef).then((response) => {
    //     response.items.forEach((item) => {
    //       getDownloadURL(item).then((url) => {
    //         setImageUrls((prev) => [...prev, url]);
    //       });
    //     });
    //   });
    // }, []);

    // const uploadFile = async () => {
     
    //   if (imageUpload === null) return;
    //   // const imageRef  = ref(storage, `${myContext.environmentstate+environmentstatefrom}ProfilePicture${v4()+'.jpg'}`);
    //   const imageRef = ref(storage, `${myContext.environmentstate+environmentstatefrom}CarPicture${v4()+'.jpg'}`);
    //   uploadBytes(imageRef, imageUpload).then((snapshot) => {
    //     getDownloadURL(snapshot.ref).then((url) => {
    //       setImageUrls((prev) => [...prev, url]);
    //     });
    //   });
    // };

    const handleSubmitReq = async (event) => {
      // setIsLoading(true);
      if(vinNumber === undefined){
        setIsLoading(false);
        document.getElementById('vinValid').innerHTML = 'Requird';
      }
      else{
        event.preventDefault();
        // await uploadFile();
        let moDel = {
          vinNumber,
          make,
          model,
          year,
          Trim,
          Engine,
          Manufacturer,
          Odometer,
          Transmission,
          imageUrls,
          selectedOption,
          api_token,
          base_url,
        };
        let readytogo = false;
        let odometer = false;
        let dealershiId = false;

        if (moDel.Odometer == undefined || parseInt(Odometer) == 0 || moDel.Odometer == "") {
          odometer = false;
          document.getElementById("oddometer").innerHTML = "Required*";
        } else {
          odometer = true;
          document.getElementById("oddometer").innerHTML = "";
        }


        if (moDel.selectedOption == "") {
          dealershiId = false;
          document.getElementById("dealerShipddid").innerHTML = "Required*";
        } else {
          dealershiId = true;
          document.getElementById("dealerShipddid").innerHTML = "";
        }


        if (odometer && dealershiId) {
          readytogo = true;
        } else {
          readytogo = false;
        }

        if (readytogo) {
            setIsLoading(true);
            const response = await UserCarAddCar(moDel);
            if (response.status) {
              setIsLoading(false);
              vs = response.carId;
              setQandAOpen(true);
              setQandACarId(vs);

              // const responseCarID = await AddNewCarQuestion({
              //   vs,
              //   base_url,
              //   api_token,
              // });
              // if (responseCarID.status) {
              //   setQandAArr(responseCarID.newCarQuestionResponse);
              // } else {
              //   alert(responseCarID.message);
              // }
            } else {
              setIsLoading(false);
              alert("Please Enter A Valid VinNumber");
            }
          }
      }
      
    }
    
    return (
      <div className="shop_frame_controller f-grow-1">
        <div className="top_iconbar">
          <span className="top_Left_column">
            <h4 className="title_medium align-center">
              {myContext.openaddnewcarfromrewards ? (
                <>
                  <span className="ml-2">Reward</span>{" "}
                  <i className="fa-solid fa-chevron-right small mini"></i>{" "}
                  {title}
                  {/* {title} */}
                </>
              ) : (
                title
              )}
            </h4>
          </span>
          {myContext.addnewcarisopen === false && <TopNav />}
        </div>
        <div className="Single_vehicles_info_wapper overflow-auto f-grow-1 flex-center-center">
          {
            myContext.openaddnewcarfromrewards ? (
              <div
                className="closedetailsection addnewcarcrossbtn"
                onClick={() => {
                  myContext.setActive("Rewards");
                  myContext.setopenaddnewcarfromrewards(false);
                  myContext.setaddnewcarisopen(false);
                }}>
                <i className="fa-solid ml-1 fa-chevron-left"></i>
              </div>
            ) : (
              ""
            )
            // <div className='closedetailsection addnewcarcrossbtn' onClick={back}><i className="fa-solid fa-xmark"></i></div>
          }
          {QandAOpen ? (
            <>
              {/* <QuestionAndAnswer obj={QandAArr} /> */}
              <Rewards
                title="Add New Vehicle"
                addcarquestion={true}
                newcarid={objId}
              />
            </>
          ) : (
            <div className="form-wrapper addnewcarwrapper">
              <form>
                {isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : (
                  <>
                    {FeildsEnabled && (
                      <>
                        <div className="row">
                          <div className="form_group">
                            <label>Year</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Year"
                                value={year}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>

                          <div className="form_group">
                            <label>Make</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Make"
                                value={make}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form_group">
                            <label>Model</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Model"
                                value={model}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>

                          <div className="form_group">
                            <label>Engine</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Engine"
                                value={Engine}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form_group">
                            <label>Trim</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Trim"
                                value={Trim}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>

                          <div className="form_group">
                            <label>Manufacturer</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Engine"
                                value={Manufacturer}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form_group">
                            <label>Transmission</label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                placeholder="Enter Text"
                                value={Transmission}
                                className="grey-bdr m-0"
                                disabled
                                required
                              />
                              <label id="userNameID"></label>
                            </div>
                          </div>

                          <div className="form_group">
                            <label>
                              Mileage{" "}
                              <span
                                id="oddometer"
                                className="text-danger"></span>
                            </label>
                            <div className="inputwrapper">
                              <input
                                type="text"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                placeholder="Enter Mileage"
                                value={Odometer}
                                onChange={(e) => {
                                  setodometer(e.target.value);
                                }}
                                className="grey-bdr m-0"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form_group">
                            <label>
                              Dealership{" "}
                              <span
                                id="dealerShipddid"
                                className="text-danger"></span>
                            </label>
                            {readytomapindd ? (
                              <Select
                                options={dropdownOptions}
                                onChange={({ value }) =>
                                  setSelectedOption(value)
                                }
                              />
                            ) : (
                              <div className="loader-div">
                                <img
                                  alt="img"
                                  src={loader}
                                  id="belliconid"></img>
                              </div>
                            )}
                            {/* {imageUrls.map((url) => { return <img width={200} height={200} src={url} />; })} */}
                          </div>
                        </div>

                        <div className="mt-2"></div>
                        <button
                          type="button"
                          onClick={handleSubmitReq}
                          className="btn_theme m-auto flex">
                          Continue
                        </button>
                      </>
                    )}
                    {FeildsEnabled === false && (
                      <>
                        <div className="form_group">
                          <label>Enter Vin</label>
                          <div className="inputwrapper">
                            <input
                              type="text"
                              placeholder="VIN #"
                              className="grey-bdr m-0"
                              id="VinNo"
                              onChange={(e) => setvinNumber(e.target.value)}
                              onKeyDown={handleKeyDown}
                              required
                            />
                            <label id="vinValid" className="c-red mt-1"></label>
                          </div>
                        </div>
                        <span className="text-gray">
                          Add a new VIN number & press Enter key.
                        </span>
                      </>
                    )}
                  </>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    );
};

export default Garage_Add_New_Car;