import React from 'react';
import img from '../../images/demoimage.png';
import successtick from '../../images/successtick.png';
import AppContext from "../../AppContext";
import { useContext } from "react";

const dateFormate = (value) => {
  let dategetted = value;
  let parts = dategetted.split("-");
  let convertedDate = parts[1] + "/" + parts[0] + "/" + parts[2];

  let date = new Date(convertedDate);
  let options = { month: "long", day: "numeric", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

const Overlay = ({ title, message, otherInfo }) => {
  const myContext = useContext(AppContext);
  return title === "interested" ? (
    <div className="car_success_wrapper">
      <div className="car_inner_success">
        <div className="logo_Text">
          <div className="image-frame square">
            <img src={successtick}></img>
          </div>
        </div>
        <div className="details">
          <h2>
            {localStorage.getItem("usermodelfirstname")}{" "}
            {localStorage.getItem("usermodellastname")}
          </h2>
        </div>
        <div className="details">
          <p>
            Your information has been shared with the dealership. They will
            contact you soon.
          </p>
          <br></br>
          <p>Meanwhile, Continue Browsing.</p>
          <br></br>
        </div>
      </div>
    </div>
  ) : title === "additem" ? (
    <div className="car_success_wrapper">
      <div className="car_inner_success">
        <div className="logo_Text">
          <div className="image-frame square">
            <img src={successtick}></img>
          </div>
        </div>

        <div className="message mb-1">
          {/* <h3>{message}</h3> */}
          <h3>Added To Cart</h3>
        </div>

        <div className="details">
          {/* <h3>Thank you, <br></br> IBIZI</h3> */}
        </div>
      </div>
    </div>
  ) : title === "succesfullpayment" ? (
    <div className="car_success_wrapper">
      <div className="car_inner_success">
        <div className="logo_Text">
          <div className="image-frame" style={{ width: "15%" }}>
            <img src={successtick}></img>
          </div>
        </div>

        <div className="message">
          <h3 className="mb-0">
            Thank You <br></br>
            {localStorage.getItem("usermodelfirstname")}{" "}
            {localStorage.getItem("usermodellastname")}
          </h3>
        </div>

        <div className="details">
          <p className="mt-0">
            for scheduling the services or protection plan with us.
            <br></br>
            <br></br>
            Your order has been sent to the dealership. They will contact you
            soon.
            <br></br>
            <br></br>
            <br></br>
            <span>
              <i className="fa-solid fa-calendar-days"></i>{" "}
              {dateFormate(otherInfo.date)}
            </span>
            <br></br>
            <br></br>
            <span>
              <i className="fa-solid fa-clock"></i> {otherInfo.time}
            </span>
            <br></br>
            <br></br>
          </p>

          <button
            className="btn_theme fullwidth w-100"
            onClick={async () => {
              myContext.setActive("Garage");
              // let end_point = "Notifications/NotificationsList";
              // let api_url = myContext.baseUrl + end_point;

              // try {
              //   const token = myContext.token;
              //   const headers = {
              //     Authorization: token,
              //   };
              //   const response = await fetch(api_url, { headers });
              //   const data = await response.json();
              //   this.context.setnotificationBagdeCount(data.notificationBadgeCount);
              // } catch (error) {
              //   console.error("Error fetching data:", error);
              // }
              // let end_pointt = "Notifications/NotificationsList";
              // let api_url = myContext.Base_url + end_pointt;

              // try {
              //   const token = localStorage.getItem('token');
              //   const headers = {
              //     Authorization: token,
              //   };
              //   const response = await fetch(api_url, { headers });
              //   const data = await response.json();
              //     myContext.setnotificationBagdeCount(data.notificationBadgeCount);
              // } catch (error) {
              //   console.error("Error fetching data:", error);
              // }
              myContext.setupdatenoticount(true);
            }}>
            Back to Garage
          </button>
          {/* <h3>Thank you, <br></br> IBIZI</h3> */}
        </div>
      </div>
    </div>
  ) : title === "partaccessories" ? (
    <div className="car_success_wrapper">
      <div className="car_inner_success">
        <div className="logo_Text">
          <div className="image-frame square">
            <img src={successtick}></img>
          </div>
        </div>

        <div className="message mb-1">
          <h3>{message}</h3>
          {/* <h3>Added To Cart</h3> */}
        </div>

        <div className="details">
          {/* <h3>Thank you, <br></br> IBIZI</h3> */}
        </div>
      </div>
    </div>
  ) : (
    <div className="car_success_wrapper">
      <div className="car_inner_success">
        <div className="logo_Text">
          <div className="image-frame">
            <img src={img}></img>
          </div>
          <h5>Dealership</h5>
        </div>

        <div className="message">
          <h3>
            You have successfully <br></br> added a new car.
          </h3>
        </div>

        <div className="details">
          <h5>
            Now you can review this car’s status and avail services from the
            relevant dealership. If you need further assistance, contact our
            Customer Service Representative.
          </h5>

          {/* <h3>Thank you, <br></br> IBIZI</h3> */}
        </div>
      </div>
    </div>
  );
};

export default Overlay;