import React from 'react';
import Slider from "react-slick";
import { useContext } from 'react';
import AppContext from '../../AppContext';
import ShopViewMore from './Shop_view_more';
import loader from '../../images/loader.gif';
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';



async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

const ShopNewVehicles = ({title}) => { 
    const myContext = useContext(AppContext);


    let base_url = myContext.Base_url;
    let endpoint = 'Shop/ShopVehicleFilterSearch'
    let api_token = localStorage.getItem('token'); 


    const favoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };
      const response = await markAsFavorite(itemId, contextData);
      if (!response) {
          setIsLoading(false);
          myContext.setfavoriteitemloader(false);
      }
    };

    const unfavoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };
      
      const response = await removeFromFavorite(itemId, contextData);
      if (!response) {
        setIsLoading(false);
        myContext.setfavoriteitemloader(false);
      }
    };

    // API URL For  Used Vehicles > Used Member Ship
    let apiurlUsedMember = base_url+endpoint+'?'+'New'+'='+false+'&'+'Used'+'='+true+'&'+'CarShopType'+'='+0+'&'+'Year'+'='+'&'+'Make'+'='+'&'+'Model'+'='+'&'+'LowPrice'+'='+0+'&'+'HighPrice'+'='+0+'&'+'MonthlyLowPrice'+'='+0+'&'+'MonthlyHighPrice'+'='+0+'&'+'SearchString'+'='+''+'&'+'PageIndex'+'='+1+'&'+'PageSize'+'='+8;

    // Check API Loading Status
    const [isLoading, setIsLoading] = React.useState(true);
    
    // Set API Response into model
    const [isdataloded, setisdataloded] = React.useState(false);
    
    // Set API Model
    const [data, setData] = React.useState([]);

     const [apistatus, setapistatus] = React.useState(false);
     const [apimessage, setapimessage] = React.useState(false);


    

    // Membership Discounts on Used Cars
    const [dataformember, setdataformember] = React.useState([]);
    const [totalcountusedmember, settotalcountusedmember] = React.useState();
    
    // Used Special
    const [dataforusedspecial, setdataforusedspecial] = React.useState([]);
    const [totalcountusedspecial, settotalcountusedspecial] = React.useState();
    
    //  Used All
    const [dataforusedall, setdataforusedall] = React.useState([]);
    const [totalcountusedall, settotalcountusedall] = React.useState();


    
    function callAPIForShop() {
        let api_url = apiurlUsedMember;
        const url = api_url;
        axios({
          method: "get",
          url: url,
          headers: { Authorization: api_token },
        })
          .then((res) => {
            setapistatus(res.data.status);
            setapimessage(res.data.message);
            setData(res.data.getAllCategorizedList);
          })
          .catch((error) => {
            if (error.response.data.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            }
            console.log(error);
          }); 
    } 
    if(myContext.favoriteitemloader) {
        callAPIForShop();
    }

    // Create our number formatter.
    var  formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });

    React.useEffect(() => {
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            callAPIForShop();
        }
       
    }, []);
    React.useEffect(() => { 
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            if (data.length !== 0) {
                setIsLoading(false);
                setisdataloded(true);

                setdataformember(data.usedCars.shopModelUsedMembership); 
                settotalcountusedmember(data.usedCars.shopModelUsedMembershipCount); 


                setdataforusedspecial(data.usedCars.shopModelUsedSpecial); 
                settotalcountusedspecial(data.usedCars.shopModelUsedSpecialCount); 


                setdataforusedall(data.usedCars.shopModelUsedAll);
                settotalcountusedall(data.usedCars.shopModelUsedAllCount);
            } else {

            }
        }
    }, [data]);

    // Slider Setting
    const settings = {
        arrows: true,
        slidesToShow: 4, 
        slidesToScroll: 1,
        infinite: false,
        dot: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    

    if(api_token !== undefined && api_token !== null && api_token !== '') {    
        return (
          <div className="content_panel_controller dir_column">
            <ToastContainer hideProgressBar={true} />
            {myContext.shopcardetailsectionisopen === "UsedVehicleUsedCars" && (
              <ShopViewMore
                title="UsedVehicleUsedCars"
                totalCount={totalcountusedmember}
                CarShopType="5"
                from="used"
              />
            )}
            {myContext.shopcardetailsectionisopen ===
              "UsedVehicleUsedSpecial" && (
              <ShopViewMore
                title="UsedVehicleUsedSpecial"
                totalCount={totalcountusedspecial}
                CarShopType="6"
                from="used"
              />
            )}
            {myContext.shopcardetailsectionisopen === "UsedVehicleUsedAll" && (
              <ShopViewMore
                title="UsedVehicleUsedAll"
                totalCount={totalcountusedall}
                CarShopType="8"
                from="used"
              />
            )}
            {isLoading && (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            )}
            {apistatus === false ? (
              <h4>{apimessage}</h4>
            ) : (
              <div className="shop__row_wrapper">
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountusedmember != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountusedmember != 0 && (
                          <h4 className="title_medium">
                            Membership Discounts on Used Cars
                          </h4>
                        )}
                        <span
                          id="usedvehicleusedcar"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "UsedVehicleUsedCars"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            dataformember.map((user, i) => (
                              <div className="relative" key={i}>
                                {console.log(user)}
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              // myContext.setfavoriteitemloader(user.id);
                                              // myContext.setcarID(user.id);
                                              // myContext.setentityenum(2);
                                              // myContext.setcallfavAPI('favorite');
                                              // myContext.setsinglecardetailsectionisopen(false);
                                              // setIsLoading(true);
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carcutprice">
                                        <del>
                                          {formatter.format(user.sellingPrice)}
                                        </del>
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.netPrice)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountusedspecial != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountusedspecial != 0 && (
                          <h4 className="title_medium">Used Special</h4>
                        )}
                        <span
                          id="usedvehicleusedspecial"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "UsedVehicleUsedSpecial"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            dataforusedspecial.map((user, i) => (
                              <div className="relative" key={i}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              // myContext.setfavoriteitemloader(user.id);
                                              // myContext.setcarID(user.id);
                                              // myContext.setentityenum(2);
                                              // myContext.setcallfavAPI('unfavorite');
                                              // myContext.setsinglecardetailsectionisopen(false);
                                              // setIsLoading(true);
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              // myContext.setfavoriteitemloader(user.id);
                                              // myContext.setcarID(user.id);
                                              // myContext.setentityenum(2);
                                              // myContext.setcallfavAPI('favorite');
                                              // myContext.setsinglecardetailsectionisopen(false);
                                              // setIsLoading(true);
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.sellingPrice)}
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}

                {myContext.shopcardetailsectionisopen === "" &&
                  totalcountusedall != 0 && (
                    <div className="shop__row">
                      <div className="flex_start_center">
                        {totalcountusedall != 0 && (
                          <h4 className="title_medium">
                            All Used Vehicles Inventory
                          </h4>
                        )}
                        <span
                          id="usedvehicleusedall"
                          className="ml-auto v-more"
                          onClick={() => {
                            myContext.setshopcardetailsectionisopen(
                              "UsedVehicleUsedAll"
                            );
                          }}>
                          View More
                        </span>
                      </div>
                      <div className="shop__row__slider_wrapper">
                        <Slider {...settings}>
                          {isLoading ? (
                            <div className="loader-div">
                              <img alt="img" src={loader} id="belliconid"></img>
                            </div>
                          ) : (
                            isdataloded &&
                            dataforusedall.map((user, i) => (
                              <div className="relative" key={i}>
                                <div className="heart_icon">
                                  {(() => {
                                    switch (myContext.favoriteitemloader) {
                                      case user.id:
                                        return (
                                          <div className="loader-div">
                                            <img
                                              alt="img"
                                              src={loader}
                                              id="belliconid"></img>
                                          </div>
                                        );
                                      default:
                                        return user.favorite ? (
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Remove From Favorite");
                                              // myContext.setfavoriteitemloader(user.id);
                                              // myContext.setcarID(user.id);
                                              // myContext.setentityenum(2);
                                              // myContext.setcallfavAPI('unfavorite');
                                              // myContext.setsinglecardetailsectionisopen(false);
                                              // setIsLoading(true);
                                              // toast("Remove From Favorite");
                                              unfavoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        ) : (
                                          <img
                                            alt="img"
                                            src={UnfavIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              // toast("Mark As Favorite");
                                              // myContext.setfavoriteitemloader(user.id);
                                              // myContext.setcarID(user.id);
                                              // myContext.setentityenum(2);
                                              // myContext.setcallfavAPI('favorite');
                                              // myContext.setsinglecardetailsectionisopen(false);
                                              // setIsLoading(true);
                                              // toast("Mark As Favorite");
                                              favoriteMe({
                                                Entity: 2,
                                                tableId: user.id,
                                                CarId: 0,
                                              });
                                              myContext.setfavoriteitemloader(
                                                user.id
                                              );
                                              myContext.setsinglecardetailsectionisopen(
                                                false
                                              );
                                              // setIsLoading(true);
                                            }}></img>
                                        );
                                    }
                                  })()}
                                </div>
                                <div
                                  className="shop__row__slider__items relative"
                                  onClick={() => {
                                    myContext.setsinglecardetailsectionisopen(
                                      true
                                    );
                                    myContext.setcarID(user.id);
                                  }}>
                                  <div className="shop__row__slider__items__image_wrapper">
                                    <img
                                      alt="img"
                                      src={user.images}
                                      id=""></img>
                                  </div>
                                  <div className="shop__row__slider__items__content_wrapper">
                                    <div className="flex-space-between-start">
                                      <span className="cartitle">
                                        {user.make} {user.model}
                                      </span>
                                      <span className="carprice">
                                        {formatter.format(user.sellingPrice)}
                                      </span>
                                    </div>
                                    <div className="flex-space-between-start">
                                      <span className="carmodel">
                                        {user.year}
                                      </span>
                                      <span className="carprice"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        );
    } else {
        return(
            <h1>Token Expired</h1>
        )
    }
    
};

export default ShopNewVehicles;