import React, { useState, useEffect } from "react";
import AppContext from '../../AppContext';
import { useContext } from 'react';
import '../Maincomponents/Shop.css';
import bellicon from '../../images/bellicon.png';
import usericon from '../../images/usericon.png';
import loader from "../../images/loader.gif";
import axios from "axios";
import { async } from '@firebase/util';
import $ from "jquery";


async function NotificationDetail(model) {
    return fetch(model.base_url + 'Notifications/NotificationDetail?NotificationIds='+ model.notiID, {
        method: 'GET',
        headers: {
        'Authorization' : model.api_token,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8'
        }
    })
    .then((response) => response.json())
    .then((json) => json)
}

const TopNav = ({title}) => {
  const myContext = useContext(AppContext);
  const [notificationList, setnotificationList] = useState(false);
  const [notificationCount, setnotificationCount] = useState(
    myContext.notificationBagdeCount
  );
  const [loaders, setloaders] = useState(false);
  const [enableDD, setenableDD] = useState(false);
  let base_url = myContext.Base_url;
  let api_token = localStorage.getItem("token");
  let notificationListObj = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  function opensidenav() {
    var sidebar = document.querySelector(".Left__panel");
    sidebar.classList.toggle("toggle");
  }


  const NotificationsList = async () => {
    try {
      const response = await axios.get(
        base_url + "Notifications/NotificationsList?",
        {
          params: {
            'PageNumber': currentPage,
            'PageSize': 20,
          },
          headers: { Authorization: api_token },
        }
      );
      setloaders(false);
      return response.data; // Assuming your API response is an object with a "data" property that holds the array of items.
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const setStateTrue = async () => {
    setloaders(true);
    setnotificationList(false);
    const response = await NotificationsList();
    if (response.status) {
      setnotificationList(true);
      setloaders(false);
      notificationListObj = response;
      // myContext.setnotificationBagdeCount(
      //   notificationListObj.notificationBadgeCount
      // );
      setData(notificationListObj.notificationModels);
    } else {
      alert(response.message);
    }
  };

   React.useEffect(() => { 
     if (notificationList) {
       setStateTrue();
     }
   }, [currentPage]);
  
  const handleSeen = async (event, noti) => {
    const jsonObject = JSON.parse(noti.notificationFilterAttributeModelString);
    debugger
    if (jsonObject.NotificationsLanding == 2) {
      // For Market Place
      (async () => {
        const Shop = async () => {
          $(".backbutton").click();
          myContext.setActive("Shop");
          return true;
        };
        const shopIsOpen = await Shop();
        if (shopIsOpen) {
          if (jsonObject.IsNew) {
            $("#newVahicleTab").click();
            if (jsonObject.TableId == 0) {
              function findElement() {
                const myElement = document.getElementById(
                  "newallvahicleinventory"
                );
                if (myElement) {
                  (async () => {
                    const clickedVMore = async () => {
                      $("#newallvahicleinventory").click();
                      return true;
                    };
                    const clickedVMoreIsOpen = await clickedVMore();
                    if (clickedVMoreIsOpen) {
                      (async () => {
                        const clickedVMore = async () => {
                          // $(".filter").click();
                          // $(".input_field").val("X-90");

                          return true;
                        };
                        const clickedVMoreIsOpen = await clickedVMore();
                        if (clickedVMoreIsOpen) {
                          setenableDD(false);

                          // $(".input_field").trigger($.Event("keypress", { keyCode: 13 }));

                          function findElements() {
                            const myElement = $(".loader-div");
                            if (myElement.length == 0) {
                              //  $(".input_field").val("X-90");
                              (async () => {
                                const clickedVMoreFetchProperly = async () => {
                                  return true;
                                };
                                const clickedVMoreFetchProperlyValue =
                                  await clickedVMoreFetchProperly();
                                if (clickedVMoreFetchProperlyValue) {
                                  // Optionally, you can trigger the keyup event programmatically
                                  // setTimeout(function () {
                                  //   console.log("run");
                                  //   // $(".input_field").trigger("keypress", { which: 13 });
                                  //   $(document).ready(function() {
                                  //     // Select the input field
                                  //     const inputField = $(".input_field");
                                  //     // Simulate Enter key press
                                  //     inputField.keypress(function(event) {
                                  //       if (event.keyCode === 13) {
                                  //         // Trigger your desired action here
                                  //         inputField.trigger($.Event("keypress", { keyCode: 13 }));
                                  //         console.log("Enter key pressed");
                                  //       }
                                  //     });
                                  //     // Optionally, trigger the keypress event programmatically
                                  //     inputField.trigger($.Event("keypress", { keyCode: 13 }));
                                  //   });
                                  // }, 5000);
                                }
                              })();
                            } else {
                              console.log("trying...");
                              setTimeout(findElements, 100);
                            }
                          }
                          findElements();
                        }
                      })();
                    }
                  })();
                } else {
                  setTimeout(findElement, 100);
                }
              }
              findElement();
              document.addEventListener("DOMContentLoaded", findElement);
            }
          } else {
            $("#usedVahicleTab").click();
            if (jsonObject.TableId == 0) {
              function findElement() {
                const myElement = document.getElementById("usedvehicleusedall");
                if (myElement) {
                  (async () => {
                    const clickedVMore = async () => {
                      $("#usedvehicleusedall").click();
                      return true;
                    };
                    const clickedVMoreIsOpen = await clickedVMore();
                    if (clickedVMoreIsOpen) {
                      (async () => {
                        const clickedVMore = async () => {
                          // $(".filter").click();
                          // $(".input_field").val("X-90");

                          return true;
                        };
                        const clickedVMoreIsOpen = await clickedVMore();
                        if (clickedVMoreIsOpen) {
                          setenableDD(false);

                          // $(".input_field").trigger($.Event("keypress", { keyCode: 13 }));

                          function findElements() {
                            const myElement = $(".loader-div");
                            if (myElement.length == 0) {
                              //  $(".input_field").val("X-90");
                              (async () => {
                                const clickedVMoreFetchProperly = async () => {
                                  return true;
                                };
                                const clickedVMoreFetchProperlyValue =
                                  await clickedVMoreFetchProperly();
                                if (clickedVMoreFetchProperlyValue) {
                                  // Optionally, you can trigger the keyup event programmatically
                                  // setTimeout(function () {
                                  //   console.log("run");
                                  //   // $(".input_field").trigger("keypress", { which: 13 });
                                  //   $(document).ready(function() {
                                  //     // Select the input field
                                  //     const inputField = $(".input_field");
                                  //     // Simulate Enter key press
                                  //     inputField.keypress(function(event) {
                                  //       if (event.keyCode === 13) {
                                  //         // Trigger your desired action here
                                  //         inputField.trigger($.Event("keypress", { keyCode: 13 }));
                                  //         console.log("Enter key pressed");
                                  //       }
                                  //     });
                                  //     // Optionally, trigger the keypress event programmatically
                                  //     inputField.trigger($.Event("keypress", { keyCode: 13 }));
                                  //   });
                                  // }, 5000);
                                }
                              })();
                            } else {
                              console.log("trying...");
                              setTimeout(findElements, 100);
                            }
                          }
                          findElements();
                        }
                      })();
                    }
                  })();
                } else {
                  setTimeout(findElement, 100);
                }
              }
              findElement();
              document.addEventListener("DOMContentLoaded", findElement);
            }
          }
        }
      })();

    } else if ( jsonObject.NotificationsLanding == 3 || jsonObject.NotificationsLanding == 4 ) {
      // For Services & Protection Plan
      (async () => {
        const garage = async () => {
          $(".backbutton").click();
          myContext.setActive("Garage");
          return true;
        };
        const garageIsOpen = await garage();
        if (garageIsOpen) {
          function findElement() {
            const myElement = document.getElementById("garageCarBox");
            if (myElement) {
              (() => {
                let end_point = "Garage/UserCars";
                let api_url = base_url + end_point;

                // Main CarInfo API Start
                fetch(api_url, {
                  method: "GET",
                  headers: {
                    Authorization: api_token,
                  },
                })
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      if (result.status) {
                        (async () => {
                          const doFunc = async () => {
                            const indexOfDodge =
                              result.carInfoDetailModels.findIndex(
                                (car) =>
                                  car.make === jsonObject.Make ||
                                  car.make === jsonObject.Model ||
                                  car.make === jsonObject.Year
                              );
                            $("#clickId").attr(
                              "car-id",
                              result.carInfoDetailModels[indexOfDodge].id
                            );
                            $("#clickId").attr(
                              "carimg",
                              result.carInfoDetailModels[indexOfDodge].images
                            );
                            $("#clickId").attr(
                              "carmakemodel",
                              result.carInfoDetailModels[indexOfDodge].make +
                                " " +
                                result.carInfoDetailModels[indexOfDodge].model
                            );
                            $("#clickId").attr(
                              "dealername",
                              result.carInfoDetailModels[indexOfDodge]
                                .dealerShipName
                            );
                            $("#clickId").attr(
                              "dealarrid",
                              result.carInfoDetailModels[indexOfDodge]
                                .dealerShipId
                            );
                            $("#clickId").click();
                            setenableDD(false);

                            return true;
                          };
                          const ress = await doFunc();
                          if (ress) {
                            if (jsonObject.NotificationsLanding == 4) {
                              const doFuncTabclick = async () => {
                                $("#cardetailtabpp").click();
                                return true;
                              };
                              const aftertabclick = await doFuncTabclick();
                              if (aftertabclick) {
                                function findElement() {
                                  const element = document.querySelector(".carinfo_cards_box_wrapper_items_frame_controller");
                                  if (element) {
                                    $(".carinfo_cards_box_wrapper_items_frame_controller[box-id='"+jsonObject.TableId+"']").click();// Do something with the element
                                  } else {
                                    console.log(
                                      "Element not found. Retrying..."
                                    );
                                    // Retry after a delay (e.g., 100 milliseconds)
                                    setTimeout(findElement, 100);
                                  }
                                }

                                // Start trying to find the element
                                findElement();
                                
                                
                              }

                            } else {
                              const doFuncTabclick = async () => {
                                $("#cardetailtabservice").click();
                                return true;
                              };
                              const aftertabclick = await doFuncTabclick();
                              if (aftertabclick) {
                                function findElement() {
                                  const element = document.querySelector(
                                    ".carinfo_cards_box_wrapper_items_frame_controller"
                                  );
                                  if (element) {
                                    $(
                                      ".carinfo_cards_box_wrapper_items_frame_controller[box-id='" +
                                        jsonObject.TableId +
                                        "']"
                                    ).click(); // Do something with the element
                                  } else {
                                    console.log(
                                      "Element not found. Retrying..."
                                    );
                                    // Retry after a delay (e.g., 100 milliseconds)
                                    setTimeout(findElement, 100);
                                  }
                                }

                                // Start trying to find the element
                                findElement();
                              }
                              
                            }
                          }
                        })();
                      } else {
                        if (result.message == "AuthToken is Expired") {
                          localStorage.setItem("token", "");
                        }
                      }
                    },
                    (error) => {}
                  );
              })();

              // if (jsonObject.Make.length != 0 || jsonObject.Model.length != 0 || jsonObject.Year.length != 0) {
              //   findIndexWithText(jsonObject.Make, 100); // Adjust the maximum attempts as needed
              // }
            } else {
              setTimeout(findElement, 100);
            }
          }
          findElement();
          document.addEventListener("DOMContentLoaded", findElement);
        }
      })();

    } else {
      // For Notification Screen
    }


    // event.preventDefault();

    // var response = await NotificationDetail({ api_token, base_url, notiID });
    // //  console.log(response);
    // if (response.status) {
    //   setStateTrue();
    // }
  };

  const handleProfileState = () => {
    myContext.setActive("UserProfile");
  };

  function handleLoadMore() {
    
    setCurrentPage(currentPage+1);
    // setStateTrue();
  };

  return (
    <span className="top_iconbar__right">
      <img
        alt="img"
        src={bellicon}
        onClick={() => {
          // myContext.setnotidropdowstatehide(true);
          if (enableDD) {
            setenableDD(false);
          } else {
            setenableDD(true);
            myContext.setnotificationBagdeCount(0);
          }
          setStateTrue();
        }}
        id="belliconid"></img>

      {myContext.notificationBagdeCount == 0 ? (
        // <span className="notibadge">{notificationCount}</span>
        ""
      ) : (
        <span className="notibadge">{myContext.notificationBagdeCount}</span>
      )}
      {enableDD && (
        <>
          <div
            className={`notification_list_wrapper ${
              data.length === 0 ? "flex-center-center" : ""
            }`}>
            {loaders == true ? (
              <div className="loader-div">
                <img alt="img" src={loader}></img>
              </div>
            ) : notificationList == true ? (
              data.length === 0 ? (
                <div className="">
                  <h2 className="title_small App w-100">
                    No Further Notification
                  </h2>
                  <div className="Loadmorebtn Loadmorebtnnoti">
                    <button
                      onClick={() => {
                        setCurrentPage(1);
                      }}
                      className="btn_theme">
                      Reload
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {data.map((noti, i) => (
                    <div key={i}>
                      <div
                        className="new_notifications"
                        onClick={(event) => handleSeen(event, noti)}>
                        <div className="email_inner">
                          <div className="image_wrapper">
                            <img src={noti.notificationImage} alt="img"></img>
                          </div>
                          <div className="text-wrapper">
                            <h4
                              className={
                                noti.isNotificationView ? "c-grey" : ""
                              }>
                              {noti.notificationSubject}
                            </h4>
                            <p
                              className={
                                noti.isNotificationView ? "c-grey" : ""
                              }>
                              {noti.notificationDetails}
                            </p>
                            <span>
                              {noti.daysAgo > 1
                                ? noti.daysAgo + " days ago"
                                : "Today"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="hr_css"></hr>
                    </div>
                  ))}
                  <div className="Loadmorebtn Loadmorebtnnoti">
                    {(() => {
                      let button;

                      let comparelength = button;
                      if (50 > 10) {
                        button = (
                          <button
                            onClick={handleLoadMore}
                            className="btn_theme">
                            Load More
                          </button>
                        );
                      } else {
                        button = (
                          <button
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                            className="btn_theme">
                            Reload
                          </button>
                        );
                      }
                      return button;
                    })()}
                  </div>
                </>
              )
            ) : (
              ""
            )}
          </div>
        </>
      )}
      <span className="verticle__seprator">|</span>
      <span className="username">
        {localStorage.getItem("usermodellastname")}
        {/* {sessionStorage.getItem('usermodellastname')} */}
      </span>
      <img
        alt="img"
        src={usericon}
        onClick={handleProfileState}
        id="usericonid"></img>
      <span className="humburger showonmobile" onClick={opensidenav}>
        <i className="fa-solid fa-bars"></i>
      </span>
    </span>
  );
};
export default TopNav;


