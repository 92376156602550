import React, { useEffect, useState } from "react";
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import { useContext } from 'react';
import Login from "./Login";
import OTPInput from "otp-input-react";
import { useParams } from 'react-router-dom';
import { async } from "@firebase/util";
import $ from 'jquery';

async function CheckOTP(otp) {
    const body_user = { 
      "mobileNumber": "",
      "email": "",
      "otpCode": otp.OTP,
      "verifyOtpFrom": 1
    }
    let end_point = 'User/VerifyOTP';
    let api_url = ''; 
    if (otp.getfrom === 'declinedflow') {
      api_url = otp.val+end_point; 
    } else {
      api_url = otp.val.Base_url+end_point; 
    }
    return fetch(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body_user)
    })
    .then((response) => response.json())
    .then((json) => json)
}
async function DeclinedCheckOTP(otp) {
    const body_user = { 
      "mobileNumber": "",
      "email": "",
      "otpCode": otp.otpcode,
      "verifyOtpFrom": 1
    }
    let end_point = 'User/VerifyOTP';
    let api_url = otp.url+end_point; 
    return fetch(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body_user)
    })
    .then((response) => response.json())
    .then((json) => json)
}


async function SignUpUser(userInfo) {
  const body_user = {
      "firstName": userInfo.firstname,
      "lastName": userInfo.lastname,
      "email": userInfo.username,
      "password": userInfo.password,
      "mobileNumber": userInfo.mobilenumber,
      "zipCode": userInfo.zipcode,
      "dateOfBirth": userInfo.dob,
      "dealerShipId": userInfo.dealershipID,
      "DeviceId": "EC46D0B5-B5F4-4814-A5C1-9E8E542A2B12",
      "DeviceFCMToken": "e28GP-9sgEtsskoxOmlkym:APA91bFPqx5brFOzQl_92yi_6spmYS_nxWp9naQlaJxZURZJ1XDLFh0OeFzLTY3O5a9eZPwg81JFdY7soi3lFlEJ1gcg63v2o7b9bkktbSzj9oFB7zWdvV1NN2yiGoofAgT64YPP8ddo",
      "IsIOSorAndroid": true
  }
  
  let end_point = 'User/Signup';
  let api_url = userInfo.myContext.Base_url+end_point; 

  return fetch(api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
}


async function loginUser(credentials) {
  
  const body_user = {
    "email": credentials.username,
    "password": credentials.password,
    "DeviceId": "",
    "DeviceFCMToken": "",
    "IsIOSOrAndroid": true
  }
  let end_point = 'User/Signin';
  let api_url = credentials.myContext.Base_url+end_point; 
  return fetch(api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
  .catch((error) => {
    console.log("error " + error)
  }) 
}


const Register = (props) => {
  const myContext = useContext(AppContext);
  const [guid, setguid] = useState();
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [username, setUserName] = useState();
  const [mobilenumber, setmobilenumber] = useState();
  const [zipcode, setzipcode] = useState();
  const [dob, setdob] = useState();
  const [password, setPassword] = useState();
  const [PasswordOne, setPasswordOne] = useState();
  const [PasswordConfirm, setPasswordConfirm] = useState();
  const [isloaderopen, setisloaderopen] = useState(false);
  const [valid, setValid] = useState();
  const [guidForm, setguidForm] = useState(true);
  const [signupForm, setsignupForm] = useState(false);
  const [dealershipID, setdealershipID] = useState();
  const [passmatched, setpassmatched] = useState(false);

  const [OTP, setOTP] = useState("");
  const [btnstate, setbtnstate] = useState(false);

  const [declinedotpstate, setdeclinedotpstate] = useState();
  const [disabledfield, setdisabledfield] = useState(false);
  // console.log(myContext.declineAddtoModel);
 
  $(document).ready(function() {
    const today = new Date().toISOString().split('T')[0];
    $('#dateofbirth').attr('max', today);
  });
  $("#zipcodefield").on("input", function () {
    if ($(this).val().length > 5) {
      $(this).val($(this).val().slice(0, 5));
    }
  });
  useEffect(() => {
    // setguidForm(false);
    let url_str = window.location.href;
    let url = new URL(url_str);
    let search_params = url.searchParams; 

    if (props.registrationfrom === 'declinedflow') {
      setdisabledfield(true)
    }
    (async () => {
      if (props.registrationfrom === 'declinedflow') {
        setguidForm(false);
        setOTP(search_params.get('declinedId'));
        let url = props.baseUrl;
        let otpcode = search_params.get('declinedId');
        const signup_me = await DeclinedCheckOTP({
          otpcode,
          url
        }); 
        if(signup_me.status){
          localStorage.setItem('usermodelfirstname', signup_me.prospectUserModel.firstName);
          localStorage.setItem('usermodellastname', signup_me.prospectUserModel.lastName);
         
          setisloaderopen(false);
          setFirstName(signup_me.prospectUserModel.firstName);
          setLastName(signup_me.prospectUserModel.lastName);
          setUserName(signup_me.prospectUserModel.email);
          setmobilenumber(signup_me.prospectUserModel.mobileNumber);
          setdealershipID(signup_me.prospectUserModel.dealerShipId);
        }
        else{
          setisloaderopen(false);
          setValid(signup_me.message);
        }
      }
    })();
  
    return () => {
      // this now gets called when the component unmounts
      
    };
    
  }, []);
  

  const handleSubmit = async e => {
    if(OTP !== "") {
      setisloaderopen(true);
      e.preventDefault();
      let getfrom = props.registrationfrom;
      let val = myContext;
      if (props.registrationfrom === 'declinedflow') {
          val = props.baseUrl;
      } else {
          val = myContext;
      }
      const signup_me = await CheckOTP({
        OTP,
        val,
        getfrom
      });
      if(signup_me.status){
        localStorage.setItem('usermodelfirstname', signup_me.prospectUserModel.firstName);
        localStorage.setItem('usermodellastname', signup_me.prospectUserModel.lastName);
        setguidForm(false);
        setisloaderopen(false);
        setFirstName(signup_me.prospectUserModel.firstName);
        setLastName(signup_me.prospectUserModel.lastName);
        setUserName(signup_me.prospectUserModel.email);
        setmobilenumber(signup_me.prospectUserModel.mobileNumber);
        setdealershipID(signup_me.prospectUserModel.dealerShipId);
      }
      else{
        setisloaderopen(false);
        setValid(signup_me.message);
      }
    } else {
      setValid('Invalid OTP')
    }
    
  }

  const handleSignUP = async e => {
      if (passmatched) {
        setisloaderopen(true);
        setValid("")
        e.preventDefault();
        const signup = await SignUpUser({
          firstname,
          lastname,
          mobilenumber,
          zipcode,
          dob,
          username,
          password,
          dealershipID,
          myContext
        });
        if(signup.status){
          setisloaderopen(false);
          localStorage.setItem('usermodelfirstname', firstname);
          localStorage.setItem('usermodellastname', lastname);
          // localStorage.setItem('isDeclined', false);
          setValid("Sign Up Successfully");

          
          
          if (Object.keys(myContext.declineAddtoModel).length > 0) {
            localStorage.setItem('isDeclined', true);
              let x = 0;
              if (x === 0) {
                  const token = await loginUser({
                    username,
                    password,
                    myContext
                  });
                  if(token.status){
                    setisloaderopen(false);
                    localStorage.setItem('usermodelfirstname', token.userInfoModel.firstName);
                    localStorage.setItem('usermodellastname', token.userInfoModel.lastName);
                    localStorage.setItem('token', token.token);
                    localStorage.setItem('isDeclined', true);
                  }
                  else{
                    setisloaderopen(false);
                    setValid("Credientials are Invalid.")
                  }
                  
                  
                  // this.AddToCartApiFunction(this.context.declineAddtoModel);
                  

                  let end_point = 'Cart/AddItemToCart';
                  const url = myContext.Base_url+end_point;
                  fetch(url,{
                      method: 'POST', 
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization' : token.token
                      },
                      body: JSON.stringify(myContext.declineAddtoModel),
                  })
                  .then(res => res.json())
                  .then((result) => {
                      if(result.status) {
                        myContext.setActive("Cart");
                      } else {
                      }
                  },
                  (error) => {
                      this.setState({
                          // additemapistatus:false,
                          error
                      });
                  }) 

                  x = 1;

                  
              }
              
          }
          
        }
        else{
          setisloaderopen(false);
          setValid(signup.message);
        }
      }
  }

 

  function handleChange(OTP) {
    const cleanedValue = OTP.replace(/\D/g, "");
    setOTP(cleanedValue);
    if (OTP.length === 7 ) {
      setbtnstate(true);
    } else {
      setbtnstate(false);
    }
  }

  // Password Show Hide
  const showpwd = (e) =>  {
    const ClickID = e.currentTarget.getAttribute("clickid");
    document.getElementById(ClickID);
    var x = document.getElementById(ClickID);
    if (x.type === "password") {
        e.currentTarget.innerHTML = "Hide";
        x.type = "text";
    } else {
        e.currentTarget.innerHTML = "Show";
        x.type = "password";
    }
  }
  // Password Show Hide

  const Validate = (Np, Cp) => {
    var myInputerrr = document.getElementById("vinValid");
    if (Np.length === 0 || Cp.length === 0 || Np.length === undefined || Cp.length === undefined) {
      setpassmatched(false);
      myInputerrr.innerHTML = "";
    } else {
      if (Np === Cp) {
        setPassword(Np);
        setpassmatched(true);
        myInputerrr.innerHTML = "";
      } else {
        setpassmatched(false);
        myInputerrr.innerHTML = "Password and confirm password must match.";
      }
    }
    
  }


  return (
    <div className="login_wrapper flex-center-center">
      <div className="z-in-1">
        {/* <div className="img-wrap"><img alt='img' src={Logo} className="invertColor" id='Logoid'></img></div> */}
        <div className="form-wrapper">
          {guidForm ? (
            <>
              <form onSubmit={handleSubmit}>
                <div className="form_group">
                  <label>Enter Your OTP For Sign Up</label>
                  <br></br>
                  <div className="inputwrapper otp-wrappr">
                    <OTPInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="inputStyle"
                      OTPLength={7}
                      separator={<span></span>}
                    />
                  </div>
                </div>
                {valid}
                <div className="flex-center-center mt-1">
                  {btnstate && (
                    <>
                      {isloaderopen ? (
                        <div className="loader-div">
                          <img alt="img" src={loader} id="belliconid"></img>
                        </div>
                      ) : (
                        ""
                      )}
                      <button type="submit" className="btn_theme btn-white">
                        Verify
                      </button>
                    </>
                  )}
                </div>
              </form>
            </>
          ) : valid === "Sign Up Successfully" ? (
            <>
              <div className="text-center mb-2">{valid}</div>

              <button
                type="submit"
                className="btn_theme w-100 btn-white"
                onClick={() => {
                  localStorage.setItem("isDeclined", true);
                  const currentUrl = window.location.href;
                  const urlWithoutParams = currentUrl.split("?")[0];
                  window.location.replace(urlWithoutParams);
                  // console.log(urlWithoutParams);
                  // window.location.reload();
                  // myContext.setloginscreenfromsignedup(true);
                  // myContext.setdeclineworkstate(false);
                }}>
                Login
              </button>
            </>
          ) : (
            <form className="formuserinfo" onSubmit={handleSignUP}>
              <span className="w-100 p-1">{valid}</span>

              <div className="form_group_wrapper">
                <div className="form_group">
                  <label>First Name</label>
                  <div
                    className={`${
                      disabledfield ? "disabledield" : ""
                    } inputwrapper`}>
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form_group">
                  <label>Last Name</label>
                  <div
                    className={`${
                      disabledfield ? "disabledield" : ""
                    } inputwrapper`}>
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_group_wrapper">
                <div className="form_group">
                  <label>Email</label>
                  <div
                    className={`${
                      disabledfield ? "disabledield" : ""
                    } inputwrapper`}>
                    <input
                      type="email"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form_group">
                  <label>Mobile Number</label>
                  <div
                    className={`${
                      disabledfield ? "disabledield" : ""
                    } inputwrapper`}>
                    <input
                      type="text"
                      value={mobilenumber}
                      onChange={(e) => setmobilenumber(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_group_wrapper">
                <div className="form_group">
                  <label>Password</label>
                  <div className="inputwrapper">
                    <input
                      type="password"
                      id="oldpwd"
                      onChange={(e) => {
                        // setPassword(e.target.value)
                        setPasswordOne(e.target.value);
                        Validate(e.target.value, PasswordConfirm);
                      }}
                      required
                    />
                    <span
                      className="show_hide"
                      onClick={showpwd}
                      clickid="oldpwd">
                      Show
                    </span>
                  </div>
                </div>
                <div className="form_group">
                  <label>Confirm Password</label>
                  <div className="inputwrapper">
                    <input
                      type="password"
                      id="newpwd"
                      onChange={(e) => {
                        // setPassword(e.target.value)
                        setPasswordConfirm(e.target.value);
                        Validate(e.target.value, PasswordOne);
                      }}
                      required
                    />
                    {/* <label id="vinValid" className='c-red m-0'></label> */}
                    <span
                      className="show_hide"
                      onClick={showpwd}
                      clickid="newpwd">
                      Show
                    </span>
                  </div>
                </div>
              </div>

              <div className="form_group_wrapper">
                <div className="form_group">
                  <label>Zip Code</label>
                  <div className="inputwrapper">
                    <input
                      id="zipcodefield"
                      type="number"
                      onChange={(e) => setzipcode(e.target.value)}
                      max="99999"
                      maxlength="5"
                      pattern="[0-9]{5}"
                      required
                    />
                  </div>
                </div>
                <div className="form_group">
                  <label>Date Of Birth</label>
                  <div className="inputwrapper">
                    <input
                      type="date"
                      id="dateofbirth"
                      onChange={(e) => setdob(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_group_wrapper">
                <div className="form_group">
                  <div className="inputwrapper">
                    <label id="vinValid" className="c-red m-0"></label>
                  </div>
                </div>
              </div>

              {passmatched && (
                <div className="flex-center-center mt-2">
                  {isloaderopen ? (
                    <div className="loader-div">
                      <img
                        alt="img"
                        src={loader}
                        className="brokenimg"
                        id="belliconid"></img>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className='btn_theme btn-white' onClick={() => {setmainsectionopen(true)}}>Add New Car</div> */}
                  <button type="submit" className="btn_theme btn-white">
                    Sign up
                  </button>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
}


export default Register;