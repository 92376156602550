import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import AppContext from "./AppContext";
import { useContext } from "react";

// const vall = localStorage.getItem('username');
// debugger

// FOR DEVELOPMENT CONFIGURATION
const firebaseConfig = {
  apiKey: "AIzaSyDwPxFmxdU-e0KoYdowsvOhueWV9JtKk8k",
  authDomain: "ibizi-uat.firebaseapp.com",
  projectId: "ibizi-uat",
  storageBucket: "ibizi-uat.appspot.com",
  messagingSenderId: "763547194979",
  appId: "1:763547194979:web:a09c31f406c4c7efb8ca56",
  measurementId: "G-ZQK79X71ZN"
};

// FOR PRODUCTION CONFIGURATION
// const firebaseConfig = {
//   apiKey: "AIzaSyDLsCxqSd-4bHdORQshJvA0J_rz2FEWLOU",
//   authDomain: "ibizi-prod.firebaseapp.com",
//   projectId: "ibizi-prod",
//   storageBucket: "ibizi-prod.appspot.com",
//   messagingSenderId: "1023988618997",
//   appId: "1:1023988618997:web:c783e45953d012ee5057bc",
//   measurementId: "G-H7RXGLV3ES",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);