import React, { Component } from 'react';
import loader from '../../../images/loader.gif';
import AppContext from '../../../AppContext';
import { v4 } from 'uuid';


class CarInfo extends Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isRighpanelOpen: false,
            api_token: localStorage.getItem('token')
        };
        this.boxClick = this.boxClick.bind(this);
        
    }


    // After Rendering
    componentDidMount() {
        let end_point = 'Garage/GetUserCarCarInfo';
        let url_parameter = '?CarId=';
        let api_url = this.context.Base_url+end_point+url_parameter+this.props.carId;

        // Main CarInfo API Start
        fetch(api_url,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: result.carInfoModels,
                        isRighpanelOpen: false,
                        clickItemId: 0,
                        isLoadedDetail: false,
                        color: '',
                        apiStatusDetail: false
                    });
                } else {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
            
        )
        // Main CarInfo API End
    }

    // After Compeleting Cycle..
    componentWillUnmount() {
        // Do Something ...
    }

    boxClick = e => {
        const boxID = e.currentTarget.getAttribute("box-id");
        const color = e.currentTarget.getAttribute("color");
        this.setState({
            color: color
        })
        this.setState({
            isRighpanelOpen: true
        })
        this.setState({
            isLoadedDetail: false
        })

        // Detail API Start
        let end_point_detail_panel = 'Garage/GetUserCarCarInfoDetail';
        let url_parameter_detail = '?CarInfoCarId=';
        let api_url2 = this.context.Base_url+end_point_detail_panel+url_parameter_detail+boxID;        
        fetch(api_url2,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoadedDetail: true,
                        apiStatusDetail: result.status,
                        apiStatusMessageDetail: result.message,
                        rightItems: result.garageServiceCategoryModels
                    });
                } else {
                    this.setState({
                        isLoadedDetail: false,
                        apiStatusDetail: result.status,
                        apiStatusMessageDetail: result.message,
                        rightItems: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoadedRight: false,
                    error
                });
            }
        )
        // Detail API End
    }
    
    render() {
        return (
            <div className='content_panel_controller'>
                {
                    this.state.apiStatus === false 
                    ?
                    <h3 className='title_medium'>{this.state.apiStatusMessage}</h3> 
                    :
                    <div className='carinfo_cards_box_wrapper'>
                        {this.state.isLoaded === false ? (
                            <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>
                        ) : (
                            this.state.items.map((user) => (
                                <div className='carinfo_cards_box_wrapper_items' key={user.carInfoCarId+v4}>
                                    <div className='carinfo_cards_box_wrapper_items_frame_controller' onClick={this.boxClick} box-id={user.carInfoCarId}  color={user.color} data-title={user.carInfoName} data-price={user.carInfoCarId} data-model={user}>
                                        <div className='carinfo_cards_box_wrapper_items_image_wrapper'>
                                            <img alt='Failed to load' className='brokenimg' src={user.carInfoImage}></img>
                                        </div>
                                        <div className='carinfo_cards_box_wrapper_items_content_wrapper'>
                                            <h4 className='carinfo_cards_box_wrapper_items_title'>{user.carInfoName}</h4>
                                            <div className='flex'>
                                                <strong className='text_normal'>Status:</strong>
                                                <span className={`${user.color} text_normal percent`}>{user.status}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )} 
                    </div> 
                }
                {
                    this.state.isRighpanelOpen &&
                    <div className='carinfo_cards_box_right_panel active'>
                        <div className='closedetailsection' onClick={() =>{this.setState( {
                            isRighpanelOpen: false
                            })}}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        {

                            this.state.apiStatusDetail === false 
                            ?
                                <h3 className='title_medium'>{this.state.apiStatusMessageDetail}</h3> 
                            : 
                            (
                                this.state.isLoadedDetail === false
                                ?
                                    <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>
                                :
                                <>
                                    <div className='right-panel_title'>
                                        <h4>{this.state.rightItems.dealerShipName}</h4> 
                                        <span>${this.state.rightItems.serviceCategoryCost}</span>
                                    </div>
                                    <div className='right-panel_image_wrapper'>
                                        <img alt='img' className='brokenimg' src={this.state.rightItems.serviceCategoryImage} id='belliconid'></img>
                                    </div>
                                    <div className='right-panel_content_wrapper'>
                                        <div className='flex'>
                                            <span className='text_normal'>Status:</span>
                                            <span className={`${this.state.color} text_normal percent`} >{this.state.rightItems.serviceCategoryStatus}</span>
                                            {/* <span className={`${datamodeltogo.color} text_normal percent`} >{this.state.rightItems.serviceCategoryStatus}</span> */}
                                        </div>
                                        <p className='text'>Dealership: <strong>{this.state.rightItems.dealerShipName}</strong></p>
                                    </div>
                                    <div className='btn_theme light fullwidth' onClick={() => {this.context.setActive("Shop")}}>Shop Now</div>
                                </>
                            )
                        }
                    </div>
                }
            </div>
        );
    }
    
};
export default CarInfo;