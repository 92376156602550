import React, { useHistory } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import TopNav from '../Snippets/top_nav_items';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import Calendar from 'react-calendar';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import Overlay from '../Snippets/overlay'
import { v4 } from 'uuid';
import $ from 'jquery'
import { getActiveElement } from 'formik';

const Cart = ({title}) => {

    const myContext = useContext(AppContext);
    let base_url = myContext.Base_url;
    let endpoint = 'Cart/ListCartItemsForPayment'
    let api_token = localStorage.getItem('token'); 

    // API URL For  Benefits > Services
    let apiurl = base_url+endpoint;
    // Check API Loading Status
    const [isLoading, setIsLoading] = React.useState(true);
    const [loaderrow, setloaderrow] = React.useState(false);
    // Set API Response into model
    const [isdataloded, setisdataloded] = React.useState(false);
    // Set API Model
    const [data, setData] = React.useState([]);
    const [dataforcreditcardlist, setdataforcreditcardlist] = React.useState([]);
    const [rendrvalue, setrendrvalue] = React.useState();
    const [rendrvalueforfni, setrendrvalueforfni] = React.useState();
    const [apiresponsestatus, setapiresponsestatus] = React.useState(false);
    const [apiresponsemessage, setapiresponsemessage] = React.useState();
    const [onlyservicesscheduled, setonlyservicesscheduled] = React.useState(false);
    const [cardDetailModelsList, setcardDetailModelsList] = React.useState();
    const [rendrpoints, setrendrpoints] = React.useState();
    const [rendrpointsfni, setrendrpointsfni] = React.useState();
    const [rewardspoints, setrewardspoints] = React.useState();
    const [estimatedprice, setestimatedprice] = React.useState();
    const [estimatedpoints, setestimatedpoints] = React.useState();
    const [isRadeem, setisRadeem] = React.useState();
    const [isScheduleCompelete, setisScheduleCompelete] = React.useState(false);
    
    const [proceedpanel, setproceedpanel] = React.useState(false);
    const [schedulepanel, setschedulepanel] = React.useState(false);
    const [checkoutpanel, setcheckoutpanel] = React.useState(false);
    const [timevalue, settimevalue] = useState(0);
    const [timevalueforwrd, settimevalueforwrd] = useState();
    const [compeltecart, setcompeltecart] = useState(false);
    const [isDataSetForScheduling, setisDataSetForScheduling] = useState({selectedDate : '', selectedTime : ''});
    const [stateforemptycart, setstateforemptycart] = useState(false); //plain object as state
    const [confirmitemdelmodal, setconfirmitemdelmodal] = useState(false);  //plain object as state
    const [deletediteminfo, setdeletediteminfo] = React.useState([]);
    const [scheduledatemodel, setscheduledatemodel] = React.useState({});
    const [addcardplease, setaddcardplease] = React.useState(false);
    const [timeslotfromapi, settimeslotfromapi] = React.useState([]);
    const [timeslotfromapiisvisible, settimeslotfromapiisvisible] = React.useState(false);
    const [isTax, setisTax] = useState(false);
    const [isTaxvalidationmssg, setisTaxvalidationmssg] = useState(false);
    const [scheduleddate, setscheduleddate] = useState("");
    const [iSchecked, setiSchecked] = useState(false); //plain object as state

    var subTotals = 0;
    var subTotalsfni = 0;
    var points = 0;
    var pointsfni = 0;
    var usersrewardspoints = 0;
    var tmrwdatestatevariable;

     const tommorowdatefromtoday = new Date();
     const tomorrow = new Date(tommorowdatefromtoday);
     tomorrow.setDate(tommorowdatefromtoday.getDate() + 1);
     
     let nextmn = tomorrow.getMonth() + 1;
     let nextdt = tomorrow.getDate();
     let nextyr = tomorrow.getFullYear();

     const tomorrowdate =
       nextyr +
       "-" +
       (nextmn > 9 ? nextmn : "0" + nextmn) +
       "-" +
       (nextdt > 9 ? nextdt : "0" + nextdt) +
       "T09:00:00.000Z";

     tmrwdatestatevariable = tomorrowdate;

    React.useEffect(() => {
     myContext.setupdatecartcount(true);
    }, []);
    

    function CartAPI() {
        setIsLoading(true);
        myContext.setupdatecartcount(true);
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            const url = apiurl;
            fetch(url,{
                method: 'GET', 
                headers: {
                    'Authorization' : api_token
                }
            })
            .then((response) => response.json())
            .then((json) => {
                myContext.setupdatecartcount(true);
                setData(json['cartList']);  
                setapiresponsestatus(json.status);
                setapiresponsemessage(json.message)
                setcardDetailModelsList(json.setcardDetailModelsList)
                setIsLoading(false);
                myContext.setupdatecartcount(true);
                
            })
            .catch((error) => console.log(error));
        }
    } 

    React.useEffect(() => {
      
      // if (localStorage.getItem("isDeclined")) {
      //   debugger
      //   let firsttime = true;
      //   if(firsttime) {
      //     var currentURLL = window.location.href;
      //     currentURLL = currentURLL.split("?")[0];
      //     window.location.replace(currentURLL);
      //     firsttime = false
      //   } else {
      //     myContext.setActive("Cart");
      //   }

      // }
      // localStorage.setItem("isDeclined", false);
      // myContext.setupdatecartcount(true);
      if (api_token !== undefined && api_token !== null && api_token !== "") {
        const url = apiurl;
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: api_token,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            } 
            setapiresponsestatus(json.status);
            setData(json["cartList"]);
            setapiresponsemessage(json.message);
            setcardDetailModelsList(json.setcardDetailModelsList);
            setloaderrow(false);
          })
          .catch((error) => console.log(error));
      }







        // Check after Login
        if (Object.keys(myContext.declineAddtoModel).length > 0) {
          let x = 0;
          if (x === 0) {
            AddToCartApiFunction(myContext.declineAddtoModel);
            CartAPI();
            x = 1;
          }
        }

        // Check after Register
        let chckdeclineisTrue = localStorage.getItem("isDeclined");
        if (chckdeclineisTrue === "true") {
          let x = 0;
          if (x === 0) {
            myContext.setActive("Cart");
            myContext.setdeclineAddtoModel([]);
            localStorage.setItem("isDeclined", false);
            x = 1;
          }
        } else {
          localStorage.setItem("isDeclined", false);
        }

      






    }, []);


    // Add To Cart API Function
    function AddToCartApiFunction(ReqData) {

        let end_points = 'Cart/AddItemToCart';
        const url = base_url + end_points;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_token,
          },
          body: JSON.stringify(ReqData),
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.status) {
                myContext.setdeclineAddtoModel([]);
                localStorage.setItem("isDeclined", false);
              } else {
                myContext.setdeclineAddtoModel([]);
                localStorage.setItem("isDeclined", false);
              }
            },
            (error) => {}
          ); 

    }
    // Add To Cart API Function



    React.useEffect(() => { 
        setIsLoading(true);
        setloaderrow(false);
        setisdataloded(false);
        // CartAPI();
        // setloaderrow(false); 
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            if (apiresponsestatus) {                 
                if (data.length !== 0) {
                  setIsLoading(false);
                  setisdataloded(true);

                  setrendrvalue(data.sumPriceServices);
                  setrendrpoints(data.sumPriceInPointsServices);

                  setrendrvalueforfni(data.sumPriceFandI);
                  setrendrpointsfni(data.sumPriceInPointsFandIs);

                  setrewardspoints(data.userRewardPoints);
                  setestimatedprice(data.sumPriceFandI + data.sumPriceServices);

                  setestimatedpoints(
                    data.sumPriceInPointsFandIs + data.sumPriceInPointsServices
                  );
                } else {
                  setapiresponsestatus(false);
                  
                }  
                if(data.sumPriceFandI === 0 || data.sumPriceFandI === undefined) {
                  setonlyservicesscheduled(true);
                } else {
                  setonlyservicesscheduled(false);
                }
                if (data.userRewardPoints > data.sumPriceInPointsServices) {
                  setCheckedItems({ checkedItems, ["Radeem"]: true });
                  setcheckedValue("Radeem");
                  setiSchecked(true);
                } 
            } else {
                setIsLoading(false);
                setisdataloded(true);
            }
        }
        myContext.setupdatecartcount(true);
    }, [data]);

    function CallsAPIForSchdules() {
        if(rendrvalueforfni === 0 || rendrvalueforfni === undefined) {

            // setcheckoutpanel(false);
            // confirmpayment();
            let end_point = "UserInfo/AllCreditCard";
            //api url for right
            let api_url = base_url + end_point;

            const url = api_url;
            axios({
              method: "get",
              url: url,
              headers: { Authorization: api_token },
            })
            .then((res) => {
              res.data.status &&
                setdataforcreditcardlist(res.data.cardDetailModelsList);
            })
            .catch((error) => console.log(error)); 


        } else {

            let end_point = 'UserInfo/AllCreditCard';
            //api url for right
            let api_url = base_url+end_point;
            
            const url = api_url;
            axios(
            {
                method: 'get',
                url: url,
                headers: {'Authorization': api_token}
            }
            )
            .then(res => {
                res.data.status && setdataforcreditcardlist(res.data.cardDetailModelsList);
                
            })
            .catch((error) => console.log(error)) 


        }
    }

    const [date, setDate] = useState(new Date());
    
    const [slotsavailable, setslotsavailable] = useState(true);
    const onDateChange = (newDate) => {
      setDate(newDate);
      settimeslotfromapiisvisible(false);

      const tommorowdatefromtoday = new Date(newDate);

      let nextmn = tommorowdatefromtoday.getMonth() + 1;
      let nextdt = tommorowdatefromtoday.getDate();
      let nextyr = tommorowdatefromtoday.getFullYear();

      const tomorrowdate =
        nextyr +
        "-" +
        (nextmn > 9 ? nextmn : "0" + nextmn) +
        "-" +
        (nextdt > 9 ? nextdt : "0" + nextdt) +
        "T09:00:00.000Z";

      tmrwdatestatevariable = tomorrowdate;
      getTime();
    }
    const Timeslots = [];

    const getTime = () => {
      // tmrwdatestatevariable = tmrwdatestate;
      
      // const URL = base_url + "Cart/DealershipScheduling?dealerShipId="+data.dealershipId+"&ToScheduleDate=2023-10-31T09:00:00.000Z";
      const URL = base_url + "Cart/DealershipScheduling?dealerShipId="+data.dealershipId+"&ToScheduleDate="+tmrwdatestatevariable;
      setscheduleddate(tmrwdatestatevariable);
      fetch(URL, {
        // method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: api_token,
        },
        // body: JSON.stringify(ReqData),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            settimeslotfromapiisvisible(true);
            if(result.status) {
              settimeslotfromapi(result.timeSlotsModels);
              setslotsavailable(true);
            } else {
              setslotsavailable(false);
            }

          },
          (error) => {}
        );
    };


    const radioItems = [
        {
            'name' : "Radeem"
        },
        // {
        //     'name' : "Add Payment"
        // }
    ];
    
    const [checkedValue, setcheckedValue] = useState(); //plain object as state
    const [checkedItems, setCheckedItems] = useState({}); //plain object as state
    
    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
            <input type={type} name={name}  checked={checked} onChange={onChange} />
        );
    };
    const handleChange = (event) => {
        // updating an object instead of a Map
        setCheckedItems({checkedItems, [event.target.name] : event.target.checked });
        setcheckedValue(event.target.name);
        iSchecked ? setiSchecked(false) : setiSchecked(true)
        // setdatafordetailcheckbox({...checkedItems, [event.target.name] : event.target.checked })
    }
    
    function paymentconfirmAPI(appointmentDate, appointmentStartTime, appointmentEndTime, IsRedeemPoints, RedeemPoints, CartPrice, CardId) {
        
        
        
        // setcompeltecart(false);
        let variableappointmentDate;
        let variableappointmentStartTime;
        let variableappointmentEndTime;
        let variableIsRedeemPoints;
        let variableRedeemPoints;
        let variableCartPrice;
        let variableCardId;
        let variabledealershipMessage;

        if(onlyservicesscheduled) {

            variableappointmentDate = appointmentDate;
            variableappointmentStartTime = appointmentStartTime;
            variableappointmentEndTime = appointmentEndTime;
            variableIsRedeemPoints = IsRedeemPoints;
            variableRedeemPoints = RedeemPoints;
            variableCartPrice = CartPrice;
            variableCardId = 0;
            variabledealershipMessage = 'Services Scheduled';

        } else {

            variableappointmentDate = appointmentDate;
            variableappointmentStartTime = appointmentStartTime;
            variableappointmentEndTime = appointmentEndTime;
            variableIsRedeemPoints = IsRedeemPoints;
            variableRedeemPoints = RedeemPoints;
            variableCartPrice = CartPrice;
            variableCardId = 0;
            variabledealershipMessage = 'F&I Scheduled';
        }
        let end_point = 'Appointment/CreateAppointment';
        //api url for right
        let api_url = base_url+end_point;
        const url = api_url;
        axios(
          {
            method: 'post',
            url: url,
            data: {
            "appointmentDate": variableappointmentDate,
            "appointmentStartTime": variableappointmentStartTime,
            "appointmentEndTime": variableappointmentEndTime,
            "IsRedeemPoints": variableIsRedeemPoints,
            "CardId": variableCardId,
            "notesForDealerShip" : variabledealershipMessage
            },
            headers: {'Authorization': api_token}
          }
        )
        .then(res => {
            // setisScheduleCompelete(false);

            if(res.data.status) {
              setisScheduleCompelete(true);
              setproceedpanel(false);
              setschedulepanel(false);
              setcheckoutpanel(false);
              settimevalue(0);
              setstateforemptycart(true);
              setcompeltecart(true);
              setIsLoading(false);
              myContext.setcountforcart(undefined);
              myContext.setupdatecartcount(true);
              
              (async () => {
                  let end_pointt = "Notifications/NotificationsList";
                  let api_url = base_url + end_pointt;

                  try {
                    const token = localStorage.getItem("token");
                    const headers = {
                      Authorization: token,
                    };
                    const response = await fetch(api_url, { headers });
                    const data = await response.json();

                    if (data.notificationBadgeCount != 0) {
                      setTimeout(() => {
                        // setnotificationBagdeCount(data.notificationBadgeCount);
                        myContext.setnotificationBagdeCount(
                          data.notificationBadgeCount
                        );
                        
                        // setupdatenoticount(false);
                      }, 500);
                      // CartAPI();  

                      // clearTimeout(myTimeout);
                    }
                    // setupdatecartcount(false);
                  } catch (error) {
                    console.error("Error fetching data:", error);
                    // setupdatecartcount(false);
                  }
              })()
              CartAPI();  
              myContext.setActive("Appointments");
            } else {
              setisScheduleCompelete(false);
              alert(res.data.message);
            }
            
        })
        .catch((error) => console.log(error)) 
    } 

    function confirmpayment() {
        const current = new Date(isDataSetForScheduling.selectedDate);
        let month = 0;
        if (current.getMonth() < 10) {
            month = (current.getMonth() + "0"+1);
        } else {
            month = (current.getMonth() + 1);
        }
        let monthdate = 0;
        if (current.getDate() < 10) {
            monthdate = "0"+current.getDate();
        } else {
            monthdate = current.getDate();
        }
        const date = `${current.getFullYear() + '-' + month + '-' + monthdate + "T" + isDataSetForScheduling.selectedTime}`;


        let appointmentDate = date;
        let appointmentStartTime = date;
        let appointmentEndTime = date;

        let IsRedeemPoints = iSchecked;
        

        let RedeemPoints;
        if(IsRedeemPoints) {
            RedeemPoints = rendrpoints;
        } else {
            RedeemPoints = 0;
        }

        let CartPrice;
        if(checkedValue !== 'Radeem') {
            CartPrice = rendrvalue;
        } else {
            CartPrice = 0;
        }

        let CardId;
        if(checkedValue === 'Radeem') {
            CardId = 0;
        } else {
            // CardId = checkedValue;
            CardId = 0;
        }

        // if (checkedValue == 'Add Payment') {
        //   // debugger
          
          
        // } else {
            
        //     appointmentDate = '';
        //     appointmentStartTime = '';
        //     appointmentEndTime = '';
        //     IsRedeemPoints = '';
        //     RedeemPoints = '';
        //     CartPrice = '';
        //     CardId = '' ;

        // }
        
        const scheduleddates = new Date(appointmentDate);
        let months = 0;
        if (scheduleddates.getMonth() < 10) {
          months = "0" + scheduleddates.getMonth() + 1;
        } else {
          months = scheduleddates.getMonth() + 1;
        }
        let monthdates = 0;
        if (scheduleddates.getDate() < 10) {
          monthdates = "0" + scheduleddates.getDate();
        } else {
          monthdates = scheduleddates.getDate();
        }
        let modell = {
          date: monthdates + "-" + months + "-" + scheduleddates.getFullYear(),
          time: timevalueforwrd,
        };
        
        setaddcardplease(false);
        setscheduledatemodel(modell);
        setIsLoading(true);
        paymentconfirmAPI(
          appointmentDate,
          appointmentStartTime,
          appointmentEndTime,
          IsRedeemPoints,
          RedeemPoints,
          CartPrice,
          parseInt(CardId)
        );

        // if (checkedValue === undefined) {
        //   debugger
          
        // } else {
        //   debugger;
        //   const scheduleddate = new Date(appointmentDate);
        //   let month = 0;
        //   if (scheduleddate.getMonth() < 10) {
        //     month = "0" + (scheduleddate.getMonth() + 1);
        //   } else {
        //     month = scheduleddate.getMonth() + 1;
        //   }
        //   let monthdate = 0;
        //   if (scheduleddate.getDate() < 10) {
        //     monthdate = "0" + scheduleddate.getDate();
        //   } else {
        //     monthdate = scheduleddate.getDate();
        //   }
        //   let modell = {
        //     date: monthdate + "-" + month + "-" + scheduleddate.getFullYear(),
        //     time: timevalueforwrd,
        //   };
        //   setaddcardplease(false);
        //   setscheduledatemodel(modell);
        //   setIsLoading(true);
        //   paymentconfirmAPI(
        //     appointmentDate,
        //     appointmentStartTime,
        //     appointmentEndTime,
        //     IsRedeemPoints,
        //     RedeemPoints,
        //     CartPrice,
        //     parseInt(CardId)
        //   );
        // }
    }

    // const tileDisabled = ({ activeStartDate, date, view }) => {


    //     if (date.getDay() === 0) {
    //       return true;
    //     }
        

    //     let crrntyear = date.getFullYear();
    //     const thirtyDaysFromToday = new Date();
    //     // debugger
    //     thirtyDaysFromToday.setDate(thirtyDaysFromToday.getDate() + 31);

    //     let nextmonth = thirtyDaysFromToday.getMonth() + 1;
        
    //     const enddate = crrntyear + "-" + (nextmonth > 9 ? nextmonth : "0" + nextmonth) + "-" + (thirtyDaysFromToday.getDate() > 9 ? thirtyDaysFromToday.getDate() : '0'+thirtyDaysFromToday.getDate());
    //     let val2 = date < new Date() || date > new Date(enddate);
        
    //     const currentYear = date.getFullYear();
    //     const nextYear = currentYear + 1;

    //     // debugger
    //     if (currentYear >= nextYear) {
    //       return true;
    //     } else {
    //       // return false;
    //       return val2;
    //     }

    // };

    const tileDisabled = ({ date }) => {
      const today = new Date();
      const thirtyDaysFromToday = new Date();
      thirtyDaysFromToday.setDate(thirtyDaysFromToday.getDate() + 31);

      const currentYear = today.getFullYear();
      const nextYear = currentYear + 1;

      // Disable Sundays
      if (date.getDay() === 0) {
        return true;
      }

      // Disable dates beyond the next 31 days
      if (date > thirtyDaysFromToday) {
        return true;
      }

      // Disable dates in the past or beyond the next year
      if (date < today || date.getFullYear() >= nextYear) {
        return true;
      }

      return false;
    };


    var formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    return (
      <>
        <div className="top_iconbar">
          <span className="top_Left_column">
            {myContext.shopcardetailsectionisopen !== "" && (
              <span className="backbutton">
                <i className="fa-solid fa-chevron-left"></i>
              </span>
            )}
            <h4 className="title_medium">{title}</h4>
          </span>
          {myContext.singlecardetailsectionisopen === false && <TopNav />}
        </div>

        {
          <div className="garage_car_detail_appointment_content_wrapper flex-column flex radius p-0">
            {isLoading ? (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            ) : apiresponsestatus ? (
              <>
                {isdataloded &&
                  [data].map((data1, i) =>
                    data1.cartItemsListFandI.length === 0 &&
                    data1.cartItemsListServices.length === 0 &&
                    data1.cartItemsListPartAndAccessory.length === 0 ? (
                      <div className="emptywndow">
                        <i className="fa-solid fa-cart-shopping"></i>
                        <h3>There are no items in your cart.</h3>
                      </div>
                    ) : (
                      <>
                        <div
                          key={v4}
                          className="flex-space-between-center w-100 firsttr">
                          <div>
                            {
                              data1.cartItemsListFandI.length === 0 &&
                              data1.cartItemsListServices.length === 0 &&
                              data1.cartItemsListPartAndAccessory.length ===
                                0 ? (
                                <span className="text-black">
                                  <strong>Dealership Name</strong>
                                  <strong>
                                    <h3>Car Name</h3>
                                  </strong>
                                </span>
                              ) : data1.cartItemsListFandI.length !== 0 ? (
                                <span className="text-black">
                                  <strong>
                                    Dealership: {data1.dealershipName}{" "}
                                  </strong>
                                  <strong>
                                    <h3>{data1.carName}</h3>
                                  </strong>
                                </span>
                              ) : (
                                data1.cartItemsListServices.length !== 0 && (
                                  <span className="text-black">
                                    <strong>
                                      Dealership: {data1.dealershipName}{" "}
                                    </strong>
                                    <strong>
                                      <h3>{data1.carName}</h3>
                                    </strong>
                                  </span>
                                )
                              )

                              //<span className='text-black'><strong>Dealership Name</strong><strong><h3>Car Name</h3></strong></span>
                            }
                          </div>
                        </div>

                        <div className="flex w-100 f-grow-1 overflow-hidden">
                          <div className="f-grow-1 overflow-auto">
                            <div className="table__wrapper">
                              <h4 className="title_medium bold mb-2">
                                Services
                              </h4>
                              <hr className="mt-1"></hr>
                              <table className="table mb-2">
                                <thead>
                                  <tr>
                                    <th scope="col" width="40%">
                                      <strong>Service Name</strong>
                                    </th>
                                    <th scope="col" width="20%">
                                      <strong>Service Type</strong>
                                    </th>
                                    <th scope="col" width="30%">
                                      <strong>Amount</strong>
                                    </th>
                                    <th scope="col" width="10%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading ? (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  ) : data1.cartItemsListServices.length ===
                                    0 ? (
                                    ""
                                  ) : (
                                    (() => {
                                      let td = [];
                                      for (
                                        let i = 0;
                                        i < data1.cartItemsListServices.length;
                                        i++
                                      ) {
                                        td.push(
                                          <>
                                            <tr>
                                              <td className="flex_start_center">
                                                <div className="table_image_wrapper mini">
                                                  <img
                                                    className="brokenimg"
                                                    alt="img"
                                                    src={
                                                      data1
                                                        .cartItemsListServices[
                                                        i
                                                      ].image
                                                    }
                                                    id="belliconid"></img>
                                                </div>
                                                <div className="ml-1">
                                                  <span className="text-black">
                                                    {data1
                                                      .cartItemsListServices[i]
                                                      .cartItemType === 0
                                                      ? data1
                                                          .cartItemsListServices
                                                          .length !== 0
                                                        ? data1
                                                            .cartItemsListServices[
                                                            i
                                                          ]
                                                            .dealershipServiceName
                                                        : "-"
                                                      : data1
                                                          .cartItemsListServices
                                                          .length !== 0
                                                      ? data1
                                                          .cartItemsListServices[
                                                          i
                                                        ].itemName
                                                      : "-"}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                {data1.cartItemsListServices[i]
                                                  .cartItemType === 0
                                                  ? data1.cartItemsListServices
                                                      .length !== 0
                                                    ? data1
                                                        .cartItemsListServices[
                                                        i
                                                      ].itemName
                                                    : "-"
                                                  : data1.cartItemsListServices
                                                      .length !== 0
                                                  ? data1.cartItemsListServices[
                                                      i
                                                    ].cartItemTypeName
                                                  : "-"}
                                              </td>
                                              <td>
                                                {data1.cartItemsListServices
                                                  .length !== 0
                                                  ? data1.cartItemsListServices[
                                                      i
                                                    ].price === "Free"
                                                    ? "Included in the Package"
                                                    : "$" +
                                                      [
                                                        data1.cartItemsListServices[
                                                          i
                                                        ].price.includes(".00")
                                                          ? data1.cartItemsListServices[
                                                              i
                                                            ].price
                                                              .replace(
                                                                ".00",
                                                                ""
                                                              )
                                                              .toLocaleString()
                                                          : data1.cartItemsListServices[
                                                              i
                                                            ].price.toLocaleString(),
                                                      ] +
                                                      " | " +
                                                      [
                                                        data1.cartItemsListServices[
                                                          i
                                                        ].priceInPoints.toLocaleString(),
                                                      ] +
                                                      " Points"
                                                  : "-"}
                                              </td>

                                              <td>
                                                <span
                                                  onClick={() => {
                                                    setconfirmitemdelmodal(
                                                      true
                                                    );
                                                    $(
                                                      ".garage_car_detail_appointment_content_wrapper"
                                                    ).addClass("blur");
                                                    $(".Left__panel").addClass(
                                                      "blur"
                                                    );
                                                    $(".top_iconbar").addClass(
                                                      "blur"
                                                    );
                                                    myContext.setupdatecartcount(
                                                      true
                                                    );
                                                    let delData = [];
                                                    delData.push({
                                                      CartItemType: 0,
                                                      ItemId:
                                                        data1
                                                          .cartItemsListServices[
                                                          i
                                                        ].itemId,
                                                    });
                                                    setdeletediteminfo(delData);
                                                  }}>
                                                  {loaderrow ? (
                                                    <div className="loader-div">
                                                      <img
                                                        alt="img"
                                                        src={loader}
                                                        id="belliconid"></img>
                                                    </div>
                                                  ) : (
                                                    <i className="fa-solid fa-trash"></i>
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr className="table__row__seperator_small trans"></tr>
                                          </>
                                        );
                                      }
                                      return td;
                                    })()
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>
                                      <strong>Pay To Dealership</strong>
                                    </td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        $
                                        {data1.sumPriceServices === undefined
                                          ? 0
                                          : data1.sumPriceServices.toLocaleString()}
                                        <span className="space"> | </span>
                                        {data1.sumPriceInPointsServices.toLocaleString() +
                                          " Points"}
                                      </strong>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tfoot>
                              </table>

                              <h4 className="title_medium bold mb-2">
                                Protection Plans
                              </h4>
                              <hr className="mt-1"></hr>
                              <table className="table mb-2">
                                <thead>
                                  <tr>
                                    <th scope="col" width="40%">
                                      <strong>Protection Plans Name</strong>
                                    </th>
                                    <th scope="col" width="20%">
                                      <strong>Service Type</strong>
                                    </th>
                                    <th scope="col" width="30%">
                                      <strong>Amount</strong>
                                    </th>
                                    <th scope="col" width="10%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading ? (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  ) : data1.cartItemsListFandI.length === 0 ? (
                                    ""
                                  ) : (
                                    (() => {
                                      let td = [];
                                      for (
                                        let i = 0;
                                        i < data1.cartItemsListFandI.length;
                                        i++
                                      ) {
                                        td.push(
                                          <>
                                            <tr key={v4}>
                                              <td className="flex_start_center">
                                                <div className="table_image_wrapper mini">
                                                  <img
                                                    alt="img"
                                                    className="brokenimg"
                                                    src={
                                                      data1.cartItemsListFandI[
                                                        i
                                                      ].image
                                                    }
                                                    id="belliconid"></img>
                                                </div>
                                                <div className="ml-1">
                                                  <span className="text-black">
                                                    {data1.cartItemsListFandI[i]
                                                      .cartItemType === 0
                                                      ? data1.cartItemsListFandI
                                                          .length !== 0
                                                        ? data1
                                                            .cartItemsListFandI[
                                                            i
                                                          ]
                                                            .dealershipServiceName
                                                        : "-"
                                                      : data1.cartItemsListFandI
                                                          .length !== 0
                                                      ? data1
                                                          .cartItemsListFandI[i]
                                                          .itemName
                                                      : "-"}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                {data1.cartItemsListFandI[i]
                                                  .cartItemType === 0
                                                  ? data1.cartItemsListFandI
                                                      .length !== 0
                                                    ? data1.cartItemsListFandI[
                                                        i
                                                      ].itemName
                                                    : "-"
                                                  : data1.cartItemsListFandI
                                                      .length !== 0
                                                  ? // data1.cartItemsListFandI[i]
                                                    //     .cartItemTypeName
                                                    "Protection"
                                                  : "-"}
                                              </td>
                                              <td>
                                                {data1.cartItemsListFandI
                                                  .length !== 0
                                                  ? data1.cartItemsListFandI[i]
                                                      .price === "Free"
                                                    ? "Included in the Package"
                                                    : "$" +
                                                      [
                                                        data1.cartItemsListFandI[
                                                          i
                                                        ].price.includes(".00")
                                                          ? data1.cartItemsListFandI[
                                                              i
                                                            ].price
                                                              .replace(
                                                                ".00",
                                                                ""
                                                              )
                                                              .toLocaleString()
                                                          : data1.cartItemsListFandI[
                                                              i
                                                            ].price.toLocaleString(),
                                                      ] +
                                                      " | " +
                                                      [
                                                        data1.cartItemsListFandI[
                                                          i
                                                        ].priceInPoints.toLocaleString(),
                                                      ] +
                                                      " Points"
                                                  : "-"}
                                              </td>

                                              <td>
                                                <span
                                                  onClick={() => {
                                                    setconfirmitemdelmodal(
                                                      true
                                                    );
                                                    $(
                                                      ".garage_car_detail_appointment_content_wrapper"
                                                    ).addClass("blur");
                                                    $(".Left__panel").addClass(
                                                      "blur"
                                                    );
                                                    $(".top_iconbar").addClass(
                                                      "blur"
                                                    );
                                                    myContext.setupdatecartcount(
                                                      true
                                                    );
                                                    let delData = [];
                                                    delData.push({
                                                      CartItemType: 2,
                                                      ItemId:
                                                        data1
                                                          .cartItemsListFandI[i]
                                                          .itemId,
                                                    });
                                                    setdeletediteminfo(delData);
                                                  }}>
                                                  {loaderrow ? (
                                                    <div className="loader-div">
                                                      <img
                                                        alt="img"
                                                        src={loader}
                                                        id="belliconid"></img>
                                                    </div>
                                                  ) : (
                                                    <i className="fa-solid fa-trash"></i>
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr className="table__row__seperator_small trans"></tr>
                                          </>
                                        );
                                      }
                                      return td;
                                    })()
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>
                                      <strong>Pay To Dealership</strong>
                                    </td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        $
                                        {data1.sumPriceFandI === undefined
                                          ? 0
                                          : data1.sumPriceFandI.toLocaleString()}
                                        <span className="space"> | </span>
                                        {data1.sumPriceInPointsFandIs.toLocaleString() +
                                          " Points"}
                                      </strong>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tfoot>
                              </table>

                              {/* <h4 className="title_medium bold mb-2">
                                Parts & Accessories
                              </h4>
                              <hr className="mt-1"></hr>
                              <table className="table mb-2">
                                <thead>
                                  <tr>
                                    <th scope="col" width="40%">
                                      <strong>Parts & Accessories Name</strong>
                                    </th>
                                    <th scope="col" width="20%">
                                      <strong>Parts & Accessories Type</strong>
                                    </th>
                                    <th scope="col" width="30%">
                                      <strong>Amount</strong>
                                    </th>
                                    <th scope="col" width="10%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading ? (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  ) : data1.cartItemsListPartAndAccessory
                                      .length === 0 ? (
                                    ""
                                  ) : (
                                    (() => {
                                      let td = [];
                                      for (
                                        let i = 0;
                                        i <
                                        data1.cartItemsListPartAndAccessory
                                          .length;
                                        i++
                                      ) {
                                        td.push(
                                          <>
                                            <tr key={v4}>
                                              <td className="flex_start_center">
                                                <div className="table_image_wrapper mini">
                                                  <img
                                                    alt="img"
                                                    className="brokenimg"
                                                    src={
                                                      data1
                                                        .cartItemsListPartAndAccessory[
                                                        i
                                                      ].image
                                                    }
                                                    id="belliconid"></img>
                                                </div>
                                                <div className="ml-1">
                                                  <span className="text-black">
                                                    {data1
                                                      .cartItemsListPartAndAccessory[
                                                      i
                                                    ].cartItemType === 0
                                                      ? data1
                                                          .cartItemsListPartAndAccessory
                                                          .length !== 0
                                                        ? data1
                                                            .cartItemsListPartAndAccessory[
                                                            i
                                                          ]
                                                            .dealershipServiceName
                                                        : "-"
                                                      : data1
                                                          .cartItemsListPartAndAccessory
                                                          .length !== 0
                                                      ? data1
                                                          .cartItemsListPartAndAccessory[
                                                          i
                                                        ].itemName
                                                      : "-"}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                {data1
                                                  .cartItemsListPartAndAccessory[
                                                  i
                                                ].cartItemType === 0
                                                  ? data1
                                                      .cartItemsListPartAndAccessory
                                                      .length !== 0
                                                    ? data1
                                                        .cartItemsListPartAndAccessory[
                                                        i
                                                      ].itemName
                                                    : "-"
                                                  : data1
                                                      .cartItemsListPartAndAccessory
                                                      .length !== 0
                                                  ? // data1.cartItemsListFandI[i]
                                                    //     .cartItemTypeName
                                                    "Protection"
                                                  : "-"}
                                              </td>
                                              <td>
                                                {data1
                                                  .cartItemsListPartAndAccessory
                                                  .length !== 0
                                                  ? data1
                                                      .cartItemsListPartAndAccessory[
                                                      i
                                                    ].price === "Free"
                                                    ? "Included in the Package"
                                                    : "$" +
                                                      [
                                                        data1.cartItemsListPartAndAccessory[
                                                          i
                                                        ].price.includes(".00")
                                                          ? data1.cartItemsListPartAndAccessory[
                                                              i
                                                            ].price
                                                              .replace(
                                                                ".00",
                                                                ""
                                                              )
                                                              .toLocaleString()
                                                          : data1.cartItemsListPartAndAccessory[
                                                              i
                                                            ].price.toLocaleString(),
                                                      ] +
                                                      " | " +
                                                      [
                                                        data1.cartItemsListPartAndAccessory[
                                                          i
                                                        ].priceInPoints.toLocaleString(),
                                                      ] +
                                                      " Points"
                                                  : "-"}
                                              </td>

                                              <td>
                                                <span
                                                  onClick={() => {
                                                    setconfirmitemdelmodal(
                                                      true
                                                    );
                                                    $(
                                                      ".garage_car_detail_appointment_content_wrapper"
                                                    ).addClass("blur");
                                                    $(".Left__panel").addClass(
                                                      "blur"
                                                    );
                                                    $(".top_iconbar").addClass(
                                                      "blur"
                                                    );
                                                    myContext.setupdatecartcount(
                                                      true
                                                    );
                                                    let delData = [];
                                                    delData.push({
                                                      CartItemType: 1,
                                                      ItemId:
                                                        data1
                                                          .cartItemsListPartAndAccessory[
                                                          i
                                                        ].itemId,
                                                    });
                                                    setdeletediteminfo(delData);
                                                  }}>
                                                  {loaderrow ? (
                                                    <div className="loader-div">
                                                      <img
                                                        alt="img"
                                                        src={loader}
                                                        id="belliconid"></img>
                                                    </div>
                                                  ) : (
                                                    <i className="fa-solid fa-trash"></i>
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr className="table__row__seperator_small trans"></tr>
                                          </>
                                        );
                                      }
                                      return td;
                                    })()
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>
                                      <strong>Pay To Dealership</strong>
                                    </td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        $
                                        {data1.sumPricePartAndAccessory ===
                                        undefined
                                          ? 0
                                          : data1.sumPricePartAndAccessory.toLocaleString()}
                                        <span className="space"> | </span>
                                        {data1.sumPriceInPointsPartAndAccessory.toLocaleString() +
                                          " Points"}
                                      </strong>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tfoot>
                              </table> */}
                            </div>
                            {proceedpanel === false && (
                              <div className="btn_wrapper">
                                <div
                                  className="btn_theme"
                                  onClick={() => {
                                    setproceedpanel(true);
                                    getTime();
                                  }}>
                                  Proceed
                                </div>
                              </div>
                            )}
                          </div>
                          {proceedpanel && (
                            <div className="Proceed_container bg-white overflow-auto">
                              {proceedpanel && (
                                <div
                                  className="closedetailsection rightpanel"
                                  onClick={() => {
                                    setproceedpanel(false);
                                    setschedulepanel(false);
                                    setcheckoutpanel(false);
                                    settimevalue(0);
                                  }}>
                                  <i className="fa-solid fa-xmark"></i>
                                </div>
                              )}
                              <h4 className="title_medium">
                                <strong>Summary</strong>
                              </h4>

                              {schedulepanel === false ? (
                                <div className="infopanel">
                                  <div className="flex w-100 mt-1">
                                    <span className="f-grow-1">
                                      <strong>Pay To Dealership</strong>
                                    </span>
                                  </div>
                                  <div className="flex w-100 mt-1">
                                    <span className="f-grow-1">Services</span>
                                    <span>
                                      $
                                      {data1.sumPriceServices === undefined
                                        ? 0
                                        : data1.sumPriceServices.toLocaleString()}{" "}
                                      |{" "}
                                      {data1.sumPriceInPointsServices ===
                                      undefined
                                        ? 0
                                        : data1.sumPriceInPointsServices.toLocaleString()}{" "}
                                      Points
                                    </span>
                                  </div>
                                  <div className="flex w-100 mt-1">
                                    <span className="f-grow-1">
                                      Protections
                                    </span>
                                    <span>
                                      $
                                      {data1.sumPriceFandI === undefined
                                        ? 0
                                        : data1.sumPriceFandI.toLocaleString()}{" "}
                                      |{" "}
                                      {data1.sumPriceInPointsFandIs ===
                                      undefined
                                        ? 0
                                        : data1.sumPriceInPointsFandIs.toLocaleString()}{" "}
                                      Points
                                    </span>
                                  </div>
                                  {/* <div className="flex w-100 mt-1">
                                    <span className="f-grow-1">
                                      Parts & Accessories
                                    </span>
                                    <span>
                                      $
                                      {data1.sumPricePartAndAccessory ===
                                      undefined
                                        ? 0
                                        : data1.sumPricePartAndAccessory.toLocaleString()}{" "}
                                      |{" "}
                                      {data1.sumPriceInPointsPartAndAccessory ===
                                      undefined
                                        ? 0
                                        : data1.sumPriceInPointsPartAndAccessory.toLocaleString()}{" "}
                                      Points
                                    </span>
                                  </div> */}
                                  <hr className="mt-1"></hr>
                                  <div className="flex w-100 mt-2">
                                    <span className="f-grow-1">
                                      Estimated Total
                                    </span>
                                    <span>
                                      ${data1.finalSumPrice.toLocaleString()} |{" "}
                                      {data1.finalSumPriceInPoints.toLocaleString()}{" "}
                                      Points
                                    </span>
                                  </div>
                                  <hr className="mt-2"></hr>
                                  <div className="flex w-100 mt-2">
                                    <span className="f-grow-1">
                                      Add Note for Dealer
                                    </span>
                                  </div>
                                  <div className="flex w-100 mt-2 mb-2">
                                    <textarea
                                      className="w-100 p-1"
                                      rows="6"
                                      placeholder="Type Here..."></textarea>
                                  </div>
                                  <div
                                    className="btn_theme fullwidth"
                                    onClick={() => {
                                      setschedulepanel(true);
                                    }}>
                                    Schedule
                                  </div>
                                </div>
                              ) : checkoutpanel === false ? (
                                <>
                                  <div className="mt-2">Select Date</div>
                                  <Calendar
                                    className="calander"
                                    onChange={onDateChange}
                                    value={date}
                                    showNeighboringMonth={false}
                                    locale={"en-US"}
                                    tileDisabled={tileDisabled}
                                  />
                                  <hr></hr>
                                  <div className="mt-2">
                                    Avalible Time Slots
                                  </div>
                                  <div className="time_container">
                                    {timeslotfromapiisvisible ? (
                                      slotsavailable ? (
                                        timeslotfromapi.map((time, i) => (
                                          <span
                                            className={`slot-div 
                                           ${
                                             time.timeSlot.split(":")[0] < 10
                                               ? time.timeSlot
                                                   .replace(/(\d{1}):/, "$1:")
                                                   .replace(
                                                     /( AM| PM)/g,
                                                     ":00.767Z"
                                                   ) === timevalue
                                                 ? "active"
                                                 : ""
                                               : time.timeSlot
                                                   .replace(/^0(\d{1}):/, "$1:")
                                                   .replace(
                                                     /( AM| PM)/g,
                                                     ":00.767Z"
                                                   ) === timevalue
                                               ? "active"
                                               : ""
                                           }
                                           ${
                                             time.isActive == false
                                               ? "disabd"
                                               : ""
                                           }
                                          `}
                                            onClick={() => {
                                              // settimevalue(time.sendvalue);

                                              let timeString = time.timeSlot;
                                              let hour =
                                                timeString.split(":")[0];
                                              let timeWithoutAMPM;

                                              if (parseInt(hour) < 10) {
                                                timeWithoutAMPM = timeString
                                                  .replace(/(\d{1}):/, "$1:")
                                                  .replace(
                                                    /( AM| PM)/g,
                                                    ":00.767Z"
                                                  );
                                              } else {
                                                timeWithoutAMPM = timeString
                                                  .replace(/^0(\d{1}):/, "$1:")
                                                  .replace(
                                                    /( AM| PM)/g,
                                                    ":00.767Z"
                                                  );
                                              }

                                              settimevalue(timeWithoutAMPM);
                                              settimevalueforwrd(time.timeSlot);
                                            }}>
                                            {time.timeSlot}
                                          </span>
                                        ))
                                      ) : (
                                        "No Slots Available For Tommorrow"
                                      )
                                    ) : (
                                      <div className="loader-div">
                                        <img
                                          alt="img"
                                          src={loader}
                                          id="belliconid"></img>
                                      </div>
                                    )}
                                  </div>

                                  <hr></hr>
                                  <br></br>
                                  <br></br>
                                  {date !== null && timevalue !== 0 && (
                                    <button
                                      className="btn_theme fullwidth w-100"
                                      onClick={() => {
                                        setcheckoutpanel(true);

                                        isDataSetForScheduling.selectedDate =
                                          date;
                                        isDataSetForScheduling.selectedTime =
                                          timevalue;
                                        var GivenDate = date;
                                        var CurrentDate = new Date();
                                        GivenDate = new Date(GivenDate);

                                        if (GivenDate > CurrentDate) {
                                          CallsAPIForSchdules();
                                        } else {
                                          alert(
                                            "Sorry! no slots are available today."
                                          );
                                          setcheckoutpanel(false);
                                        }
                                        // CallsAPIForSchdules();
                                      }}>
                                      Checkout
                                    </button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="mt-1 flex_center_start">
                                    <strong className="f-grow-1">
                                      Cart Items (
                                      {data1.cartItemsListServices.length +
                                        data1.cartItemsListFandI.length +
                                        data1.cartItemsListPartAndAccessory
                                          .length}
                                      )
                                    </strong>
                                    <div className="flex flex-column">
                                      {(() => {
                                        let spanService = [];
                                        let spanProtection = [];
                                        for (
                                          let i = 0;
                                          i <
                                          data1.cartItemsListServices.length;
                                          i++
                                        ) {
                                          spanService.push(
                                            <>
                                              <span>
                                                {data1.cartItemsListServices[i]
                                                  .cartItemType === 0
                                                  ? data1.cartItemsListServices
                                                      .length !== 0
                                                    ? data1
                                                        .cartItemsListServices[
                                                        i
                                                      ].dealershipServiceName
                                                    : "-"
                                                  : data1.cartItemsListServices
                                                      .length !== 0
                                                  ? data1.cartItemsListServices[
                                                      i
                                                    ].itemName
                                                  : "-"}
                                              </span>
                                            </>
                                          );
                                        }
                                        return spanService;
                                      })()}
                                      {(() => {
                                        let spanFni = [];
                                        for (
                                          let i = 0;
                                          i < data1.cartItemsListFandI.length;
                                          i++
                                        ) {
                                          spanFni.push(
                                            <>
                                              <span>
                                                {data1.cartItemsListFandI[i]
                                                  .cartItemType === 2
                                                  ? data1.cartItemsListFandI
                                                      .length !== 0
                                                    ? data1.cartItemsListFandI[
                                                        i
                                                      ].itemName
                                                    : "-"
                                                  : data1.cartItemsListFandI
                                                      .length !== 0
                                                  ? data1.cartItemsListFandI[i]
                                                      .itemName
                                                  : "-"}
                                              </span>
                                            </>
                                          );
                                        }
                                        return spanFni;
                                      })()}
                                      {(() => {
                                        let spanParts = [];
                                        for (
                                          let i = 0;
                                          i <
                                          data1.cartItemsListPartAndAccessory
                                            .length;
                                          i++
                                        ) {
                                          spanParts.push(
                                            <>
                                              <span>
                                                {data1
                                                  .cartItemsListPartAndAccessory[
                                                  i
                                                ].cartItemType === 2
                                                  ? data1
                                                      .cartItemsListPartAndAccessory
                                                      .length !== 0
                                                    ? data1
                                                        .cartItemsListPartAndAccessory[
                                                        i
                                                      ].itemName
                                                    : "-"
                                                  : data1
                                                      .cartItemsListPartAndAccessory
                                                      .length !== 0
                                                  ? data1
                                                      .cartItemsListPartAndAccessory[
                                                      i
                                                    ].itemName
                                                  : "-"}
                                              </span>
                                            </>
                                          );
                                        }
                                        return spanParts;
                                      })()}
                                    </div>
                                    <div className="flex flex-column"></div>
                                  </div>
                                  <hr className="mt-2"></hr>
                                  <div className="checkboxes checkboxes2 overflow-hidden pt-0">
                                    {radioItems.map((item) => (
                                      <label
                                        key={item.name}
                                        className={`${
                                          item.name === "Radeem" &&
                                          rewardspoints < estimatedpoints
                                            ? "disable eventnone spacebtwn"
                                            : "spacebtwn"
                                        }`}>
                                        <Checkbox
                                          name={item.name}
                                          value={item.name}
                                          checked={checkedItems[item.name]}
                                          onChange={handleChange}
                                        />
                                        {item.name +
                                          " ( You Have " +
                                          formatter.format(rewardspoints) +
                                          " Points )"}
                                        {/* {item.name == "Add Payment" ? (
                                          <span className="">
                                            Add Payment Method
                                            <i
                                              className="ml-auto fa-solid fa-circle-plus"
                                              onClick={() => {
                                                myContext.setaddcardpagefromcart(
                                                  true
                                                );
                                                myContext.setActive("Payment");
                                              }}></i>
                                          </span>
                                        ) : (
                                          item.name +
                                          " ( You Have " +
                                          rewardspoints +
                                          " Points )"
                                        )} */}
                                        {item.name !== "Add Payment" && (
                                          <span className="check-bullet"></span>
                                        )}
                                      </label>
                                    ))}
                                    {/* {dataforcreditcardlist.map((item) => (
                                      <label key={item.name}>
                                        <Checkbox
                                          name={item.id}
                                          value={item.name}
                                          checked={checkedItems[item.id]}
                                          onChange={handleChange}
                                        />
                                        {item.name}
                                        <br></br>
                                        {item.cardNumber}
                                        <i className="fa-regular fa-credit-card"></i>
                                        <span className="check-bullet"></span>
                                      </label>

                                      // <label  key={item.id}>
                                      //     <input type="radio" value={item.name} checked={true} name={item.name}/>
                                      //     {item.name}
                                      //     <br></br>
                                      //     {item.cardNumber}
                                      //     <span className='check-bullet'></span>
                                      // </label>
                                    ))} */}
                                  </div>
                                  <div className="flex w-100 mt-1">
                                    <span className="f-grow-1">
                                      Purchase Summary
                                    </span>
                                    <span>
                                      ${data1.finalSumPrice.toLocaleString()} |{" "}
                                      {data1.finalSumPriceInPoints.toLocaleString()}{" "}
                                      Points
                                    </span>
                                  </div>
                                  <hr className="mt-2"></hr>
                                  {/* <div className="flex w-100"> */}
                                  {/* <div className="checkboxes checkboxes2 overflow-hidden pt-0">
                                      <label>
                                        <Checkbox
                                          checked={isTax}
                                          onChange={() => {
                                            setisTax((prevValue) => !prevValue);
                                            setisTaxvalidationmssg(false);
                                          }}
                                        />
                                        <span className="">
                                          All prices are exclusive of taxes.
                                          Taxes will be applicable as per law.
                                        </span>

                                        <span className="check-bullet"></span>
                                      </label>
                                      {isTaxvalidationmssg && (
                                        <div className="text-danger mt-1">
                                          Please acknowledge the law to proceed
                                          further.
                                        </div>
                                      )}
                                    </div>
                                  </div> */}
                                  <br></br>
                                  <span className="schdulenote">
                                    All prices are exclusive of taxes. Taxes
                                    will be applicable as per law.
                                  </span>

                                  {addcardplease ? (
                                    <span className="text-danger">
                                      Please add payment method to proceed.
                                      <br></br> Or please select a payment
                                      method
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <br></br>
                                  {isTax ? (
                                    <button
                                      className="btn_theme fullwidth w-100"
                                      onClick={confirmpayment}>
                                      Confirm
                                    </button>
                                  ) : (
                                    // <button
                                    //   className="btn_theme fullwidth w-100"
                                    //   onClick={() => {
                                    //     setisTaxvalidationmssg(true);
                                    //   }}>
                                    //   Confirm
                                    // </button>
                                    <button
                                      className="btn_theme fullwidth w-100"
                                      onClick={confirmpayment}>
                                      Confirm
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )
                  )}
              </>
            ) :
            data == undefined ? (
              <div className="emptywndow">
                <i className="fa-solid fa-cart-shopping"></i>
                <h3>There are no items in your cart.</h3>
                {" "}
              </div>
            ) : (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            )}

            {isScheduleCompelete && (
              <div className="overlay">
                <Overlay
                  title="succesfullpayment"
                  otherInfo={scheduledatemodel}
                />
              </div>
            )}
          </div>
        }
        {confirmitemdelmodal && (
          <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
            <i className="fa-solid fa-trash delicon"></i>
            <h3 className="text-center">
              Are you sure you want to delete this item?
            </h3>
            <div className="flex justify-center mt-2">
              <div
                className="btn_theme btndel light mr-1"
                onClick={() => {
                  setconfirmitemdelmodal(false);
                  $(
                    ".garage_car_detail_appointment_content_wrapper"
                  ).removeClass("blur");
                  $(".Left__panel").removeClass("blur");
                  $(".top_iconbar").removeClass("blur");
                }}>
                Cancel
              </div>
              <div
                className="btn_theme btndel"
                onClick={() => {
                  setconfirmitemdelmodal(false);
                  myContext.setupdatecartcount(true);
                  $(
                    ".garage_car_detail_appointment_content_wrapper"
                  ).removeClass("blur");
                  $(".Left__panel").removeClass("blur");
                  $(".top_iconbar").removeClass("blur");
                  setloaderrow(true);
                  let end_point = "Cart/RemoveCartItem";
                  //api url for right
                  let api_url = base_url + end_point;
                  const url = api_url;
                  axios({
                    method: "post",
                    url: url,
                    data: {
                      CartItemType: deletediteminfo[0].CartItemType,
                      ItemId: deletediteminfo[0].ItemId,
                    },
                    headers: { Authorization: api_token },
                  })
                    .then((res) => {
                      if (isdataloded) {
                        CartAPI();
                        setloaderrow(true);
                        myContext.setupdatecartcount(true);
                      }
                      // setloaderrow(true);
                      // setIsLoading(true);
                      // CartAPI();
                    })
                    .catch((error) => console.log(error));
                  myContext.setupdatecartcount(true);
                }}>
                Delete
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default Cart;
