import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import { v4 } from 'uuid';
import Overlay from '../Snippets/overlay'




class Declined extends Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            carrid: props.carId,
            error: null,
            isLoaded: false,
            items: [],
            AddItemToCart: {}, 
            additemapiisloaded: false,
            additemapistatus: false,
            additemapimessage: false,
            api_token: localStorage.getItem('token')
        };
        this.AddToCart = this.AddToCart;
         
    }

    // After Rendering
    componentDidMount() {
        let end_point = 'DeclinedUser/DeclinedUserCarsServices?CarId='+this.state.carrid;
        let api_url = this.context.Base_url+end_point; 
        // Main Appointment API Start
        fetch(api_url ,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        // items: result.declinedUserDataModelList
                        items: result.declinedUserDataModel
                    });
                } else {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
            
        )
        // Main Appointment API End
    }

    // Add To Cart API Function
    AddToCartApiFunction = (ReqData) => {
        
        let end_point = 'Cart/AddItemToCart';
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            },
            body: JSON.stringify(ReqData),
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                }, () => {

                    this.context.setupdatecartcount(true);
                    setTimeout(() => {
                        this.setState({additemapiisloaded:false})
                    }, 2000);
                });
            } else {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                });
            }
        },
        (error) => {
            this.setState({
                additemapistatus:false,
                error
            });
        }) 

    }
    // Add To Cart API Function


    // Add To cart 
    AddToCart = e => {
        
        let item = e.currentTarget.getAttribute("serviceID");
        let CarID =  e.currentTarget.getAttribute("carID");
        let DealershipID = e.currentTarget.getAttribute("dealershipID");
        let CartItemModels = "";
        let PromoCode = "";
        let Notes = "";

        var priceval = e.currentTarget.getAttribute('serviceprice');
        
        let val = [{   
            "CartItemType": 0,
            "ItemId": parseInt(item),
            "isBeneift": false,
            "Price" : parseInt(priceval)
        }] 
        let data = {
            "DealerShipId": parseInt(DealershipID),
            "CarId": parseInt(CarID),
            "CartItemModels" : val,
            "PromoCode": PromoCode,
            "Notes": Notes
        }
        this.setState(
            { 
                AddItemToCart : data, 
                additemapiisloaded : true
            }, () => {
                // Add To Cart API Hit Function
                this.AddToCartApiFunction(this.state.AddItemToCart);
            }
        )
        
    }

    
    EmptyCart = e => {
        let end_point = 'Cart/EmptyCartItems';  
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    emptycartapistatus: result.status
                }, () => {
                    this.setState({
                        additemapiisloaded : true
                    })
                    this.AddToCartApiFunction(this.state.AddItemToCart);
                });
            } else {
                this.setState({
                    emptycartapistatus: result.status
                });
            }
        },
        (error) => {
            this.setState({
                emptycartapistatus: false,
                error
            });
        }) 
    }



    render() {
        return (
          <>
            <div className="garage_car_detail_appointment_content_wrapper garagedecline bg-white radius mt-5">
              {this.state.additemapiisloaded
                ? this.state.additemapistatus && (
                    <div className="loader-div">
                      <Overlay
                        title="additem"
                        message={this.state.additemapimessage}
                      />
                    </div>
                  )
                : ""}
              {this.state.additemapiisloaded ? (
                this.state.additemapistatus === false &&
                this.state.additemapimessage ? (
                  <div className="loader-div">
                    <h3 className="title_medium"></h3>
                    <div className="confirm_dialog">
                      <h4>{this.state.additemapimessage}</h4>
                      <div className="flex">
                        {this.state.additemapimessage ===
                        "Item Already Exist in the Cart." ? (
                          <div
                            className={`btn_theme small`}
                            onClick={() => {
                              this.setState({
                                additemapiisloaded: false,
                              });
                            }}>
                            Okay
                          </div>
                        ) : (
                          <>
                            <div
                              className={`btn_theme small light`}
                              onClick={this.EmptyCart}>
                              Empty Cart
                            </div>
                            <div
                              className={`btn_theme small`}
                              onClick={() => {
                                this.setState({
                                  additemapiisloaded: false,
                                });
                              }}>
                              Cancel
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="confirm_dialog_layer"></div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {this.state.apiStatus === false ? (
                <div className="emptywndow emptywndowfav">
                  <h3 className="mb-0">No declined service found</h3>
                </div>
              ) : this.state.isLoaded === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : (
                <>
                  {this.state.items.declineServices.length == 0 ? (
                    <div className="emptywndow emptywndowfav">
                      <h3 className="mb-0">There is no declined work found.</h3>
                    </div>
                  ) : (
                    [this.state.items].map((data1, i) => (
                      <div key={i}>
                        <div className="flex-space-between-center w-100 firsttr">
                          <div className="">
                            <span className="text-black">
                              <strong>
                                Dealership: {data1.dealershipName}{" "}
                              </strong>
                              <strong>
                                <h3>{data1.carName}</h3>
                              </strong>
                            </span>
                          </div>
                        </div>

                        <div className="table__wrapper">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" width="30%">
                                  <strong>Service Name</strong>
                                </th>
                                <th scope="col" width="15%">
                                  <strong>Service Type</strong>
                                </th>
                                {/* <th scope="col" width='15%'><strong>Discounted %</strong></th> */}
                                <th scope="col" width="15%">
                                  <strong>Discounted Amount</strong>
                                </th>
                                <th scope="col" width="10%">
                                  <strong>Amount</strong>
                                </th>
                                <th scope="col" width="15%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.isLoaded === false ? (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              ) : data1.declineServices.length === 0 ? (
                                ""
                              ) : (
                                (() => {
                                  let td = [];
                                  for (
                                    let i = 0;
                                    i < data1.declineServices.length;
                                    i++
                                  ) {
                                    td.push(
                                      <tr className="spacebottom" key={v4}>
                                        <td className="flex_start_center">
                                          <div className="table_image_wrapper mini">
                                            <img
                                              className="brokenimg"
                                              alt="img"
                                              src={
                                                data1.declineServices[i]
                                                  .serviceImage
                                              }
                                              id="belliconid"></img>
                                          </div>
                                          <div className="ml-1">
                                            <span className="text-black">
                                              {data1.declineServices.length !==
                                              0
                                                ? data1.declineServices[i]
                                                    .dealershipServiceName
                                                : "-"}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          {data1.declineServices.length !== 0
                                            ? data1.declineServices[i]
                                                .serviceParent
                                            : "-"}
                                        </td>
                                        {/* <td>{data1.declineServices.length !== 0 ? data1.declineServices[i].discount+'% Off' : '-'}</td> */}
                                        <td>
                                          {data1.declineServices.length !== 0
                                            ? "$" +
                                              data1.declineServices[i]
                                                .discountedPrice
                                            : "-"}
                                        </td>
                                        <td>
                                          <del>
                                            {data1.declineServices.length !== 0
                                              ? "$" +
                                                data1.declineServices[i]
                                                  .orignalPrice
                                              : "-"}
                                          </del>
                                        </td>
                                        <td>
                                          {data1.declineServices[i]
                                            .dealerShipServiceIsCart ? (
                                            <div className="btn_theme small light ml-auto">
                                              <i className="fa-solid fa-cart-shopping mr-1"></i>
                                              Already in Cart
                                            </div>
                                          ) : (
                                            <div
                                              className="btn_theme small light ml-auto"
                                              onClick={this.AddToCart}
                                              dealershipID={data1.dealershipId}
                                              carID={data1.carId}
                                              serviceID={
                                                data1.declineServices[i]
                                                  .dealershipServiceId
                                              }
                                              serviceprice={
                                                data1.declineServices[i]
                                                  .discountedPrice
                                              }>
                                              Add To Cart
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      // {/* <tr className="table__row__seperator_small"></tr> */}
                                    );
                                  }
                                  return td;
                                })()
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </>
        );
    }
};

export default Declined;