import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import dictag from '../../images/discounttag.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Overlay from '../Snippets/overlay';
import $ from 'jquery';
import { v4 } from 'uuid';
import axios from "axios";

async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
        // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}


async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}



class ShopServices extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      carrid: props.carId,
      error: null,
      isLoaded: false,
      additemapiisloaded: false,
      additemapistatus: "",
      additemapimessage: false,
      isLoadedcard: false,
      dealershiID: 0,
      isLoadedcardDetail: false,
      items: [],
      checkBoxModelObject: [],
      checkBoxModelObjectPrice: [],
      checkBoxModelObjectisbene: [],
      checkBoxModelObjectisspecial: [],
      DropDownIdAfterSelect: context.garagefirstcarid,
      isRighpanelOpen: false,
      boxID: 0,
      AddItemToCart: {},
      readyToGoCartModel: [],
      readyToGoCartModelStatus: false,
      api_token: localStorage.getItem("token"),
      checkedItems: new Map(),
      radioButtonLoader: false,
      readyToGoCartModel: {},
      readyToGoCartModelpush: [],
      finalModel: {},
    };
    this.HandleChangeCarDropDown = this.HandleChangeCarDropDown;
    this.DataRendering = this.DataRendering;
    this.DropDownAPI = this.DropDownAPI;
    this.favoriteMe = this.favoriteMe;
    this.unfavoriteMe = this.unfavoriteMe;
    this.boxClick = this.boxClick;
    this.DetailDataDRendering = this.DetailDataDRendering;
    this.handleChangeCheckboxes = this.handleChangeCheckboxes.bind(this);
    this.AddToCart = this.AddToCart.bind(this);
    this.checkBoxModelObject = [];
    this.checkBoxModelObjectPrice = [];
    this.checkBoxModelObjectisbene = [];
    this.checkBoxModelObjectisspecial = [];
    this.readyToCart = [];
    this.readyToCartModel = [];
    this.readyToGoCartModel = {};
    this.AddToCartApiFunction = this.AddToCartApiFunction;
    this.functionReadtToAddToCart = this.functionReadtToAddToCart;
    this.EmptyCart = this.EmptyCart;
  }

  favoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await markAsFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoadedcardDetail: false,
          radioButtonLoader: false,
          isRighpanelOpen: false,
          checkBoxModelObject: [],
        },
        () => {
          this.setState(
            {
              isRighpanelOpen: true,
            },
            () => {
              this.checkBoxModelObject = [];
              setTimeout(() => {
                this.DetailDataDRendering(this.props.carId, this.state.boxID);
              }, 100);
            }
          );
        }
      );
    }
  };

  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoadedcardDetail: false,
          radioButtonLoader: false,
          isRighpanelOpen: false,
        },
        () => {
          this.setState(
            {
              isRighpanelOpen: true,
              checkBoxModelObject: [],
            },
            () => {
              this.checkBoxModelObject = [];
              setTimeout(() => {
                this.DetailDataDRendering(this.props.carId, this.state.boxID);
              }, 100);
            }
          );
        }
      );
    }
  };

  // Car DropDown API Start
  DropDownAPI = () => {
    let end_point = "Garage/UserCars";
    let api_url = this.context.Base_url + end_point;

    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.carInfoDetailModels,
            });
          } else {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
  };
  // Car DropDown API End

  // Data API Start
  DataRendering = (props) => {
    let end_point_Shop_Services = "Shop/DealerShipServicesAndCategory";
    let url_parameter =
      "?" +
      "IsDetail" +
      "=" +
      false +
      "&" +
      "CarId" +
      "=" +
      this.state.carrid +
      "&" +
      "ServiceCategoryId" +
      "=" +
      0 +
      "&" +
      "ApplySearch" +
      "=" +
      false +
      "&" +
      "SearchString" +
      "=" +
      "Something";
    const url = this.context.Base_url + end_point_Shop_Services + url_parameter;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoadedcard: true,
              apiStatuscard: result.status,
              apiStatusMessagecard: result.message,
              carditems: result.serviceCategories,
            });
          } else {
            this.setState({
              isLoadedcard: true,
              apiStatuscard: result.status,
              apiStatusMessagecard: result.message,
              carditems: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoadedcard: false,
            error,
          });
        }
      );
  };
  // Data API End

  // Details Data API Start
  DetailDataDRendering = (carId, ServiceCategoryId) => {
    this.setState({
      radioButtonLoader: false,
    });
    let end_point_Shop_Services = "Shop/DealerShipServicesAndCategory";
    let url_parameter =
      "?" +
      "IsDetail" +
      "=" +
      true +
      "&" +
      "CarId" +
      "=" +
      carId +
      "&" +
      "ServiceCategoryId" +
      "=" +
      ServiceCategoryId +
      "&" +
      "ApplySearch" +
      "=" +
      false +
      "&" +
      "SearchString" +
      "=" +
      "Something";
    const url = this.context.Base_url + end_point_Shop_Services + url_parameter;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoadedcardDetail: true,
              apiStatuscardDetail: result.status,
              apiStatusMessagecardDetail: result.message,
              carditemsDetail: result.serviceCategoryDetailModel,
              radioButtonLoader: true,
              dealershiID: result.serviceCategoryDetailModel.dealerShipId,
            });
          } else {
            this.setState({
              isLoadedcardDetail: true,
              apiStatuscardDetail: result.status,
              apiStatusMessagecardDetail: result.message,
              carditemsDetail: [],
              dealershiID: 0,
            });
          }
        },
        (error) => {
          this.setState({
            isLoadedcardDetail: false,
            error,
          });
        }
      );
  };
  // Details Data API End

  // After Rendering
  componentDidMount() {
    // DropDown API
    this.DropDownAPI();

    // Data API
    this.DataRendering(this.state.DropDownIdAfterSelect);
  }

  boxClick = (e) => {
    const boxID = e.currentTarget.getAttribute("box-id");
    this.setState({
      isRighpanelOpen: true,
      isLoadedcardDetail: false,
      boxID: boxID,
    });
    // Details Data API
    this.DetailDataDRendering(this.props.carId, boxID);
    $(".garage_car_detail_header").addClass("addlayer");
    $(".carinfo_cards_box_wrapper").addClass("addlayer");
    $(".top_iconbar").addClass("blur");
  };

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
  }

  // Car Drop Down Change Event Start
  HandleChangeCarDropDown = (e) => {
    const DropDownID = e.target.value;
    this.setState({
      DropDownIdAfterSelect: DropDownID,
      isLoadedcard: false,
      isRighpanelOpen: false,
    });
    this.DataRendering(e.target.value);
  };
  // Car Drop Down Change Event End

  handleChangeCheckboxes = (e) => {
    var item = e.target.value;
    var checkmodel = {
      CartItemModels: item,
    };
    var priceval = e.currentTarget.getAttribute("data");
    var isSpecial = e.currentTarget.getAttribute("isSpecial");
    var iBenifitt = e.currentTarget.getAttribute("isBene");
    
    let pr;
    let bene;
    let special;

    if (isSpecial == "true") {
        special = true;
    } else {
        special = false;
    }


    if (priceval == 0) {
      pr = "0";
      // bene = true;
    } else {
      // bene = false;
      pr = priceval;
    }


    if (iBenifitt == "true") {
      bene = true;
    } else {
      bene = false;
    }

    if (this.state.checkBoxModelObject.includes(checkmodel.CartItemModels)) {
      let index = this.checkBoxModelObject.indexOf(checkmodel.CartItemModels);
      this.checkBoxModelObject.splice(index, 1);
    } else {
      this.checkBoxModelObject.push(checkmodel.CartItemModels);
    }
    this.checkBoxModelObjectPrice.push(pr);
    this.checkBoxModelObjectisbene.push(bene);
    this.checkBoxModelObjectisspecial.push(special);
    this.setState(
      {
        checkBoxModelObject: this.checkBoxModelObject,
        checkBoxModelObjectPrice: this.checkBoxModelObjectPrice,
        checkBoxModelObjectisbene: this.checkBoxModelObjectisbene,
        checkBoxModelObjectisspecial: this.checkBoxModelObjectisspecial,
      },
      () => {
        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
        } else {
          e.target.classList.add("active");
        }
        var val = {};
        var objeCt = [];
        for (let i = 0; i < this.state.checkBoxModelObject.length; i++) {
          val = {
            CartItemType: 0,
            ItemId: parseInt(this.state.checkBoxModelObject[i]),
            isBeneift: this.state.checkBoxModelObjectisbene[i],
            Price: parseFloat(this.state.checkBoxModelObjectPrice[i]),
            ItemTypeAddToCondition: this.state.checkBoxModelObjectisbene[i]
              ? 2
              : this.state.checkBoxModelObjectisspecial[i]
              ? 3
              : 1,
          }
          objeCt.push(val);
        }
        this.functionReadtToAddToCart(objeCt);
      }
    );
    e.preventDefault();
  };

  functionReadtToAddToCart = (model) => {
    let CarID = this.props.carId;
    let DealershipID = this.state.dealershiID;
    let CartItemModels = model;
    let PromoCode = "";
    let Notes = "";

    let data = {
      DealerShipId: DealershipID,
      CarId: parseInt(CarID),
      CartItemModels: CartItemModels,
      PromoCode: PromoCode,
      Notes: Notes,
    };
    this.setState({ AddItemToCart: data });
    return this.state.AddItemToCart;
  };

  // Add To Cart API Function
  AddToCartApiFunction = (ReqData) => {
    
    this.setState({
      additemapiisloaded: true,
    });
    let end_point = "Cart/AddItemToCart";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                additemapistatus: result.status,
                additemapimessage: result.message
              },
              () => {
                this.checkBoxModelObjectPrice = [];
                this.checkBoxModelObjectisbene = [];
                this.checkBoxModelObjectisspecial = [];
                this.context.setupdatecartcount(true);
                setTimeout(() => {
                  this.setState({ additemapiisloaded: false });
                }, 2000);
              }
            );
          } else {
            this.setState({
              additemapistatus: result.status,
              additemapimessage: result.message,
            });
          }
        },
        (error) => {
          this.setState({
            additemapistatus: false,
            error,
          });
        }
      );
  };
  // Add To Cart API Function

  // Add To cart
  AddToCart = (e) => {
    this.setState(
      {
        checkBoxModelObject: [],
        isLoadedcardDetail: false,
        additemapiisloaded: true,
        isRighpanelOpen: false,
      },
      () => {
        this.checkBoxModelObject = [];
        this.DetailDataDRendering(this.props.carId, this.state.boxID);
      }
    );

    // Add To Cart API Hit Function
    this.AddToCartApiFunction(this.state.AddItemToCart);

    $(".garage_car_detail_header").removeClass("addlayer");
    $(".carinfo_cards_box_wrapper").removeClass("addlayer");
    $(".top_iconbar").removeClass("blur");
  };

  EmptyCart = (e) => {
    let end_point = "Cart/EmptyCartItems";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                emptycartapistatus: result.status,
              },
              () => {
                this.setState({
                  additemapiisloaded: true,
                  isRighpanelOpen: false,
                });
                this.AddToCartApiFunction(this.state.AddItemToCart);
              }
            );
          } else {
            this.setState({
              emptycartapistatus: result.status,
            });
          }
        },
        (error) => {
          this.setState({
            emptycartapistatus: false,
            error,
          });
        }
      );
    $(".garage_car_detail_header").removeClass("addlayer");
    $(".carinfo_cards_box_wrapper").removeClass("addlayer");
  };

  render() {
    return (
      <>
        <ToastContainer hideProgressBar={true} />
        {this.state.isRighpanelOpen && (
          <div className="carinfo_cards_box_right_panel active">
            <div
              className="closedetailsection"
              onClick={() => {
                this.setState({
                  isRighpanelOpen: false,
                });
                $(".garage_car_detail_header").removeClass("addlayer");
                $(".carinfo_cards_box_wrapper").removeClass("addlayer");
                $(".top_iconbar").removeClass("blur");
              }}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            {this.state.apiStatuscardDetail === false ? (
              <h3 className="title_medium">
                {this.state.apiStatusMessagecardDetail}
              </h3>
            ) : this.state.isLoadedcardDetail === false ? (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            ) : (
              <>
                <div className="right-panel_image_wrapper">
                  <img
                    className="brokenimg"
                    alt="img"
                    src={this.state.carditemsDetail.serviceCategoryImage}
                    id="belliconid"></img>
                </div>
                <div className="right-panel_title mt-1">
                  <h4>{this.state.carditemsDetail.serviceCategoryName}</h4>
                </div>
                <div>
                  {this.state.carditemsDetail.serviceCategoryDescription}
                </div>
                <div className="checkboxes">
                  {this.state.carditemsDetail.shopServiceCategoryServiceDetailModels.map(
                    (item, i) => (
                      <div key={i} className="flex-space-between-center">
                        <label className="labelcon" key={v4}>
                          {console.log(item)}
                          {item.dealerShipServiceIsCart ? (
                            <i className="fa-solid fa-cart-shopping mr-1"></i>
                          ) : (
                            <input
                              value={item.dealerShipServiceId}
                              type="checkbox"
                              aria-checked="false"
                              data={
                                item.isBenefit
                                  ? 0
                                  : item.dealerShipServiceIsSpecial
                                  ? item.dealerShipServiceSpecialPrice
                                  : item.dealerShipServiceOrignalPrice
                              }
                              isSpecial={
                                item.dealerShipServiceIsSpecial
                                  ? "true"
                                  : "false"
                              }
                              isBene={item.isBenefit ? "true" : "false"}
                              onChange={this.handleChangeCheckboxes}
                            />
                          )}
                          <span className="inputname">
                            {item.dealerShipServiceName}
                            {item.isBenefit && (
                              <span className="tag3">
                                <span>{item.isBenefitCount}x</span>
                                <span>Included in the package</span>
                              </span>
                            )}
                            {item.dealerShipServiceIsSpecial && (
                              <span className="tag3">
                                <span>Special</span>
                              </span>
                            )}
                            {(() => {
                              let span;
                              if (
                                item.isBenefit ||
                                item.dealerShipServiceIsSpecial
                              ) {
                                span = (
                                  <span className="tag3">
                                    <span>Valid till {item.validTillDate}</span>
                                  </span>
                                );
                              }
                              return span;
                            })()}
                          </span>

                          <div className="flex dir-col alignstart">
                            <div className="flex dir-col">
                              <span
                                className=""
                                id="priceId"
                                data={
                                  item.isBenefit
                                    ? 0
                                    : item.dealerShipServiceIsSpecial
                                    ? item.dealerShipServiceSpecialPrice
                                    : item.dealerShipServiceOrignalPrice
                                }>
                                {item.isBenefit === false && (
                                  <>
                                    <del>
                                      {item.dealerShipServiceIsSpecial && (
                                        <>
                                          $
                                          {item.dealerShipServiceOrignalPrice.toLocaleString()}
                                          {/* <strong> Price</strong> */}
                                        </>
                                      )}
                                    </del>
                                    <strong className="sepra"> </strong>
                                  </>
                                )}
                              </span>
                              <span
                                className=""
                                id="priceId"
                                data={
                                  item.isBenefit
                                    ? 0
                                    : item.dealerShipServiceIsSpecial
                                    ? item.dealerShipServiceSpecialPrice
                                    : item.dealerShipServiceOrignalPrice
                                }>
                                {item.isBenefit === false && (
                                  <>
                                    $
                                    {item.dealerShipServiceIsSpecial
                                      ? item.dealerShipServiceSpecialPrice.toLocaleString()
                                      : item.dealerShipServiceOrignalPrice.toLocaleString()}
                                    <strong> Price</strong>
                                    <strong className="sepra"> | </strong>
                                  </>
                                )}
                              </span>
                            </div>

                            <span className="" id="PriceInPoints">
                              {item.isBenefit === false && (
                                <>
                                  {item.dealerShipServicePriceInPoints.toLocaleString()}
                                  <strong> Points</strong>
                                </>
                              )}
                            </span>
                          </div>
                          <span className="check-bullet"></span>
                        </label>
                        {!item.isBenefit && (
                          <div className="heart_icon heart_icon_service">
                            {(() => {
                              switch (this.context.favoriteitemloader) {
                                case item.dealerShipServiceId:
                                  return (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  );
                                default:
                                  return this.state.radioButtonLoader ===
                                    false ? (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  ) : item.dealerShipServiceIsFavorites ? (
                                    <img
                                      alt="img"
                                      src={favIcon}
                                      id="belliconid"
                                      onClick={() => {
                                        this.unfavoriteMe({
                                          Entity: 3,
                                          tableId: item.dealerShipServiceId,
                                          CarId: this.props.carId,
                                        });
                                        this.context.setcarID(
                                          item.dealerShipServiceId
                                        );
                                        this.context.setsinglecardetailsectionisopen(
                                          false
                                        );
                                      }}></img>
                                  ) : (
                                    <img
                                      alt="img"
                                      src={UnfavIcon}
                                      id="belliconid"
                                      onClick={() => {
                                        this.favoriteMe({
                                          Entity: 3,
                                          tableId: item.dealerShipServiceId,
                                          CarId: this.props.carId,
                                        });
                                        this.context.setcarID(
                                          item.dealerShipServiceId
                                        );
                                      }}></img>
                                  );
                              }
                            })()}
                          </div>
                        )}
                        {item.dealerShipServiceIsCart ? (
                          <span className="aic">Already in Cart</span>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )}
                </div>
                {(() => {
                  let ele;
                  if (this.state.checkBoxModelObject.length != 0) {
                    ele = (
                      <div
                        className="btn_theme fullwidth"
                        onClick={this.AddToCart}>
                        Add to Cart
                      </div>
                    );
                  } else {
                    ele = "";
                  }
                  return ele;
                })()}
              </>
            )}
          </div>
        )}
        <div className="flex flex-column h-100">
          {this.state.apiStatus === false ? (
            <h3 className="title_medium">{this.state.apiStatusMessage}</h3>
          ) : this.state.isLoaded === false ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : (
            <>
              {/* <select className='custom_select_select_ele' onChange={this.HandleChangeCarDropDown}>       
                            {
                                this.state.items.map((option) => (
                                    <option value={option.id}>{option.dealerShipName} | {option.year} | {option.make} {option.model}</option>
                                ))
                            }
                            </select> */}
              <div className="content_panel_controller overflow-auto">
                <div className="carinfo_cards_box_wrapper">
                  {this.state.isLoaded === false ? (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  ) : this.state.isLoadedcard === false ? (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  ) : this.state.carditems.length != 0 ? (
                    this.state.carditems.map((user) => (
                      <div
                        className="carinfo_cards_box_wrapper_items"
                        key={user.serviceCategoryId + v4}>
                        <div
                          className="carinfo_cards_box_wrapper_items_frame_controller"
                          onClick={this.boxClick}
                          box-id={user.serviceCategoryId}>
                          <div className="carinfo_cards_box_wrapper_items_image_wrapper">
                            <div className="tags_wrapper">
                              {user.isBenefit && (
                                <span className="tag2">
                                  {/* <span>{user.isBenefitCount}x</span> */}
                                  <span>Included</span>
                                </span>
                              )}
                              {user.dealerShipServiceIsSpecial && (
                                <span className="tag">
                                  <img
                                    className="brokenimg"
                                    alt="Failed to load"
                                    src={dictag}></img>
                                </span>
                              )}
                            </div>
                            <img
                              className="brokenimg"
                              alt="Failed to load"
                              src={user.serviceCategoryImage}></img>
                          </div>
                          <div className="carinfo_cards_box_wrapper_items_content_wrapper">
                            <h4 className="carinfo_cards_box_wrapper_items_title">
                              {user.serviceCategoryName}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="emptywndow emptywndowfav">
                      <h3 className="mb-0">No Service Available</h3>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {this.state.additemapiisloaded
          ? this.state.additemapistatus && (
              <div className="loader-div succesmodal">
                <Overlay
                  title="additem"
                  message={this.state.additemapimessage}
                />
              </div>
            )
          : ""}
        {this.state.additemapiisloaded
          ? this.state.additemapistatus === false && (
              <div className="loader-div succesmodal">
                <h3 className="title_medium"></h3>
                <div className="confirm_dialog">
                  <h4>{this.state.additemapimessage}</h4>

                  <div className="flex">
                    {this.state.additemapimessage ===
                    "Item Already Exist in the Cart." ? (
                      <div
                        className={`btn_theme small`}
                        onClick={() => {
                          this.setState({
                            additemapiisloaded: false,
                          });
                        }}>
                        Okay
                      </div>
                    ) : (
                      <>
                        <div
                          className={`btn_theme small light`}
                          onClick={this.EmptyCart}>
                          Empty Cart
                        </div>
                        <div
                          className={`btn_theme small`}
                          onClick={() => {
                            this.setState({
                              additemapiisloaded: false,
                            });
                          }}>
                          Cancel
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="confirm_dialog_layer"></div>
              </div>
            )
          : ""}
      </>
    );
  }
}
export default ShopServices;
