import React, { useHistory } from 'react';
import { useContext } from 'react';
import TopNav from '../Snippets/top_nav_items';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
 import Appointments from "../Detailcomponents/Garagedetailcomponent/Appointments";
import { v4 } from 'uuid';

const AllAppointments = ({title, carId}) => {

    const myContext = useContext(AppContext);
    let base_url = myContext.Base_url;
    let endpoint = 'Appointment/GetUserAppointments'
    let api_token = localStorage.getItem('token'); 


    
    const [upcomingstate, setupcomingstate] = React.useState(true);
    const [previousstate, setpreviousstate] = React.useState(false);
    const [cancelledstate, setcancelledstate] = React.useState(false);

    // API URL For  Benefits > Services
    let apiurl = base_url+endpoint+'?carId='+carId+'&getAppointmentType=';
    // Check API Loading Status
    const [isLoading, setIsLoading] = React.useState(true);
    // Set API Response into model
    const [isdataloded, setisdataloded] = React.useState(false);
    // Set API Model
    const [data, setData] = React.useState([]);
    // Set API Status Appointments
    const [appointmentapistatus, setappointmentapistatus] = React.useState(false);
    const [appointmentapimessage, setappointmentapimessage] = React.useState(false);


    
    const [confirmDialogState, setconfirmDialogState] = React.useState(false);
    const [currentDeletedID, setcurrentDeletedID] = React.useState('');

    const [selected, setSelected] = React.useState();
    const [cnfirmbtdisabled, setcnfirmbtdisabled] = React.useState(true);
    const [datareadytogo, setdatareadytogo] = React.useState(false);
    const [apistatus, setapistatus] = React.useState(false);
    const [apimessage, setapimessage] = React.useState();


    

    const onLoadAPI = (e) => {  
        const url = apiurl+e;
        fetch(url,{
            method: 'GET', 
            headers: {  
                'Content-Type': 'application/json',
                'Authorization' : api_token
            }
        }) 
        .then((response) => response.json())
        .then((json) => { 
            myContext.setappoinmentcount(json.appointmentDetailsCount);
            setData(json['appointmentDetails']);
            setappointmentapistatus(json.status);
            setappointmentapimessage(json.message);
            setIsLoading(false);
            setisdataloded(true);
        })
        .catch((error) => console.log(error));
    }


    React.useEffect(() => {
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            
            onLoadAPI(2);
        }
       
    }, []); 
    React.useEffect(() => { 
        if(api_token !== undefined && api_token !== null && api_token !== '') {
            if(appointmentapistatus) {
                if (data.length !== 0) {
                    setIsLoading(false);
                    setisdataloded(true);
                }
            }
        }
    }, [data]);


     
    const confirmationDialog = (e) => {
        const ClickID = e.currentTarget.getAttribute("cancel-appoinment-id");
        setconfirmDialogState(true);
        setcurrentDeletedID(ClickID);
    }
    const closeDialog = (e) => {
        setconfirmDialogState(false);
        setcurrentDeletedID('');
        setcnfirmbtdisabled(true);
    }

    const doneDialog = (e) => {
        setconfirmDialogState(false);
        setcurrentDeletedID('');
        setcnfirmbtdisabled(true);
        setIsLoading(true);
        setisdataloded(false);
        setapistatus(false);
        onLoadAPI(2);
    }
    const confirmDelete = (e) => {
        if (cnfirmbtdisabled === false) {
            setdatareadytogo(true);
            const dataReq = {
                "AppointmentId": currentDeletedID,
                "CancelComments": selected
            }
            let endpointdelappoint = 'Appointment/CancelAppointments'
            let apiurldelapp = base_url+endpointdelappoint;
            const url = apiurldelapp;
            fetch(url,{
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : api_token
                },
                body: JSON.stringify(dataReq),
            })
            .then(res => res.json())
            .then((res) => {
                setdatareadytogo(false);
                setapistatus(res.status);
                setapimessage(res.message);
            })
            .catch((error) => console.log(error));
        }
    }


    const onChange = (e) => {
        setSelected(e);
        setcnfirmbtdisabled(false);
    }
    

    const upcoming = (e) => {

        setupcomingstate(true);
        setpreviousstate(false);
        setcancelledstate(false);
        setIsLoading(true);
        onLoadAPI(2);
    }
    const previous = (e) => {
        setupcomingstate(false);
        setpreviousstate(true);
        setcancelledstate(false);
        setIsLoading(true);
        onLoadAPI(3);
    }
    const cancelled = (e) => {
        setupcomingstate(false);
        setpreviousstate(false);
        setcancelledstate(true);
        setIsLoading(true);
        onLoadAPI(1);
    }
    


    return (
      <>
        {datareadytogo && (
          <div className="loader-div z-in-4">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        )}
        <div className="top_iconbar">
          <span className="top_Left_column">
            {myContext.shopcardetailsectionisopen !== "" && (
              <span className="backbutton">
                <i className="fa-solid fa-chevron-left"></i>
              </span>
            )}
            <h4 className="title_medium">{title}</h4>
          </span>
          {myContext.singlecardetailsectionisopen === false && <TopNav />}
        </div>
        <div className="content_panel_controller dir_column">
          {/* <div className="innertab__wrapper round_tabs widthauto">
            <span
              className={`innertab__wrapper_items ${
                upcomingstate ? "active" : ""
              }`}
              onClick={upcoming}>
              Upcoming
            </span>
            <span
              className={`innertab__wrapper_items ${
                previousstate ? "active" : ""
              }`}
              onClick={previous}>
              Previous
            </span>
            <span
              className={`innertab__wrapper_items ${
                cancelledstate ? "active" : ""
              }`}
              onClick={cancelled}>
              Canceled
            </span>
          </div> */}
          <div className="innertab__content_wrapper appoincustm">
            <div className="garage_car_detail_appointment_content_wrapper bg-white radius">
              <Appointments title="Appointments" carId={0} />
              {/* {appointmentapistatus === false ? (
                <h3 className="title_medium">No Appointments are found</h3>
              ) : appointmentapimessage === "No Appointments are found" ? (
                <h3 className="title_medium">{appointmentapimessage}</h3>
              ) : isLoading ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : (
                <>
                  <div className="table__wrapper">
                    <table className="table tableintotable">
                      <thead>
                        <tr>
                          <th scope="col" width="40%">
                            Service Name
                          </th>
                          <th scope="col" width="35%">
                            Service Type
                          </th>
                          <th scope="col" width="25%">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                  {confirmDialogState && (
                    <>
                      {apistatus ? (
                        <>
                          <div className="confirm_dialog">
                            <h4>{apimessage}</h4>
                            <div className="flex">
                              <div
                                className={`btn_theme small light`}
                                onClick={doneDialog}>
                                Done
                              </div>
                            </div>
                          </div>
                          <div className="confirm_dialog_layer"></div>
                        </>
                      ) : (
                        <>
                          <div className="confirm_dialog">
                            <p>
                              Are you sure? You want to cancel this Appointment?
                            </p>
                            <div className="w-100 mb-2">
                              <h4>Reason of Cancelation</h4>

                              <RadioGroup onChange={onChange} verticle>
                                <RadioButton
                                  rootColor="#757575"
                                  pointColor="#121212"
                                  padding="15px"
                                  value="The cost of this appointment is too high"
                                  className="Radio">
                                  The cost of this appointment is too high
                                </RadioButton>
                                <RadioButton
                                  rootColor="#757575"
                                  pointColor="#121212"
                                  padding="15px"
                                  value="The dealer's location is far away from my locality.">
                                  The dealer's location is far away from my
                                  locality.
                                </RadioButton>
                                <RadioButton
                                  rootColor="#757575"
                                  pointColor="#121212"
                                  padding="15px"
                                  value="I have some other commitments in this time slot.">
                                  I have some other commitments in this time
                                  slot.
                                </RadioButton>
                                <RadioButton
                                  rootColor="#757575"
                                  pointColor="#121212"
                                  padding="15px"
                                  value="I have already acquired this service.">
                                  I have already acquired this service.
                                </RadioButton>
                                <RadioButton
                                  rootColor="#757575"
                                  pointColor="#121212"
                                  padding="15px"
                                  value="Other.">
                                  Other.
                                </RadioButton>
                              </RadioGroup>
                            </div>
                            <div className="flex">
                              <div
                                className={`btn_theme small mr-1 ${
                                  cnfirmbtdisabled && "disable"
                                }`}
                                onClick={confirmDelete}>
                                Confirm
                              </div>
                              <div
                                className={`btn_theme small light`}
                                onClick={closeDialog}>
                                Cancel
                              </div>
                            </div>
                          </div>
                          <div className="confirm_dialog_layer"></div>
                        </>
                      )}
                    </>
                  )}

                  {isdataloded &&
                    data.map((data1, i) => (
                      <>
                        <div
                          key={v4}
                          className="flex-space-between-center w-100 firsttr">
                          <div className="">
                            <h3>
                              <span className="text-black">
                                Dealership: {data1.dealershipName}
                                <br></br>
                                {data1.carName}
                              </span>
                              <br></br>
                              <br></br>
                              <span className="text-black">
                                {data1.appointmentDate} |{" "}
                                {data1.appointmentTime} <br></br>{" "}
                                <small>
                                  (Appointment Number: {data1.appointmentNumber}
                                  )
                                </small>
                              </span>
                            </h3>
                          </div>
                          {cancelledstate === false && (
                            <div className="">
                              <span
                                className="text-black cursor-pointer"
                                onClick={confirmationDialog}
                                cancel-appoinment-id={data1.appointmentId}>
                                cancel
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="table__wrapper">
                          <table className="table tableintotable">
                            <thead>
                              <tr>
                                <th scope="col" width="40%"></th>
                                <th scope="col" width="35%"></th>
                                <th scope="col" width="25%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              ) : data1.serviceAppointmentModel.length === 0 ? (
                                ""
                              ) : (
                                (() => {
                                  let td = [];
                                  for (
                                    let i = 0;
                                    i < data1.serviceAppointmentModel.length;
                                    i++
                                  ) {
                                    td.push(
                                      <>
                                        <tr>
                                          <td className="flex_start_center">
                                            <div className="table_image_wrapper">
                                              <img
                                                className="brokenimg"
                                                alt="img"
                                                src={
                                                  data1.serviceAppointmentModel[
                                                    i
                                                  ].dealershipServiceImage
                                                }
                                                id="belliconid"></img>
                                            </div>
                                            <div className="ml-1">
                                              <span className="text-black">
                                                {data1.serviceAppointmentModel
                                                  .length !== 0
                                                  ? data1
                                                      .serviceAppointmentModel[
                                                      i
                                                    ].dealershipServiceName
                                                  : "-"}
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            {data1.serviceAppointmentModel
                                              .length !== 0
                                              ? data1.serviceAppointmentModel[i]
                                                  .dealershipServiceParentName
                                              : "-"}
                                          </td>
                                          <td>
                                            {data1.serviceAppointmentModel
                                              .length !== 0
                                              ? "$" +
                                                data1.serviceAppointmentModel[i]
                                                  .dealershipServicePrice
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="table__row__seperator_small"></tr>
                                      </>
                                    );
                                  }
                                  return td;
                                })()
                              )}
                              {isLoading ? (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              ) : data1.fandiAppointmentModel.length === 0 ? (
                                ""
                              ) : (
                                (() => {
                                  let td = [];
                                  for (
                                    let i = 0;
                                    i < data1.fandiAppointmentModel.length;
                                    i++
                                  ) {
                                    td.push(
                                      <>
                                        <tr>
                                          <td className="flex_start_center">
                                            <div className="table_image_wrapper">
                                              <img
                                                className="brokenimg"
                                                alt="img"
                                                src={
                                                  data1.fandiAppointmentModel[i]
                                                    .dealershipCarPartFandiImage
                                                }
                                                id="belliconid"></img>
                                            </div>
                                            <div className="ml-1">
                                              <span className="text-black">
                                                {data1.fandiAppointmentModel
                                                  .length !== 0
                                                  ? data1.fandiAppointmentModel[
                                                      i
                                                    ].dealershipCarPartFandiName
                                                  : "-"}
                                              </span>
                                            </div>
                                          </td>
                                          <td></td>
                                          <td>
                                            {data1.fandiAppointmentModel
                                              .length !== 0
                                              ? "$" +
                                                data1.fandiAppointmentModel[i]
                                                  .dealershipCarPartFandiPrice
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="table__row__seperator_small"></tr>
                                      </>
                                    );
                                  }
                                  return td;
                                })()
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ))}
                </>
              )} */}
            </div>
          </div>
        </div>
      </>
    );
};

export default AllAppointments;