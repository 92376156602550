import React, {useState} from 'react';
import './Shop.css';
import TopNav from '../Snippets/top_nav_items';
import ShopNewVehicles from '../Shopcomponents/Shop_New_Vehicles';
import ShopUsedVehicles from '../Shopcomponents/Shop_Used_Vehicles';
import ShopPartsandAccessories from "../Shopcomponents/Shop_PartsandAccessories";
import ShopServices from '../Shopcomponents/Shop_services';
import ShopProtectionPlan from '../Shopcomponents/Shop_Protection_Plan';
import { useContext } from 'react';
import AppContext from '../../AppContext';
import SingleVehicleDetail from '../Shopcomponents/SingleVehiclesDetail';


      


const Shop = ({title}) => {
    const myContext = useContext(AppContext);
    
    const [shopnewvehicle, setshopnewvehicle] = useState(true);
    const [shopusedvehicle, setshopusedvehicle] = useState(false);
    const [shoppartaccesories, setshoppartaccesories] = useState(false);
    const [shopservices, setshopservices] = useState(false);
    const [shopprotectionplan, setshopprotectionplan] = useState(false);
    

    function tabClicknewvehicle(){
        setshopnewvehicle(true);
        setshopusedvehicle(false);
        setshoppartaccesories(false);
        setshopservices(false);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickusedvehicle(){
        setshopnewvehicle(false);
        setshopusedvehicle(true);
        setshoppartaccesories(false);
        setshopservices(false);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickpartaccesories() {
      setshopnewvehicle(false);
      setshopusedvehicle(false);
      setshoppartaccesories(true);
      setshopservices(false);
      setshopprotectionplan(false);
      myContext.setshopcardetailsectionisopen("");
    }
    function tabClickservices(){
        setshopnewvehicle(false);
        setshopusedvehicle(false);
        setshoppartaccesories(false);
        setshopservices(true);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickprotectionplan(){
        setshopnewvehicle(false);
        setshoppartaccesories(false);
        setshopusedvehicle(false);
        setshopservices(false);
        setshopprotectionplan(true);
        myContext.setshopcardetailsectionisopen("");
    }

    function back() {
        myContext.setshopcardetailsectionisopen("");
    }
    return (
      <>
        <div className="shop_frame_controller">
          <div className="top_iconbar">
            <span className="top_Left_column">
              {myContext.shopcardetailsectionisopen !== "" && (
                <span className="backbutton" onClick={back}>
                  <i className="fa-solid fa-chevron-left"></i>
                </span>
              )}
              {/* <h4 className="title_medium">{title}</h4> */}
              <h4
                className={`title_medium ${
                  myContext.singlecardetailsectionisopen ? "spacetext" : ""
                }${
                  myContext.singlecardetailsectionisopen &&
                  myContext.shopcardetailsectionisopen !== ""
                    ? "spacetext0"
                    : ""
                }`}>
                {title}
              </h4>
            </span>
            {myContext.singlecardetailsectionisopen === false && <TopNav />}
          </div>
          {myContext.singlecardetailsectionisopen && <SingleVehicleDetail />}
          <div
            className={`shop_section special_tabs_wrapper ${
              myContext.singlecardetailsectionisopen ? "d-none" : ""
            }`}>
            <div className="round_tabs widthauto">
              <span
                id="newVahicleTab"
                className={`innertab__wrapper_items  ${
                  shopnewvehicle ? "active" : ""
                }`}
                onClick={tabClicknewvehicle}>
                New Vehicles
              </span>
              <span
                id="usedVahicleTab"
                className={`innertab__wrapper_items  ${
                  shopusedvehicle ? "active" : ""
                }`}
                onClick={tabClickusedvehicle}>
                Used Vehicles
              </span>
              {/* // Active Soon */}
              {/* <span
                className={`innertab__wrapper_items  ${
                  shoppartaccesories ? "active" : ""
                }`}
                onClick={tabClickpartaccesories}>
                Parts & Accessories
              </span> */}
              {/* <span className={`shop__navigation_items ${shopservices ? 'active' : '' }`} onClick={tabClickservices}>Services</span>
                        <span className={`shop__navigation_items ${shopprotectionplan ? 'active' : '' }`} onClick={tabClickprotectionplan}>Protection Plans</span> */}
            </div>
            <div className="shop_content_wrapper">
              {shopnewvehicle && <ShopNewVehicles title="New Vehicles" />}
              {shopusedvehicle && <ShopUsedVehicles title="Used Vehicles" />}
              {/* // Active Soon */}
              {/* {shoppartaccesories && <ShopPartsandAccessories title="Parts & Accessories" />} */}

              {/* {shopservices && <ShopServices title='Services'/>}
                        {shopprotectionplan && <ShopProtectionPlan title='Protection Plans'/>} */}
            </div>
          </div>
        </div>
      </>
    );
};
export default Shop;