import React, { Component } from 'react'

export default class TermOfUse extends Component {
  render() {
    return (
        <div className="legal_wrapper flwidth">
            {/* <h4 className="title_large">Term Of Use</h4> */}
            <div className='iframe_wrapper'>
                <iframe src="https://dealership.ibizi.com/DealerArea/DealerAdmin/TermsAndConditions" title="Iframe Example"></iframe>
            </div>
        </div>
    )
  }
}