import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import successtick from "../../images/successtick.png";
import Slider from "react-slick";
import mileage from '../../images/speed.png';
import fuel from '../../images/fuelicon.png';
import body from '../../images/bodyicon.png';
import transmission from '../../images/gearstick.png';
import drive from '../../images/driving.png';
import engine from '../../images/engineicon.png';
import interior from '../../images/interior.png';
import Overlay from '../Snippets/overlay';
import { v4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import axios from "axios";
import $ from "jquery";


async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
    //   toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
    //   toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

class SingleVehicleDetail extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      statecarid: 0,
      error: null,
      isLoaded: false,
      items: [],
      intapistatus: false,
      intapimessage: false,
      intapihit: false,
      IsLoading: true,
      AddedtoCartApiHit: false,
      AddedtoCart: false,
      AddedtoCartMessage: "",
      api_token: localStorage.getItem("token"),
      nodatafound: false,
      modalForDealerContact: false,
      imageModal: false
    };
    this.DataRendering = this.DataRendering;
    this.interestedFunc = this.interestedFunc.bind(this);
    this.interestedApi = this.interestedApi.bind(this);
    this.favoriteMe = this.favoriteMe;
    this.unfavoriteMe = this.unfavoriteMe;
    this.DataRenderingAfterFavroite = this.DataRenderingAfterFavroite;
    // this.formatter = this.formatter;
  }

  favoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await markAsFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          IsLoading: true,
        },
        () => {
          this.DataRenderingAfterFavroite(this.state.statecarid);
        }
      );
    }
  };

  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          IsLoading: true,
        },
        () => {
          this.DataRenderingAfterFavroite(this.state.statecarid);
        }
      );
    }
  };

  // Data API Start
  DataRendering = () => {
    this.setState({
      isLoaded: false,
      statecarid: this.context.carID,
    });
    let endpoint = "Shop/GetVehicleInfo";
    let url_parameter = "?CarId=" + this.context.carID;
    const url = this.context.Base_url + endpoint + url_parameter;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: true,
              IsLoading: false,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.carDetails,
              nodatafound: false,
            });
          } else {
            this.setState({
              isLoaded: true,
              IsLoading: false,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
              nodatafound: true,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: "",
            IsLoading: false,
          });
        }
      );
  };
  // Data API End



  // Add To Cart API Function
  AddToCartApiFunction = (ReqData) => {
    let end_point = "Cart/AddItemToCart";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            
            this.setState(
              {
                AddedtoCartMessage: result.message,
                AddedtoCart: true,
              },
              () => {
                this.context.setupdatecartcount(true);
                setTimeout(() => {
                  this.setState({
                    AddedtoCart: false,
                    AddedtoCartApiHit: false,
                  });
                }, 2000);
              }
            );
          } else {
            this.setState(
              {
                AddedtoCartMessage: result.message,
                AddedtoCart: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    AddedtoCart: false,
                    AddedtoCartApiHit: false,
                  });
                }, 2000);
              }
            );
          }
        },
        (error) => {
          this.setState({
            AddedtoCart: false,
            error,
          });
        }
      );
  };
  // Add To Cart API Function

  // Add To cart
  AddToCart = (e) => {
    let item = e.currentTarget.getAttribute("itemID");
    let dealerShipID = e.currentTarget.getAttribute("dealerId");
    var priceval = e.currentTarget.getAttribute("price");
    var isSpecial = e.currentTarget.getAttribute("isSpecial");

    let CarID = this.props.carId;
    let CartItemModels = "";
    let PromoCode = "";
    let Notes = "";

    

    let special;

    if (isSpecial == "true") {
      special = true;
    } else {
      special = false;
    }

    let val;
    if (priceval == 0) {
      val = [
        {
          CartItemType: 1,
          ItemId: parseInt(item),
          isBeneift: true,
          Price: 0,
          ItemTypeAddToCondition: 2,
        },
      ];
    } else {
      val = [
        {
          CartItemType: 1,
          ItemId: parseInt(item),
          isBeneift: false,
          Price: parseInt(priceval),
          ItemTypeAddToCondition: special ? 3 : 1,
        },
      ];
    }

    let data = {
      DealerShipId: parseInt(dealerShipID),
      CarId: 0,
      CartItemModels: val,
      PromoCode: PromoCode,
      Notes: Notes,
    };
    
    this.setState(
      {
        AddedtoCartApiHit: true,
        AddItemToCart: data,
        AddedtoCart: false,
      },
      () => {
        // Add To Cart API Hit Function
        this.AddToCartApiFunction(this.state.AddItemToCart);
      }
    );
  };



  // Data API Start
  DataRenderingAfterFavroite = (carId) => {
    this.setState({
      IsLoading: true,
      statecarid: carId,
      items: [],
    });
    let endpoint = "Shop/GetVehicleInfo";
    let url_parameter = "?CarId=" + carId;
    const url = this.context.Base_url + endpoint + url_parameter;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              IsLoading: false,
              items: result.carDetails,
            });
          } else {
            this.setState({
              IsLoading: false,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            IsLoading: false,
          });
        }
      );
  };
  // Data API End

  // After Rendering
  componentDidMount() {
    // Data API
    this.DataRendering();

    // Create our number formatter.
    this.formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  }

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
  }

  interestedFunc = (e) => {
    var cash = this.state.items.cash;
    var interestedStatus = this.state.items.interested;
    
    if (interestedStatus) {
      if (cash == "0") {
        this.setState({
          modalForDealerContact: true,
        });
      }
    } else {
      if (cash == "0") {
        // this.setState({
        //   modalForDealerContact: true,
        // });
        this.interestedApi(this.context.carID);
      } else {
        this.interestedApi(this.context.carID);
      }
    }
    // 
  };

  interestedApi = (carid) => {
    this.setState({ intapihit: true });
    var caridval = {
      carId: carid,
    };
    let end_point = "Shop/ContactSeller";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(caridval),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                intapistatus: result.status,
                intapimessage: result.message,
              },
              () => {
                // debugger;
                // setTimeout(() => {
                //   this.setState({ intapihit: false });
                // }, 3000);
              }
            );
          } else {
            this.setState({
              intapistatus: result.status,
              intapimessage: result.message,
            });
          }
        },
        (error) => {
          this.setState({
            intapimessage: false,
            error,
          });
        }
      );
  };

  render() {
    const settings = {
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      lazyLoad: true,
      infinite: false,
      dot: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="Single_vehicles_info_wapper">
        <div
          className="closedetailsection"
          onClick={() => {
            this.context.setsinglecardetailsectionisopen(false);
            this.context.setpartsandAccessoryisopen(false);
            this.context.setcarID();
          }}>
          {/* <i className="fa-solid fa-xmark"></i> */}
          <i className="fa-solid fa-chevron-left"></i>
        </div>
        {this.context.partsandAccessoryisopen ? (
          // Part and Accessories Start

          <div className="Single_vehicles_wapper">
            {this.state.isLoaded === false ? (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            ) : (
              <>
                <div
                  className={`Single_vehicles_carimages_wrapper ${
                    this.context.partsandAccessoryModel.ImageList.length === 1
                      ? "singleimg"
                      : ""
                  }`}>
                  {(() => {
                    let item = [];
                    for (
                      let i = 0;
                      i < this.context.partsandAccessoryModel.ImageList.length;
                      i++
                    ) {
                      item.push(
                        <>
                          <div
                            className="Single_vehicles_carimages_images_frame"
                            key={
                              this.context.partsandAccessoryModel.ImageList[i] +
                              v4
                            }>
                            <img
                              className="brokenimg"
                              alt="img"
                              src={
                                this.context.partsandAccessoryModel.ImageList[i]
                              }
                              id="belliconid"></img>
                          </div>
                        </>
                      );
                    }
                    return item;
                  })()}
                </div>
                {
                  <div className="Single_vehicles_content_wrapper">
                    <div className="sticky">
                      <h4 className="title">
                        {this.context.partsandAccessoryModel.title} (
                        {this.context.partsandAccessoryModel.dealershipmake})
                      </h4>
                      <div className="subtitle">
                        Dealership:{" "}
                        {this.context.partsandAccessoryModel.dealershipname}
                      </div>
                    </div>
                    <div className="flex single_vehicle_lr_wrapper">
                      <div className="single_vehicle_left">
                        <h4 className="title">Overview</h4>
                        <div className="subtitle">
                          {this.context.partsandAccessoryModel.description}
                        </div>
                        <div className="overview_box">
                          <div className="overview_box_item">
                            <strong>Payment</strong>
                            <strong>
                              {this.formatter.format(
                                this.context.partsandAccessoryModel.Orignalprice
                              )}{" "}
                              |{" "}
                              {this.formatter.format(
                                this.context.partsandAccessoryModel.points
                              )}{" "}
                              Points
                            </strong>
                          </div>
                        </div>
                        <div className="flex mt-2 overview_box bg_none p-0">
                          {this.context.partsandAccessoryModel.isaddtocart ==
                          "true" ? (
                            <div className="btn_theme fullwidth m-0">
                              Already In Cart
                            </div>
                          ) : (
                            <div
                              className="btn_theme fullwidth m-0"
                              onClick={this.AddToCart}
                              itemID={
                                this.context.partsandAccessoryModel.itemid
                              }
                              enum={1}
                              dealerId={
                                this.context.partsandAccessoryModel.dealershipid
                              }
                              price={
                                this.context.partsandAccessoryModel.isSpecial
                                  ? this.context.partsandAccessoryModel
                                      .SpecialPrice
                                  : this.context.partsandAccessoryModel
                                      .Orignalprice
                              }
                              isSpecial={
                                this.context.partsandAccessoryModel.isSpecial
                                  ? "true"
                                  : "false"
                              }>
                              Add To Cart
                            </div>
                          )}
                        </div>
                        {
                          <div className="flex mt-1 overview_box bg_none p-0">
                            {this.context.partsandAccessoryModel.isfavorite ? (
                              <div
                                className="btn_theme pausehover light fullwidth"
                                onClick={() => {
                                  let entitynumber;
                                  if (this.state.items.new_Used) {
                                    entitynumber = 1;
                                  } else {
                                    entitynumber = 2;
                                  }
                                  this.unfavoriteMe({
                                    Entity: entitynumber,
                                    tableId: this.context.carID,
                                    CarId: 0,
                                  });
                                  this.context.setcarID(this.context.carID);
                                  //   this.context.setsinglecardetailsectionisopen(
                                  //     false
                                  //   );
                                }}>
                                {this.state.IsLoading === false ? (
                                  <>
                                    <img alt="img" src={favIcon}></img>
                                    Remove From Favorites
                                  </>
                                ) : (
                                  <div className="loader-div">
                                    <img
                                      alt="img"
                                      src={loader}
                                      id="belliconid"></img>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                className="btn_theme pausehover light fullwidth"
                                onClick={() => {
                                  let entitynumber;
                                  if (this.state.items.new_Used) {
                                    entitynumber = 1;
                                  } else {
                                    entitynumber = 2;
                                  }
                                  this.favoriteMe({
                                    Entity: entitynumber,
                                    tableId: this.context.carID,
                                    CarId: 0,
                                  });
                                  this.context.setcarID(this.context.carID);
                                }}>
                                {this.state.IsLoading === false ? (
                                  <>
                                    <img alt="img" src={UnfavIcon}></img>
                                    Add to Favorites
                                  </>
                                ) : (
                                  <div className="loader-div">
                                    <img
                                      alt="img"
                                      src={loader}
                                      id="belliconid"></img>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </div>

                    {/* <div className='flex mt-2 overview_box bg_none p-0'>
                            <div className='btn_theme fullwidth m-0' onClick={this.interestedFunc}>Interested</div>
                        </div> */}
                  </div>
                }
              </>
            )}
          </div>
        ) : // Part and Accessories End
        this.state.IsLoading === false ? (
          this.state.nodatafound ? (
            <div className="emptywndow emptywndowfav">
              <i className="fa-solid fa-car"></i>
              <h3 className="mb-0">No Vehicle Detail Found</h3>
            </div>
          ) : (
            this.state.items != undefined && (
              <div className="Single_vehicles_wapper">
                {this.state.isLoaded === false ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : (
                  <>
                    {this.state.items != undefined ? (
                      <div
                        className={`Single_vehicles_carimages_wrapper ${
                          this.state.items.image.length === 1 ? "singleimg" : ""
                        }`}>
                        {this.state.isLoaded &&
                          (() => {
                            let item = [];
                            for (
                              let i = 0;
                              i < this.state.items.image.length;
                              i++
                            ) {
                              item.push(
                                <>
                                  {this.state.isLoaded === false ? (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  ) : (
                                    <div
                                      className="Single_vehicles_carimages_images_frame"
                                      key={this.state.items.image[i] + v4}
                                      onClick={() => {
                                        this.setState({
                                          imageModal: true,
                                        });
                                      }}>
                                      <img
                                        className="brokenimg"
                                        alt="img"
                                        src={this.state.items.image[i]}
                                        id="belliconid"></img>
                                    </div>
                                  )}
                                </>
                              );
                            }
                            return item;
                          })()}
                      </div>
                    ) : (
                      <div className="loader-div">
                        <img alt="img" src={loader} id="belliconid"></img>
                      </div>
                    )}
                    {
                      <div className="Single_vehicles_content_wrapper">
                        <div className="sticky">
                          <h4 className="title">
                            {this.state.items.make} {this.state.items.model} |{" "}
                            {this.state.items.year}
                          </h4>
                          <div className="subtitle">
                            Dealership: {this.state.items.dealerShipName}
                          </div>
                        </div>
                        <div className="flex single_vehicle_lr_wrapper">
                          <div className="single_vehicle_left">
                            <h4 className="title">Overview</h4>
                            <div className="overview_wrapper">
                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={mileage}
                                    id="belliconid"></img>
                                </div>
                                <span>Mileage: </span>
                                <strong>
                                  {!this.state.items.mileage
                                    ? "-"
                                    : this.state.items.mileage + "ml"}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={fuel}
                                    id="belliconid"></img>
                                </div>
                                <span>Fuel: </span>
                                <strong>
                                  {!this.state.items.fuelType
                                    ? "-"
                                    : this.state.items.fuelType}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={body}
                                    id="belliconid"></img>
                                </div>
                                <span>Body: </span>
                                <strong>
                                  {!this.state.items.body
                                    ? "-"
                                    : this.state.items.body}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={transmission}
                                    id="belliconid"></img>
                                </div>
                                <span>Transmission: </span>
                                <strong>
                                  {!this.state.items.transmission
                                    ? "-"
                                    : this.state.items.transmission}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={drive}
                                    id="belliconid"></img>
                                </div>
                                <span>Drive: </span>
                                <strong>
                                  {!this.state.items.driveTrain
                                    ? "-"
                                    : this.state.items.driveTrain}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={engine}
                                    id="belliconid"></img>
                                </div>
                                <span>Engine: </span>
                                <strong>
                                  {!this.state.items.engine
                                    ? "-"
                                    : this.state.items.engine}
                                </strong>
                              </div>

                              <div className="overview_item">
                                <div className="overview_wrapper_images_frame">
                                  <img
                                    alt="img"
                                    src={interior}
                                    id="belliconid"></img>
                                </div>
                                <span>Interior Color: </span>
                                <strong>
                                  {!this.state.items.interiorColor
                                    ? "-"
                                    : this.state.items.interiorColor}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div className="single_vehicle_right">
                            <h4 className="title">Payment</h4>
                            {/* <div className="subtitle">
                              Select the buying or leasing option that you are
                              interested in.
                            </div> */}
                            <div className="overview_box">
                              <div className="overview_box_item">
                                <strong>Pay Cash</strong>
                                <strong>
                                  {this.formatter.format(this.state.items.cash)}
                                </strong>
                              </div>
                            </div>
                            {this.state.items.isLeaseed && (
                              <div className="overview_box">
                                <div className="overview_box_item">
                                  <strong>Lease</strong>
                                  <strong>
                                    {this.formatter.format(
                                      this.state.items.carLeaseModel
                                        .leaseMonthlyPrice
                                    )}{" "}
                                    / Month
                                  </strong>
                                </div>
                                <div className="overview_box_item">
                                  <span>Term</span>
                                  <span>
                                    {this.state.items.carLeaseModel.leaseTerm}{" "}
                                    months
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Due At Signing</span>
                                  <span>
                                    {
                                      this.state.items.carLeaseModel
                                        .leaseDueAtSigning
                                    }
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Offer Expire</span>
                                  <span>
                                    {
                                      this.state.items.carLeaseModel
                                        .leaseOfferExpire
                                    }
                                  </span>
                                </div>
                              </div>
                            )}
                            {this.state.items.isFinanced && (
                              <div className="overview_box">
                                <div className="overview_box_item">
                                  <strong>Finance</strong>
                                  <strong>
                                    {this.formatter.format(
                                      this.state.items.carFinanceModel
                                        .financeMonthlyPrice
                                    )}{" "}
                                    / Month
                                  </strong>
                                </div>
                                <div className="overview_box_item">
                                  <span>Total Purchase Price</span>
                                  <span>
                                    {this.formatter.format(
                                      this.state.items.carFinanceModel
                                        .financeTotalPurchasePrice
                                    )}
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Down Payment</span>
                                  <span>
                                    {this.formatter.format(
                                      this.state.items.carFinanceModel
                                        .downPayment
                                    )}
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Total Amount</span>
                                  <span>
                                    {this.formatter.format(
                                      this.state.items.carFinanceModel
                                        .financeTotalAmount
                                    )}
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Interest Rate</span>
                                  <span>
                                    {
                                      this.state.items.carFinanceModel
                                        .financeInterestRate
                                    }
                                    % APR
                                  </span>
                                </div>
                                <div className="overview_box_item">
                                  <span>Term</span>
                                  <span>
                                    {
                                      this.state.items.carFinanceModel
                                        .financeTerm
                                    }{" "}
                                    months
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="flex mt-2 overview_box bg_none p-0">
                              <div
                                className="btn_theme fullwidth m-0"
                                onClick={this.interestedFunc}>
                                {this.state.items.interested
                                  ? "Already Interested"
                                  : "Interested"}
                              </div>
                            </div>
                            {
                              <div className="flex mt-1 overview_box bg_none p-0">
                                {this.state.items.favorite ? (
                                  <div
                                    className="btn_theme pausehover light fullwidth"
                                    onClick={() => {
                                      // toast("Remove From Favorite");
                                      // this.context.setcarID(this.context.carID);
                                      // if (this.state.items.new_Used) {
                                      //     this.context.setentityenum(1);
                                      // } else {
                                      //     this.context.setentityenum(2);
                                      // }
                                      // this.context.setcallfavAPI('unfavorite');
                                      // // this.DataRendering();
                                      // this.setState({
                                      //     IsLoading: true
                                      // }, () => {
                                      //     this.DataRenderingAfterFavroite(this.state.statecarid);
                                      // })
                                      let entitynumber;
                                      if (this.state.items.new_Used) {
                                        entitynumber = 1;
                                      } else {
                                        entitynumber = 2;
                                      }
                                      this.unfavoriteMe({
                                        Entity: entitynumber,
                                        tableId: this.context.carID,
                                        CarId: 0,
                                      });
                                      this.context.setcarID(this.context.carID);
                                      //   this.context.setsinglecardetailsectionisopen(
                                      //     false
                                      //   );
                                    }}>
                                    {this.state.IsLoading === false ? (
                                      <>
                                        <img alt="img" src={favIcon}></img>
                                        Remove From Favorites
                                      </>
                                    ) : (
                                      <div className="loader-div">
                                        <img
                                          alt="img"
                                          src={loader}
                                          id="belliconid"></img>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="btn_theme pausehover light fullwidth"
                                    onClick={() => {
                                      // toast("Added To Favorite");
                                      // this.context.setcarID(this.context.carID);
                                      // if (this.state.items.new_Used) {
                                      //     this.context.setentityenum(1);
                                      // } else {
                                      //     this.context.setentityenum(2);
                                      // }
                                      // this.context.setcallfavAPI('favorite');
                                      // this.setState({
                                      //     IsLoading: true
                                      // }, () => {
                                      //     this.DataRenderingAfterFavroite(this.state.statecarid);
                                      // })
                                      let entitynumber;
                                      if (this.state.items.new_Used) {
                                        entitynumber = 1;
                                      } else {
                                        entitynumber = 2;
                                      }
                                      this.favoriteMe({
                                        Entity: entitynumber,
                                        tableId: this.context.carID,
                                        CarId: 0,
                                      });
                                      this.context.setcarID(this.context.carID);
                                    }}>
                                    {this.state.IsLoading === false ? (
                                      <>
                                        <img alt="img" src={UnfavIcon}></img>
                                        Add to Favorites
                                      </>
                                    ) : (
                                      <div className="loader-div">
                                        <img
                                          alt="img"
                                          src={loader}
                                          id="belliconid"></img>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                        {/* <h4 className='title'>Overview</h4>
                                <div className='overview_wrapper'>
                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={mileage} id='belliconid'></img>
                                        </div>
                                        <span>Mileage: </span>
                                        <strong>{this.state.items.mileage} ml</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={fuel} id='belliconid'></img>
                                        </div>
                                        <span>Fuel: </span>
                                        <strong>{this.state.items.fuelType}</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={body} id='belliconid'></img>
                                        </div>
                                        <span>Body: </span>
                                        <strong>{this.state.items.body}</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={transmission} id='belliconid'></img>
                                        </div>
                                        <span>Transmission: </span>
                                        <strong>{this.state.items.transmission}</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={drive} id='belliconid'></img>
                                        </div>
                                        <span>Drive: </span>
                                        <strong>{this.state.items.driveTrain}</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={engine} id='belliconid'></img>
                                        </div>
                                        <span>Engine: </span>
                                        <strong>{this.state.items.engine}</strong>
                                    </div>

                                    <div className='overview_item'>
                                        <div className='overview_wrapper_images_frame'>
                                            <img alt='img' src={interior} id='belliconid'></img>
                                        </div>
                                        <span>Interior Color: </span>
                                        <strong>{this.state.items.interiorColor}</strong>
                                    </div>

                                </div> */}
                        {/* <h4 className='title'>Payment</h4>
                                <div className='subtitle'>Select the buying or leasing option that you are interested in.</div>
                                <div className='overview_box'>
                                    <div className='overview_box_item'>
                                        <strong>Pay Cash</strong>
                                        <strong>{this.formatter.format(this.state.items.cash)}</strong>
                                    </div>
                                </div>
                                {
                                this.state.items.isLeaseed &&
                                <div className='overview_box'>
                                    <div className='overview_box_item'>
                                        <strong>Lease</strong>
                                        <strong>{this.formatter.format(this.state.items.carLeaseModel.leaseMonthlyPrice)} / Month</strong>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Term</span>
                                        <span>{this.state.items.carLeaseModel.leaseTerm} months</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Due At Signing</span>
                                        <span>{this.state.items.carLeaseModel.leaseDueAtSigning}</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Offer Expire</span>
                                        <span>{this.state.items.carLeaseModel.leaseOfferExpire}</span>
                                    </div>
                                </div>
                                }
                                {
                                this.state.items.isFinanced &&
                                <div className='overview_box'>
                                    <div className='overview_box_item'>
                                        <strong>Finance</strong>
                                        <strong>{this.formatter.format(this.state.items.carFinanceModel.financeMonthlyPrice)} / Month</strong>
                                        
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Total Purchase Price</span>
                                        <span>{this.formatter.format(this.state.items.carFinanceModel.financeTotalPurchasePrice)}</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Down Payment</span>
                                        <span>{this.formatter.format(this.state.items.carFinanceModel.downPayment)}</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Total Amount</span>
                                        <span>{this.formatter.format(this.state.items.carFinanceModel.financeTotalAmount)}</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Interest Rate</span>
                                        <span>{this.state.items.carFinanceModel.financeInterestRate}% APR</span>
                                    </div>
                                    <div className='overview_box_item'>
                                        <span>Term</span>
                                        <span>{this.state.items.carFinanceModel.financeTerm} months</span>
                                    </div>
                                    
                                </div>
                                } */}
                        {/* <div className='flex mt-2 overview_box bg_none p-0'>
                                    <div className='btn_theme fullwidth m-0' onClick={this.interestedFunc}>Interested</div>
                                </div> */}
                      </div>
                    }
                  </>
                )}
              </div>
            )
          )
        ) : (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        )}
        {this.state.intapihit ? (
          this.state.intapistatus === false ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : (
            <div className="loader-div">
              <div className="car_success_wrapper">
                <div className="car_inner_success">
                  <div className="logo_Text">
                    <div className="image-frame square">
                      <img src={successtick}></img>
                    </div>
                  </div>
                  <div className="details">
                    <h2>
                      {localStorage.getItem("usermodelfirstname")}{" "}
                      {localStorage.getItem("usermodellastname")}
                    </h2>
                  </div>
                  <div className="details">
                    <p>
                      Your information has been shared with the dealership.{" "}
                      <br></br>They will contact you soon.
                    </p>
                    <br></br>
                    <h4>Contact Dealership</h4>
                    <p className="phoneicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none">
                        <path
                          d="M43.7496 32.955V39.33C43.752 39.9218 43.6307 40.5076 43.3936 41.0499C43.1565 41.5921 42.8088 42.0789 42.3727 42.479C41.9366 42.8791 41.4218 43.1837 40.8611 43.3733C40.3005 43.5629 39.7065 43.6333 39.1171 43.58C32.5781 42.8695 26.2969 40.6351 20.7783 37.0563C15.6439 33.7937 11.2909 29.4406 8.0283 24.3063C4.43701 18.7626 2.20207 12.4509 1.50455 5.8825C1.45145 5.29487 1.52129 4.70262 1.70962 4.14345C1.89795 3.58429 2.20064 3.07046 2.59843 2.63469C2.99622 2.19893 3.48039 1.85076 4.02011 1.61236C4.55983 1.37396 5.14328 1.25056 5.7333 1.25H12.1083C13.1396 1.23985 14.1394 1.60504 14.9213 2.27751C15.7032 2.94997 16.214 3.88382 16.3583 4.905C16.6274 6.94514 17.1264 8.94829 17.8458 10.8763C18.1317 11.6368 18.1936 12.4634 18.0241 13.2581C17.8546 14.0528 17.4609 14.7822 16.8896 15.36L14.1908 18.0587C17.2159 23.3788 21.6208 27.7837 26.9408 30.8088L29.6396 28.11C30.2173 27.5387 30.9468 27.1449 31.7414 26.9754C32.5361 26.806 33.3627 26.8678 34.1233 27.1537C36.0513 27.8732 38.0544 28.3722 40.0946 28.6413C41.1268 28.7869 42.0695 29.3068 42.7434 30.1022C43.4173 30.8975 43.7754 31.9128 43.7496 32.955Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      {this.state.items.dealerShipNumber} <br></br>
                    </p>
                    <br></br>
                    <button
                      className="btn_theme fullwidth w-100"
                      onClick={async () => {
                        this.setState({ intapihit: false });
                        this.DataRendering();
                        this.context.setsinglecardetailsectionisopen(false);
                        this.context.setpartsandAccessoryisopen(false);
                        this.context.setcarID();
                      }}>
                      Continue Browsing
                    </button>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
        {this.state.AddedtoCartApiHit ? (
          this.state.AddedtoCart ? (
            <div className="loader-div">
              <Overlay
                title="partaccessories"
                message={this.state.AddedtoCartMessage}
              />
            </div>
          ) : (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          )
        ) : (
          ""
        )}
        {this.state.modalForDealerContact && (
          <div className="loader-div">
            <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
              <h2 className="text-center">Details</h2>
              <div className="flex space-between">
                <h3 className="text-center">Contact Dealership</h3>
                <h3 className="text-center flex phoneicon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none">
                    <path
                      d="M43.7496 32.955V39.33C43.752 39.9218 43.6307 40.5076 43.3936 41.0499C43.1565 41.5921 42.8088 42.0789 42.3727 42.479C41.9366 42.8791 41.4218 43.1837 40.8611 43.3733C40.3005 43.5629 39.7065 43.6333 39.1171 43.58C32.5781 42.8695 26.2969 40.6351 20.7783 37.0563C15.6439 33.7937 11.2909 29.4406 8.0283 24.3063C4.43701 18.7626 2.20207 12.4509 1.50455 5.8825C1.45145 5.29487 1.52129 4.70262 1.70962 4.14345C1.89795 3.58429 2.20064 3.07046 2.59843 2.63469C2.99622 2.19893 3.48039 1.85076 4.02011 1.61236C4.55983 1.37396 5.14328 1.25056 5.7333 1.25H12.1083C13.1396 1.23985 14.1394 1.60504 14.9213 2.27751C15.7032 2.94997 16.214 3.88382 16.3583 4.905C16.6274 6.94514 17.1264 8.94829 17.8458 10.8763C18.1317 11.6368 18.1936 12.4634 18.0241 13.2581C17.8546 14.0528 17.4609 14.7822 16.8896 15.36L14.1908 18.0587C17.2159 23.3788 21.6208 27.7837 26.9408 30.8088L29.6396 28.11C30.2173 27.5387 30.9468 27.1449 31.7414 26.9754C32.5361 26.806 33.3627 26.8678 34.1233 27.1537C36.0513 27.8732 38.0544 28.3722 40.0946 28.6413C41.1268 28.7869 42.0695 29.3068 42.7434 30.1022C43.4173 30.8975 43.7754 31.9128 43.7496 32.955Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {this.state.items.dealerShipNumber}
                </h3>
              </div>
              <div className="flex justify-center mt-2">
                <div
                  className="btn_theme mr-1"
                  onClick={() => {
                    this.setState({
                      modalForDealerContact: false,
                    });
                  }}>
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.imageModal && (
          <div className="loader-div">
            <div className="carinfo_cards_box_right_panel modalcenter sliderModal garagemodal active">
              <div
                className="closedetailsection"
                onClick={() => {
                  this.setState({
                    imageModal: false,
                  });
                }}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div className="imageCarousel">
                <Slider {...settings}>
                  {(() => {
                    let item = [];
                    for (let i = 0; i < this.state.items.image.length; i++) {
                      item.push(
                        <>
                          <div
                            className="imageFrame"
                            key={this.state.items.image[i] + v4}
                            onClick={() => {
                              this.setState({
                                imageModal: true,
                              });
                            }}>
                            <img
                              className="brokenimg"
                              alt="img"
                              src={this.state.items.image[i]}
                              id="belliconid"></img>
                          </div>
                        </>
                      );
                    }
                    return item;
                  })()}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default SingleVehicleDetail ;
