import React, { useState, Component } from "react";
import loader from "../../images/loader.gif";
import "./Garage.css";
import AppContext from "../../AppContext";
import CarInfo from "../Detailcomponents/Garagedetailcomponent/Carinfo";
import Benefits from "../Detailcomponents/Garagedetailcomponent/Benefit";
import Appointments from "../Detailcomponents/Garagedetailcomponent/Appointments";
import ShopProtectionPlan from "../Shopcomponents/Shop_Protection_Plan";
import ShopServices from "../Shopcomponents/Shop_services";
import $ from "jquery";
import Declined from "./Declined";
import Slider from "react-slick/lib/slider";
import TopNav from "../Snippets/top_nav_items";
import { v4 } from "uuid";

// const Specials = (props) => {
const settings = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class Connects extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      slideropen: false,
      items: [],
      itemsImages: [],
      itemsServiceArr: [],
      itemsFnIArr: [],
      carArry: [],
      apistatus: false,
      isModallOpen: false,
      apimessage: "",
      defaultcarid: 0,
      api_token: localStorage.getItem("token"),
      detailobject: {},
      agentlist: [],
      currentItemIndex: 0
    };
    this.onloadAPI = this.onloadAPI;
    this.getallusercars = this.getallusercars;
    this.showMap = this.showMap;
    this.tabClick = this.tabClick;
    this.openDialog = this.openDialog;
    this.getAgent = this.getAgent;
  }

  onloadAPI = (e) => {
    let end_point = "Shop/ConnectDealershipAgent";
    let api_url = this.context.Base_url + end_point;
    // Main Appointment API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
    .then((res) => res.json())
    .then((result) => {
      
                
          if (result.status) {
            this.setState(
              {
                apistatus: result.status,
                // apiStatusMessage: result.message,
                items: result.connectAgentModel,
              },
              () => {
                this.setState({
                  slideropen: true,
                  isLoaded: true,
                  itemsImages: this.state.items.dealershipImage,
                  // itemsServiceArr: this.state.items.specialServiceModels,
                  // itemsFnIArr: this.state.items.specialFandIModels,
                });
          // debugger;

              }
            );
          } else {
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              items: [],
              itemsImages: [],
              slideropen: true,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main Appointment API End
  };




  getallusercars = (e) => {
    let end_point = "Garage/UserCars";  
    let api_url = this.context.Base_url + end_point;
    // Main Appointment API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {

          if (result.status) {
            this.setState(
              {
                apistatus: result.status,
                apiStatusMessage: result.message,
                carArry: result.carInfoDetailModels
              },
              () => {
                this.setState(
                  {
                    slideropen: true,
                    isLoaded: false,
                    defaultcarid: this.state.carArry[0].id
                  }
                );
                this.onloadAPI();
                if(this.state.defaultcarid === this.state.carArry[0].id) {
                    $('.special_tabs_wrapper .innertab__wrapper_items:nth-child(1)').addClass('active')
                }
              }
            );
          } else {
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              items: [],
              itemsImages: [],
              carArry: [],
              slideropen: true,
            });
            if (result.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            } 
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main Appointment API End
  };

  // After Rendering
  componentDidMount() {
    this.onloadAPI();
    // this.getallusercars();
  }


  tabClick = (e) => {
    const tabId = e.currentTarget.getAttribute("carid");
    $(e.currentTarget).siblings().removeClass('active');   
    $(e.currentTarget).addClass('active');

    this.setState({
        defaultcarid: tabId,
        isLoaded: false
      }, () => {
        this.onloadAPI();
      });
  }



  openDialog = (e) => {
    const dealerimage = e.currentTarget.getAttribute("dealerimg");
    const dealerindex = e.currentTarget.getAttribute('dealerindex');
    

    debugger
    this.setState( {
        isModallOpen: true,
        currentItemIndex: dealerindex,
        detailobject: {
          'img' : dealerimage
        }
    })
    $('.connects_content_wrapper').addClass('blur');
    $('.top_iconbar').addClass('blur');






  }

  render() {
    return (
      <div className="special_section">
        <div className="top_iconbar">
          <span className="top_Left_column">
            <h4 className="title_medium" id="top__title">
              {this.props.title}
            </h4>
          </span>
          <TopNav />
        </div>
        {this.state.userCars === 0 ? (
          <div className="special_content_wrapper center">
            <h2>No Connects Available</h2>
          </div>
        ) : (
          <>
            <div className="connects_content_wrapper">
              {this.state.isLoaded ? (
                <>
                  {this.state.items.map((data, index) => {
                    return (
                      <div
                        className="connect_box_wrappr"
                        onClick={this.openDialog}
                        dealerimg={data.dealershipImage}
                        dealerindex={index}
                        key={v4}>
                        <div className="image_wrapper">
                          <img
                            className="brokenimg"
                            alt="Failed to load"
                            src={data.dealershipImage}
                          />
                        </div>
                        <h4>{data.dealershipName}</h4>
                        {/* <span>{data.dealershipName}</span> */}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              )}
            </div>
          </>
        )}
        {this.state.isModallOpen && (
          <div className="carinfo_cards_box_right_panel modalcenter connect_modal active">
            <div
              className="closedetailsection"
              onClick={() => {
                this.setState({
                  isModallOpen: false,
                });
                $(".connects_content_wrapper").removeClass("blur");
                $(".top_iconbar").removeClass("blur");
              }}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            {
              <>
                <div className="right-panel_image_wrapper">
                  <img
                    className="brokenimg"
                    alt="img"
                    src={this.state.detailobject.img}
                    id="belliconid"></img>
                </div>
                <div className="connectmodalscroll">
                  {console.log(this.state.items)}
                  {this.state.items[0].agents.length != 0 ? (
                    <>
                      <div className="right-panel_title pad dir-col mt-1">
                        <h2>
                          <strong>Contact Advisor</strong>
                        </h2>
                      </div>
                      {(() => {
                        let ele = [];
                        for (
                          let i = 0;
                          i < this.state.items[0].agents.length;
                          i++
                        ) {
                          ele.push(
                            <>
                              <div className="right-panel_title detail center">
                                <div className="agntimgwrapper">
                                  <img
                                    className="brokenimg"
                                    alt="img"
                                    src={
                                      this.state.items[
                                        this.state.currentItemIndex
                                      ].agents[i].agentImage
                                    }
                                    id="belliconid"></img>
                                </div>
                                <div className="agntimgwrapperdetail">
                                  <div className="dir-col">
                                    <h4>
                                      {
                                        this.state.items[
                                          this.state.currentItemIndex
                                        ].agents[i].agentName
                                      }{" "}
                                      <strong>
                                        {
                                          this.state.items[
                                            this.state.currentItemIndex
                                          ].agents[i].agentType
                                        }
                                      </strong>
                                    </h4>
                                  </div>
                                  <div className="dir-col">
                                    <h3>
                                      {
                                        this.state.items[
                                          this.state.currentItemIndex
                                        ].agents[i].agentMobileNumber
                                      }
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                        return ele;
                      })()}
                    </>
                  ) : (
                    <>
                      <div className="right-panel_title center mt-1">
                        <h2>
                          <strong>No Advisor Found</strong>
                        </h2>
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          </div>
        )}
      </div>
    );
  }
}
export default Connects;
