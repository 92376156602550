import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Overlay from '../Snippets/overlay';
import dictag from '../../images/discounttag.png';
import favIcon from "../../images/heartico_colored.png";
import UnfavIcon from "../../images/hearticonborder.png";
import $ from 'jquery'
import { v4 } from 'uuid';
import axios from "axios";


async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  console.log(itemId);
  console.log(contextData);
  console.log(api_url);
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

class ShopServices extends Component {
    static contextType = AppContext

    constructor(props, context) {
        super(props);
        this.state = {
            error: null, 
            carrid: props.carId,
            isLoaded: false,
            isLoadedcard: false,
            isLoadedcardDetail: false,
            additemapiisloaded: false,
            additemapistatus: '',
            additemapimessage: false,
            items: [],
            AddItemToCart: {},
            emptycartapistatus: false,
            DropDownIdAfterSelect: context.garagefirstcarid,
            isRighpanelOpen: false,
            boxID: 0,
            api_token: localStorage.getItem('token'),
            checkedItems: new Map(),
            radioButtonLoader: false,
            dealershiID: 0
        };
        this.HandleChangeCarDropDown = this.HandleChangeCarDropDown;
        this.DataRendering = this.DataRendering;
        this.DropDownAPI = this.DropDownAPI;
        this.favoriteMe = this.favoriteMe;
        this.unfavoriteMe = this.unfavoriteMe;
        this.boxClick = this.boxClick;
        this.DetailDataDRendering = this.DetailDataDRendering;
        this.handleChangeCheckboxes = this.handleChangeCheckboxes.bind(this); 
        this.AddToCartApiFunction = this.AddToCartApiFunction;
        this.AddToCart = this.AddToCart;
        this.EmptyCart = this.EmptyCart
    }

    
    favoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: this.context.Base_url,
        token: this.state.api_token,
      };
      const response = await markAsFavorite(itemId, contextData);
      if (!response) {
        this.setState(
          {
            isLoadedcardDetail: false,
            radioButtonLoader: false,
            isRighpanelOpen: false,
          },
          () => {
            this.setState(
              {
                isRighpanelOpen: true,
              },
              () => {
                setTimeout(() => {
                  this.DetailDataDRendering(this.props.carId, this.state.boxID);
                }, 100);
              }
            );
          }
        );
      }
    };

    unfavoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: this.context.Base_url,
        token: this.state.api_token,
      };
      const response = await removeFromFavorite(itemId, contextData);
      if (!response) {
        this.setState(
          {
            isLoadedcardDetail: false,
            radioButtonLoader: false,
            isRighpanelOpen: false,
          },
          () => {
            this.setState(
              {
                isRighpanelOpen: true,
              },
              () => {
                setTimeout(() => {
                  this.DetailDataDRendering(this.props.carId, this.state.boxID);
                }, 100);
              }
            );
          }
        );
      }
    };
  
    // Car DropDown API Start
    DropDownAPI = () => {
        let end_point = 'Garage/UserCars';
        let api_url = this.context.Base_url+end_point

        
        fetch(api_url,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: result.carInfoDetailModels,
                    });
                } else {
                    this.setState({
                        isLoaded: true,
                        apiStatus: result.status,
                        apiStatusMessage: result.message,
                        items: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
            
        )
    }
    // Car DropDown API End

    // Data API Start
    DataRendering = (carId) => {
        let end_point_Shop_Services = 'Shop/DealerShipFandIAndDetail';
        let url_parameter = '?'+'IsDetail'+'='+false+'&'+'CarId'+'='+this.state.carrid+'&'+'CarParttId'+'='+0+'&'+'ApplySearch'+'='+false+'&'+'SearchString'+'='+'Something';
        const url = this.context.Base_url+end_point_Shop_Services+url_parameter;

        
        fetch(url,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoadedcard: true,
                        apiStatuscard: result.status,
                        apiStatusMessagecard: result.message,
                        carditems: result.carParts,
                    });
                } else {
                    this.setState({
                        isLoadedcard: true,
                        apiStatuscard: result.status,
                        apiStatusMessagecard: result.message,
                        carditems: []
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoadedcard: false,
                    error
                });
            }
            
        )
        
    }
    // Data API End

    // Details Data API Start
    DetailDataDRendering = (carId, ServiceFnICarParttId) => {
        this.setState({
            radioButtonLoader: false
        });
        let end_point_Shop_fni = 'Shop/DealerShipFandIAndDetail';
        let url_parameter = '?'+'IsDetail'+'='+true+'&'+'CarId'+'='+carId+'&'+'CarParttId'+'='+ServiceFnICarParttId+'&'+'ApplySearch'+'='+false+'&'+'SearchString'+'='+'Something';
        const url = this.context.Base_url+end_point_Shop_fni+url_parameter;
        fetch(url,{
            method: 'GET', 
            headers: {
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status) {
                    this.setState({
                        isLoadedcardDetail: true,
                        apiStatuscardDetail: result.status,
                        apiStatusMessagecardDetail: result.message,
                        carditemsDetail: result.shopFandIProductDetail,
                        radioButtonLoader: true,
                        dealershiID : result.shopFandIProductDetail.dealerShipId
                    });
                    
                } else {
                    this.setState({
                        isLoadedcardDetail: true,
                        apiStatuscardDetail: result.status,
                        apiStatusMessagecardDetail: result.message,
                        carditemsDetail: [],
                        radioButtonLoader: true,
                        dealershiID : 0
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoadedcardDetail: false,
                    error
                });
            }
            
        )
        
    }
    // Details Data API End

    // After Rendering
    componentDidMount() { 

        // DropDown API
        this.DropDownAPI()

        // Data API
        this.DataRendering(this.state.DropDownIdAfterSelect)

    }

    boxClick = e => {
        const boxID = e.currentTarget.getAttribute("box-id");
        this.setState({
            isRighpanelOpen: true,
            isLoadedcardDetail: false,
            boxID: boxID
        })
        // Details Data API
        this.DetailDataDRendering(this.props.carId, boxID);
        $('.garage_car_detail_header').addClass('addlayer');
        $('.carinfo_cards_box_wrapper').addClass('addlayer');
        $('.top_iconbar').addClass('blur');
    }

    // After Compeleting Cycle..
    componentWillUnmount() {
        // Do Something ...
    }

    // Car Drop Down Change Event Start
    HandleChangeCarDropDown = e => {
        const DropDownID = e.target.value;
        this.setState({
            DropDownIdAfterSelect: DropDownID,
            isLoadedcard: false,
            isRighpanelOpen: false
        })
        this.DataRendering(e.target.value)

    }
    // Car Drop Down Change Event End

    handleChangeCheckboxes = e => {
        var isChecked = e.target.checked;  
        var item = e.target.value;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));  
        e.preventDefault();  
    }




    // Add To Cart API Function
    AddToCartApiFunction = (ReqData) => {
        let end_point = 'Cart/AddItemToCart';
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            },
            body: JSON.stringify(ReqData),
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                }, () => {

                    this.context.setupdatecartcount(true);
                    setTimeout(() => {
                        this.setState({additemapiisloaded:false})
                    }, 2000);
                });
            } else {
                this.setState({
                    additemapistatus: result.status,
                    additemapimessage: result.message,
                });
            }
        },
        (error) => {
            this.setState({
                additemapistatus:false,
                error
            });
        }) 

    }
    // Add To Cart API Function

    // Add To cart 
    AddToCart = e => {
        
        let item = e.currentTarget.getAttribute("itemID");
        let CarID = this.props.carId;
        let DealershipID = this.state.dealershiID;
        let CartItemModels = "";
        let PromoCode = "";
        let Notes = "";
        
        var priceval = e.currentTarget.getAttribute('data');
        var isSpecial = e.currentTarget.getAttribute("isSpecial");

        let special;

        if (isSpecial == "true") {
          special = true;
        } else {
          special = false;
        }
        
        let val;
        if ( priceval == 0) {
          val = [{   
              "CartItemType": 2,
              "ItemId": parseInt(item),
              "isBeneift": true,
              "Price" : 0,
              "ItemTypeAddToCondition": 2,
          }]
        } else {
          val = [{   
              "CartItemType": 2,
              "ItemId": parseInt(item),
              "isBeneift": false,
              "Price" : priceval,
              "ItemTypeAddToCondition": special ? 3 : 1,
          }] 
        }
        
        let data = {
            "DealerShipId": DealershipID,
            "CarId": parseInt(CarID),
            "CartItemModels" : val,
            "PromoCode": PromoCode,
            "Notes": Notes
        }
        this.setState(
            { 
                AddItemToCart : data, 
                additemapiisloaded : true,
                isRighpanelOpen: false
            }, () => {
                // Add To Cart API Hit Function
                this.AddToCartApiFunction(this.state.AddItemToCart);
                $('.garage_car_detail_header').removeClass('addlayer');
                $('.carinfo_cards_box_wrapper').removeClass('addlayer');
                $('.top_iconbar').removeClass('blur');
            }
        )
        
    }


    EmptyCart = e => {
        let end_point = 'Cart/EmptyCartItems';
        const url = this.context.Base_url+end_point;
        fetch(url,{
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.api_token
            }
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status) {
                this.setState({
                    emptycartapistatus: result.status
                }, () => {
                    this.setState({
                        additemapiisloaded : true
                    })
                    this.AddToCartApiFunction(this.state.AddItemToCart);
                });
            } else {
                this.setState({
                    emptycartapistatus: result.status
                });
            }
        },
        (error) => {
            this.setState({
                emptycartapistatus: false,
                error
            });
        }) 
        $('.garage_car_detail_header').removeClass('addlayer');
        $('.carinfo_cards_box_wrapper').removeClass('addlayer');
    }


    render() {
        return (
          <>
            <ToastContainer hideProgressBar={true} />
            {this.state.isRighpanelOpen && (
              <div className="carinfo_cards_box_right_panel active">
                <div
                  className="closedetailsection"
                  onClick={() => {
                    this.setState({
                      isRighpanelOpen: false,
                    });
                    $(".garage_car_detail_header").removeClass("addlayer");
                    $(".carinfo_cards_box_wrapper").removeClass("addlayer");
                    $(".top_iconbar").removeClass("blur");
                  }}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                {this.state.apiStatuscardDetail === false ? (
                  <h3 className="title_medium">
                    {this.state.apiStatusMessagecardDetail}
                  </h3>
                ) : this.state.isLoadedcardDetail === false ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : (
                  <>
                    <div className="right-panel_image_wrapper">
                      <img
                        className="brokenimg"
                        alt="img"
                        src={
                          this.state.carditemsDetail
                            .dealerShipCarPartsFandIImage
                        }
                        id="belliconid"></img>
                    </div>

                    <div className="right-panel_content_wrapper">
                      <div className="right-panel_title">
                        <h3>
                          {
                            this.state.carditemsDetail
                              .dealerShipCarPartsFandIName
                          }
                        </h3>
                        <span className="font-controller">
                          {this.state.carditemsDetail.isBenefit === false && (
                            <>
                              <del>
                                {this.state.carditemsDetail
                                  .dealerShipCarPartsFandIIsSpecial && (
                                  <>
                                    $
                                    {this.state.carditemsDetail.dealerShipCarPartsFandIOrignalPrice.toLocaleString()}
                                    <strong> Price</strong>
                                  </>
                                )}
                              </del>
                              <strong className="sepra"> </strong>
                            </>
                          )}
                          {this.state.carditemsDetail.isBenefit === false && (
                            <>
                              $
                              {this.state.carditemsDetail
                                .dealerShipCarPartsFandIIsSpecial
                                ? this.state.carditemsDetail.dealerShipCarPartsFandISpecialPrice.toLocaleString()
                                : this.state.carditemsDetail.dealerShipCarPartsFandIOrignalPrice.toLocaleString()}
                              <strong> Price</strong>
                              <strong className="sepra"> | </strong>
                              {this.state.carditemsDetail.dealerShipCarPartsFandIPriceInPoints.toLocaleString()}{" "}
                              Points
                            </>
                          )}
                        </span>
                      </div>
                      <div className="flex ">
                        <strong className="text_normal">Valid:</strong>
                        <strong className={`text_normal percent`}>
                          {this.state.carditemsDetail.validTillDate} ({" "}
                          {
                            this.state.carditemsDetail
                              .dealerShipCarPartsFandIValidFor
                          }{" "}
                          Months )
                        </strong>
                      </div>
                      {this.state.carditemsDetail.isBenefit && (
                        <span className="tag3">
                          <span>
                            {this.state.carditemsDetail.isBenefitCount}x
                          </span>
                          <span>Included in the package</span>
                        </span>
                      )}
                      {this.state.carditemsDetail
                        .dealerShipCarPartsFandIIsSpecial && (
                        <span className="tag3">
                          <span>Special</span>
                        </span>
                      )}
                    </div>

                    {this.state.carditemsDetail.dealerShipServiceIsCart ? (
                      <div className="btn_theme fullwidth">
                        <i className="fa-solid fa-cart-shopping mr-1"></i>
                        Already in Cart
                      </div>
                    ) : (
                      <div
                        className="btn_theme fullwidth"
                        onClick={this.AddToCart}
                        data={
                          this.state.carditemsDetail.isBenefit
                            ? 0
                            : this.state.carditemsDetail
                                .dealerShipCarPartsFandIIsSpecial
                            ? this.state.carditemsDetail
                                .dealerShipCarPartsFandISpecialPrice
                            : this.state.carditemsDetail
                                .dealerShipCarPartsFandIOrignalPrice
                        }
                        isSpecial={
                          this.state.carditemsDetail
                            .dealerShipCarPartsFandIIsSpecial
                            ? "true"
                            : "false"
                        }
                        itemID={
                          this.state.carditemsDetail.dealerShipCarPartsFandIId
                        }>
                        {this.state.carditemsDetail.isBenefit
                          ? "Claim"
                          : "Add to Cart"}
                      </div>
                    )}
                    {this.state.radioButtonLoader === false ? (
                      <div className="loader-div">
                        <img alt="img" src={loader} id="belliconid"></img>
                      </div>
                    ) : this.state.carditemsDetail
                        .dealerShipCarPartsFandIIsFavorites ? (
                      <div
                        className="pausehover btn_theme light fullwidth"
                        onClick={() => {
                          this.unfavoriteMe({
                            Entity: 4,
                            tableId:
                              this.state.carditemsDetail
                                .dealerShipCarPartsFandIId,
                            CarId: this.props.carId,
                          });
                          this.context.setcarID(
                            this.state.carditemsDetail.dealerShipCarPartsFandIId
                          );
                        }}>
                        <img alt="img" src={favIcon}></img>
                        Remove From Favorites
                      </div>
                    ) : (
                      <div
                        className="pausehover btn_theme light fullwidth"
                        onClick={() => {
                          this.favoriteMe({
                            Entity: 4,
                            tableId:
                              this.state.carditemsDetail
                                .dealerShipCarPartsFandIId,
                            CarId: this.props.carId,
                          });
                          this.context.setcarID(
                            this.state.carditemsDetail.dealerShipCarPartsFandIId
                          );
                        }}>
                        <img alt="img" src={UnfavIcon}></img>
                        Add to Favorites
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="flex flex-column h-100">
              {this.state.apiStatus === false ? (
                <h3 className="title_medium">{this.state.apiStatusMessage}</h3>
              ) : this.state.isLoaded === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : (
                <>
                  {/* <select className='custom_select_select_ele' onChange={this.HandleChangeCarDropDown}>       
                            {
                                this.state.items.map((option) => (
                                    <option value={option.id}>{option.dealerShipName} | {option.year} | {option.make} {option.model}</option>
                                ))
                            }
                            </select> */}
                  <div className="content_panel_controller overflow-auto">
                    <div className="carinfo_cards_box_wrapper">
                      {this.state.isLoaded === false ? (
                        <div className="loader-div">
                          <img alt="img" src={loader} id="belliconid"></img>
                        </div>
                      ) : this.state.isLoadedcard === false ? (
                        <div className="loader-div">
                          <img alt="img" src={loader} id="belliconid"></img>
                        </div>
                      ) : (
                        this.state.carditems.length != 0 ?
                        this.state.carditems.map((user) => (
                          <div
                            className="carinfo_cards_box_wrapper_items"
                            key={user.carParttId + v4}>
                            <div
                              className="carinfo_cards_box_wrapper_items_frame_controller"
                              onClick={this.boxClick}
                              box-id={user.carParttId}>
                              <div className="carinfo_cards_box_wrapper_items_image_wrapper">
                                <div className="tags_wrapper">
                                  {user.isBenefit && (
                                    <span className="tag2">
                                      {/* <span>{user.isBenefitCount}x</span> */}
                                      <span>Included</span>
                                    </span>
                                  )}
                                  {user.dealerShipProtectionIsSpecial && (
                                    <span className="tag">
                                      <img
                                        className="brokenimg"
                                        alt="Failed to load"
                                        src={dictag}></img>
                                    </span>
                                  )}
                                </div>
                                <img
                                  className="brokenimg"
                                  alt="Failed to load"
                                  src={user.carPartImage}></img>
                              </div>
                              <div className="carinfo_cards_box_wrapper_items_content_wrapper">
                                <h4 className="carinfo_cards_box_wrapper_items_title">
                                  {user.carPartName}
                                </h4>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        <div className="emptywndow emptywndowfav">
                          <h3 className="mb-0">No Protection Plans Available</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {this.state.additemapiisloaded
              ? this.state.additemapistatus && (
                  <div className="loader-div succesmodal">
                    <Overlay
                      title="additem"
                      message={this.state.additemapimessage}
                    />
                  </div>
                )
              : ""}
            {this.state.additemapiisloaded
              ? this.state.additemapistatus === false && (
                  <div className="loader-div succesmodal">
                    <h3 className="title_medium"></h3>
                    <div className="confirm_dialog">
                      <h4>{this.state.additemapimessage}</h4>
                      <div className="flex">
                        {this.state.additemapimessage ===
                        "Item Already Exist in the Cart." ? (
                          <div
                            className={`btn_theme small`}
                            onClick={() => {
                              this.setState({
                                additemapiisloaded: false,
                              });
                            }}>
                            Okay
                          </div>
                        ) : (
                          <>
                            <div
                              className={`btn_theme small light`}
                              onClick={this.EmptyCart}>
                              Empty Cart
                            </div>
                            <div
                              className={`btn_theme small`}
                              onClick={() => {
                                this.setState({
                                  additemapiisloaded: false,
                                });
                              }}>
                              Cancel
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="confirm_dialog_layer"></div>
                  </div>
                )
              : ""}
          </>
        );
    }
}
export default ShopServices;
