import Main from './components/main_layout.js'
import Login from './components/Authentication/Login.js';
import './App.css';
import Slider from "react-slick";
import AppContext from './AppContext.js';
import { useState } from 'react';
import Splash from './components/splash.js';
import axios from 'axios';
import React from 'react';
import { useEffect } from "react";
import $ from 'jquery'
import Logo from './images/IBIZILogo.svg';
import gplay from './images/gplay.png'
import astore from './images/astore.png'
import loader from './images/loader.gif';
import { v4 } from 'uuid';
// import { useContext } from 'react';
import DeclineServiceSignup from './components/Maincomponents/DeclineServiceSignup.js';

function setToken(userToken) {
  localStorage.setItem('token', JSON.stringify(userToken));
}

function App() {
  // Global Variables...
  let environmentvariablev = "Prod"; 
  const [environmentstate, setenvironmentstate] =
    useState(environmentvariablev);
  var Base_url;
  var Environment_Folder; 

  switch (environmentvariablev) {
    case "Dev":
      Base_url = "http://168.62.52.139:6062/api/";
      Environment_Folder = "DEV/";
      break;
    case "QA":
      Base_url = "http://168.62.52.139:7071/api/";
      Environment_Folder = "QA/";
      break;
    case "Demo":
      Base_url = "https://ibizi-demo-api.azurewebsites.net/api/";
      Environment_Folder = "DEMO/";
      break;
    case "UAT":
      Base_url = "https://api-preprod.azurewebsites.net/api/";
      Environment_Folder = "UAT/";
      break;
    case "Prod":
      Base_url = "https://api.ibizi.com/api/";
      Environment_Folder = "PROD/";
      break;
    default:
      Base_url = "http://168.62.52.139:6062/api/";
      Environment_Folder = "DEV/";
  }

  React.useEffect(() => { 
    if (api_token !== undefined && api_token !== null && api_token !== "") {
      setenvironmentstate(Environment_Folder);
    }
    thorwEmailDeclineWork();
  }, []);

  // React.useEffect(() => {
  //   if (api_token !== undefined && api_token !== null && api_token !== "") {

  //     const fetchData = async () => {
  //       try {
  //         fetch(Base_url + "Notifications/NotificationsList", {
  //           method: "GET",
  //           headers: {
  //             Authorization: localStorage.getItem("token"),
  //           },
  //         })
  //           .then((res) => res.json())
  //           .then(
  //             (result) => {
  //               // debugger
  //               setnotificationBagdeCount(result.notificationBadgeCount);
  //               console.log("asas");
  //             },
  //             (error) => {}
  //           );
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }

  //     };
  //     fetchData();
  //   }
  // }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let api_token = localStorage.getItem("token");

  const [shopcardetailsectionisopen, setshopcardetailsectionisopen] =
    useState("");
  const [updatecartcount, setupdatecartcount] = useState(true);
  const [updatenoticount, setupdatenoticount] = useState(false); 
  const [appoinmentcount, setappoinmentcount] = useState();
  const [singlecardetailsectionisopen, setsinglecardetailsectionisopen] =
    useState(false);
  const [carID, setcarID] = useState();
  const [countforcart, setcountforcart] = useState();
  const [carIDForService, setcarIDForService] = useState();
  const [entityenum, setentityenum] = useState();
  const [dataforappointments, setdataforappointments] = useState([]);
  const [splashIsOpen, setsplashIsOpen] = useState(true);
  const [addnewcarisopen, setaddnewcarisopen] = useState(false);
  const [openprofilesetting, setopenprofilesetting] = useState(false);
  const [active, setActive] = useState("Garage");
  const [callfavAPI, setcallfavAPI] = useState();
  const [favoriteitemloader, setfavoriteitemloader] = useState(false);
  const [garagefirstcarid, setgaragefirstcarid] = useState();
  const [openaddnewcarfromrewards, setopenaddnewcarfromrewards] =
    useState(false);
  const [loginscreenfromsignedup, setloginscreenfromsignedup] = useState(false);
  const [declineworkstate, setdeclineworkstate] = useState(false);
  const [nodeclinescreen, setnodeclinescreen] = useState(false);
  const [nodeclinemsg, setnodeclinemsg] = useState("");
  const [declineworkcarlist, setdeclineworkcarlist] = useState([]);
  const [declineworkdetail, setdeclineworkdetail] = useState([]);
  const [declineworkdefaultcarid, setdeclineworkdefaultcarid] = useState();
  const [declinecontentloader, setdeclinecontentloader] = useState(false);
  const [declineusername, setdeclineusername] = useState();
  const [declinedemail, setdeclinedemail] = useState();
  const [totalservices, settotalservices] = useState();
  const [totalDiscountedPriceservices, settotalDiscountedPriceservices] =
    useState();
  const [totalPriceservices, settotalPriceservices] = useState();
  const [declineworkservicesdetail, setdeclineworkservicesdetail] = useState(
    []
  );
  // const [discountpercentage, setdiscountpercentage] = useState();
  const [deeplink, setdeeplink] = useState();
  const [availservices, setavailservices] = useState(false);
  const [notidropdowstatehide, setnotidropdowstatehide] = useState(false);
  const [showdeclinedanduserservices, setshowdeclinedanduserservices] =
    useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const [declineuserwebstate, setdeclineuserwebstate] = useState(false);
  const [declineData, setDeclineData] = useState([]);

  const [declineId, setdeclineId] = useState(0);
  const [userId, setuserId] = useState(0);
  const [declineAddtoModel, setdeclineAddtoModel] = useState([]);
  const [notificationBagdeCount, setnotificationBagdeCount] = useState(0);
  const [addcardpagefromcart, setaddcardpagefromcart] = useState(false);
  const [partsandAccessoryisopen, setpartsandAccessoryisopen] = useState(false);
  const [partsandAccessoryModel, setpartsandAccessoryModel] = useState({});
  const [SoldCarConfirmationURL, setSoldCarConfirmationURL] = useState(false); 

  React.useEffect(() => {
    checkEmailSoldCarConfirmUrl();
  }, []);

  // const [additemincartconfirmation, setadditemincartconfirmation] = useState(false);

  if (callfavAPI === "favorite") {
    if (entityenum === 3 || entityenum === 4) {
      favoriteAPI(entityenum, carID, carIDForService);
    } else {
      favoriteAPI(entityenum, carID, 0);
    }
    setcallfavAPI("");
    setcarIDForService("");
    setcarID("");
    setentityenum("");
  } else if (callfavAPI === "unfavorite") {
    if (entityenum === 3 || entityenum === 4) {
      favoriteAPIremove(entityenum, carID, carIDForService);
    } else {
      favoriteAPIremove(entityenum, carID, 0);
    }
    setcallfavAPI("");
    setcarIDForService("");
    setcarID("");
    setentityenum("");
  }


  const SoldCarConfirmationTokenAPI = async (tk) => {
    var token = tk;
    const APIhitstatus = true
    return APIhitstatus;
  };
  const checkEmailSoldCarConfirmUrl = async () => {
    // var currentURL = window.location.href+'?SoldCarConfirmationToken='+'TalhaAbbasAsas';
    var currentURL = window.location.href;

    let paramString = currentURL.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    // let declinedId = currentURL.substring(currentURL.lastIndexOf("=") + 1);
    let token = queryString.get("SoldCarConfirmationToken");
    if (token) {
      // console.log(token);
      const APIResponse = await SoldCarConfirmationTokenAPI(token);
      setSoldCarConfirmationURL(true); 
    } else {
      setSoldCarConfirmationURL(false);  
    }
    
  };

  const thorwEmailDeclineWork = async () => {
    setdeclinecontentloader(true);
    // var currentURL = window.location.href+'?UserId='+0+'&declinedId='+4234605;
    // var currentURL =
    //   window.location.href + "?UserId=" + 1586 + "&declinedId=" + 0;
    // https://consumer.ibizi.com/?UserId=0&declinedId=5204207
    var currentURL = window.location.href;

    let paramString = currentURL.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    let declinedId = currentURL.substring(currentURL.lastIndexOf("=") + 1);
    let userId = queryString.get("UserId");
    let endpoint =
      "Garage/UserCarsForDeclined?declinedUserId=" +
      declinedId +
      "&UserId=" +
      userId;
    setdeclineId(declinedId);
    setuserId(userId);
    if (currentURL.indexOf("declinedId") > -1) {
      let apiurl = Base_url + endpoint;
      const response = await axios
        .get(apiurl)
        .then((response) => response.data);
      setdeclineworkstate(true);
      
      if (response.status) {
        setdeclineworkdefaultcarid(response.carInfoDetailModels[0].id);
        setdeclineworkdetail(response);
        setdeclineworkcarlist(response.carInfoDetailModels);
        setnodeclinescreen(false);
        setnodeclinemsg("");
        let endpointgetservices =
          "Shop/DeclinedUserServicesDetails?CarId=" +
          response.carInfoDetailModels[0].id +
          "&DeclinedId=" +
          declinedId;
        let apiurlservices = Base_url + endpointgetservices;
        const responseServices = await axios
          .get(apiurlservices)
          .then((responseServices) => responseServices.data);
        if (responseServices.status) {
          const valuee = responseServices.declinedUserServiceDetailsModel.deepLink
          
          
          const getLastValueFromURL = (url) => {
            const parts = url.split("/");
            const lastValue = parts[parts.length - 1];
            return lastValue;
          };

          const lastValue = getLastValueFromURL(valuee);
          setdeclinedemail(lastValue);
          setdeeplink(
            responseServices.declinedUserServiceDetailsModel.deepLink
          );
          setdeclineworkservicesdetail(
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels
          );
          setdeclineusername(
            responseServices.declinedUserServiceDetailsModel.firstName +
              " " +
              responseServices.declinedUserServiceDetailsModel.lastName
          );
          settotalservices(
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels.length
          );
          if (
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels.length === 0
          ) {
            setavailservices(true);
          } else {
            setavailservices(false);
          }
          setdeclinecontentloader(false);
          // calculate total price or discounted price
          let totalserviceamount = 0;
          let totalserviceamountorignal = 0;
          for (
            let i = 0;
            i <
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              responseServices.declinedUserServiceDetailsModel
                .shopServiceCategoryModels[i]
                .shopServiceCategoryServiceDetailModels.length;
              j++
            ) {
              totalserviceamount +=
                responseServices.declinedUserServiceDetailsModel
                  .shopServiceCategoryModels[i]
                  .shopServiceCategoryServiceDetailModels[j]
                  .dealerShipServiceDiscountedPrice;
              totalserviceamountorignal +=
                responseServices.declinedUserServiceDetailsModel
                  .shopServiceCategoryModels[i]
                  .shopServiceCategoryServiceDetailModels[j]
                  .dealerShipServiceOrignalPrice;
            }
          }
          // settotalDiscountedPriceservices(totalserviceamount);
          settotalDiscountedPriceservices(
            Math.round(totalserviceamount * 100) / 100
          );
          settotalPriceservices(
            Math.round(totalserviceamountorignal * 100) / 100
          );
        } else {
          setdeclinecontentloader(true);
        }
      } else {
        setnodeclinescreen(true);
        setnodeclinemsg(response.message);
      }
    } else {
      setnodeclinescreen(false);
      setdeclineworkstate(false);
      setnodeclinemsg("");
    }
  };

  const thorwEmailDeclineWorkrecall = async (carID) => {
    setdeclinecontentloader(true);
    // var currentURL = window.location.href+'?UserId='+1429+'&declinedId='+0;

    // var currentURL = window.location.href+'?UserId='+1415+'&declinedId='+0;
    var currentURL = window.location.href;

    let paramString = currentURL.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    let declinedId = currentURL.substring(currentURL.lastIndexOf("=") + 1);
    let userId = queryString.get("UserId");
    let endpoint =
      "Garage/UserCarsForDeclined?declinedUserId=" +
      declinedId +
      "&UserId=" +
      userId;
    if (currentURL.indexOf("declinedId") > -1) {
      let apiurl = Base_url + endpoint;
      const response = await axios
        .get(apiurl)
        .then((response) => response.data);
      setdeclineworkstate(true);
      if (response.status) {
        setdeclineworkdefaultcarid(carID);
        setdeclineworkdetail(response);
        setdeclineworkcarlist(response.carInfoDetailModels);
        setnodeclinescreen(false);
        setnodeclinemsg("");
        let endpointgetservices =
          "Shop/DeclinedUserServicesDetails?CarId=" +
          carID +
          "&DeclinedId=" +
          declinedId;
        let apiurlservices = Base_url + endpointgetservices;
        const responseServices = await axios
          .get(apiurlservices)
          .then((responseServices) => responseServices.data);
        if (responseServices.status) {
          const valuee =
            responseServices.declinedUserServiceDetailsModel.deepLink;

          const getLastValueFromURL = (url) => {
            const parts = url.split("/");
            const lastValue = parts[parts.length - 1];
            return lastValue;
          };

          const lastValue = getLastValueFromURL(valuee);
          setdeclinedemail(lastValue);
          setdeeplink(
            responseServices.declinedUserServiceDetailsModel.deepLink
          );
          setdeclineworkservicesdetail(
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels
          );
          setdeclineusername(
            responseServices.declinedUserServiceDetailsModel.firstName +
              " " +
              responseServices.declinedUserServiceDetailsModel.lastName
          );
          settotalservices(
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels.length
          );
          setdeclinecontentloader(false);
          // calculate total price or discounted price

          let totalserviceamount = 0;
          let totalserviceamountorignal = 0;
          for (
            let i = 0;
            i <
            responseServices.declinedUserServiceDetailsModel
              .shopServiceCategoryModels.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              responseServices.declinedUserServiceDetailsModel
                .shopServiceCategoryModels[i]
                .shopServiceCategoryServiceDetailModels.length;
              j++
            ) {
              totalserviceamount +=
                responseServices.declinedUserServiceDetailsModel
                  .shopServiceCategoryModels[i]
                  .shopServiceCategoryServiceDetailModels[j]
                  .dealerShipServiceDiscountedPrice;
              totalserviceamountorignal +=
                responseServices.declinedUserServiceDetailsModel
                  .shopServiceCategoryModels[i]
                  .shopServiceCategoryServiceDetailModels[j]
                  .dealerShipServiceOrignalPrice;
            }
          }
          // settotalDiscountedPriceservices(totalserviceamount);
          settotalDiscountedPriceservices(
            Math.round(totalserviceamount * 100) / 100
          );
          settotalPriceservices(
            Math.round(totalserviceamountorignal * 100) / 100
          );

          // let totalserviceamount = 0;
          // let totalserviceamountorignal = 0;

          // for (let i = 0; i < responseServices.declinedUserServiceDetailsModel.shopServiceCategoryModels.length; i++) {
          //   totalserviceamount += responseServices.declinedUserServiceDetailsModel.shopServiceCategoryModels[i].discountedPrice;
          //   totalserviceamountorignal += responseServices.declinedUserServiceDetailsModel.shopServiceCategoryModels[i].totalPrice;
          // }
          // // settotalDiscountedPriceservices(totalserviceamount);
          // settotalDiscountedPriceservices(Math.round(totalserviceamount * 100) / 100);
          // settotalPriceservices(Math.round(totalserviceamountorignal * 100) / 100);
        } else {
          setdeclinecontentloader(true);
        }
      } else {
        setnodeclinescreen(true);
        setnodeclinemsg(response.message);
      }
    } else {
      setnodeclinescreen(false);
      setnodeclinemsg("");
      setdeclineworkstate(false);
    }
  };
  // const fetchData = async () => {
  //   // You can await here
  //   let end_pointt = "Notifications/NotificationsList";
  //   let api_url = Base_url + end_pointt;

  //   try {
  //     const token = localStorage.getItem("token");
  //     const headers = {
  //       Authorization: token,
  //     };
  //     const response = await fetch(api_url, { headers });
  //     const data = await response.json();
  //     setnotificationBagdeCount(data.notificationBadgeCount);
  //     setupdatenoticount(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setupdatenoticount(false);
  //   }
  //   // ...
  // }
  // if (updatenoticount) {

  //   fetchData();
  // }
  const fetchData = async () => {
    // setupdatecartcount(false);
    // You can await here
      let end_pointt = "Notifications/NotificationsList";
      let api_url = Base_url + end_pointt;

      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: token,
        };
        const response = await fetch(api_url, { headers });
        const data = await response.json();
        
        if (data.notificationBadgeCount != 0) {
          setTimeout(() => {
            setnotificationBagdeCount(data.notificationBadgeCount);
            setupdatenoticount(false);
          }, 1500);
          // clearTimeout(myTimeout);
        }
        // setupdatecartcount(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setupdatecartcount(false);
      }
      // ...
    
  };

  // if (updatenoticount) {
  //   fetchData();
  //   setupdatenoticount(false);
  //   // setupdatecartcount(false);
  // }
  if (updatecartcount) {
    getcountcard();
    getAppointmentCount();
  }

  // Declined Serivces
  function declinedServices() {
    let endpoint = "";
    let apiurl = Base_url + endpoint;
    fetch(apiurl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        const data = json;
        setDeclineData(data.declinedUserDataModel);
      })
      .catch((error) => {});
  }

  // Mark As Favorite
  function favoriteAPI(Entity, TableId, CarId) {
    let end_point = "Favorite/MarkAsFavourite";
    //api url for right
    let api_url = Base_url + end_point;
    const url = api_url;
    axios({
      method: "post",
      url: url,
      data: {
        favouriteSystemEntity: Entity,
        tableId: TableId,
        CarId: CarId,
      },
      headers: { Authorization: api_token },
    })
      .then((res) => {
        setcallfavAPI("");
        setfavoriteitemloader(false);
      })
      .catch((error) => console.log(error));
  }

  // Remove As Favorite
  function favoriteAPIremove(Entity, TableId, CarId) {
    let end_point = "Favorite/RemoveFromFavourite";
    //api url for right
    let api_url = Base_url + end_point;
    const url = api_url;
    axios({
      method: "post",
      url: url,
      data: {
        favouriteSystemEntity: Entity,
        tableId: TableId,
        CarId: CarId,
      },
      headers: { Authorization: api_token },
    })
      .then((res) => {
        setcallfavAPI("");
        setfavoriteitemloader(false);
      })
      .catch((error) => console.log(error));
  }

  function getcountcard() {
    
    let endpoint = "Cart/ListCartItemsForPayment";
    let apiurl = Base_url + endpoint;
    const url = apiurl;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: api_token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // debugger;
        if (json.status) {
          if (json.cartList.cartItemsListFandI.length > 0 || json.cartList.cartItemsListServices.length > 0 || json.cartList.cartItemsListPartAndAccessory.length > 0) {
            let fnilen;
            let serlen;
            let palen;
            if (json.cartList.cartItemsListServices.length === 0) {
              serlen = 0;
            } else {
              serlen = json.cartList.cartItemsListServices.length;
            }

            if (json.cartList.cartItemsListFandI.length === 0) {
              fnilen = 0;
            } else {
              fnilen = json.cartList.cartItemsListFandI.length;
            }

            if (json.cartList.cartItemsListPartAndAccessory.length === 0) {
              palen = 0;
            } else {
              palen = json.cartList.cartItemsListPartAndAccessory.length;
            }

            setcountforcart(serlen + fnilen + palen);
            setupdatecartcount(false);
          }
          if (json.cartList.cartItemsListServices.length < 1 && json.cartList.cartItemsListFandI.length < 1 && json.cartList.cartItemsListPartAndAccessory.length < 1) {
            setcountforcart(undefined);
            setupdatecartcount(false);
          }
        } else {
          setcountforcart(undefined);
          setupdatecartcount(false);
        }
        
      })
      .catch((error) => {});
  }

  // function getNotiCount() {
  //   fetch(
  //     Base_url + "Notifications/NotificationsList",
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         // debugger
  //         setnotificationBagdeCount(result.notificationBadgeCount);
  //       },
  //       (error) => {}
  //     );
  // }

  function getAppointmentCount() {
    let endpoint = "Appointment/GetUserAppointments";
    let apiurl = Base_url + endpoint + "?carId=" + 0 + "&getAppointmentType=";
    const url = apiurl + 2;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: api_token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        
        setappoinmentcount(json.appointmentDetailsCount);
      })
      .catch((error) => console.log(error));
  }

  const toggleSetting2 = () => {
    setshopcardetailsectionisopen("");
    setsinglecardetailsectionisopen(false);
    setcarID();
    setfavoriteitemloader();
    setentityenum();
    setcallfavAPI();
    setcarIDForService();
    setdataforappointments([]);
    setaddnewcarisopen(false);
    setopenaddnewcarfromrewards(false);
    setopenprofilesetting(false);
    setActive("Garage");
    setgaragefirstcarid();
    setcountforcart();
    setupdatecartcount();
    setenvironmentstate(environmentvariablev);
    setloginscreenfromsignedup(false);
    setdeclineAddtoModel([]);
    setdeclineuserwebstate(false);
    setdeclineworkstate(false);
    setnotidropdowstatehide(false);
    setnotificationBagdeCount();
    setappoinmentcount();
    setaddcardpagefromcart();
    setdeclinedemail();
    setpartsandAccessoryisopen(false);
    setpartsandAccessoryModel({});
  };

  const userSettings = {
    shopcardetailsectionisopen,
    singlecardetailsectionisopen,
    carID,
    callfavAPI,
    favoriteitemloader,
    dataforappointments,
    Base_url,
    entityenum,
    addnewcarisopen,
    carIDForService,
    openprofilesetting,
    active,
    garagefirstcarid,
    openaddnewcarfromrewards,
    countforcart,
    updatecartcount,
    environmentstate,
    loginscreenfromsignedup,
    showdeclinedanduserservices,
    declineAddtoModel,
    declineuserwebstate,
    declineworkstate,
    notidropdowstatehide,
    notificationBagdeCount,
    appoinmentcount,
    updatenoticount,
    addcardpagefromcart,
    declinedemail,
    partsandAccessoryisopen,
    partsandAccessoryModel,
    setpartsandAccessoryModel,
    setdeclinedemail,
    setaddcardpagefromcart,
    setupdatenoticount,
    setappoinmentcount,
    setnotificationBagdeCount,
    setnotidropdowstatehide,
    setdeclineworkstate,
    setdeclineuserwebstate,
    setshowdeclinedanduserservices,
    setloginscreenfromsignedup,
    setenvironmentstate,
    setupdatecartcount,
    setcountforcart,
    setopenaddnewcarfromrewards,
    setgaragefirstcarid,
    setcarIDForService,
    setToken,
    setdataforappointments,
    setshopcardetailsectionisopen,
    setsinglecardetailsectionisopen,
    setcarID,
    setfavoriteitemloader,
    setcallfavAPI,
    setentityenum,
    toggleSetting2,
    setaddnewcarisopen,
    setopenprofilesetting,
    setActive,
    setdeclineAddtoModel,
    setpartsandAccessoryisopen,
  };

  setInterval(() => {
    setsplashIsOpen(false);
  }, 2300);

  const settings = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    lazyLoad: true,
    infinite: false,
    dot: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleActiveClass = (e) => {
    var item = e.currentTarget;
    $(item).addClass("active");
    $(item).siblings().removeClass("active");
    setdeclinecontentloader(true);
    thorwEmailDeclineWorkrecall(item.getAttribute("carid"));
  };

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  async function checkAppInstalled() {
    var deeplinkUrl = deeplink + "/" + declineworkdefaultcarid; // Replace 'ibiziapp://' with your custom app scheme
    const iosFallbackUrl = "https://apps.apple.com/pk/app/ibizi/id1659590288"; // Replace with the App Store URL or any fallback URL

    try {
      await openDeepLink(deeplinkUrl);
    } catch (error) {
      window.location.href = iosFallbackUrl;
    }
  }
  function openDeepLink(deepLinkUrl) {
    const iosFallbackUrl = "https://apps.apple.com/pk/app/ibizi/id1659590288";
    return new Promise((resolve, reject) => {
      const anchor = document.createElement("a");
      anchor.href = deepLinkUrl;

      const clickHandler = () => {
        resolve();
      };

      const errorHandler = () => {
        reject(new Error("Deep link failed"));
        window.location.href = iosFallbackUrl;
      };

      anchor.addEventListener("click", clickHandler);
      anchor.addEventListener("error", errorHandler);

      document.body.appendChild(anchor);
      anchor.click();

      setTimeout(() => {
        document.body.removeChild(anchor);
        reject(new Error("Deep link timeout"));
        window.location.href = iosFallbackUrl;
      }, 3500); // Adjust the timeout duration as needed
    });
  }

  const deeplinkfunc = async (e) => {
    const isAndroid = /Android/.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    var scheme;

    var deeplinkUrl = deeplink + "/" + declineworkdefaultcarid;
    var androidDeepLink = deeplink + "/" + declineworkdefaultcarid;

    if (isAndroid) {
      scheme = androidDeepLink;
    } else if (isiOS) {
      scheme = deeplinkUrl;
    } else {
      //  this is for web application
      declinedServices();
      setdeclineuserwebstate(true);
    }

    const androidFallbackUrl =
      "https://play.google.com/store/apps/details?id=com.hudasoft.ibizi";
    const iosFallbackUrl = "https://apps.apple.com/pk/app/ibizi/id1659590288";

    if (isAndroid) {
      window.location.href = scheme;
      setTimeout(() => {
        window.location.href = androidFallbackUrl;
      }, 250);
    } else if (isiOS) {
      checkAppInstalled();
    } else {
      console.error("Unsupported device type");
    }
  };

  // const deepLinkUrl = scheme; // Replace with your specific deep link URL

  // // Create an anchor element with the deep link URL
  // const anchor = document.createElement("a");
  // anchor.href = deepLinkUrl;
  // // Trigger a click event on the anchor element
  // const event = new MouseEvent('click', {
  //   bubbles: true,
  //   cancelable: true,
  //   view: window
  // });
  // anchor.dispatchEvent(event);
  // // Set a timeout to check if the app was opened successfully
  // setTimeout(() => {
  //   // If the user is still on the same page, the deep link failed
  //   if (document.hidden) {
  //     console.log("Deep link is valid");
  //   } else {
  //     window.location.href = iosFallbackUrl;
  //   }
  // }, 2500); // Adjust the timeout duration as needed

  return (
    <>
      {SoldCarConfirmationURL ? (
        <div>
          <div className="SoldCarConfirmationURLWrapper">
            <div className="SoldCarConfirmationURL">
              <h3>Thank you for your response.</h3>
            </div>
          </div>
        </div>
      ) : declineuserwebstate ? (
        <AppContext.Provider value={userSettings}>
          <DeclineServiceSignup
            parameter={declineworkdefaultcarid}
            baseUrl={Base_url}
            isDecline={declineId > 0 ? true : false}
          />
        </AppContext.Provider>
      ) : declineworkstate ? (
        nodeclinescreen ? (
          <div>
            <div className="declined-container hh">
              <div className="declined-container_left">
                <h3>{nodeclinemsg}</h3>
              </div>
            </div>
          </div>
        ) : (
          <>
            {availservices ? (
              <div>
                <div className="declined-container hh">
                  <div className="declined-container_left">
                    <h3>You have already availed the declined services.</h3>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="navbar-decline">
                  <img
                    alt="img"
                    className="brokenimg"
                    src={Logo}
                    id="Logoid"></img>
                </div>
                <div className="declined-container_tap_wrapper">
                  {declineworkcarlist.map((user) => (
                    <span
                      key={v4}
                      onClick={toggleActiveClass}
                      className={`${
                        declineworkdefaultcarid === user.id ? "active" : ""
                      }`}
                      carid={user.id}>
                      {user.name} <strong>( {user.dealershipName} )</strong>
                    </span>
                  ))}
                </div>
                <div className="declined-container">
                  {declinecontentloader === false ? (
                    <>
                      <div className="declined-container_left">
                        <h3>Welcome, {declineusername}!</h3>
                        <h2>Declined Services</h2>
                        <p>
                          Hurry Up! Grab this exciting offer on services
                          declined by you on your recent visit. You can avail
                          this offer by clicking{" "}
                          <span onClick={deeplinkfunc}>here.</span>
                        </p>

                        {/* <br></br>
                        <br></br> */}
                        {/* <a href={deeplink} target="_blank">Grap this offer now?</a> */}
                        <div className="declined-container_left_price_wrapper">
                          <div className="declined-container_left_price_wrapper_box">
                            <h4>Services</h4>
                            <strong>{totalservices}</strong>
                          </div>
                          {/* <div className='declined-container_left_price_wrapper_box'>
                            <h4>Total Amount</h4>
                            <strong><del>${totalPriceservices}</del></strong>
                          </div> */}
                          <div className="declined-container_left_price_wrapper_box">
                            <h4>Amount</h4>
                            <strong>
                              ${totalDiscountedPriceservices}{" "}
                            </strong>{" "}
                            <sup>
                              <strong>
                                <small>
                                  <del> ${totalPriceservices}</del>{" "}
                                </small>
                              </strong>
                            </sup>
                          </div>
                        </div>
                        <div className="flex download-btn">
                          <a
                            href="https://apps.apple.com/pk/app/ibizi/id1659590288"
                            target="_blank"
                            rel="noreferrer">
                            <img
                              className=""
                              src={astore}
                              alt="BannerRightImage"
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.hudasoft.ibizi"
                            target="_blank"
                            rel="noreferrer">
                            <img
                              className=""
                              src={gplay}
                              alt="BannerRightImage"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="declined-container_right">
                        {declineworkservicesdetail.length < 5 ? (
                          <div className="hide_on_mob desktopview">
                            {declineworkservicesdetail.map((user) => (
                              <div
                                key={v4 + user.serviceCategoryName}
                                className="declined-container_right_box_wrapper">
                                <div className="flex img-title_wrapp">
                                  <div className="right-panel_image_wrapper">
                                    <img
                                      className="brokenimg"
                                      alt="img"
                                      src={user.serviceCategoryImage}
                                      id="belliconid"></img>
                                  </div>
                                  <div className="right-panel_title f-dir-col med-title mt-2">
                                    <div>
                                      <h2>
                                        <strong>
                                          {user.serviceCategoryName}
                                        </strong>
                                      </h2>
                                    </div>
                                    {user.serviceCategoryDescription != null ? (
                                      <div className="right-panel_title dir-col">
                                        {/* <h3>Description</h3> */}
                                        <p className="hadjust">
                                          {user.serviceCategoryDescription}
                                        </p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>

                                <>
                                  <div
                                    className={`checkboxes mt-2 ${
                                      user
                                        .shopServiceCategoryServiceDetailModels
                                        .length > 1
                                        ? "hadjust"
                                        : ""
                                    }`}>
                                    {user.shopServiceCategoryServiceDetailModels.map(
                                      (sermodel) => (
                                        <div
                                          className="flex-space-between-center"
                                          key={
                                            v4 +
                                            sermodel.dealerShipServiceOrignalPrice
                                          }>
                                          <label className="labelcon p-0">
                                            {sermodel.dealerShipServiceName}
                                            <div className="flex dir-col alignstart">
                                              <div className="flex dir-col">
                                                <span className="" id="priceId">
                                                  <>
                                                    <del>
                                                      <>
                                                        <strong>
                                                          $
                                                          {
                                                            sermodel.dealerShipServiceOrignalPrice
                                                          }
                                                        </strong>
                                                      </>
                                                    </del>
                                                    <strong className="sepra">
                                                      {" "}
                                                    </strong>
                                                  </>
                                                </span>
                                                <span className="" id="priceId">
                                                  <>
                                                    <strong>
                                                      $
                                                      {
                                                        sermodel.dealerShipServiceDiscountedPrice
                                                      }
                                                    </strong>
                                                    <strong className="sepra">
                                                      {" "}
                                                      |{" "}
                                                    </strong>
                                                  </>
                                                </span>
                                              </div>
                                              <span
                                                className=""
                                                id="PriceInPoints">
                                                <strong>
                                                  {
                                                    sermodel.dealerShipServicePriceInPoints
                                                  }{" "}
                                                  Points
                                                </strong>
                                              </span>
                                              <sup className="single-discount">
                                                {sermodel.discountInPercentage}%
                                                off
                                              </sup>
                                            </div>
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  {/* <a href={deeplink} target="_blank"><div className='btn_theme fullwidth'>Grab This Offer Now</div></a> */}
                                </>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="hide_on_mob block">
                            <Slider {...settings}>
                              {declineworkservicesdetail.map((user) => (
                                <div
                                  className="declined-container_right_box_wrapper"
                                  key={v4 + user.serviceCategoryName}>
                                  <div className="right-panel_image_wrapper">
                                    <img
                                      className="brokenimg"
                                      alt="img"
                                      src={user.serviceCategoryImage}
                                      id="belliconid"></img>
                                  </div>
                                  <div className="right-panel_title med-title mt-2">
                                    <div>
                                      <h2>
                                        <strong>
                                          {user.serviceCategoryName}
                                        </strong>
                                      </h2>
                                    </div>
                                  </div>
                                  {user.serviceCategoryDescription != null ? (
                                    <div className="right-panel_title dir-col mt-1">
                                      <h3>Description</h3>
                                      <p>{user.serviceCategoryDescription}</p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <>
                                    <div className="checkboxes  mt-2">
                                      {user.shopServiceCategoryServiceDetailModels.map(
                                        (sermodel) => (
                                          <div
                                            className="flex-space-between-center"
                                            key={
                                              v4 +
                                              sermodel.dealerShipServiceOrignalPrice
                                            }>
                                            <label className="labelcon p-0">
                                              {sermodel.dealerShipServiceName}
                                              <div className="flex dir-col alignstart">
                                                <div className="flex dir-col">
                                                  <span
                                                    className=""
                                                    id="priceId">
                                                    <>
                                                      <del>
                                                        <>
                                                          <strong>
                                                            $
                                                            {
                                                              sermodel.dealerShipServiceOrignalPrice
                                                            }
                                                          </strong>
                                                        </>
                                                      </del>
                                                      <strong className="sepra">
                                                        {" "}
                                                      </strong>
                                                    </>
                                                  </span>
                                                  <span
                                                    className=""
                                                    id="priceId">
                                                    <>
                                                      <strong>
                                                        $
                                                        {
                                                          sermodel.dealerShipServiceDiscountedPrice
                                                        }
                                                      </strong>
                                                      <strong className="sepra">
                                                        {" "}
                                                        |{" "}
                                                      </strong>
                                                    </>
                                                  </span>
                                                </div>
                                                <span
                                                  className=""
                                                  id="PriceInPoints">
                                                  <strong>
                                                    {
                                                      sermodel.dealerShipServicePriceInPoints
                                                    }{" "}
                                                    Points
                                                  </strong>
                                                </span>
                                                <sup className="single-discount">
                                                  {
                                                    sermodel.discountInPercentage
                                                  }
                                                  % off
                                                </sup>
                                              </div>
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    {/* <a href={deeplink} target="_blank"><div className='btn_theme fullwidth'>Grab This Offer Now</div></a> */}
                                  </>
                                </div>
                              ))}
                            </Slider>
                          </div>
                        )}
                        <div className="show_on_mob">
                          {/* <a href={deeplink} target="_blank"><div className='btn_theme fullwidth grapofferbtn'>Grab This Offer Now</div></a> */}
                          {declineworkservicesdetail.map((user) => (
                            <div
                              className="declined-container_right_box_wrapper"
                              key={user.serviceCategoryName + v4}>
                              <div className="right-panel_image_wrapper">
                                <img
                                  className="brokenimg"
                                  alt="img"
                                  src={user.serviceCategoryImage}
                                  id="belliconid"></img>
                              </div>
                              <div className="right-panel_title med-title mt-2">
                                <div>
                                  <h2>
                                    <strong>{user.serviceCategoryName}</strong>
                                  </h2>
                                </div>
                              </div>
                              {user.serviceCategoryDescription != null ? (
                                <div className="right-panel_title dir-col mt-1">
                                  <p>{user.serviceCategoryDescription}</p>
                                </div>
                              ) : (
                                <></>
                              )}
                              <>
                                <div className="checkboxes  mt-2">
                                  {user.shopServiceCategoryServiceDetailModels.map(
                                    (sermodel) => (
                                      <div
                                        className="flex-space-between-center"
                                        key={
                                          sermodel.dealerShipServiceDiscountedPrice +
                                          v4
                                        }>
                                        <label className="labelcon p-0">
                                          {sermodel.dealerShipServiceName}
                                          <div className="flex dir-col alignstart">
                                            <div className="flex dir-col">
                                              <span className="" id="priceId">
                                                <>
                                                  <del>
                                                    <>
                                                      <strong>
                                                        $
                                                        {
                                                          sermodel.dealerShipServiceOrignalPrice
                                                        }
                                                      </strong>
                                                    </>
                                                  </del>
                                                  <strong className="sepra">
                                                    {" "}
                                                  </strong>
                                                </>
                                              </span>
                                              <span className="" id="priceId">
                                                <>
                                                  <strong>
                                                    $
                                                    {
                                                      sermodel.dealerShipServiceDiscountedPrice
                                                    }
                                                  </strong>
                                                  <strong className="sepra">
                                                    {" "}
                                                    |{" "}
                                                  </strong>
                                                </>
                                              </span>
                                            </div>
                                            <span
                                              className=""
                                              id="PriceInPoints">
                                              <strong>
                                                {
                                                  sermodel.dealerShipServicePriceInPoints
                                                }{" "}
                                                Points
                                              </strong>
                                            </span>
                                            <sup className="single-discount">
                                              {sermodel.discountInPercentage}%
                                              off
                                            </sup>
                                          </div>
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* <a href={deeplink} target="_blank"><div className='btn_theme fullwidth'>Grab This Offer Now</div></a> */}
                              </>
                            </div>
                          ))}
                        </div>
                        <div
                          className="btn_theme fullwidth grapofferbtn"
                          onClick={deeplinkfunc}>
                          Grab This Offer Now
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )
      ) : (
        <>
          <div className="Mobile_View">
            <img alt="img" className="brokenimg" src={Logo} id="Logoid"></img>
            <p>Want to get a real time experience? Download our app version</p>
            <div className="flex">
              <a
                href="https://apps.apple.com/pk/app/ibizi/id1659590288"
                target="_blank"
                rel="noreferrer">
                <img className="" src={astore} alt="BannerRightImage" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.hudasoft.ibizi"
                target="_blank"
                rel="noreferrer">
                <img className="" src={gplay} alt="BannerRightImage" />
              </a>
            </div>
            {/* <span className='developedby'>Design & Develop by Hudasoft.Inc</span> */}
          </div>
          <div className="root_support_element">
            <AppContext.Provider value={userSettings}>
              {splashIsOpen ? (
                localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== null &&
                localStorage.getItem("token") !== "" ? (
                  <Main />
                ) : (
                  <Splash />
                )
              ) : localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== null &&
                localStorage.getItem("token") !== "" ? (
                <Main />
              ) : (
                <Login />
              )}
            </AppContext.Provider>
          </div>
        </>
      )}
    </>
  );
}
export default App;
