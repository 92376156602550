import React from "react";
import { render } from "react-dom";
import {
    easeSinOut,
    easeQuadIn,
    easeQuadInOut,
    easeLinear,
    easeCubicInOut
  } from "d3-ease";
// Import module and default styles;
import "react-circular-progressbar/dist/styles.css";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Animate } from "react-move";


class AnimatedProgressbar extends React.Component {
    interval = undefined;

  state = {
    isAnimated: false
  };

  static defaultProps = {
    valueStart: 0
  };

  componentDidMount() {
    if (this.props.repeat) {
      this.interval = window.setInterval(() => {
        this.setState({
          isAnimated: !this.state.isAnimated
        });
      }, this.props.duration * 1000);
    } else {
      this.setState({
        isAnimated: !this.state.isAnimated
      });
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction
          }
        })}
      >
        {({ value }) => this.props.children(value)}
      </Animate>
    );
  }
}
const Progressbar = (props) => (
    <div className="progress_bar">
      <AnimatedProgressbar
        valueStart={0}
        valueEnd={46}
        duration={1.4}
        easingFunction={easeQuadInOut}
        repeat
      >
        {value => {
          return (
            <CircularProgressbar
              strokeWidth={3}
              value={props.val}
              styles={buildStyles({
                pathColor: props.color,
              })}
            //   text={`${props.val}%`}
            />
          );
        }}
      </AnimatedProgressbar>
    </div>
);

export default Progressbar;


