import React, {useState} from 'react';
import AppContext from '../../AppContext';
import { useContext } from 'react';
import Select from 'react-select';
import loader from '../../images/loader.gif';
import PrivacyPolicy from './PrivacyPolicy';
import "./Garage_Add_New_Car.css";
import img from '../../images/icons8-back-40.png';
import "../Maincomponents/ProfileSetting.css";
import Faqs from './Faq';
import ResetPassword from './ResetPassword';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from "uuid";
import TermOfUse from './TermOfUse';
import $ from 'jquery'





async function SetProfileSetting(objres) {
    return fetch(objres.base_url + 'UserInfo/SetProfileSetting', {
      method: 'GET',
      headers: {
        'Authorization' : objres.api_token,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then((response) => response.json())
    .then((json) => json)
  }

  
async function UpdateProfileSetting(objres) {
    var obj = {
    "firstName": objres.firstname ,
    "lastName": objres.lastname,
    "zipCode": "123",
    "dateOfBirth": objres.dateCreate
    }


    return fetch(objres.base_url + 'UserInfo/UpdateProfileSetting', {
      method: 'POST',
      headers: {
        'Authorization' : objres.api_token,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(obj)
    })
    .then((response) => response.json())
    // sessionStorage.setItem('usermodelfirstname', token.userInfoModel.firstName)
    .then((json) => json)
    // .then((json) => console.log(json))
  }

const ProfileSetting = ({title}) => { 
    const myContext = useContext(AppContext);
    const [firstname, setfirstname] = useState();
    const [lastname, setlastname] = useState();
    const [email, setemail] = useState();
    const [number, setnumber] = useState();
    const [zipCode, setzipCode] = useState();
    const [dobdate, setdobdate] = useState();
    const [dobmonth, setdobmonth] = useState();
    const [profile_image, setprofile_image] = useState();
    const [profilesectionstate, setprofilesectionstate] = useState(false);
    const [faqsstate, setfaqsstate] = useState(false);
    const [chatsupportmodal, setchatsupportmodal] = useState(false);
    const [delaccpopup, setdelaccpopup] = useState(false);
    const [termofuse, settermofuse] = useState(false);
    const [legal, setlegal] = useState(false);
    const [resetpassword, setresetpassword] = useState(false);
    const [imageUpload, setImageUpload] = useState(null);
    const [imguploadloader, setimguploadloader] = useState(false);
    const [responsemessage, setresponsemessage] = useState();
    


    let base_url = myContext.Base_url;
    let api_token = localStorage.getItem('token'); 

  
    const changeState = async (event) =>{
        // event.preventDefault();
                const response = await SetProfileSetting({api_token,base_url});
                if (response.status) {
                    
                    setfirstname(response.userInfoSettingModel.firstName);
                    setlastname(response.userInfoSettingModel.lastName);
                    setemail(response.userInfoSettingModel.email);
                    setnumber(response.userInfoSettingModel.mobileNumber);
                    setzipCode(response.userInfoSettingModel.zipCode);
                    setprofile_image(response.userInfoSettingModel.profileImage);
                    
                    var dt = new Date(response.userInfoSettingModel.dateOfBirth);
                    // document.write("getMonth() : " + dt.getMonth() ); 
                    var month;
                    if(dt.getMonth() > 9) {
                        month = (dt.getMonth() + 1);
                        setmonthsval(month);
                    } else {
                        month =(dt.getMonth() + 1);
                        setmonthsval(0+''+month);
                    }



                    var datee;
                    if(dt.getDate() > 9) {
                        datee = dt.getDate();
                        setdatesval(datee);
                    } else {
                        datee = dt.getDate();
                        setdatesval(0+''+datee);
                    }

                    setdobdate(dt.getDate());
                    setdobmonth(month);
                    // setdob();

                   
                    // setdatesval(dt.getDate());
                    setprofilesectionstate(true);
                }
                else{
                    alert(response.message);
                }

        // if (myContext.openprofilesetting) {
        //     document.getElementsByClassName('main__layout__controller').style.backgroundColor = "black";
        // }
    }

    const updateuserProfile = async (event) =>{
        
        setimguploadloader(true);
        event.preventDefault();

        var dateCreate = "1997-"+ monthsval +"-"+datessval+"T10:09:04.941Z";
        // readyforupdatedate
        const response = await UpdateProfileSetting({api_token,base_url,firstname,lastname,zipCode,dateCreate});
        console.log(response)
        if (response.status) {
            setimguploadloader(false);
            setresponsemessage(response.message);
            localStorage.setItem('usermodelfirstname', firstname)
            localStorage.setItem("usermodellastname", lastname);
            toast(response.message); 
        }
        else{
            toast(response.title); 
            setimguploadloader(false);
            setresponsemessage(response.message);
        }

    }

     
    const cancelImage = () => {
        var label = document.getElementById("img-name");
        var uploadbtn = document.getElementById("uploadpibtn");
        var cancelpibtn = document.getElementById("cancelpibtn");
        uploadbtn.classList.remove('show');
        cancelpibtn.classList.remove('show');
        label.innerHTML = '';
    }

    const onChangeFunction = (event) => {
        if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png") {
          setImageUpload(event.target.files[0]);
          var label = document.getElementById("img-name");
          var uploadbtn = document.getElementById("uploadpibtn");
          var cancelpibtn = document.getElementById("cancelpibtn");
          uploadbtn.classList.add("show");
          cancelpibtn.classList.add("show");
          label.innerHTML = event.target.files[0].name;
        } else {
          var label = document.getElementById("img-name");
          var uploadbtn = document.getElementById("uploadpibtn");
          var cancelpibtn = document.getElementById("cancelpibtn");
          uploadbtn.classList.remove("show");
          cancelpibtn.classList.remove("show");
          label.innerHTML = "<span style='color: red;'>Unsupported File Type.</span>";
        }


    }
   

    const uploadImage = () => {
        setimguploadloader(true);
        let environmentstatefrom = "FROM_APP/";
        
        if ( imageUpload == null ) return;
        const storageRef  = ref(storage, `${myContext.environmentstate+environmentstatefrom}ProfilePicture${v4()+'.jpg'}`);
        // const storageRef  = ref(storage, `${'PROD/'+'FROM_APP/'}ProfilePicture${v4()+'.jpg'}`);
        const uploadTask = uploadBytesResumable(storageRef, imageUpload);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    const data = {
                        ImageUrl: url
                    }
                    let endpoint = 'UserInfo/UpdateUserImage'
                    let apiurl = base_url+endpoint;

                    if(api_token !== undefined && api_token !== null && api_token !== '') {
                        const url = apiurl;
                        fetch(url,{
                            method: 'POST', 
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization' : api_token
                            },
                            body: JSON.stringify(data),
                        })
                        .then((response) => response.json())
                        .then((json) => {

                            setimguploadloader(false);
                            setprofilesectionstate(false);
                            var label = document.getElementById("img-name");
                            var uploadbtn = document.getElementById("uploadpibtn");
                            var cancelpibtn = document.getElementById("cancelpibtn");
                            uploadbtn.classList.remove('show');
                            cancelpibtn.classList.remove('show');
                            label.innerHTML = '';
                            changeState();
                            
                        })
                        .catch((error) => console.log(error));
                    }
                });
            }
        )
    }

    const delaccount = () => {
      
    }

    const months = [
        {label: 'January', value: '01'},
        {label: 'February', value: '02'},
        {label: 'March', value: '03'},
        {label: 'April', value: '04'},
        {label: 'May', value: '05'},
        {label: 'June', value: '06'},
        {label: 'July', value: '07'},
        {label: 'August', value: '08'},
        {label: 'September', value: '09'},
        {label: 'October', value: '10'},
        {label: 'November', value: '11'},
        {label: 'December', value: '12'}
    ];
    const dates = [
        {label: '01', value: '01'},
        {label: '02', value: '02'},
        {label: '03', value: '03'},
        {label: '04', value: '04'},
        {label: '05', value: '05'},
        {label: '06', value: '06'},
        {label: '07', value: '07'},
        {label: '08', value: '08'},
        {label: '09', value: '09'},
        {label: '10', value: '10'},
        {label: '11', value: '11'},
        {label: '12', value: '12'},
        {label: '13', value: '13'},
        {label: '14', value: '14'},
        {label: '15', value: '15'},
        {label: '16', value: '16'},
        {label: '17', value: '17'},
        {label: '18', value: '18'},
        {label: '19', value: '19'},
        {label: '20', value: '20'},
        {label: '21', value: '21'},
        {label: '22', value: '22'},
        {label: '23', value: '23'},
        {label: '24', value: '24'},
        {label: '25', value: '25'},
        {label: '26', value: '26'},
        {label: '27', value: '27'},
        {label: '28', value: '28'},
        {label: '29', value: '29'},
        {label: '30', value: '30'},
        {label: '31', value: '31'},
    ];
    const [monthsval, setmonthsval] = useState();
    const [datessval, setdatesval] = useState();

    return (
      <div className="shop_frame_controller" onLoad={changeState}>
        <ToastContainer hideProgressBar={true} />
        <div className="top_iconbar">
          <span className="top_Left_column setting_screen">
            {faqsstate ? (
              <>
                <img
                  alt="img"
                  src={img}
                  onClick={() => {
                    setfaqsstate(false);
                  }}></img>
                <h4 className="title_medium">Frequently Asked Questions</h4>
              </>
            ) : legal ? (
              <>
                <img
                  alt="img"
                  src={img}
                  onClick={() => {
                    setlegal(false);
                  }}></img>
                <h4 className="title_medium">Privacy Policy</h4>
              </>
            ) : resetpassword ? (
              <>
                <img
                  alt="img"
                  src={img}
                  onClick={() => {
                    setresetpassword(false);
                  }}></img>
                <h4 className="title_medium" id="top__title">
                  Reset Password
                </h4>
              </>
            ) : termofuse ? (
              <>
                <img
                  alt="img"
                  src={img}
                  onClick={() => {
                    settermofuse(false);
                  }}></img>
                <h4 className="title_medium">Terms & Condition</h4>
              </>
            ) : (
              <>
                <img
                  alt="img"
                  src={img}
                  onClick={() => {
                    myContext.setActive("Garage");
                  }}></img>{" "}
                <h4 className="title_medium" id="top__title">
                  Profile
                </h4>
              </>
            )}
          </span>
        </div>
        {faqsstate ? (
          <Faqs />
        ) : legal ? (
          <PrivacyPolicy />
        ) : termofuse ? (
          <TermOfUse />
        ) : resetpassword ? (
          <ResetPassword />
        ) : profilesectionstate ? (
          <div className="Single_vehicles_info_wapper overflow-auto">
            {imguploadloader && (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            )}
            <div className="main-profile_wrapper">
              <div className="profile_wrapper">
                <div className="profile_pic_wrapper">
                  <div className="image_wrap add_icon">
                    <img className="brokenimg" src={profile_image}></img>
                    <input type="file" onChange={onChangeFunction} />
                  </div>
                  <label className="mb-1 mt-1" id="img-name"></label>
                  <div className="flex">
                    <button
                      onClick={uploadImage}
                      id="uploadpibtn"
                      className="btn btn_theme mini">
                      <i className="fa-solid fa-check"></i>
                    </button>
                    <button
                      onClick={cancelImage}
                      id="cancelpibtn"
                      className="btn btn_theme mini ml-1">
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                  <h1>{firstname + " " + lastname}</h1>
                  <div className="details">
                    {/* <div className="field">
                      <p>Email</p>
                      <span>{email}</span>
                    </div>
                    <hr></hr> */}
                    <div className="field">
                      <p>Phone</p>
                      <span>{number}</span>
                    </div>
                    <hr></hr>
                    <div
                      className="field"
                      onClick={() => {
                        setchatsupportmodal(false);
                        $(".Single_vehicles_info_wapper").removeClass("blur");
                        $(".top_iconbar").removeClass("blur");
                        setlegal(true);
                      }}>
                      <p>Privacy Policy</p>
                    </div>
                    <hr></hr>
                    <div
                      className="field"
                      onClick={() => {
                        setchatsupportmodal(false);
                        $(".Single_vehicles_info_wapper").removeClass("blur");
                        $(".top_iconbar").removeClass("blur");
                        settermofuse(true);
                      }}>
                      <p>Term Of Use</p>
                    </div>
                    <hr></hr>
                    <div
                      className="field"
                      onClick={() => {
                        setchatsupportmodal(false);
                        $(".Single_vehicles_info_wapper").removeClass("blur");
                        $(".top_iconbar").removeClass("blur");
                        setfaqsstate(true);
                      }}>
                      <p>FAQs</p>
                    </div>
                    <hr></hr>
                    <div
                      className="field"
                      onClick={() => {
                        $(".top_iconbar").addClass("blur");
                        $(".Single_vehicles_info_wapper").addClass("blur");
                        $(".Left__panel").addClass("blur");
                        setchatsupportmodal(true);
                      }}>
                      <p>Chat Support</p>
                    </div>
                    <hr></hr>
                    <div
                      className="field"
                      onClick={() => {
                        setchatsupportmodal(false);
                        $(".Single_vehicles_info_wapper").removeClass("blur");
                        $(".top_iconbar").removeClass("blur");
                        setresetpassword(true);
                      }}>
                      <p>Reset Password</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_details_wrapper">
                {/* <h4 className="title_medium">{title}</h4> */}
                <div className="form-wrapper">
                  <form>
                    <div className="form-inner-wrap">
                      <div className="form_group w-50">
                        <label>First Name</label>
                        <div className="inputwrapper">
                          <input
                            type="text"
                            placeholder="First Name"
                            value={firstname}
                            onChange={(e) =>
                              // setfirstname(e.target.value.replace(/\s+/g, ""))
                              setfirstname(
                                e.target.value.replace(/[^a-zA-Z]/g, "")
                              )
                            }
                            className="grey-bdr m-0"
                            required
                            maxLength="15"
                          />
                          <label id="vinValid" className="c-red"></label>
                        </div>
                      </div>

                      <div className="form_group w-50">
                        <label>Last Name</label>
                        <div className="inputwrapper">
                          <input
                            type="text"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={(e) =>
                              setlastname(
                                e.target.value.replace(/[^a-zA-Z]/g, "")
                              )
                            }
                            className="grey-bdr m-0"
                            required
                            maxLength="15"
                          />
                          <label id="vinValid" className="c-red"></label>
                        </div>
                      </div>
                    </div>
                    <div className="form-inner-wrap">
                      <div className="form_group w-50">
                        <label>Email</label>
                        <div className="inputwrapper disabledieldprofile">
                          <input
                            type="text"
                            placeholder="example@hudasoft.com"
                            value={email}
                            className="grey-bdr m-0"
                            disabled
                            required
                          />
                          <label id="vinValid" className="c-red"></label>
                        </div>
                      </div>
                      <div className="form_group w-50">
                        <label>Date Of Birth</label>
                        <div className="form-inner-wrap spacebetween">
                          <div className="form_group w-50 ddcustom">
                            <Select
                              options={months}
                              defaultValue={months[dobmonth - 1]}
                              onChange={({ value }) => setmonthsval(value)}
                            />
                          </div>
                          <div className="form_group w-50 ddcustom">
                            <Select
                              options={dates}
                              defaultValue={dates[dobdate - 1]}
                              onChange={({ value }) => setdatesval(value)}
                            />
                          </div>
                        </div>

                        <label id="vinValid" className="c-red"></label>
                      </div>
                    </div>
                    <div className="form_group">
                      {/* <label>
                              Email
                          </label>
                          <div className="inputwrapper">
                              <input type="text" placeholder='example@hudasoft.com' value={email} className='grey-bdr m-0' disabled required/>
                              <label id="vinValid" className='c-red'></label>
                          </div> */}
                    </div>

                    <div className="form_group">
                      {/* <label>
                              Zip Code
                          </label>
                          <div className="inputwrapper">
                              <input type="text" placeholder='Zip Code' value={zipCode} onChange={e => setzipCode(e.target.value)} className='grey-bdr m-0' required/>
                              <label id="vinValid" className='c-red'></label>
                          </div> */}
                      <div className="p-0 mt-1 btn_wrapper justify-start">
                        <button
                          type="button"
                          onClick={updateuserProfile}
                          className="btn_theme">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                  <span
                    className="delacctxt text-danger"
                    onClick={() => {
                      setdelaccpopup(true);
                      $(".Single_vehicles_info_wapper").addClass("blur");
                      $(".Left__panel").addClass("blur");
                      $(".top_iconbar").addClass("blur");
                    }}>
                    Delete Account
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        )}
        {chatsupportmodal && (
          <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
            <div
              className="closedetailsection"
              onClick={() => {
                setchatsupportmodal(false);
                $(".Single_vehicles_info_wapper").removeClass("blur");
                $(".Left__panel").removeClass("blur");
                $(".top_iconbar").removeClass("blur");
              }}>
              <i className="fa-solid fa-xmark"></i>
            </div>

            <div className="right-panel_title detail center">
              <div className="agntimgwrapper">
                <img
                  className="brokenimg"
                  alt="img"
                  src="https://firebasestorage.googleapis.com/v0/b/ibizi-uat.appspot.com/o/CommonImagesUseInProject%2F1024.png?alt=media"
                  id="belliconid"></img>
              </div>
              <div className="agntimgwrapperdetail">
                <div className="dir-col">
                  <h4>IBIZI</h4>
                </div>
                <div className="dir-col">
                  <h3>+1 (832)878-0081</h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {delaccpopup && (
          <div className="carinfo_cards_box_right_panel modalcenter garagemodal active">
            <div
              className="closedetailsection"
              onClick={() => {
                setdelaccpopup(false);
                $(".Single_vehicles_info_wapper").removeClass("blur");
                $(".Left__panel").removeClass("blur");
                $(".top_iconbar").removeClass("blur");
              }}>
              <i className="fa-solid fa-xmark"></i>
            </div>

            <div className="right-panel_title detail center">
              <div className="agntimgwrapperdetail">
                <h4>Are you sure you want to delete your account?</h4>
                <div className="flex justify-center mt-2">
                  <div className="btn_theme mr-1" onClick={delaccount}>
                    Yes
                  </div>
                  <div
                    className="btn_theme light"
                    onClick={() => {
                      setdelaccpopup(false);
                      $(".Single_vehicles_info_wapper").removeClass("blur");
                      $(".Left__panel").removeClass("blur");
                      $(".top_iconbar").removeClass("blur");
                    }}>
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default ProfileSetting;