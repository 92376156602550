import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import AppContext from '../../AppContext';
import loader from '../../images/loader.gif';
import Register from '../Authentication/Register';
import Login from '../Authentication/Login';
import '../../App.css';
import Main from '../../components/main_layout.js';

import Logo from '../../images/IBIZILogo.svg';
import { v4 } from 'uuid';


function DeclineServiceSignup(props) {
  const myContext = useContext(AppContext);
  const [responseData, setResponseData] = useState(null);
  const [checked, setChecked] = useState(false); 
  const [isDecline, setisDecline] = useState(props.isDecline);
  const [signupForm, setsignupForm] = useState(false); 
  const [checkBoxModelObject, setcheckBoxModelObject] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsPrice, setSelectedItemsPrice] = useState([]);
  var objeCt = [];

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(props.baseUrl+'DeclinedUser/DeclinedUserCarsServices?CarId='+props.parameter);
      const data = await response.json();
      setResponseData(data);
    }
    fetchData();
  }, [props.parameter]);

  if (!responseData) {
    return <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>;
  }


  const handleChange = (e) => {
    
    if(e.target.classList.contains('active')) {
        e.target.classList.remove("active");
    } else {
        e.target.classList.add("active");
    }
    const { name, checked } = e.target;
    let pricevalue = e.target.getAttribute('priceval')
    if (checked) {
      if (!selectedItems.includes(name)) {
        setSelectedItems([...selectedItems, name]); // add the name to the selectedItems array if it doesn't already exist
        setSelectedItemsPrice([...selectedItemsPrice, pricevalue]); // add the name to the selectedItems array if it doesn't already exist
      }
      // if (!selectedItemsPrice.includes(pricevalue)) {
      // }
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== name)); // remove the name from the selectedItems array
      setSelectedItemsPrice(selectedItemsPrice.filter((item) => item !== pricevalue)); // remove the name from the selectedItems array
    }

    e.preventDefault();  
}



  const ScheduleServices = () => {
    
    var val = {};
    var objeCt = [];
    for (let i = 0; i < selectedItems.length; i++) { 
        val = {   
            "CartItemType": 0,
            "ItemId": parseInt(selectedItems[i]),
            "isBeneift": false,
            "Price" : parseFloat(selectedItemsPrice[i])
        } 
        objeCt.push(val);  
    } 
    functionReadtToAddToCart(objeCt);
    
    
  }

  function functionReadtToAddToCart(model) {

    let CarID = responseData.declinedUserDataModel.carId;
    let DealershipID = responseData.declinedUserDataModel.dealershipId;
    let CartItemModels = model;
    let PromoCode = "";
    let Notes = "";
    
    let data = {
        "DealerShipId": DealershipID,
        "CarId": parseInt(CarID),
        "CartItemModels" : CartItemModels,
        "PromoCode": PromoCode,
        "Notes": Notes
    }
    myContext.setdeclineAddtoModel(data); 
    return data;
  }

  return (
    <>
      {signupForm ? (
        isDecline === true ? (
          <div className="root_support_element">
            <div className="login_frame_controller flex-center-center">
              <span
                className="back-to-login"
                onClick={() => {
                  setsignupForm(false);
                }}>
                Back
              </span>
              <Register
                baseUrl={props.baseUrl}
                registrationfrom="declinedflow"
              />
            </div>
          </div>
        ) : localStorage.getItem("token") !== null ? (
          <Login loginFrom="declinedflowbutalreadyLoggedIn" />
        ) : (
          <Login loginFrom="declinedflow" />
        )
      ) : (
        <>
          <div className="navbar-decline">
            <img alt="img" className="brokenimg" src={Logo} id="Logoid"></img>
          </div>
          <div className="main__layout__controller">
            {/* Left Panel */}
            {/* <div className="Left__panel">
            <img alt='img' className='brokenimg' src={Logo} id='Logoid'></img>
          </div> */}

            {/* Right Panel */}
            <div className="Right__panel declined-contain">
              <div className="top_iconbar center">
                <span className="top_Left_column m-auto">
                  <h4 className="title_medium" id="top__title">
                    Decline
                  </h4>
                </span>
                {/* <TopNav/> */}
              </div>

              <div className="declinedScreenWrapper">
                <div className="flex-space-between-center w-100 firsttr">
                  <div className="">
                    {
                      // <h3 className='navy-blue'>{responseData.declinedUserDataModel.carName}</h3>
                      <span className="text-black">
                        <strong className="navy-blue">
                          {responseData.declinedUserDataModel.dealershipName}{" "}
                        </strong>
                        <strong></strong>
                      </span>
                    }
                  </div>
                </div>
                <div className='diclinescreenwrappper2'>
                  {responseData.declinedUserDataModel.declineServices.map(
                    (service) => (
                      <div
                        key={service.dealershipServiceId + v4}
                        className="checkboxes">
                        <div className="flex-space-between-center">
                          <label className="labelcon">
                            <input
                              name={service.dealershipServiceId}
                              type="checkbox"
                              aria-checked="false"
                              // checked={selectedItems.includes(service.dealershipServiceId)}
                              onChange={handleChange}
                              priceval={service.discountedPrice}
                            />
                            <div className="table_image_wrapper mini">
                              <img
                                className="brokenimg"
                                alt="img"
                                src={service.serviceImage}
                                id="belliconid"></img>
                            </div>
                            <span className="inputname">
                              {service.dealershipServiceName}
                            </span>
                            <div className="flex dir-col pricetarget">
                              <div className="flex dir-col">
                                <span
                                  className=""
                                  id="priceId"
                                  data={service.discountedPrice}>
                                  {
                                    <>
                                      <del>
                                        <strong>
                                          {" "}
                                          ${service.orignalPrice}
                                        </strong>
                                      </del>
                                      <strong className="sepra">
                                        ${service.discountedPrice}{" "}
                                      </strong>
                                    </>
                                  }
                                </span>
                              </div>
                            </div>

                            <span className="check-bullet"></span>
                          </label>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {(() => {
                  let ele;
                  if (selectedItems.length != 0) {
                    // ele = <div className='btn_theme fullwidth'  onClick={this.AddToCart} serviceid={this.state.detailobject.id}>Schedule Service</div>
                    // ele = <div className='btn_theme fullwidth' onClick={() => {setsignupForm(true); ScheduleServices();}}>Schedule Service</div>
                    ele = (
                      <div
                        className="btn_theme fullwidth"
                        onClick={() => {
                          setsignupForm(true);
                          ScheduleServices();
                        }}>
                        Add to Cart
                      </div>
                    );
                  } else {
                    ele = "";
                  }
                  return ele;
                })()}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}



export default DeclineServiceSignup;
