import React, {useState} from 'react';
import './Shop.css';
import TopNav from '../Snippets/top_nav_items';
import { useContext } from 'react';
import AppContext from '../../AppContext';
import SingleVehicleDetail from '../Shopcomponents/SingleVehiclesDetail';
import FavoriteNewVehicles from '../Favoritescomponents/FavoritesNewVehicles';
import FavoriteUsedVehicles from '../Favoritescomponents/FavoritesUsedVehicles';
import FavoriteServices from '../Favoritescomponents/FavoritesServices';
import FavoriteProtectionPlan from '../Favoritescomponents/FavoritesProtectionPlan';

      


const Favorites = ({title}) => {
    const myContext = useContext(AppContext);
    
    const [shopnewvehicle, setshopnewvehicle] = useState(true);
    const [shopusedvehicle, setshopusedvehicle] = useState(false);
    const [shopservices, setshopservices] = useState(false);
    const [shopprotectionplan, setshopprotectionplan] = useState(false);
    

    function tabClicknewvehicle(){
        setshopnewvehicle(true);
        setshopusedvehicle(false);
        setshopservices(false);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickusedvehicle(){
        setshopnewvehicle(false);
        setshopusedvehicle(true);
        setshopservices(false);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickservices(){
        setshopnewvehicle(false);
        setshopusedvehicle(false);
        setshopservices(true);
        setshopprotectionplan(false);
        myContext.setshopcardetailsectionisopen("");
    }
    function tabClickprotectionplan(){
        setshopnewvehicle(false);
        setshopusedvehicle(false);
        setshopservices(false);
        setshopprotectionplan(true);
        myContext.setshopcardetailsectionisopen("");
    }

    function back() {
        myContext.setshopcardetailsectionisopen("");
    }
    
    return (
      <>
        <div className="shop_frame_controller">
          <div className="top_iconbar">
            <span className="top_Left_column">
              {myContext.shopcardetailsectionisopen !== "" && (
                <span className="backbutton" onClick={back}>
                  <i className="fa-solid fa-chevron-left"></i>
                </span>
              )}
              {/* <h4 className="title_medium">{title}</h4> */}
              <h4
                className={`title_medium ${
                  myContext.singlecardetailsectionisopen ? "spacetext" : ""
                }${
                  myContext.singlecardetailsectionisopen &&
                  myContext.shopcardetailsectionisopen !== ""
                    ? "spacetext0"
                    : ""
                }`}>
                {title}
              </h4>
            </span>
            {myContext.singlecardetailsectionisopen === false && <TopNav />}
          </div>
          {myContext.singlecardetailsectionisopen && <SingleVehicleDetail />}
          <div
            className={`special_tabs_wrapper  ${
              myContext.singlecardetailsectionisopen ? "d-none" : ""
            }`}>
            <div className="round_tabs widthauto">
              <span
                className={`innertab__wrapper_items  ${
                  shopnewvehicle ? "active" : ""
                }`}
                onClick={tabClicknewvehicle}>
                New Vehicles
              </span>
              <span
                className={`innertab__wrapper_items  ${
                  shopusedvehicle ? "active" : ""
                }`}
                onClick={tabClickusedvehicle}>
                Used Vehicles
              </span>
              <span
                className={`innertab__wrapper_items  ${
                  shopservices ? "active" : ""
                }`}
                onClick={tabClickservices}>
                Services
              </span>
              <span
                className={`innertab__wrapper_items   ${
                  shopprotectionplan ? "active" : ""
                }`}
                onClick={tabClickprotectionplan}>
                Protection Plans
              </span>
            </div>
            <div className="shop_content_wrapper">
              {shopnewvehicle && <FavoriteNewVehicles />}
              {shopusedvehicle && <FavoriteUsedVehicles />}
              {shopservices && <FavoriteServices />}
              {shopprotectionplan && <FavoriteProtectionPlan />}
            </div>
          </div>
        </div>
      </>
    );
};
export default Favorites;