import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="legal_wrapper flwidth">
        {/* <h4 className="title_large">Privacy Policies</h4> */}
        <div className="iframe_wrapper">
          <iframe
            src="https://dealership.ibizi.com/DealerArea/DealerAdmin/Privacy"
            title="Iframe Example"></iframe>
        </div>
      </div>
    );
  }
}
