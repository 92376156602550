import React, { useState, useEffect, useRef } from 'react';
import { useContext } from 'react';
import Select from 'react-select';
import AppContext from '../../AppContext';
import filtericon from '../../images/filtericon.png';
import loader from '../../images/loader.gif';
import favIcon from '../../images/heartico_colored.png';
import UnfavIcon from '../../images/hearticonborder.png';
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios';
import $ from 'jquery'
import 'react-toastify/dist/ReactToastify.css';


// import MultiRangeSlider from "./component/multiRangeSlider/MultiRangeSlider";
import MultiRangeSlider from '../multiRangeSlider/MultiRangeSlider';
import { click } from '@testing-library/user-event/dist/click';





async function markAsFavorite(itemId, contextData) {
  let end_point = "Favorite/MarkAsFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Mark As Favorite");
    })
    .catch((error) => console.log(error));
}

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}




const ShopViewMore = ({title, totalCount, CarShopType, from}) => {

    let newstate;
    let usedstate;
    if (from === 'new') {
        newstate = true;
        usedstate = false;
    } else {
        newstate = false;
        usedstate = true;
    }

    const myContext = useContext(AppContext);
    const [searchpanelopen, setsearchpanelopen] = useState(false);
    const [favoriteloaderstate, setfavoriteloaderstate] = useState(false);

    const [pageNumberState, setpageNumberState] = React.useState(1);
    const [FirstlistLimitState, setFirstlistLimitState] = React.useState(50);
    const [viewmorestate, setviewmorestate] = useState(false);



    let base_url = myContext.Base_url;
    let endpoint = 'Shop/ShopVehicleFilterSearch'
    let api_token = localStorage.getItem('token'); 



    const favoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };
      const response = await markAsFavorite(itemId, contextData);
      if (!response) {
        setIsLoading(false);
        myContext.setfavoriteitemloader(false);
      }
    };

    const unfavoriteMe = async (itemId) => {
      let contextData = {
        baseUrl: base_url,
        token: api_token,
      };

      const response = await removeFromFavorite(itemId, contextData);
      if (!response) {
        setIsLoading(false);
        myContext.setfavoriteitemloader(false);
      }
    };







    // API URL For New Vehicles & Used Vehicles All Data
    let apiurlNewUsed = base_url+endpoint+'?'+
    'New'+'='+newstate+'&'+
    'Used'+'='+usedstate+'&'+
    'CarShopType'+'='+CarShopType+'&'+
    'Year'+'='+'&'+
    'Make'+'='+'&'+
    'Model'+'='+'&'+
    'LowPrice'+'='+0+'&'+
    'HighPrice'+'='+0+'&'+
    'MonthlyLowPrice'+'='+0+'&'+
    'MonthlyHighPrice'+'='+0+'&'+
    'SearchString'+'='+''+'&'+
    'PageIndex'+'='+pageNumberState+'&'+
    'PageSize'+'='+FirstlistLimitState; 
    
    // Check API Loading Status
    const [isLoading, setIsLoading] = React.useState(true);
    const [pagingLoader, setPagingLoader] = React.useState(false);
    
    // Set API Response into model
    const [isdataloded, setisdataloded] = React.useState(false);
    
    // Set API Model
    const [data, setData] = React.useState([]);

    const [filterAPIStatus, setfilterAPIStatus] = React.useState(false);


    // Set Data For New Vehicles View More

    // Membership Discounts on New Cars View More
    const [dataformember, setdataformember] = React.useState([]);
    const [dataformemberlenght, setdataformemberlenght] = React.useState(1);
    const [dataformemberlenghtforfilter, setdataformemberlenghtforfilter] = React.useState(1);
    const [dataformemberlenghtforSearchfilter, setdataformemberlenghtforSearchfilter] = React.useState(1);

    // New Special View More
    const [datafornewspecialviewmore, setdatafornewspecialviewmore] = React.useState([]);
    const [datafornewspecialviewmorelenght, setdatafornewspecialviewmorelenght] = React.useState(1);
    const [datafornewspecialviewmorelenghtforfilter, setdatafornewspecialviewmorelenghtforfilter] = React.useState(1);
    const [datafornewspecialviewmorelenghtforSearchfilter, setdatafornewspecialviewmorelenghtforSearchfilter] = React.useState(1);

    // New Lease Special View More
    const [dataforleasspecialviewmore, setdataforleasspecialviewmore] = React.useState([]);
    const [dataforleasspecialviewmorelenght, setdataforleasspecialviewmorelenght] = React.useState(1);
    const [dataforleasspecialviewmorelenghtforfilter, setdataforleasspecialviewmorelenghtforfilter] = React.useState(1);
    const [dataforleasspecialviewmorelenghtforSearchfilter, setdataforleasspecialviewmorelenghtforSearchfilter] = React.useState(1);

    //  New All View More
    const [datafornewallviewmore, setdatafornewallviewmore] = React.useState([]);
    const [datafornewallviewmorelenght, setdatafornewallviewmorelenght] = React.useState(1);
    const [datafornewallviewmorelenghtforfilter, setdatafornewallviewmorelenghtforfilter] = React.useState(1);
    const [datafornewallviewmorelenghtforSearchfilter, setdatafornewallviewmorelenghtforSearchfilter] = React.useState(1);

    // Filter Result Screen IsOpen
    const [filterscreenisopen, setfilterscreenisopen] = React.useState(false); 



    // Set Data For Used Vehicles View More

    // Membership Discounts on Used Cars View More
    const [dataforusedmember, setdataforusedmember] = React.useState([]);
    const [dataforusedmemberlenght, setdataforusedmemberlenght] = React.useState(1);
    const [dataforusedmemberlenghtforfilter, setdataforusedmemberlenghtforfilter] = React.useState(1);
    const [dataforusedmemberlenghtforSearchfilter, setdataforusedmemberlenghtforSearchfilter] = React.useState(1);


    // Used Special View More
    const [dataforusedspecialviewmore, setdataforusedspecialviewmore] = React.useState([]);
    const [dataforusedspecialviewmorelenght, setdataforusedspecialviewmorelenght] = React.useState(1);
    const [dataforusedspecialviewmorelenghtforfilter, setdataforusedspecialviewmorelenghtforfilter] = React.useState(1);
    const [dataforusedspecialviewmorelenghtforSearchfilter, setdataforusedspecialviewmorelenghtforSearchfilter] = React.useState(1);


    //  Used All View More
    const [dataforusedallviewmore, setdataforusedallviewmore] = React.useState([]);
    const [dataforusedallviewmorelenght, setdataforusedallviewmorelenght] = React.useState(1);
    const [dataforusedallviewmorelenghtforfilter, setdataforusedallviewmorelenghtforfilter] = React.useState(1);
    const [dataforusedallviewmorelenghtforSearchfilter, setdataforusedallviewmorelenghtforSearchfilter] = React.useState(1);




    // Year
    const [dataforyear, setdataforyear] = React.useState([]);
    // Make
    const [dataformake, setdataformake] = React.useState([]);
    // Model
    const [dataformodel, setdataformodel] = React.useState([]);



    const [resultapplyfilter, setresultapplyfilter] = React.useState([]);


    const [readytomapindd, setreadytomapindd] = React.useState(false);
    const [dataformakeyearmodel, setdataformakeyearmodel] = React.useState([]);
    
    const yearDD = [];
    const makeDD = [];
    const modelDD = [];

    let endpointMakeYearModel = 'Shop/GetMakeModelYearMapping'
    // API URL For New Vehicles & Used Vehicles All Data
    let apiurlMakeYearModel = base_url+endpointMakeYearModel+'?'+'Make'+'='+'&'+'Model'+'='+'&'+'Year'+'=';

    const sectionRef = useRef(null);

    
    function callAPIForShop() {
        let api_url = apiurlNewUsed;
        const url = api_url;
        axios(
            {
            method: 'get',
            url: url,
            headers: {'Authorization': api_token}
            }
        )
        .then(res => {
           
            setData(res.data.getAllCategorizedList);
        })
        .catch((error) => {
          console.log(error)
        });

    } 

    // Create our number formatter.
    var  formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });


    const getMakeYearModel = () => {


        let api_url2 = apiurlMakeYearModel;
        const url2 = api_url2;
        axios(
            {
            method: 'get',
            url: url2,
            headers: {'Authorization': api_token}
            }
        )
        .then(res => {
            setdataformakeyearmodel(res.data.carBasicModel);
            setreadytomapindd(true);
        })
        .catch((error) => console.log(error))



        if (dataformakeyearmodel.length !== 0) {
            let lengyear = dataformakeyearmodel.year.length;
            let lengmake = dataformakeyearmodel.make.length;
            let lengmodel = dataformakeyearmodel.model.length;

            for (var i = 0; i < dataformakeyearmodel.year.length; i++) {
                yearDD.push({label: dataformakeyearmodel.year[i], value: dataformakeyearmodel.year[i]});
                if (i === lengyear - 1) {
                    // setreadytomapindd(true);
                //     setTimeout(() => {
                //     this.setState({additemapiisloaded:false})
                // }, 2000);
                }
                setreadytomapindd(true);
            }

            for (var i = 0; i < dataformakeyearmodel.make.length; i++) {
                makeDD.push({label: dataformakeyearmodel.make[i], value: dataformakeyearmodel.make[i]});
                if (i === lengmake - 1) {
                    // setreadytomapindd(true);
                }
                setreadytomapindd(true);
            }

            for (var i = 0; i < dataformakeyearmodel.model.length; i++) {
                modelDD.push({label: dataformakeyearmodel.model[i], value: dataformakeyearmodel.model[i]});
                if (i === lengmodel - 1) {
                    // setreadytomapindd(true);
                }
                
            }

            setdataforyear(yearDD);
            setdataformake(makeDD);
            setdataformodel(modelDD);
            setreadytomapindd(true);

            
        } 

        
    }

    
    

    const [currentPage, setCurrentPage] = useState(1);
    const [inputvalue, setinputvalue] = useState("");
    
    const [favloader, setfavloader] = useState(false);
    const [activeSpan, setActiveSpan] = useState(null);

    

    const fetchData = async (page) => {
      try {
        const response = await axios.get(base_url+endpoint+'?',
          {
            params: {
              New: newstate,
              Used: usedstate,
              CarShopType: CarShopType,
              Year: "",
              Make: "",
              Model: "",
              LowPrice: 0,
              HighPrice: 0,
              MonthlyLowPrice: 0,
              MonthlyHighPrice: 0,
              SearchString: searchvalue,
              PageIndex: page,
              PageSize: 32,
            },
            headers: { Authorization: api_token },
          }
        );
        return response.data; // Assuming your API response is an object with a "data" property that holds the array of items.
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };



    const fetchPageData = async () => {
      const pageData = await fetchData(currentPage);
      if (pageData.status) {
        
        function offLoader() {
            setIsLoading(false);
        }
        setTimeout(offLoader, 1000);
      } else {
        
        function offLoader() {
            setIsLoading(false);
        }
        setTimeout(offLoader, 1000);
      }
      setData(pageData.getAllCategorizedList);
    };
  


    if (myContext.favoriteitemloader) {
      fetchPageData();
    }
    React.useEffect(() => {
      
      if (api_token !== undefined && api_token !== null && api_token !== "") {
        setIsLoading(true);
        fetchPageData();
        filterscreenisopen && applyFilterFunc();
        searchDataIsloaded && fetchSearchPageData(parseInt(CarShopType), inputvalue);
      }
    }, [currentPage]);




    const handleLoadMore = () => {
      setCurrentPage((prevPage) => prevPage + 1);
      setIsLoading(true);
    };

    // const handleSpanClick = (event) => {
    //   // Get the clicked <span> element
      
    //   debugger
    // }



    React.useEffect(() => {
      if (api_token !== undefined && api_token !== null && api_token !== "") {
        if (data.length !== 0) {
          if (viewmorestate) {
            
            // setIsLoading(false);
            // function offLoader() {
            //   setIsLoading(false);
            // }
            // setTimeout(offLoader, 5000);
            setisdataloded(true);
            // setisdataloded(true);
            setdataformember((prevArray) => [
              ...prevArray,
              ...data.newCars.shopModelNewMembership,
            ]);
            // setdataformember([...data.newCars.shopModelNewMembership, ...data.newCars.shopModelNewMembership]);
            // if (!data.newCars.shopModelNewMembership.includes(data.newCars.shopModelNewMembership.id)) {
            // } else {
            //     setdataformember(data.newCars.shopModelNewMembership.id.filter((item) => item !== data.newCars.shopModelNewMembership)); // remove the name from the selectedItems array
            // }
          } else {
            // setIsLoading(false);
            setisdataloded(true);

            // Set Data For New Vehicles View More
            setdataformember(data.newCars.shopModelNewMembership);
            setdataformemberlenght(data.newCars.shopModelNewMembershipCount);

            setdatafornewspecialviewmore(data.newCars.shopModelNewSpecial);
            setdatafornewspecialviewmorelenght(data.newCars.shopModelNewSpecialCount);

            setdataforleasspecialviewmore(data.newCars.shopModelNewLeaseSpecial);
            setdataforleasspecialviewmorelenght(data.newCars.shopModelNewLeaseSpecialCount);


            setdatafornewallviewmore(data.newCars.shopModelNewAll);
            setdatafornewallviewmorelenght(data.newCars.shopModelNewAllCount);
            



            // Set Data For Used Vehicles View More
            setdataforusedmember(data.usedCars.shopModelUsedMembership);
            setdataforusedmemberlenght(data.usedCars.shopModelUsedMembershipCount);

            setdataforusedspecialviewmore(data.usedCars.shopModelUsedSpecial);
            setdataforusedspecialviewmorelenght(data.usedCars.shopModelUsedSpecialCount);

            setdataforusedallviewmore(data.usedCars.shopModelUsedAll);
            setdataforusedallviewmorelenght(data.usedCars.shopModelUsedAllCount);

            getMakeYearModel();


    // const [dataforleasspecialviewmorelenght, setdataforleasspecialviewmorelenght] = React.useState(1);
            
          }
        }
      }
    }, [data, dataformakeyearmodel, currentPage]);


  
    
    function togglefilter() { 
        
        if (searchpanelopen) { 

            setsearchpanelopen(false);
            setreadytomapindd(true);

        } else {

            setsearchpanelopen(true);
            setreadytomapindd(true);

            getMakeYearModel();
            // Set New used Status here
        }
    }
    

    const [filterTypeNew, setfilterTypeNew] = React.useState();
    const [filterTypeUsed, setfilterTypeUsed] = React.useState();
    const [yearDDResult, setyearDDResult] = React.useState();
    const [makeDDResult, setmakeDDResult] = React.useState();
    const [modelDDResult, setmodelDDResult] = React.useState();
    const [byPriceMinValue, setbyPriceMinValue] = React.useState();
    const [byPriceMaxValue, setbyPriceMaxValue] = React.useState();
    const [byMonthInstallmentMinValue, setbyMonthInstallmentMinValue] = React.useState();
    const [byMonthInstallmentMaxValue, setbyMonthInstallmentMaxValue] = React.useState(); 


    const handleChangeNew = (e) => {
        let isChecked = e.target.checked; 
        setfilterTypeNew(isChecked);
    }
    const handleChangeUsed = (e) => {
        let isChecked = e.target.checked;
        setfilterTypeUsed(isChecked);
    }




    const filterAPI = (params) => {

        let byMonthInstallmentMaxValue;
        byMonthInstallmentMaxValue = params[0].byMonthInstallmentMaxValue;
        let byMonthInstallmentMinValue;
        byMonthInstallmentMinValue = params[0].byMonthInstallmentMinValue;
        let byPriceMaxValue;
        byPriceMaxValue = params[0].byPriceMaxValue;
        let byPriceMinValue;
        byPriceMinValue = params[0].byPriceMinValue;

        let filterTypeNew; 
        if(!params[0].filterTypeNew) {
            filterTypeNew = false;
        } else {
            filterTypeNew = params[0].filterTypeNew;
        }

        let filterTypeUsed;
        if(!params[0].filterTypeUsed) {
            filterTypeUsed = false;
        } else {
            filterTypeUsed = params[0].filterTypeUsed;
        }

        let makeDDResult;
        if(params[0].makeDDResult === undefined || params[0].makeDDResult === '') {
            makeDDResult = '';
        } else {
            makeDDResult = params[0].makeDDResult;
        }

        
        let modelDDResult;
        if(params[0].modelDDResult === undefined || params[0].modelDDResult === '') {
            modelDDResult = '';
        } else {
            modelDDResult = params[0].modelDDResult;
        }



        let yearDDResult;
        if(params[0].yearDDResult === undefined || params[0].yearDDResult === '') {
            yearDDResult = '';
        } else {
            yearDDResult = params[0].yearDDResult;
        }

        

        let endpointfilterApplying = 'Shop/ShopVehicleFilterSearch'
        // API URL For New Vehicles & Used Vehicles All Data
        let apiurlApplyFilter =
          base_url +
          endpointfilterApplying +
          "?" +
          "New" +
          "=" +
          filterTypeNew +
          "&" +
          "Used" +
          "=" +
          filterTypeUsed +
          "&" +
          "CarShopType" +
          "=" +
          CarShopType +
          "&" +
          "Year" +
          "=" +
          yearDDResult +
          "&" +
          "Make" +
          "=" +
          makeDDResult +
          "&" +
          "Model" +
          "=" +
          modelDDResult +
          "&" +
          "LowPrice" +
          "=" +
          byPriceMinValue +
          "&" +
          "HighPrice" +
          "=" +
          byPriceMaxValue +
          "&" +
          "MonthlyLowPrice" +
          "=" +
          byMonthInstallmentMinValue +
          "&" +
          "MonthlyHighPrice" +
          "=" +
          byMonthInstallmentMaxValue +
          "&" +
          "SearchString" +
          "=" +
          "" +
          "&" +
          "PageIndex" +
          "=" +
          currentPage +
          "&" +
          "PageSize" +
          "=" +
          32;
        let api_url = apiurlApplyFilter;

        const url = api_url;
        axios(
            {
            method: 'get',
            url: url,
            headers: {'Authorization': api_token}
            }
        )
        .then(res => {
            if(CarShopType == 1){
                setresultapplyfilter(res.data.getAllCategorizedList.newCars.shopModelNewMembership);
                setdataformemberlenghtforfilter(res.data.getAllCategorizedList.newCars.shopModelNewMembershipCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 2) {
                setresultapplyfilter(res.data.getAllCategorizedList.newCars.shopModelNewSpecial);
                setdatafornewspecialviewmorelenghtforfilter(res.data.getAllCategorizedList.newCars.shopModelNewSpecialCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 3) {
                setresultapplyfilter(res.data.getAllCategorizedList.newCars.shopModelNewLeaseSpecial);
                setdataforleasspecialviewmorelenghtforfilter(res.data.getAllCategorizedList.newCars.shopModelNewLeaseSpecialCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 4) {
                setresultapplyfilter(res.data.getAllCategorizedList.newCars.shopModelNewAll);
                setdatafornewallviewmorelenghtforfilter(res.data.getAllCategorizedList.newCars.shopModelNewAllCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 5) {
                setresultapplyfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedMembership);
                setdataforusedmemberlenghtforfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedMembershipCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 6) {
                setresultapplyfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedSpecial);
                setdataforusedspecialviewmorelenghtforfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedSpecialCount);
                setfilterAPIStatus(true);
            } else if (CarShopType == 8) {
                setresultapplyfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedAll);
                setdataforusedallviewmorelenghtforfilter(res.data.getAllCategorizedList.usedCars.shopModelUsedAllCount);
                setfilterAPIStatus(true);
            }
            
        })
        .catch((error) => console.log(error)) 

        
    }
     
    

    const applyFilterFunc = () => {
        
        let newveh;
        let usedveh;
        if (title === 'NewVehicleNewCar' || title === 'NewVehicleNewSpecial' || title === 'NewVehicleLeaseSpecial' || title === 'NewVehicleNewAll') {
            newveh = true;
            usedveh = false;
        } else {
            newveh = false;
            usedveh = true;
        }
        
        const params = [
            {
              filterTypeNew: newveh,
              filterTypeUsed:  usedveh,
              yearDDResult: yearDDResult,
              makeDDResult: makeDDResult,
              modelDDResult: modelDDResult,
              byPriceMinValue: byPriceMinValue,
              byPriceMaxValue: byPriceMaxValue,
              byMonthInstallmentMinValue: byMonthInstallmentMinValue,
              byMonthInstallmentMaxValue: byMonthInstallmentMaxValue
            }       
        ]
        filterAPI(params);
        setfilterscreenisopen(true);
        setfilterAPIStatus(false)
    }


    function ClearFunc() {
      // setsearchpanelopen(false);
      setreadytomapindd(false);
      setfilterscreenisopen(false);
      setIsLoading(false);
      callAPIForShop();
    }


    const [searchDataIsloaded, setsearchDataIsloaded] = React.useState(false);
    const [searchvalue, setsearchvalue] = React.useState('');
    const [searchDataIsSet, setsearchDataIsSet] = React.useState([]);
    const [searchDataPanelIsopen, setsearchDataPanelIsopen] = React.useState(false);

    var minlength = 1; 

    const fetchSearchData = async (page, CarShopTypeNumber, value) => {
      try {
        const response = await axios.get(base_url + endpoint + "?", {
          params: {
            New: newstate,
            Used: usedstate,
            CarShopType: CarShopTypeNumber,
            Year: null,
            Make: null,
            Model: null,
            LowPrice: 0,
            HighPrice: 0,
            MonthlyLowPrice: 0,
            MonthlyHighPrice: 0,
            SearchString: value,
            PageIndex: currentPage,
            PageSize: 32,
          },
          headers: { Authorization: api_token },
        });
        return response.data; // Assuming your API response is an object with a "data" property that holds the array of items.
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const fetchSearchPageData = async (CarShopTypeNumber, value) => {
      const pageData = await fetchSearchData(currentPage, CarShopTypeNumber, value);
      if (CarShopType == 1) {
        setsearchDataIsSet(pageData.getAllCategorizedList.newCars.shopModelNewMembership);
        setdataformemberlenghtforSearchfilter(pageData.getAllCategorizedList.newCars.shopModelNewMembershipCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 2) {
        setsearchDataIsSet(pageData.getAllCategorizedList.newCars.shopModelNewSpecial);
        setdatafornewspecialviewmorelenghtforSearchfilter(pageData.getAllCategorizedList.newCars.shopModelNewSpecialCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 3) {
        setsearchDataIsSet(pageData.getAllCategorizedList.newCars.shopModelNewLeaseSpecial);
        setdataforleasspecialviewmorelenghtforSearchfilter(pageData.getAllCategorizedList.newCars.shopModelNewLeaseSpecialCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 4) {
        setsearchDataIsSet(pageData.getAllCategorizedList.newCars.shopModelNewAll);
        setdatafornewallviewmorelenghtforSearchfilter(pageData.getAllCategorizedList.newCars.shopModelNewAllCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 5) {
        setsearchDataIsSet(pageData.getAllCategorizedList.usedCars.shopModelUsedMembership);
        setdataforusedmemberlenghtforSearchfilter(pageData.getAllCategorizedList.usedCars.shopModelUsedMembershipCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 6) {
        setsearchDataIsSet(pageData.getAllCategorizedList.usedCars.shopModelUsedSpecial);
        setdataforusedspecialviewmorelenghtforSearchfilter(pageData.getAllCategorizedList.usedCars.shopModelUsedSpecialCount);
        setsearchDataIsloaded(true);
      } else if (CarShopType == 8) {
        setsearchDataIsSet(pageData.getAllCategorizedList.usedCars.shopModelUsedAll);
        setdataforusedallviewmorelenghtforSearchfilter(pageData.getAllCategorizedList.usedCars.shopModelUsedAllCount);
        setsearchDataIsloaded(true);
      }
      
      
    };

    React.useEffect(() => {
      $(document).keydown(function (e) {
        var value = $(".input_field").val();
        if (value.length == 0) {
          setsearchDataPanelIsopen(false);
          setsearchvalue("");
          setinputvalue("");
          setCurrentPage(1);
          setsearchDataIsloaded(false);
        }
        if (e.key === "Enter") {
          if (value.length == 0 || value.length == "") {
            setsearchDataPanelIsopen(false);
            setsearchvalue("");
            setinputvalue("");
            setsearchDataIsloaded(false);
            setCurrentPage(1);
          } else {
            setsearchvalue(value);
            setinputvalue(value);
            setsearchDataPanelIsopen(true);
            setsearchDataIsloaded(false);
          }

          if (value.length >= minlength) {
            if (CarShopType === CarShopType) {
              let CarShopTypeNumber = parseInt(CarShopType);
              setinputvalue(value)
              fetchSearchPageData(CarShopTypeNumber, value);
            }
          } else {
          }
        }
      });
    }, []);


    

    return (
      <div className="view_more_screen_frame_controller">
        <ToastContainer hideProgressBar={true} />

        <div className="searchbar_wrapper">
          <div className="search_field_group">
            <input className="input_field" placeholder="Search" type="search" />
            {/* <i className="fa-solid fa-magnifying-glass"></i> */}
          </div>
          <span
            className="filter"
            onClick={() => {
              togglefilter();
            }}>
            <img alt="img" src={filtericon}></img>
          </span>
        </div>
        <div className="flex" ref={sectionRef} id="renewitems">
          {
            <div
              className={`filter_column ${
                searchpanelopen === true ? "" : "hidefilter"
              }`}>
              <h4 className="title_large">Filter</h4>
              <strong className="normal__text">By Year</strong>
              <div className="custom_select">
                {readytomapindd ? (
                  <Select
                    id='filterYearDropDown'
                    options={dataforyear}
                    onChange={({ value }) => setyearDDResult(value)}
                    value={yearDDResult}
                  />
                ) : (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                )}
              </div>
              <strong className="normal__text">By Make</strong>
              <div className="custom_select">
                {readytomapindd ? (
                  <Select
                    options={dataformake}
                    onChange={({ value }) => setmakeDDResult(value)}
                  />
                ) : (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                )}
              </div>
              <strong className="normal__text">By Model</strong>
              <div className="custom_select">
                {readytomapindd ? (
                  <Select
                    options={dataformodel}
                    onChange={({ value }) => setmodelDDResult(value)}
                  />
                ) : (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                )}
              </div>
              <strong className="normal__text">By Price</strong>

              {readytomapindd ? (
                <MultiRangeSlider
                  min={0}
                  max={100000}
                  onChange={({ min, max }) => (
                    setbyPriceMinValue(min), setbyPriceMaxValue(max)
                  )}
                />
              ) : (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              )}
              <br></br>
              <br></br>
              <strong className="normal__text">By Monthly Installments</strong>
              {readytomapindd ? (
                <MultiRangeSlider
                  min={0}
                  max={100000}
                  onChange={({ min, max }) => (
                    setbyMonthInstallmentMinValue(min),
                    setbyMonthInstallmentMaxValue(max)
                  )}
                />
              ) : (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              )}

              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <div className="flex wrap">
                <div className="btn_theme fullwidth" onClick={applyFilterFunc}>
                  Apply Filter
                </div>
                <div
                  className="btn_theme light fullwidth"
                  onClick={() => {
                    ClearFunc();
                  }}>
                  Reset
                </div>
              </div>
            </div>
          }
          {filterscreenisopen && (
            <div className="view_more_items-wrapper">
              {filterAPIStatus === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : (
                <>
                  {resultapplyfilter.length == 0 ? (
                    <div className="emptywndow emptywndowfav">
                      <h3 className="mb-0">No Vehicle Found</h3>
                    </div>
                  ) : (
                    <>
                      {resultapplyfilter.map((user, i) => (
                        <div className="relative" key={i}>
                          <div className="heart_icon">
                            {(() => {
                              switch (myContext.favoriteitemloader) {
                                case user.id:
                                  return (
                                    <div className="loader-div">
                                      <img
                                        alt="img"
                                        src={loader}
                                        id="belliconid"></img>
                                    </div>
                                  );
                                default:
                                  return user.favorite ? (
                                    <img
                                      alt="img"
                                      src={favIcon}
                                      id="belliconid"
                                      onClick={() => {
                                        // toast("Remove From Favorite");
                                        // myContext.setfavoriteitemloader(user.id);
                                        // myContext.setcarID(user.id);
                                        // myContext.setentityenum(1);
                                        // myContext.setcallfavAPI('unfavorite');
                                        // myContext.setsinglecardetailsectionisopen(false);
                                        let entitynumber;
                                        if (
                                          title === "NewVehicleNewCar" ||
                                          title === "NewVehicleNewSpecial" ||
                                          title === "NewVehicleLeaseSpecial" ||
                                          title === "NewVehicleNewAll"
                                        ) {
                                          entitynumber = 1;
                                        } else {
                                          entitynumber = 2;
                                        }
                                        unfavoriteMe({
                                          Entity: entitynumber,
                                          tableId: user.id,
                                          CarId: 0,
                                        });
                                        myContext.setfavoriteitemloader(
                                          user.id
                                        );
                                        myContext.setsinglecardetailsectionisopen(
                                          false
                                        );
                                        setfavoriteloaderstate(true);
                                        // setfilterAPIStatus(false);
                                        applyFilterFunc();
                                      }}></img>
                                  ) : (
                                    <img
                                      alt="img"
                                      src={UnfavIcon}
                                      id="belliconid"
                                      onClick={() => {
                                        // toast("Mark As Favorite");
                                        // myContext.setfavoriteitemloader(user.id);
                                        // myContext.setcarID(user.id);
                                        // myContext.setentityenum(1);
                                        // myContext.setcallfavAPI('favorite');
                                        // myContext.setsinglecardetailsectionisopen(false);
                                        let entitynumber;
                                        if (
                                          title === "NewVehicleNewCar" ||
                                          title === "NewVehicleNewSpecial" ||
                                          title === "NewVehicleLeaseSpecial" ||
                                          title === "NewVehicleNewAll"
                                        ) {
                                          entitynumber = 1;
                                        } else {
                                          entitynumber = 2;
                                        }
                                        favoriteMe({
                                          Entity: entitynumber,
                                          tableId: user.id,
                                          CarId: 0,
                                        });
                                        myContext.setfavoriteitemloader(
                                          user.id
                                        );
                                        myContext.setsinglecardetailsectionisopen(
                                          false
                                        );
                                        setfavoriteloaderstate(true);
                                        // setfilterAPIStatus(false);
                                        applyFilterFunc();
                                      }}></img>
                                  );
                              }
                            })()}
                          </div>
                          <div
                            className="shop__row__slider__items view-more-cards relative"
                            onClick={() => {
                              myContext.setsinglecardetailsectionisopen(true);
                              myContext.setcarID(user.id);
                            }}>
                            <div className="shop__row__slider__items__image_wrapper">
                              <img
                                className="brokenimg"
                                alt="img"
                                src={user.images}
                                id="belliconid"></img>
                            </div>
                            {/* <div className="shop__row__slider__items__content_wrapper">
                                <div className="flex-space-between-start">
                                  <span className="cartitle">
                                    {user.make} {user.model}
                                  </span>
                                  <span className="carcutprice">
                                    <del>{formatter.format(user.sellingPrice)}</del>
                                  </span>
                                </div>
                                <div className="flex-space-between-start">
                                  <span className="carmodel">{user.year}</span>
                                  <span className="carprice">
                                    {formatter.format(user.netPrice)}
                                  </span>
                                </div>
                              </div> */}
                            <div className="shop__row__slider__items__content_wrapper">
                              <div className="flex-space-between-start">
                                <span className="cartitle">
                                  {user.make} {user.model}
                                </span>
                                <span className=" carprice">
                                  {formatter.format(user.sellingPrice)}
                                </span>
                              </div>
                              <div className="flex-space-between-start">
                                <span className="carmodel">{user.year}</span>
                                <span className="carcutprice"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="Loadmorebtn2">
                        {(() => {
                          let Prev;
                          let pagingCount;
                          let btnFrom = title;

                          switch (btnFrom) {
                            case "NewVehicleNewCar":
                              (() => {
                                if (isdataloded) {
                                  const lng = dataformemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleLeaseSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforleasspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewAll":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedCars":
                              (() => {
                                if (isdataloded) {
                                  const lng = dataforusedmemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedAll":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            default:
                              console.log(`Sorry, we are out of ${btnFrom}.`);
                          }
                          if (currentPage != 1) {
                            Prev = React.createElement(
                              "span",
                              {
                                className: "nextprevbutton prev",
                                onClick: (event) => {
                                  setCurrentPage(currentPage - 1);
                                  setIsLoading(true);
                                },
                              },
                              `Previous`
                            );
                          } else {
                            Prev = React.createElement(
                              "span",
                              {
                                className: "nextprevbutton prev disabledd",
                              },
                              `Previous`
                            );
                          }

                          return Prev;
                        })()}

                        {(() => {
                          let button;
                          let pagingCount;
                          let btnFrom = title;
                          switch (btnFrom) {
                            case "NewVehicleNewCar":
                              button = dataformember.length;
                              (() => {
                                if (isdataloded) {
                                  const lng = dataformemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewSpecial":
                              button = datafornewspecialviewmore.length;
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleLeaseSpecial":
                              button = dataforleasspecialviewmore.length;
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforleasspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewAll":
                              button = datafornewallviewmorelenght.length;
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedCars":
                              button = dataforusedmember.length;
                              (() => {
                                if (isdataloded) {
                                  const lng = dataforusedmemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedSpecial":
                              button = dataforusedspecialviewmore.length;
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedAll":
                              button = dataforusedallviewmore.length;
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            default:
                              console.log(`Sorry, we are out of ${btnFrom}.`);
                          }

                          let comparelength = button;
                          if (comparelength != 0) {
                            button = (
                              <>
                                <div id="paginationwrapper">
                                  {Array.from(
                                    { length: parseInt(pagingCount + 1) },
                                    (_, index) =>
                                      React.createElement(
                                        "span",
                                        {
                                          key: index,
                                          className: `pagingspan${
                                            index + 1 === currentPage
                                              ? " active"
                                              : ""
                                          }`,
                                          onClick: (event) => {
                                            setCurrentPage(index + 1);
                                            setIsLoading(true);
                                          },
                                        },
                                        `${index + 1}`
                                      )
                                  )}
                                </div>
                              </>
                            );
                          } else {
                            button = (
                              <button
                                onClick={() => {
                                  setCurrentPage(1);
                                  setIsLoading(true);
                                }}
                                className="btn_theme">
                                Reload
                              </button>
                            );
                          }
                          return button;
                        })()}

                        {(() => {
                          let Next;

                          let pagingCount;
                          let btnFrom = title;

                          switch (btnFrom) {
                            case "NewVehicleNewCar":
                              (() => {
                                if (isdataloded) {
                                  const lng = dataformemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleLeaseSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforleasspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "NewVehicleNewAll":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    datafornewallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedCars":
                              (() => {
                                if (isdataloded) {
                                  const lng = dataforusedmemberlenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedSpecial":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedspecialviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            case "UsedVehicleUsedAll":
                              (() => {
                                if (isdataloded) {
                                  const lng =
                                    dataforusedallviewmorelenghtforfilter;
                                  pagingCount = lng / 32;
                                }
                              })();
                              break;
                            default:
                              console.log(`Sorry, we are out of ${btnFrom}.`);
                          }

                          if (Math.floor(pagingCount + 1) > currentPage) {
                            Next = React.createElement(
                              "span",
                              {
                                className: "nextprevbutton next",
                                onClick: (event) => {
                                  setCurrentPage(currentPage + 1);
                                  setIsLoading(true);
                                },
                              },
                              `Next`
                            );
                          } else {
                            Next = React.createElement(
                              "span",
                              {
                                className: "nextprevbutton next disabledd",
                              },
                              `Next`
                            );
                          }
                          return Next;
                        })()}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {filterscreenisopen === false && (
            <div className="view_more_items-wrapper">
              {searchDataPanelIsopen ? (
                searchDataIsloaded === false ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : (
                  <>
                    {!searchDataIsSet ||
                    searchDataIsSet.length == 0 ||
                    searchDataIsSet.length === [] ||
                    searchDataIsSet.length == null ||
                    searchDataIsSet.length == undefined ? (
                      <div className="emptywndow emptywndowfav">
                        <h3 className="mb-0">No Vehicle Found</h3>
                      </div>
                    ) : (
                      <>
                        {isLoading ? (
                          <div className="loader-div">
                            <img alt="img" src={loader} id="belliconid"></img>
                          </div>
                        ) : (
                          searchDataIsSet.map((user, i) => (
                            <div className="relative" key={i}>
                              <div className="heart_icon">
                                {(() => {
                                  switch (myContext.favoriteitemloader) {
                                    case user.id:
                                      return (
                                        <div className="loader-div">
                                          <img
                                            alt="img"
                                            src={loader}
                                            id="belliconid"></img>
                                        </div>
                                      );
                                    default:
                                      return user.favorite ? (
                                        <img
                                          alt="img"
                                          src={favIcon}
                                          id="belliconid"
                                          onClick={() => {
                                            // toast("Remove From Favorite");
                                            // myContext.setfavoriteitemloader(user.id);
                                            // myContext.setcarID(user.id);
                                            // myContext.setentityenum(1);
                                            // myContext.setcallfavAPI('unfavorite');
                                            // myContext.setsinglecardetailsectionisopen(false);
                                            // setIsLoading(true);
                                            unfavoriteMe({
                                              Entity: 1,
                                              tableId: user.id,
                                              CarId: 0,
                                            });
                                            myContext.setfavoriteitemloader(
                                              user.id
                                            );
                                            myContext.setsinglecardetailsectionisopen(
                                              false
                                            );
                                            // setIsLoading(true);
                                          }}></img>
                                      ) : (
                                        <img
                                          alt="img"
                                          src={UnfavIcon}
                                          id="belliconid"
                                          onClick={() => {
                                            // toast("Mark As Favorite");
                                            // myContext.setfavoriteitemloader(user.id);
                                            // myContext.setcarID(user.id);
                                            // myContext.setentityenum(1);
                                            // myContext.setcallfavAPI('favorite');
                                            // myContext.setsinglecardetailsectionisopen(false);
                                            // setIsLoading(true);
                                            favoriteMe({
                                              Entity: 1,
                                              tableId: user.id,
                                              CarId: 0,
                                            });
                                            myContext.setfavoriteitemloader(
                                              user.id
                                            );
                                            myContext.setsinglecardetailsectionisopen(
                                              false
                                            );
                                            // setIsLoading(true);
                                          }}></img>
                                      );
                                  }
                                })()}
                              </div>
                              <div
                                className="shop__row__slider__items view-more-cards relative"
                                onClick={() => {
                                  myContext.setsinglecardetailsectionisopen(
                                    true
                                  );
                                  myContext.setcarID(user.id);
                                }}>
                                <div className="shop__row__slider__items__image_wrapper">
                                  <img
                                    className="brokenimg"
                                    alt="img"
                                    src={user.images}
                                    id="belliconid"></img>
                                </div>
                                <div className="shop__row__slider__items__content_wrapper">
                                  <div className="flex-space-between-start">
                                    <span className="cartitle">
                                      {user.make} {user.model}
                                    </span>
                                    <span className=" carprice">
                                      {formatter.format(user.sellingPrice)}
                                    </span>
                                  </div>
                                  <div className="flex-space-between-start">
                                    <span className="carmodel">
                                      {user.year}
                                    </span>
                                    <span className="carcutprice"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </>
                    )}
                  </>
                )
              ) : title === "NewVehicleNewCar" ? (
                title === "NewVehicleNewCar" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && dataformember.length != 0 ? (
                  dataformember.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    unfavoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    favoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model} {user.id}
                            </span>
                            <span className="carcutprice">
                              <del>{formatter.format(user.sellingPrice)}</del>
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carprice">
                              {formatter.format(user.netPrice)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "NewVehicleNewSpecial" ? (
                title === "NewVehicleNewSpecial" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && datafornewspecialviewmore.length != 0 ? (
                  datafornewspecialviewmore.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    unfavoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    favoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className=" carprice">
                              {formatter.format(user.sellingPrice)}
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carcutprice"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "NewVehicleLeaseSpecial" ? (
                title === "NewVehicleLeaseSpecial" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && dataforleasspecialviewmore.length != 0 ? (
                  dataforleasspecialviewmore.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    unfavoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    favoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className=" carprice">
                              {formatter.format(user.sellingPrice)}
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carcutprice"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "NewVehicleNewAll" ? (
                title === "NewVehicleNewAll" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && datafornewallviewmore.length != 0 ? (
                  datafornewallviewmore.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    unfavoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(1);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    favoriteMe({
                                      Entity: 1,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className=" carprice">
                              {formatter.format(user.sellingPrice)}
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carcutprice"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "UsedVehicleUsedCars" ? (
                title === "UsedVehicleUsedCars" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && dataforusedmember.length != 0 ? (
                  dataforusedmember.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    unfavoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    favoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className="carcutprice">
                              <del>{formatter.format(user.sellingPrice)}</del>
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carprice">
                              {formatter.format(user.netPrice)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "UsedVehicleUsedSpecial" ? (
                title === "UsedVehicleUsedSpecial" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && dataforusedspecialviewmore.length != 0 ? (
                  dataforusedspecialviewmore.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                            // return <div className='loader-div'><img alt='img' src={loader} id='belliconid'></img></div>;
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    unfavoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    setIsLoading(true);
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    // setIsLoading(true);
                                    favoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    setIsLoading(true);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className=" carprice">
                              {formatter.format(user.sellingPrice)}
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carcutprice"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : title === "UsedVehicleUsedAll" ? (
                title === "UsedVehicleUsedAll" && isLoading ? (
                  <div className="loader-div">
                    <img alt="img" src={loader} id="belliconid"></img>
                  </div>
                ) : isdataloded && dataforusedallviewmore.length != 0 ? (
                  dataforusedallviewmore.map((user, i) => (
                    <div className="relative" key={i}>
                      <div className="heart_icon">
                        {(() => {
                          switch (myContext.favoriteitemloader) {
                            case user.id:
                              return (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    id="belliconid"></img>
                                </div>
                              );
                            default:
                              return user.favorite ? (
                                <img
                                  alt="img"
                                  src={favIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    // toast("Remove From Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('unfavorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                    unfavoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                  }}></img>
                              ) : (
                                <img
                                  alt="img"
                                  src={UnfavIcon}
                                  id="belliconid"
                                  onClick={() => {
                                    favoriteMe({
                                      Entity: 2,
                                      tableId: user.id,
                                      CarId: 0,
                                    });
                                    myContext.setfavoriteitemloader(user.id);
                                    myContext.setsinglecardetailsectionisopen(
                                      false
                                    );
                                    // setIsLoading(true);
                                    // toast("Mark As Favorite");
                                    // myContext.setfavoriteitemloader(user.id);
                                    // myContext.setcarID(user.id);
                                    // myContext.setentityenum(2);
                                    // myContext.setcallfavAPI('favorite');
                                    // myContext.setsinglecardetailsectionisopen(false);
                                  }}></img>
                              );
                          }
                        })()}
                      </div>
                      <div
                        className="shop__row__slider__items view-more-cards relative"
                        onClick={() => {
                          myContext.setsinglecardetailsectionisopen(true);
                          myContext.setcarID(user.id);
                        }}>
                        <div className="shop__row__slider__items__image_wrapper">
                          <img
                            className="brokenimg"
                            alt="img"
                            src={user.images}
                            id="belliconid"></img>
                        </div>
                        <div className="shop__row__slider__items__content_wrapper">
                          <div className="flex-space-between-start">
                            <span className="cartitle">
                              {user.make} {user.model}
                            </span>
                            <span className=" carprice">
                              {formatter.format(user.sellingPrice)}
                            </span>
                          </div>
                          <div className="flex-space-between-start">
                            <span className="carmodel">{user.year}</span>
                            <span className="carcutprice"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptywndow emptywndowfav">
                    <h3 className="mb-0">No Vehicle Found</h3>
                  </div>
                )
              ) : (
                <h4>Something Went Wrong. Please Reload Browser.</h4>
              )}
              {!searchDataPanelIsopen ? (
                <div className="Loadmorebtn2">
                  {(() => {
                    let Prev;
                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforleasspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }
                    if (currentPage != 1) {
                      Prev = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton prev",
                          onClick: (event) => {
                            setCurrentPage(currentPage - 1);
                            setIsLoading(true);
                          },
                        },
                        `Previous`
                      );
                    } else {
                      Prev = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton prev disabledd",
                        },
                        `Previous`
                      );
                    }

                    return Prev;
                  })()}

                  {(() => {
                    let button;
                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        button = dataformember.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        button = datafornewspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        button = dataforleasspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataforleasspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        button = datafornewallviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        button = dataforusedmember.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        button = dataforusedspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        button = dataforusedallviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }

                    let comparelength = button;
                    if (comparelength != 0) {
                      button = (
                        <>
                          <div id="paginationwrapper">
                            {Array.from(
                              { length: parseInt(pagingCount + 1) },
                              (_, index) =>
                                React.createElement(
                                  "span",
                                  {
                                    key: index,
                                    className: `pagingspan${
                                      index + 1 === currentPage ? " active" : ""
                                    }`,
                                    onClick: (event) => {
                                      setCurrentPage(index + 1);
                                      setIsLoading(true);
                                    },
                                  },
                                  `${index + 1}`
                                )
                            )}
                          </div>
                        </>
                      );
                    } else {
                      button = (
                        <button
                          onClick={() => {
                            setCurrentPage(1);
                            setIsLoading(true);
                          }}
                          className="btn_theme">
                          Reload
                        </button>
                      );
                    }
                    return button;
                  })()}

                  {(() => {
                    let Next;

                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforleasspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        (() => {
                          if (isdataloded) {
                            const lng = datafornewallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedspecialviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedallviewmorelenght;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }

                    if (Math.floor(pagingCount + 1) > currentPage) {
                      Next = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton next",
                          onClick: (event) => {
                            setCurrentPage(currentPage + 1);
                            setIsLoading(true);
                          },
                        },
                        `Next`
                      );
                    } else {
                      Next = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton next disabledd",
                        },
                        `Next`
                      );
                    }
                    return Next;
                  })()}
                </div>
              ) : (
                <div className="Loadmorebtn2">
                  {(() => {
                    let Prev;
                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforleasspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }
                    if (currentPage != 1) {
                      Prev = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton prev",
                          onClick: (event) => {
                            setCurrentPage(currentPage - 1);
                            setIsLoading(true);
                          },
                        },
                        `Previous`
                      );
                    } else {
                      Prev = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton prev disabledd",
                        },
                        `Previous`
                      );
                    }

                    return Prev;
                  })()}

                  {(() => {
                    let button;
                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        button = dataformember.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        button = datafornewspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        button = dataforleasspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforleasspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        button = datafornewallviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        button = dataforusedmember.length;
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        button = dataforusedspecialviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        button = dataforusedallviewmore.length;
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }

                    let comparelength = button;
                    if (comparelength != 0) {
                      button = (
                        <>
                          <div id="paginationwrapper">
                            {Array.from(
                              { length: parseInt(pagingCount + 1) },
                              (_, index) =>
                                React.createElement(
                                  "span",
                                  {
                                    key: index,
                                    className: `pagingspan${
                                      index + 1 === currentPage ? " active" : ""
                                    }`,
                                    onClick: (event) => {
                                      setCurrentPage(index + 1);
                                      setIsLoading(true);
                                    },
                                  },
                                  `${index + 1}`
                                )
                            )}
                          </div>
                        </>
                      );
                    } else {
                      button = (
                        <button
                          onClick={() => {
                            setCurrentPage(1);
                            setIsLoading(true);
                          }}
                          className="btn_theme">
                          Reload
                        </button>
                      );
                    }
                    return button;
                  })()}

                  {(() => {
                    let Next;

                    let pagingCount;
                    let btnFrom = title;

                    switch (btnFrom) {
                      case "NewVehicleNewCar":
                        (() => {
                          if (isdataloded) {
                            const lng = dataformemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleLeaseSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforleasspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "NewVehicleNewAll":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              datafornewallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedCars":
                        (() => {
                          if (isdataloded) {
                            const lng = dataforusedmemberlenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedSpecial":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedspecialviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      case "UsedVehicleUsedAll":
                        (() => {
                          if (isdataloded) {
                            const lng =
                              dataforusedallviewmorelenghtforSearchfilter;
                            pagingCount = lng / 32;
                          }
                        })();
                        break;
                      default:
                        console.log(`Sorry, we are out of ${btnFrom}.`);
                    }

                    if (Math.floor(pagingCount + 1) > currentPage) {
                      Next = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton next",
                          onClick: (event) => {
                            setCurrentPage(currentPage + 1);
                            setIsLoading(true);

                            console.log(Math.floor(pagingCount));
                          },
                        },
                        `Next`
                      );
                    } else {
                      Next = React.createElement(
                        "span",
                        {
                          className: "nextprevbutton next disabledd",
                        },
                        `Next`
                      );
                    }
                    return Next;
                  })()}
                </div>
              )}

              {/* UsedVehicleUsedAll */}
            </div>
          )}
        </div>
      </div>
    );
    
};

export default ShopViewMore;