import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import 'react-toastify/dist/ReactToastify.css';
import Overlay from '../Snippets/overlay'
import { v4 } from 'uuid';
import favIcon from "../../images/heartico_colored.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
        // toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

class FavoriteProtectionPlan extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      isLoaded: false,
      apiStatus: false,
      apiStatusMessage: "",
      items: [],
      AddItemToCart: {},
      additemapiisloaded: false,
      additemapistatus: false,
      additemapimessage: false,
      api_token: localStorage.getItem("token"),
    };
    this.DataRendering = this.DataRendering;
    this.DataRendering = this.DataRendering;
    this.AddToCart = this.AddToCart;
    this.unfavoriteMe = this.unfavoriteMe;
    this.AddToCartApiFunction = this.AddToCartApiFunction;
  }

  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    if (!(await removeFromFavorite(itemId, contextData))) {
      this.setState(
        {
          isLoaded: false,
          apiStatus: false,
          items: [],
        },
        this.DataRendering(),
        () => {
          this.DataRendering();
        }
      );
    }
  };

  // Data API Start
  DataRendering = () => {
    let endpoint =
      "Favorite/UserFavourites?PageNumber=1&PageSize=50&timeSpan=0&favouriteSystemEntity=4";
    const url = this.context.Base_url + endpoint;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.allFavoriteModel.favouriteFandIModels,
            });
          } else {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: true,
            errorMessage: "There is an error",
          });
        }
      );
  };
  // Data API End

  // After Rendering
  componentDidMount() {
    // Data API
    this.DataRendering();
  }

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
  }

  // Add To Cart API Function
  AddToCartApiFunction = (ReqData) => {
    let end_point = "Cart/AddItemToCart";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            
            this.setState(
              {
                additemapiisloaded: true,
                additemapistatus: result.status,
                additemapimessage: result.message,
              },
              () => {
                this.context.setupdatecartcount(true);
                setTimeout(() => {
                  this.setState({ additemapiisloaded: false });
                }, 2000);
              }
            );
          } else {
            this.setState({
              additemapiisloaded: true,
              additemapistatus: result.status,
              additemapimessage: result.message,
            });
          }
        },
        (error) => {
          this.setState({
            additemapistatus: false,
            error,
          });
        }
      );
  };
  // Add To Cart API Function

  // Add To cart
  AddToCart = (e) => {
    let item = e.currentTarget.getAttribute("serviceID");
    let CarID = e.currentTarget.getAttribute("carID");
    let DealershipID = e.currentTarget.getAttribute("dealershipID");
    let CartItemModels = "";
    let PromoCode = "";
    let Notes = "";

    var priceval = e.currentTarget.getAttribute("servicePrice");
    let pr;
    let bene;
    if (priceval == 0) {
      pr = 0;
      bene = true;
    } else {
      bene = false;
      pr = parseInt(priceval);
    }

    let val = [
      {
        CartItemType: 2,
        ItemId: parseInt(item),
        isBeneift: bene,
        Price: pr,
      },
    ];
    let data = {
      DealerShipId: parseInt(DealershipID),
      CarId: parseInt(CarID),
      CartItemModels: val,
      PromoCode: PromoCode,
      Notes: Notes,
    };
    this.setState(
      {
        AddItemToCart: data,
        additemapiisloaded: false,
      },
      () => {
        // Add To Cart API Hit Function
        this.AddToCartApiFunction(this.state.AddItemToCart);
      }
    );
  };

  EmptyCart = (e) => {
    let end_point = "Cart/EmptyCartItems";
    const url = this.context.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                emptycartapistatus: result.status,
              },
              () => {
                this.setState({
                  additemapiisloaded: true,
                });
                this.AddToCartApiFunction(this.state.AddItemToCart);
              }
            );
          } else {
            this.setState({
              emptycartapistatus: result.status,
            });
          }
        },
        (error) => {
          this.setState({
            emptycartapistatus: false,
            error,
          });
        }
      );
  };

  render() {
    return (
      <>
        {this.state.isLoaded === false ? (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        ) : this.state.items === null ? (
          this.state.error ? (
            <h3>{this.state.errorMessage}</h3>
          ) : (
            <div className="emptywndow emptywndowfav">
              <i className="fa-solid fa-heart"></i>
              <h3 className="mb-0">No Favorite Found</h3>
              <p className="mt-0">
                Protection Plan that you mark as favorite are show here.
              </p>
            </div>
          )
        ) : (
          <div className="services_cards_wrapper">
            {this.state.additemapiisloaded
              ? this.state.additemapistatus && (
                  <div className="loader-div">
                    <Overlay
                      title="additem"
                      message={this.state.additemapimessage}
                    />
                  </div>
                )
              : ""}
            {this.state.additemapiisloaded
              ? this.state.additemapistatus === false && (
                  <div className="loader-div">
                    <h3 className="title_medium"></h3>
                    <div className="confirm_dialog">
                      <h4>{this.state.additemapimessage}</h4>
                      <div className="flex">
                        {this.state.additemapimessage ===
                        "Item Already Exist in the Cart." ? (
                          <div
                            className={`btn_theme small`}
                            onClick={() => {
                              this.setState({
                                additemapiisloaded: false,
                              });
                            }}>
                            Okay
                          </div>
                        ) : (
                          <>
                            <div
                              className={`btn_theme small light`}
                              onClick={this.EmptyCart}>
                              Empty Cart
                            </div>
                            <div
                              className={`btn_theme small`}
                              onClick={() => {
                                this.setState({
                                  additemapiisloaded: false,
                                });
                              }}>
                              Cancel
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="confirm_dialog_layer"></div>
                  </div>
                )
              : ""}
            {this.state.isLoaded === false ? (
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            ) : (
              <>
                {this.state.isLoaded &&
                  this.state.items.map((data1, i) => (
                    <>
                      <div className="services_card favServiceCard" key={v4}>
                        <div className="">
                          <h3>
                            <span className="text-black">
                              <p>Dealership: {data1.dealershipName} </p>
                              <strong>{data1.carName}</strong>
                            </span>
                          </h3>
                        </div>
                        <div className="flex">
                          {(() => {
                            let span = [];
                            for (
                              let i = 0;
                              i < data1.favProtection.length;
                              i++
                            ) {
                              span.push(
                                <>
                                  <div className="flex_start_center w-100 mb-2">
                                    <div className="ml-1 services_card_content">
                                      <div className="flex w-100 p-1 relative">
                                        <div className="heart_icon">
                                          <img
                                            alt="img"
                                            src={favIcon}
                                            id="belliconid"
                                            onClick={() => {
                                              this.unfavoriteMe({
                                                Entity: 4,
                                                tableId:
                                                  data1.favProtection[i]
                                                    .dealershipServiceId,
                                                CarId: data1.carId,
                                              });

                                              this.DataRendering();
                                            }}></img>
                                        </div>
                                        <div className="table_image_wrapper mr-1">
                                          <img
                                            alt="img"
                                            src={
                                              data1.favProtection[i]
                                                .protectionImage
                                            }
                                            id="belliconid"></img>
                                        </div>
                                        <div className="f-grow-1">
                                          <p className="services_card_content_row_title">
                                            <strong>
                                              {
                                                data1.favProtection[i]
                                                  .dealershipServiceName
                                              }{" "}
                                            </strong>
                                          </p>
                                          <div className="mb-1 services_card_content_row text-black flex-space-between-center w-100">
                                            <span>
                                              {
                                                data1.favProtection[i]
                                                  .dealershipServiceName
                                              }
                                            </span>
                                            <span className="ml-auto">
                                              {data1.favProtection[i]
                                                .isBenefit ? (
                                                "Included"
                                              ) : (
                                                <>
                                                  $
                                                  {
                                                    data1.favProtection[i]
                                                      .orignalPrice
                                                  }
                                                </>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        {data1.favProtection[i]
                                          .dealerShipServiceIsCart ? (
                                          <div className="btn_theme small light ml-auto">
                                            Already In Cart
                                          </div>
                                        ) : (
                                          <div
                                            className="btn_theme small light ml-auto"
                                            onClick={this.AddToCart}
                                            dealershipID={data1.dealershipId}
                                            carID={data1.carId}
                                            serviceID={
                                              data1.favProtection[i]
                                                .dealershipServiceId
                                            }
                                            servicePrice={
                                              data1.favProtection[i].isBenefit
                                                ? 0
                                                : data1.favProtection[i]
                                                    .orignalPrice
                                            }>
                                            Add To Cart
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className='ml-1 services_card_content'>
                                                                    <div className='flex w-100 p-1'>
                                                                        <div className='table_image_wrapper mr-1'>
                                                                            <img alt='img' src={data1.favServices[i].serviceImage} id='belliconid'></img>
                                                                        </div>
                                                                        <div className='f-grow-1'>
                                                                            <p  className='services_card_content_row_title flex-space-between-center w-100'><strong>{data1.favServices[i].serviceParent} </strong></p>
                                                                            <div className='mb-1 services_card_content_row text-black flex-space-between-center w-100'>
                                                                                <span>{data1.favServices[i].dealershipServiceName}</span>
                                                                                <span className='ml-auto'>${data1.favServices[i].orignalPrice}</span>                                                                       
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='btn_theme small light ml-auto' onClick={this.AddToCart} dealershipID={data1.dealershipId} carID={data1.carId} serviceID={data1.favServices[i].dealershipServiceId} servicePrice={data1.favServices[i].orignalPrice}>Add To Cart</div>
                                                                </div> */}
                                </>
                              );
                            }
                            return span;
                          })()}
                        </div>
                      </div>
                    </>
                  ))}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}
export default FavoriteProtectionPlan ;
