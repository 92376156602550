import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import TopNav from '../Snippets/top_nav_items';
import AppContext from '../../AppContext';
import axios from 'axios';
import { useContext } from 'react';
import loader from '../../images/loader.gif';
import { confirm } from "react-confirm-box";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';


const CheckoutForm = () => {
  const [cardReady, setcardReady] = React.useState(false);
  const [cardNameValue, setcardNameValue] = React.useState('');
  const [paymentTypeEntity, setpaymentTypeEntity] = React.useState(1);
  

  const stripe = useStripe();
  const elements = useElements();
  let val = '';
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (elements == null) {
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    // console.log(paymentMethod);
    
    let expiredate;
    if (paymentMethod.card.exp_month < 10) {
        expiredate = paymentMethod.card.exp_year + '-0' + paymentMethod.card.exp_month + '-30T23:59:00.775Z';
    } else {
        expiredate = paymentMethod.card.exp_year + '-' + paymentMethod.card.exp_month + '-30T23:59:00.775Z';
    }
    // console.log(cardNameValue);
    // console.log(expiredate); 
    // console.log(paymentTypeEntity); 
    console.log(paymentMethod); 
    
  };
  
  
  return (
    <form onSubmit={handleSubmit} className='stripeform'>
      <input className="cardinput is-complete" placeholder="Card Name"
      
      onChange={event => {
        
        let inpbox = document.querySelector('.cardinput');
        if (inpbox.classList.contains('is-complete')) {
          // do some stuff
          val = document.querySelector('.cardinput').value;
          setcardNameValue(val);
        }
        
      }}
      ></input>
      <CardElement
      className="stripeinput"
      onReady={() => {
        // console.log("CardElement [ready]");
      }}
      onChange={event => {
        // console.log("CardElement [change]", event);
        if(event.complete) {
          setcardReady(true);
          const inpboxCard = document.querySelector('.__PrivateStripeElement > iframe'); 
          // var name = inpboxCard.contentWindow.document,getElementsByTagName("input"); 


          const iWindow = inpboxCard.contentWindow;
          const iDocument = iWindow.document;  

          // accessing the element
          const element = iDocument.querySelector(".InputElement");   
          // element.style.color = "green"; 
          // var name = inpboxCard.contentWindow.document.getElementsByTagName("input"); 
          // var name = inpboxCard.contentWindow.document.getElementsByTagName("input"); 
          // // inpboxCard.style.display = "none"; 
          console.log(element);
        } else {  
          setcardReady(false);
        }
        
        // const inpboxCard = document.querySelector('.InputElement').value;
        // inpboxCard.value = cardNameValue;


        // var iframe = document.querySelector(".stripeinput");
        // console.log(inpboxCard)
        // var elmnt = iframe.contentWindow.document.getElementsByTagName("div")[0];
        // iframe.style.opacity = 0;


        // console.log(inpboxCard);
        // if (inpbox.classList.contains('is-complete')) {
        //   // do some stuff
        //   const val = document.querySelector('.InputElement').value;
        //     console.log(val);
        // }
      }}
      onBlur={() => {
        // console.log("CardElement [blur]");
      }}
      onFocus={() => {
        // console.log("CardElement [focus]");
      }}
      />
      {
        cardReady && 
        cardNameValue !== '' &&
        <button type="submit" className="btn_theme w-100" disabled={!stripe}>
            Add Payment Method
        </button>
      }
    </form>
  );
};




const optionsWithLabelChange = {
  closeOnOverlayClick: false,
  labels: {
    confirmable: "Confirm",
    cancellable: "Cancel"
  }
};

const optionsWithClonOnOverlayclick = {
  closeOnOverlayClick: true
};

const customRender = {
  render: (message, onConfirm, onCancel) => {
    return (
      <>
        <h1> Replace with {message} </h1>
        <button onClick={onConfirm}> Yes </button>
      </>
    );
  }
};

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const PaymentInfo = ({title}) => {

  const myContext = useContext(AppContext);
  let base_url = myContext.Base_url;
  let api_token = localStorage.getItem('token'); 
  const [dataforcreditcardlist, setdataforcreditcardlist] = React.useState([]);
  const [loaderrow, setloaderrow] = React.useState(false);
  const [paymethodform, setpaymethodform] = React.useState(true);
  const [isdataloded, setisdataloded] = React.useState(false);
  const [cardNameValue, setcardNameValue] = React.useState('');
  const [cardNumberValue, setcardNumberValue] = React.useState('');
  const [cardCVCValue, setCVCValue] = React.useState('');
  const [datessval, setdatesval] = React.useState('');
  const [monthsval, setmonthsval] = React.useState('');
  const [cardReady, setcardReady] = React.useState(true);


  const [onchangeNumberValue, setonchangeNumberValue] = React.useState('');
  const [onchangeCVCValue, setonchangeCVCValue] = React.useState('');



  const [nameReady, setnameReady] = React.useState(false);
  const [numberReady, setnumberReady] = React.useState(false);
  const [cvcReady, setcvcReady] = React.useState(false);
  const [dateReady, setdateReady] = React.useState(false);
  const [monthReady, setmonthReady] = React.useState(false);
  const [requirdtxt, setrequirdtxt] = React.useState('');

  


  
  function cardlist() {
    if(api_token !== undefined && api_token !== null && api_token !== '') {
        setisdataloded(true);
        let end_point = 'UserInfo/AllCreditCard';
        //api url for right
        let api_url = base_url+end_point;
        
        const url = api_url;
        axios(
        {
            method: 'get',
            url: url,
            headers: {'Authorization': api_token}
        }
        )
        .then(res => {
          if (res.status) {
            setdataforcreditcardlist(res.data.cardDetailModelsList);
            setloaderrow(false);
          }
          
        })
        .catch((error) => console.log(error)) 
    }
}
  React.useEffect(() => {
    cardlist();
  }, []);
  // const setstateforcardId(item.id)
  let itemId;
  function onClickTwo(props) {
      itemId = props.currentTarget.getAttribute("dataid");
      // setstateforcardId(Id);
  }

  const onClick = async (options, props) => {


      const result = await confirm("Are you sure. you want to delete?", options);

      if (result) {
          setloaderrow(true);
          let end_point = 'UserInfo/RemoveCreditCard';
          //api url for right
          let api_url = base_url+end_point;
          const url = api_url;
          axios(
          {
              method: 'post',
              url: url,
              data: {
              "cardId": itemId
              },
              headers: {'Authorization': api_token}
          }
          )
          .then(res => {
              if (isdataloded) {
                  cardlist();
                  setloaderrow(true);
              }
          })
          .catch((error) => console.log(error)) 
      return;
      }
      // console.log("You click No!");
  };
  

  let val = '';
  const months = [
    {label: 'January', value: '01'},
    {label: 'February', value: '02'},
    {label: 'March', value: '03'},
    {label: 'April', value: '04'},
    {label: 'May', value: '05'},
    {label: 'June', value: '06'},
    {label: 'July', value: '07'},
    {label: 'August', value: '08'},
    {label: 'September', value: '09'},
    {label: 'October', value: '10'},
    {label: 'November', value: '11'},
    {label: 'December', value: '12'}
  ];

  const year = [];
  let newDate = new Date()
  let CurrntYear = newDate.getFullYear();
  for (let i = 0; i < 6; i++) {
    year.push({label: CurrntYear+i, value: CurrntYear+i});
  }

  const addcard = () => {
      
    if (nameReady && numberReady && cvcReady && monthReady && dateReady) {
        setpaymethodform(false);
        setisdataloded(false);
        var APM_cardDate = datessval +"-"+ monthsval +"-01T10:09:04.941Z";
        var APM_cardName = cardNameValue;
        var APM_cardNumber = cardNumberValue;
        var APM_cardCVC = cardCVCValue;
        setrequirdtxt("");
        let end_point = 'UserInfo/AddCreditCard';
        //api url for right
        let api_url = base_url+end_point;
        const url = api_url;
        axios(
          {
            method: 'post',
            url: url,
            data: {
                "name": APM_cardName,
                "paymentTypeEntity": 1,
                "cardNumber": APM_cardNumber,
                "cvc": APM_cardCVC,
                "expiryDate": APM_cardDate
            },
            headers: {'Authorization': api_token}
          }
        )
        .then(res => {
          setisdataloded(true);
          setrequirdtxt(res.data.message);

          if (res.data.message === 'Card Added Successfully') {
            setrequirdtxt('');
            toast(res.data.message); 
            myContext.setActive("Payment");
            setpaymethodform(true);
            setnameReady(false);
            setnumberReady(false);
            setcvcReady(false);
            setmonthReady(false);
            setdateReady(false);
            setonchangeNumberValue("");
            setonchangeCVCValue("");
            
            if(myContext.addcardpagefromcart) {
              myContext.setaddcardpagefromcart(false);
              setTimeout(() => {
                myContext.setActive("Cart");
              }, 2000);
            }


          } else {
            setpaymethodform(true);
          }
          cardlist();
        })
        .catch((error) => {
          console.log(error)
          setisdataloded(true);
        }) 





    } else {
      setrequirdtxt("All fields are required.");
      setpaymethodform(true);
    }
  }


  const getyearfromdate = (parameter) => {
    var DateObj = new Date(parameter);
    let monthdigit;
    if (DateObj.getMonth() < 10 ) {
      monthdigit = "0"+(DateObj.getMonth()+1);
    } else {
      monthdigit = (DateObj.getMonth()+1);
    }
    var months = DateObj.getFullYear() + "/" + monthdigit;
    return months;
  }
  return (
    <>
      <div className="shop_frame_controller">
        <ToastContainer hideProgressBar={true} />
        <div className="top_iconbar">
          <span className="top_Left_column">
            {/* {myContext.shopcardetailsectionisopen !== "" && 
                    <span className='backbutton' onClick={back}><i className="fa-solid fa-chevron-left"></i></span>
                } */}
            <h4 className="title_medium">{title}</h4>
          </span>
          <TopNav />
          {/* {myContext.singlecardetailsectionisopen === false && 
            } */}
        </div>
        <h3 className="mt-1">Add New Payment Method</h3>
        <span className="mt-0 text-danger">{requirdtxt}</span>
        {paymethodform === true && (
          <>
            <div className="DemoWrapper">
              {/* <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements> */}
              <div className="group">
                <div className="form-group">
                  <label>
                    Name<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="cardinput is-complete"
                    placeholder="Card Name"
                    id="NameValue"
                    onChange={(event) => {
                      val = document.querySelector("#NameValue").value;
                      setcardNameValue(val);
                      if (val.length > 0) {
                        setnameReady(true);
                      } else {
                        setnameReady(false);
                        setcardReady(false);
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Card Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    maxLength="16"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    className="cardinput is-complete"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    id="NumberValue"
                    value={onchangeNumberValue}
                    onChange={(event) => {
                      const value = event.target.value.replace(/\D/g, "");
                      setonchangeNumberValue(value);
                      val = document.querySelector("#NumberValue").value;
                      setcardNumberValue(val);
                      if (val.length > 0) {
                        setnumberReady(true);
                      } else {
                        setnumberReady(false);
                        setcardReady(false);
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>
                    CVC Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    maxLength="3"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    className="cardinput is-complete"
                    placeholder="CVC Number"
                    id="CVCValue"
                    value={onchangeCVCValue}
                    onChange={(event) => {
                      const value = event.target.value.replace(/\D/g, "");
                      setonchangeCVCValue(value);
                      val = document.querySelector("#CVCValue").value;
                      setCVCValue(val);
                      if (val.length > 0) {
                        setcvcReady(true);
                      } else {
                        setcvcReady(false);
                        setcardReady(false);
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Expiry Year<sup className="text-danger">*</sup>
                  </label>
                  <Select
                    options={year}
                    defaultValue={1}
                    onChange={({ value }) => {
                      setdatesval(value);
                      setdateReady(true);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Expiry Month<sup className="text-danger">*</sup>
                  </label>
                  <Select
                    options={months}
                    defaultValue={1}
                    onChange={({ value }) => {
                      setmonthsval(value);
                      setmonthReady(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {
              <button type="submit" onClick={addcard} className={`btn_theme`}>
                Confirm
              </button>
            }
          </>
        )}
        <h3 className="mt-2">Payment Method</h3>
        <div className="payment-method_layout_container">
          {dataforcreditcardlist != null ? (
            dataforcreditcardlist.map((item) => (
              <div key={item.id} className="p-1 payment-method_layout">
                <i className="fa-regular fa-credit-card"></i>
                <div>
                  {item.name}
                  <div className="py-1">{getyearfromdate(item.expiryDate)}</div>
                  {item.cardNumber}
                </div>
                <span onClick={onClick} dataid={item.id}>
                  {loaderrow ? (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  ) : (
                    <i
                      className="fa-solid fa-trash"
                      onClick={onClickTwo}
                      dataid={item.id}></i>
                  )}
                </span>
              </div>
            ))
          ) : (
            <div className="emptywndow emptywndowfav">
              <h3 className="mb-0">No Card Details Found for this User.</h3>
            </div>
          )}
        </div>
        {isdataloded === false ? (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PaymentInfo;
