import React, { Component } from "react";
import loader from "../../images/loader.gif";
import AppContext from "../../AppContext";
import Slider from "react-slick";
import PartViewMore from "./Part_view_more";
import favIcon from "../../images/heartico_colored.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 } from "uuid";
import axios from "axios";

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

class PartsAndAccessories extends Component {
  
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      partscount: 0,
      isLoaded: false,
      apiStatus: false,
      apiStatusMessage: "",
      items: [],
      api_token: localStorage.getItem("token"),
    };
    this.DataRendering = this.DataRendering.bind(this);
    this.unfavoriteMe = this.unfavoriteMe;
  }
  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoaded: false,
          apiStatus: false,
          items: [],
        },
        this.DataRendering(),
        () => {
          this.DataRendering();
        }
      );
    }
  };

  // Data API Start
  DataRendering = () => {
    this.setState({
      isLoaded: false,
    });
    let endpoint = "Shop/DealershipPartAndAccessory?" +
      "IsDetail=" +
      false +
      "&" +
      "PartAndAccessoryId=" +
      0 +
      "&" +
      "PartAndAccessoryType=" +
      0 +
      "&" +
      "SearchString=" +
      "" +
      "&" +
      "PageIndex=" +
      1 +
      "&" +
      "PageSize=" +
      100 +
      "&" +
      "MainPageIndex=" +
      1 +
      "&" +
      "MainPageSize=" +
      100;
    const url = this.context.Base_url + endpoint;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: false,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.partAndAccessoriesModels,
              partscount: result.partAndAccessoriesModelsCount,
            });
          } else {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: true,
            errorMessage: "There is an error",
          });
        }
      );
  };
  // Data API End

  // After Rendering
  componentDidMount() {
    // Data API
    this.DataRendering();
  }

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
    this.setState({
      isLoaded: false,
      items: [],
    });
  }

  render() {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    // Slider Setting
    const settings = {
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      dot: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="content_panel_controller dir_column">
        <ToastContainer hideProgressBar={true} />
        {this.state.isLoaded ? (
          <div className="loader-div">
            <img alt="img" src={loader} id="belliconid"></img>
          </div>
        ) : (
          <div className="shop__row_wrapper">
            {this.context.shopcardetailsectionisopen === "" &&
              this.state.partscount != 0 &&
              this.state.items.map((data, i) => (
                <div className="shop__row">
                  <div className="flex_start_center">
                    {this.state.partscount != 0 && (
                      <h4 className="title_medium">
                        {data.partAndAccessoryName}
                      </h4>
                    )}
                    <span
                      className="ml-auto v-more"
                      // onClick={() => {
                      //   myContext.setshopcardetailsectionisopen(
                      //     "UsedVehicleUsedCars"
                      //   );
                      // }}
                    >
                      View More
                    </span>
                  </div>
                  <>
                    <div className="shop__row__slider_wrapper">
                      <Slider {...settings}>
                        {[data.partAndAccessoriesListModels].map((user, i) =>
                          (() => {
                            let ele = [];
                            for (let j = 0; j < user.length; j++) {
                              ele.push(
                                <div className="relative" key={i}>
                                  <div className="heart_icon">
                                    {/* {(() => {
                                  switch (myContext.favoriteitemloader) {
                                    case user.id:
                                      return (
                                        <div className="loader-div">
                                          <img
                                            alt="img"
                                            src={loader}
                                            id="belliconid"></img>
                                        </div>
                                      );
                                    default:
                                      return user.favorite ? (
                                        <img
                                          alt="img"
                                          src={favIcon}
                                          id="belliconid"
                                          onClick={() => {
                                            // toast("Remove From Favorite");
                                            unfavoriteMe({
                                              Entity: 2,
                                              tableId: user.id,
                                              CarId: 0,
                                            });
                                            myContext.setfavoriteitemloader(
                                              user.id
                                            );
                                            myContext.setsinglecardetailsectionisopen(
                                              false
                                            );
                                            // setIsLoading(true);
                                          }}></img>
                                      ) : (
                                        <img
                                          alt="img"
                                          src={UnfavIcon}
                                          id="belliconid"
                                          onClick={() => {
                                            // toast("Mark As Favorite");
                                            // myContext.setfavoriteitemloader(user.id);
                                            // myContext.setcarID(user.id);
                                            // myContext.setentityenum(2);
                                            // myContext.setcallfavAPI('favorite');
                                            // myContext.setsinglecardetailsectionisopen(false);
                                            // setIsLoading(true);
                                            // toast("Mark As Favorite");
                                            favoriteMe({
                                              Entity: 2,
                                              tableId: user.id,
                                              CarId: 0,
                                            });
                                            myContext.setfavoriteitemloader(
                                              user.id
                                            );
                                            myContext.setsinglecardetailsectionisopen(
                                              false
                                            );
                                            // setIsLoading(true);
                                          }}></img>
                                      );
                                  }
                                })()} */}
                                  </div>
                                  {
                                    console.log(user)
                                  }
                                  <div
                                    className="shop__row__slider__items relative"
                                    onClick={() => {
                                      this.context.setsinglecardetailsectionisopen(
                                        true
                                      );
                                      this.context.setpartsandAccessoryisopen(true);
                                      this.context.setpartsandAccessoryModel({
                                        "title": user[i].dealershipPartAndAccessoryName,
                                        "description": user[i].dealershipPartAndAccessoryDescription,
                                        "dealershipmake": user[i].dealershipPartAndAccessoryForMake,
                                        "dealershipname": user[i].dealershipName,
                                        "Orignalprice": user[i].dealershipPartAndAccessoryOriginalPrice,
                                        "ImageList": user[i].partAndAccessoryImageList,
                                        "points": user[i].dealershipPartAndAccessoryOriginalPriceInPoints,
                                        "isfavorite": user[i].dealershipPartAndAccessoryIsFavorite,
                                        "isaddtocart": user[i].dealershipPartAndAccessoryIsAddToCart,
                                        "itemid": user[i].dealershipPartAndAccessoryId,
                                        "isSpecial": user[i].dealershipPartAndAccessoryIsSpecial,
                                        "dealershipid": user[i].dealershipId,
                                        "SpecialPrice": user[i].dealershipPartAndAccessorySpecialPrice,
                                        
                                      });
                                    }}>
                                    <div className="shop__row__slider__items__image_wrapper">
                                      <img
                                        alt="img"
                                        src={
                                          user[i].partAndAccessoryImageDefault
                                        }
                                        id=""></img>
                                    </div>
                                    <div className="shop__row__slider__items__content_wrapper">
                                      <div className="flex-space-between-start">
                                        <span className="cartitle spacing">
                                          {
                                            user[i]
                                              .dealershipPartAndAccessoryName
                                          }
                                        </span>
                                        <span className="carprice">
                                          {formatter.format(
                                            user[i]
                                              .dealershipPartAndAccessoryOriginalPrice
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex-space-between-start">
                                        {/* <span className="carmodel">
                                      {user.year}
                                    </span> */}
                                        {/* <span className="carprice">
                                      {formatter.format(user.netPrice)}
                                    </span> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return ele;
                          })()
                        )}
                      </Slider>
                    </div>
                  </>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}
export default PartsAndAccessories;

// import React from "react";
// import Slider from "react-slick";
// import { useContext } from "react";
// import AppContext from "../../AppContext";
// import ShopViewMore from "./Shop_view_more";
// import loader from "../../images/loader.gif";
// import favIcon from "../../images/heartico_colored.png";
// import UnfavIcon from "../../images/hearticonborder.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

// async function markAsFavorite(itemId, contextData) {
//   let end_point = "Favorite/MarkAsFavourite";
//   let api_url = contextData.baseUrl + end_point;
//   return axios({
//     method: "post",
//     url: api_url,
//     data: {
//       favouriteSystemEntity: itemId.Entity,
//       tableId: itemId.tableId,
//       CarId: itemId.CarId,
//     },
//     headers: { Authorization: contextData.token },
//   })
//     .then((res) => {
//       // toast("Mark As Favorite");
//     })
//     .catch((error) => console.log(error));
// }

// async function removeFromFavorite(itemId, contextData) {
//   let end_point = "Favorite/RemoveFromFavourite";
//   let api_url = contextData.baseUrl + end_point;
//   return axios({
//     method: "post",
//     url: api_url,
//     data: {
//       favouriteSystemEntity: itemId.Entity,
//       tableId: itemId.tableId,
//       CarId: itemId.CarId,
//     },
//     headers: { Authorization: contextData.token },
//   })
//     .then((res) => {
//       // toast("Remove From Favorite");
//     })
//     .catch((error) => console.log(error));
// }

// // async function callAPIForShop(contextData) {
// //     let api_url = contextData.baseUrl;
// //     const url = api_url;

// //     fetch(url, {
// //       method: "GET",
// //       headers: {
// //         "Content-Type": "application/json",
// //         Authorization: contextData.token,
// //       }
// //     })
// //       .then((res) => res.json())
// //       .then(
// //         (result) => {
// //           if (result.status) {
// //             contextData.setData(result);
// //             debugger
// //           } else {
// //           }
// //         },
// //         (error) => {}
// //       );

// // }

// const PartsAndAccessories = ({ title }) => {
//   const myContext = useContext(AppContext);

//   let base_url = myContext.Base_url;
//   let endpoint = "Shop/DealershipPartAndAccessory";
//   let api_token = localStorage.getItem("token");

//   const favoriteMe = async (itemId) => {
//     let contextData = {
//       baseUrl: base_url,
//       token: api_token,
//     };
//     const response = await markAsFavorite(itemId, contextData);
//     if (!response) {
//       setIsLoading(false);
//       myContext.setfavoriteitemloader(false);
//     }
//   };

//   const unfavoriteMe = async (itemId) => {
//     let contextData = {
//       baseUrl: base_url,
//       token: api_token,
//     };

//     const response = await removeFromFavorite(itemId, contextData);
//     if (!response) {
//       setIsLoading(false);
//       myContext.setfavoriteitemloader(false);
//     }
//   };

//   // API URL For  Used Vehicles > Used Member Ship

//   // Check API Loading Status
//   const [isLoading, setIsLoading] = React.useState(true);

//   // Set API Response into model
//   const [isdataloded, setisdataloded] = React.useState(false);

//   // Set API Model
//   const [data, setData] = React.useState();
//   const [dataRender, setdataRender] = React.useState();
//   const [partscount, setpartscount] = React.useState();

//   // if (myContext.favoriteitemloader) {
//   //   callAPIForShop();
//   // }

//   // Create our number formatter.
//   var formatter = new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: "USD",
//     minimumFractionDigits: 0,
//   });

//   // const getdata = async () => {
//   //   let apiurL =
//   //     base_url +
//   //     endpoint +
//   //     "?" +
//   //     "IsDetail=" +
//   //     false +
//   //     "&" +
//   //     "PartAndAccessoryId=" +
//   //     0 +
//   //     "&" +
//   //     "PartAndAccessoryType=" +
//   //     0 +
//   //     "&" +
//   //     "SearchString=" +
//   //     "" +
//   //     "&" +
//   //     "PageIndex=" +
//   //     1 +
//   //     "&" +
//   //     "PageSize=" +
//   //     5 +
//   //     "&" +
//   //     "MainPageIndex=" +
//   //     1 +
//   //     "&" +
//   //     "MainPageSize=" +
//   //     5;

//   //   let contextData = {
//   //     baseUrl: apiurL,
//   //     token: api_token,
//   //     state: data,
//   //   };
//   //   const response = await callAPIForShop(contextData);
//   //   debugger;
//   // };

//   React.useEffect(() => {
//     // getdata();
//   });

//   // Slider Setting
//   const settings = {
//     arrows: true,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     infinite: false,
//     dot: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 800,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   if (api_token !== undefined && api_token !== null && api_token !== "") {
//     return (
//       <div className="content_panel_controller dir_column">
//         <ToastContainer hideProgressBar={true} />
//         {/* {console.log(data + " List")}
//         {console.log(partscount + " count")} */}
//         {isLoading ? (
//           <div className="loader-div">
//             <img alt="img" src={loader} id="belliconid"></img>
//           </div>
//         ) : (
//           <div className="shop__row_wrapper">
//             {/* {console.log(dataRender + " List")}
//             {console.log(partscount + " count")} */}
//             {myContext.shopcardetailsectionisopen === "aaa" &&
//               partscount != 0 &&
//               data.partAndAccessoriesModels.map((data, i) => (
//                 <div className="shop__row">
//                   {/* {console.log(data + " asdasdasdasdasd")} */}
//                   <div className="flex_start_center">
//                     {partscount != 0 && (
//                       <h4 className="title_medium">
//                         {/* {data[i].partAndAccessoryName} */}
//                       </h4>
//                     )}
//                     <span
//                       className="ml-auto v-more"
//                       // onClick={() => {
//                       //   myContext.setshopcardetailsectionisopen(
//                       //     "UsedVehicleUsedCars"
//                       //   );
//                       // }}
//                     >
//                       View More
//                     </span>
//                   </div>
//                   <>
//                     {/* <div className="shop__row__slider_wrapper">
//                     <Slider {...settings}>
//                       {isLoading ? (
//                         <div className="loader-div">
//                           <img alt="img" src={loader} id="belliconid"></img>
//                         </div>
//                       ) : (
//                         isdataloded &&
//                         dataformember.map((user, i) => (
//                           <div className="relative" key={i}>
//                             <div className="heart_icon">
//                               {(() => {
//                                 switch (myContext.favoriteitemloader) {
//                                   case user.id:
//                                     return (
//                                       <div className="loader-div">
//                                         <img
//                                           alt="img"
//                                           src={loader}
//                                           id="belliconid"></img>
//                                       </div>
//                                     );
//                                   default:
//                                     return user.favorite ? (
//                                       <img
//                                         alt="img"
//                                         src={favIcon}
//                                         id="belliconid"
//                                         onClick={() => {
//                                           // toast("Remove From Favorite");
//                                           unfavoriteMe({
//                                             Entity: 2,
//                                             tableId: user.id,
//                                             CarId: 0,
//                                           });
//                                           myContext.setfavoriteitemloader(
//                                             user.id
//                                           );
//                                           myContext.setsinglecardetailsectionisopen(
//                                             false
//                                           );
//                                           // setIsLoading(true);
//                                         }}></img>
//                                     ) : (
//                                       <img
//                                         alt="img"
//                                         src={UnfavIcon}
//                                         id="belliconid"
//                                         onClick={() => {
//                                           // toast("Mark As Favorite");
//                                           // myContext.setfavoriteitemloader(user.id);
//                                           // myContext.setcarID(user.id);
//                                           // myContext.setentityenum(2);
//                                           // myContext.setcallfavAPI('favorite');
//                                           // myContext.setsinglecardetailsectionisopen(false);
//                                           // setIsLoading(true);
//                                           // toast("Mark As Favorite");
//                                           favoriteMe({
//                                             Entity: 2,
//                                             tableId: user.id,
//                                             CarId: 0,
//                                           });
//                                           myContext.setfavoriteitemloader(
//                                             user.id
//                                           );
//                                           myContext.setsinglecardetailsectionisopen(
//                                             false
//                                           );
//                                           // setIsLoading(true);
//                                         }}></img>
//                                     );
//                                 }
//                               })()}
//                             </div>
//                             <div
//                               className="shop__row__slider__items relative"
//                               onClick={() => {
//                                 myContext.setsinglecardetailsectionisopen(true);
//                                 myContext.setcarID(user.id);
//                               }}>
//                               <div className="shop__row__slider__items__image_wrapper">
//                                 <img alt="img" src={user.images} id=""></img>
//                               </div>
//                               <div className="shop__row__slider__items__content_wrapper">
//                                 <div className="flex-space-between-start">
//                                   <span className="cartitle">
//                                     {user.make} {user.model}
//                                   </span>
//                                   <span className="carcutprice">
//                                     <del>
//                                       {formatter.format(user.sellingPrice)}
//                                     </del>
//                                   </span>
//                                 </div>
//                                 <div className="flex-space-between-start">
//                                   <span className="carmodel">{user.year}</span>
//                                   <span className="carprice">
//                                     {formatter.format(user.netPrice)}
//                                   </span>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         ))
//                       )}
//                     </Slider>
//                   </div> */}
//                   </>
//                 </div>
//               ))}
//           </div>
//         )}
//       </div>
//     );
//   } else {
//     return <h1>Token Expired</h1>;
//   }
// };

// export default PartsAndAccessories;
