import React, { Component } from 'react';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import favIcon from '../../images/heartico_colored.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';
import axios from "axios";

async function removeFromFavorite(itemId, contextData) {
  let end_point = "Favorite/RemoveFromFavourite";
  let api_url = contextData.baseUrl + end_point;
  return axios({
    method: "post",
    url: api_url,
    data: {
      favouriteSystemEntity: itemId.Entity,
      tableId: itemId.tableId,
      CarId: itemId.CarId,
    },
    headers: { Authorization: contextData.token },
  })
    .then((res) => {
      toast("Remove From Favorite");
    })
    .catch((error) => console.log(error));
}

class FavoriteUsedVehicles extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      isLoaded: false,
      apiStatus: false,
      apiStatusMessage: "",
      items: [],
      api_token: localStorage.getItem("token"),
    };
    this.DataRendering = this.DataRendering.bind(this);
    this.unfavoriteMe = this.unfavoriteMe;
  }
  unfavoriteMe = async (itemId) => {
    let contextData = {
      baseUrl: this.context.Base_url,
      token: this.state.api_token,
    };
    const response = await removeFromFavorite(itemId, contextData);
    if (!response) {
      this.setState(
        {
          isLoaded: false,
          apiStatus: false,
          items: [],
        },
        this.DataRendering(),
        () => {
          this.DataRendering();
        }
      );
    }
  };

  // Data API Start
  DataRendering = () => {
    this.setState({
      isLoaded: false,
    });
    let endpoint =
      "Favorite/UserFavourites?PageNumber=1&PageSize=50&timeSpan=0&favouriteSystemEntity=2";
    const url = this.context.Base_url + endpoint;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: result.allFavoriteModel.favouriteCarModelsUsedVehicle,
            });
          } else {
            this.setState({
              isLoaded: true,
              apiStatus: result.status,
              apiStatusMessage: result.message,
              items: [],
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: true,
            errorMessage: "There is an error",
          });
        }
      );
  };
  // Data API End

  // After Rendering
  componentDidMount() {
    // Data API
    this.DataRendering();
  }

  // After Compeleting Cycle..
  componentWillUnmount() {
    // Do Something ...
    this.setState({
      isLoaded: false,
      items: [],
    });
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    return (
      <div className="view_more_screen_frame_controller">
        <ToastContainer hideProgressBar={true} />
        <div className="flex">
          {this.state.isLoaded === false ? (
            <div className="loader-div">
              <img alt="img" src={loader} id="belliconid"></img>
            </div>
          ) : this.state.items === null ? (
            this.state.error ? (
              <h3>{this.state.errorMessage}</h3>
            ) : (
              <div className="emptywndow emptywndowfav">
                <i className="fa-solid fa-heart"></i>
                <h3 className="mb-0">No Favorite Found</h3>
                <p className="mt-0">
                  Used Vehicles that you mark as favorite are show here.
                </p>
              </div>
            )
          ) : (
            <div className="view_more_items-wrapper">
              {this.state.isLoaded === false ? (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              ) : (
                this.state.items.map((user) => (
                  <div className="relative" key={v4}>
                    <div className="heart_icon">
                      <img
                        alt="img"
                        src={favIcon}
                        id="belliconid"
                        onClick={() => {
                          this.unfavoriteMe({
                            Entity: 2,
                            tableId: user.id,
                            CarId: 0,
                          });
                          this.context.setfavoriteitemloader(true);
                          this.context.setsinglecardetailsectionisopen(false);
                          this.context.setcarID(user.id);
                        }}></img>
                    </div>
                    <div
                      className="shop__row__slider__items view-more-cards relative"
                      onClick={() => {
                        this.context.setsinglecardetailsectionisopen(true);
                        this.context.setcarID(user.id);
                      }}
                      key={user.id}>
                      <div className="shop__row__slider__items__image_wrapper">
                        <img alt="img" src={user.images} id="belliconid"></img>
                      </div>
                      <div className="shop__row__slider__items__content_wrapper">
                        <div className="flex-space-between-start">
                          <span className="cartitle">
                            {user.make} {user.model}
                          </span>
                          <span className="carprice">
                            {formatter.format(user.sellingPrice)}
                          </span>
                        </div>
                        <div className="flex-space-between-start">
                          <span className="carmodel">{user.year}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default FavoriteUsedVehicles ;
