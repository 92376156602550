import React, { useState, useEffect, useRef } from "react";
import Main_layout from "../main_layout";
import Logo from '../../images/IBIZILogo.svg';
import loader from '../../images/loader.gif';
import AppContext from '../../AppContext';
import { useContext } from 'react';
import Register from './Register';
import axios from 'axios';
import OTPInput from "otp-input-react";



async function CheckOTP(otp) {
  const body_user = {
    mobileNumber: "+" + otp.forgotpasswordnumber,
    email: otp.checkedValue,
    otpCode: otp.OTP,
    verifyOtpFrom: 2,
  };
  let end_point = 'User/VerifyOTP';
  let api_url = otp.myContext.Base_url+end_point; 
  return fetch(api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
}



async function loginUser(credentials) {
  
  const body_user = {
    "email": credentials.username,
    "password": credentials.password,
    "DeviceId": "",
    "DeviceFCMToken": "",
    "IsIOSOrAndroid": true
  }
  let end_point = 'User/Signin';
  let api_url = credentials.myContext.Base_url+end_point; 
  return fetch(api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body_user)
  })
  .then((response) => response.json())
  .then((json) => json)
  .catch((error) => {
    console.log("error " + error)
  }) 
}
 
const Login = (props) => {
  const myContext = useContext(AppContext);
  const [loginsectionisopen, setloginsectionisopen] = useState(false);
  const [mainsectionopen, setmainsectionopen] = useState(false);
  const [isloaderopen, setisloaderopen] = useState(false);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [forgotpasswordnumber, setforgotpasswordnumber] = useState();
  const [valid, setValid] = useState();
  const [forgotscreenstate, setforgotscreenstate] = useState(true);
  const [newOTPResponse, setnewOTPResponse] = useState();
  const [forgotloaderstate, setforgotloaderstate] = useState(false);
  const [recieveotpemail, setrecieveotpemail] = useState([]);
  const [otpstate, setotpstate] = useState(false);
  const [hideotpbeforeemailselect, sethideotpbeforeemailselect] = useState(false);
  const [continuewithemailbutton, setcontinuewithemailbutton] = useState(false);
  const [btnstate, setbtnstate] = useState(false);
  const [OTP, setOTP] = useState("");
  const [newpasswordset, setnewpasswordset] = useState(false);
  const [newsetPassword, setnewsetPassword] = useState(false);
  const [opencnfrmbtn, setopencnfrmbtn] = useState(false);
  const [succesfullUpdatepassword, setsuccesfullUpdatepassword] = useState(false);
  const [currentUserId, setcurrentUserId] = useState();
  const [currentDeclinedId, setcurrentDeclinedId] = useState();
  const [checkedValue, setcheckedValue] = useState(); //plain object as state
  const [checkedItems, setCheckedItems] = useState({}); //plain object as state
  const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
    return (
      <input type={type} name={name} checked={checked} onChange={onChange} />
    );
  };
  const [registerView, setregisterView] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (props.loginFrom === "declinedflow") {
        // console.log("declined flow sy aya hai login screen pr.");
        var currentURL = window.location.href;
        let paramString = currentURL.split("?")[1];
        let queryString = new URLSearchParams(paramString);
        let declinedId = queryString.get("declinedId");
        let userId = queryString.get("UserId");
        setcurrentUserId(userId);
        setcurrentDeclinedId(declinedId);
        setUserName(myContext.declinedemail);
        // console.log("arrived successfully via declined flow...");
      } else {
        // console.log("arrived successfully via normal flow...");
        var currentURL = window.location.href;
        let paramString = currentURL.split("?")[1];
        let queryString = new URLSearchParams(paramString);
        let declinedId = queryString.get("declinedId");
        let userId = queryString.get("UserId");
        // setcurrentUserId(userId);
        // debugger
        setcurrentUserId(userId);
        setcurrentDeclinedId(declinedId);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    setisloaderopen(true);
    myContext.setdeclineworkstate(false);
    e.preventDefault();
    const token = await loginUser({
      username,
      password,
      myContext,
    });

    if (token.status) {
      // setisloaderopen(false);
      localStorage.setItem("usermodelfirstname", token.userInfoModel.firstName);
      localStorage.setItem("usermodellastname", token.userInfoModel.lastName);
      localStorage.setItem("token", token.token);
      var currentURL = window.location.href;
      let paramString = currentURL.split("?")[1];
      let queryString = new URLSearchParams(paramString);
      let declinedId = queryString.get("declinedId");
      // let userId = queryString.get('UserId');
      let userId = token.userInfoModel.userId;
      localStorage.setItem("userId", userId);
      localStorage.setItem("declinedId", declinedId);

      if (Object.keys(myContext.declineAddtoModel).length === 0) {
        localStorage.setItem("isDeclined", false);
        if (declinedId == null) {
          window.location.reload();
        }
      } else {
        localStorage.setItem("isDeclined", true);
        setcurrentUserId(userId);
        
        
        // var currentURLL = window.location.href;
        // currentURLL = currentURLL.split("?")[0];
        // window.location.replace(currentURLL);
      }

      // myContext.setdeclineuserwebstate(false);
      // setadditemincartconfirmation(true);
      // window.location.reload();
      // window.location.href = +'?UserId='+0+'&declinedId='+0;
    } else {
      setisloaderopen(false);
      setValid("Credientials are Invalid.");
    }
  };

  function handleChange(OTP) {
    setOTP(OTP);
    if (OTP.length === 7) {
      setbtnstate(true);
    } else {
      setbtnstate(false);
    }
  }

  function SignUpOpen() {
    setregisterView(true);
  }

  function ForgotPassword() {
    setregisterView(true);
    setforgotscreenstate(false);
  }

  // Get New OTP
  function ConfirmForgotPassword() {
    setforgotloaderstate(true);
    if (forgotpasswordnumber.length === 0) {
      setnewOTPResponse("Please Enter Your Registered Number");
      setforgotloaderstate(false);
    } else {
      let ReqData = {
        PhoneNumber: "+"+forgotpasswordnumber,
        Email: "",
      };
      let end_point = "User/SendOTP";
      const url = myContext.Base_url + end_point;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ReqData),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status) {
              if (result.emailList !== null && result.emailList !== undefined) {
                setrecieveotpemail(result.emailList);
                sethideotpbeforeemailselect(false);
              } else {
                sethideotpbeforeemailselect(true);
              }
              setotpstate(true);
              setnewOTPResponse(result.message);
              setforgotloaderstate(false);
            } else {
              setforgotloaderstate(false);
              setnewOTPResponse(result.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  function Continuewithemailforgotpassword() {
    let ReqData = {
      PhoneNumber: "+" + forgotpasswordnumber,
      Email: checkedValue,
    };
    let end_point = "User/SendOTP";
    const url = myContext.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            setrecieveotpemail(result.emailList);
            setotpstate(true);
            // sethideotpbeforeemailselect(false);
            setnewOTPResponse(result.message);
            setforgotloaderstate(false);
          } else {
            setforgotloaderstate(false);
            setnewOTPResponse(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    sethideotpbeforeemailselect(true);
  }

  const verifyOTP = async (e) => {
    setforgotloaderstate(true);
    const otpstatus = await CheckOTP({ OTP, myContext, forgotpasswordnumber, checkedValue});
    if (otpstatus.status) {
      setnewpasswordset(true);
      setforgotloaderstate(false);
    } else {
      setValid(otpstatus.message);
      setforgotloaderstate(false);
    }
  };

  // Password Show Hide
  const showpwd = (e) => {
    const ClickID = e.currentTarget.getAttribute("clickid");
    document.getElementById(ClickID);
    var x = document.getElementById(ClickID);
    if (x.type === "password") {
      e.currentTarget.innerHTML = "Hide";
      x.type = "text";
    } else {
      e.currentTarget.innerHTML = "Show";
      x.type = "password";
    }
  };
  // Password Show Hide

  const Validate = (Np) => {
    var myInputerrr = document.getElementById("vinValid");

    var myInput = document.getElementById("afterfrgetnewpassword");


    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    // Validate numbers
    var numbers = /[0-9]/g;




    if ( myInput.value.match(numbers) && myInput.value.match(upperCaseLetters) && myInput.value.match(lowerCaseLetters) && myInput.value.length >= 10 ) {
      myInputerrr.innerHTML = "";
      setopencnfrmbtn(true);
    } else {
      setopencnfrmbtn(false);
      myInputerrr.innerHTML = "Should contain at least 10 characters. Include a combination of uppercase letter, lowercase letters, and numbers.";
      
    }









    // if (Np.length === 0) {
    //   myInputerrr.innerHTML = "";
    // } else {
    //   if (Np.length >= 8) {
    //     myInputerrr.innerHTML = "";
    //     setopencnfrmbtn(true);
    //   } else {
    //     setopencnfrmbtn(false);
    //     myInputerrr.innerHTML = "Atleast 8 Charecter";
    //   }
    // }
  };

  const confirmsavepassword = () => {
    setforgotloaderstate(true);
    let ReqData = {
      PhoneNumber: "+" + forgotpasswordnumber,
      Email: checkedValue,
      Password: newsetPassword,
    };
    let end_point = "User/UpdatePassword";
    const url = myContext.Base_url + end_point;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ReqData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            setotpstate(true);
            setnewOTPResponse(result.message);
            setforgotloaderstate(false);
            setsuccesfullUpdatepassword(true);
          } else {
            setforgotloaderstate(false);
            setnewOTPResponse(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const LoginAfterChange = () => {
    setsuccesfullUpdatepassword(false);
    setopencnfrmbtn(false);
    setforgotloaderstate(false);
    setotpstate(false);
    setnewpasswordset(false);
    setbtnstate(false);
    setOTP();
    setnewOTPResponse();
    setregisterView(false);
    setValid();
  };

  const handleChangeInput = (event) => {
    // updating an object instead of a Map
    setCheckedItems({
      checkedItems,
      [event.target.name]: event.target.checked,
    });
    setcheckedValue(event.target.name);
    setcontinuewithemailbutton(true);
    // setdatafordetailcheckbox({...checkedItems, [event.target.name] : event.target.checked })
  };
  return (
    <>
      {localStorage.getItem("userId") == currentUserId &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== "" ? (
        <Main_layout />
      ) : (
        <div className="login_frame_controller flex-center-center">
          {loginsectionisopen === false && (
            <div className="w-60">
              <h3 className="title_large">
                The All-In-One Pit Stop That Your Car Needs.
              </h3>
              <div className="flex-center-center mt-3">
                <div
                  className="btn_theme btn-white"
                  onClick={() => {
                    setloginsectionisopen(true);
                  }}>
                  Get Started
                </div>
              </div>
            </div>
          )}
          {}
          {loginsectionisopen && registerView === false && (
            <div className="login_wrapper flex-center-center">
              <div className="z-in-1">
                <div className="img-wrap">
                  <img
                    alt="img"
                    src={Logo}
                    className="invertColor brokenimg"
                    id="Logoid"></img>
                </div>
                {valid}
                <div className="form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="form_group">
                      <label>Email</label>
                      <div className="inputwrapper">
                        <input
                          type="text"
                          value={username}
                          onChange={(e) => setUserName(e.target.value)}
                          required
                        />
                        <label id="userNameID"></label>
                      </div>
                    </div>
                    <div className="form_group">
                      <label>Password</label>
                      <div className="inputwrapper">
                        <input
                          className="m-0"
                          type="password"
                          id="oldpwd"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <label id="vinValid" className="c-red m-0"></label>
                        <span
                          className="show_hide"
                          onClick={showpwd}
                          clickid="oldpwd">
                          Show
                        </span>
                      </div>
                    </div>

                    <div className="flex_end_center w-100 mt-2">
                      <span className="forgotanchor" onClick={ForgotPassword}>
                        Forgot Password?
                      </span>
                    </div>
                    <div className="flex-center-center w-100 mt-2">
                      {isloaderopen ? (
                        <div className="loader-div">
                          <img
                            alt="img"
                            src={loader}
                            className="brokenimg"
                            id="belliconid"></img>
                        </div>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        onClick={SignUpOpen}
                        className="btn_theme mr-2 f-grow-1">
                        Sign Up
                      </button>
                      <button
                        type="submit"
                        className="btn_theme btn-white f-grow-1">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {registerView && (
            <>
              {forgotscreenstate === false ? (
                <>
                  {otpstate ? (
                    newpasswordset ? (
                      succesfullUpdatepassword ? (
                        <>
                          <div className="login_wrapper flex-center-center">
                            <div className="form_group">
                              <div className="inputwrapper text-center">
                                <label>{newOTPResponse}</label>
                              </div>
                              <div className="flex-center-center mt-2">
                                <button
                                  type="submit"
                                  className="btn_theme btn-white"
                                  onClick={LoginAfterChange}>
                                  Login
                                </button>
                              </div>
                            </div>
                            {forgotloaderstate && (
                              <div className="loader-div">
                                <img
                                  alt="img"
                                  src={loader}
                                  className="brokenimg"
                                  id="belliconid"></img>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <span
                            className="back-to-login"
                            onClick={() => {
                              setnewpasswordset(false);
                            }}>
                            Back
                          </span>
                          <div className="login_wrapper flex-center-center">
                            <div className="form_group">
                              <div className="inputwrapper text-center">
                                <label>Set New Password</label>
                              </div>
                              <div className="inputwrapper mt-2">
                                <label>New Password</label>
                              </div>
                              <div className="inputwrapper">
                                <input
                                  className="m-0"
                                  type="password"
                                  id="afterfrgetnewpassword"
                                  onChange={(e) => {
                                    Validate(e.target.value);
                                    setnewsetPassword(e.target.value);
                                  }}
                                  required
                                />

                                <span
                                  className="show_hide"
                                  onClick={showpwd}
                                  clickid="afterfrgetnewpassword">
                                  Show
                                </span>
                              </div>
                              <label
                                id="vinValid"
                                className="c-red mt-1"></label>
                              {opencnfrmbtn && (
                                <div className="flex-center-center mt-2">
                                  <button
                                    type="submit"
                                    className="btn_theme btn-white"
                                    onClick={confirmsavepassword}>
                                    Save
                                  </button>
                                </div>
                              )}
                              {forgotloaderstate && (
                                <div className="loader-div">
                                  <img
                                    alt="img"
                                    src={loader}
                                    className="brokenimg"
                                    id="belliconid"></img>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <span
                          className="back-to-login"
                          onClick={() => {
                            setotpstate(false);
                            setnewOTPResponse();
                          }}>
                          Back
                        </span>
                        <div className="login_wrapper flex-center-center flex-wrap">
                          {/* {[recieveotpemail].map((emailList) => {
                            return <div>{emailList}</div>;
                          })} */}
                          {hideotpbeforeemailselect ? (
                            <div className="form_group">
                              <div className="inputwrapper text-center">
                                <label>
                                  A 7-digit recovery code has been sent to your
                                  mobile via text message
                                </label>
                                <div className="inputwrapper otp-wrappr mt-2">
                                  <OTPInput
                                    onChange={handleChange}
                                    value={OTP}
                                    inputStyle="inputStyle"
                                    OTPLength={7}
                                    separator={<span></span>}
                                  />
                                  <div className="mt-2">{valid}</div>
                                </div>
                              </div>
                              {btnstate && (
                                <div className="flex-center-center mt-1">
                                  <button
                                    type="submit"
                                    className="btn_theme btn-white"
                                    onClick={verifyOTP}>
                                    Verify
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <label>Select Your Email</label>
                              <div className="emaillistwrapper checkboxes checkboxes2">
                                {recieveotpemail.map((item) => (
                                  <label key={item}>
                                    <Checkbox
                                      name={item}
                                      value={item}
                                      checked={checkedItems[item]}
                                      onChange={handleChangeInput}
                                    />
                                    {item}
                                    {item !== "Add Payment" && (
                                      <span className="check-bullet"></span>
                                    )}
                                  </label>
                                ))}
                              </div>
                              {continuewithemailbutton && (
                                <div className="flex-center-center mt-1">
                                  <button
                                    type="submit"
                                    className="btn_theme btn-white"
                                    onClick={Continuewithemailforgotpassword}>
                                    Continue
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                          {forgotloaderstate && (
                            <div className="loader-div">
                              <img
                                alt="img"
                                src={loader}
                                className="brokenimg"
                                id="belliconid"></img>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <span
                        className="back-to-login"
                        onClick={() => {
                          setregisterView(false);
                          setforgotscreenstate(true);
                        }}>
                        Back
                      </span>
                      <div className="login_wrapper flex-center-center">
                        <div className="form_group">
                          <h4 className="title_medium">Forgot Password?</h4>
                          <br></br>
                          <label>
                            Enter your registered mobile number and we will send
                            you an OTP to recover your account.
                          </label>
                          <br></br>
                          <div className="inputwrapper">
                            <input
                              required
                              type="number"
                              onChange={(e) => {
                                setnewOTPResponse();
                                setforgotpasswordnumber(e.target.value);
                              }}
                              placeholder="Enter your Mobile Number"
                            />
                            <label id="forgotMobileNumber">
                              {newOTPResponse}
                            </label>
                          </div>
                          <div className="flex-center-center mt-1">
                            <button
                              type="submit"
                              className="btn_theme btn-white"
                              onClick={ConfirmForgotPassword}>
                              Reset
                            </button>
                          </div>
                        </div>
                        {forgotloaderstate && (
                          <div className="loader-div">
                            <img
                              alt="img"
                              src={loader}
                              className="brokenimg"
                              id="belliconid"></img>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <span
                    className="back-to-login"
                    onClick={() => {
                      setregisterView(false);
                      setforgotscreenstate(true);
                    }}>
                    Back
                  </span>
                  <Register />
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
