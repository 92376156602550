import React, { useState, Component } from "react";
import loader from "../../images/loader.gif";
import "./Garage.css";
import AppContext from "../../AppContext";
import CarInfo from "../Detailcomponents/Garagedetailcomponent/Carinfo";
import Benefits from "../Detailcomponents/Garagedetailcomponent/Benefit";
import Appointments from "../Detailcomponents/Garagedetailcomponent/Appointments";
import ShopProtectionPlan from "../Shopcomponents/Shop_Protection_Plan";
import ShopServices from "../Shopcomponents/Shop_services";
import $ from "jquery";
import Overlay from '../Snippets/overlay';
import Declined from "./Declined";
import Slider from "react-slick/lib/slider";
import TopNav from "../Snippets/top_nav_items";

// const Specials = (props) => {
const settings = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class Specials extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      slideropen: false,
      items: [],
      itemsImages: [],
      itemsServiceArr: [],
      itemsFnIArr: [],
      carArry: [],
      emptycartapistatus: false,
      apistatus: false,
      AddItemToCart: {},
      isModallOpen: false,
      apimessage: "",
      defaultcarid: 0,
      api_token: localStorage.getItem("token"),
      detailobject: {},
      enum: 0,
      additemapiisloaded: false,
      additemapistatus: '',
      dealershipImages: "",
      additemapimessage: false
    };
    this.onloadAPI = this.onloadAPI;
    this.getallusercars = this.getallusercars;
    this.showMap = this.showMap;
    this.tabClick = this.tabClick;
    this.openDialog = this.openDialog;
    this.AddToCart = this.AddToCart;
    this.EmptyCart = this.EmptyCart;
  }

  onloadAPI = (e) => {
    let end_point = "Specials/GetSpecialsListing";
    let url_parameter = "?CarId=";
    let api_url = this.context.Base_url + end_point + url_parameter + this.state.defaultcarid;
    // Main Appointment API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                apistatus: result.status,
                apiStatusMessage: result.message,
                items: result.specialsServicesAndProtectionPlanModel,
              },
              () => {
                this.setState(
                  {
                    slideropen: true,
                    isLoaded: true,
                    itemsImages: this.state.items.isMegaShowModel,
                    itemsServiceArr: this.state.items.specialServiceModels,
                    itemsFnIArr: this.state.items.specialFandIModels,
                    dealershipImages: this.state.items.dealershipImages
                  }
                );
              }
            );
          } else {
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              items: [],
              itemsImages: [],
              slideropen: true,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main Appointment API End
  };

  getallusercars = (e) => {
    let end_point = "Garage/UserCars";  
    let api_url = this.context.Base_url + end_point;
    // Main Appointment API Start
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: this.state.api_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status) {
            this.setState(
              {
                apistatus: result.status,
                apiStatusMessage: result.message,
                carArry: result.carInfoDetailModels,
              },
              () => {
                this.setState(
                  {
                    slideropen: true,
                    isLoaded: false,
                    defaultcarid: this.state.carArry[0].id,
                  },
                  () => {
                    this.onloadAPI();
                    if (this.state.defaultcarid === this.state.carArry[0].id) {
                      $(".special_tabs_wrapper .innertab__wrapper_items:nth-child(1)"
                      ).addClass("active");
                    }
                  }
                );
              }
            );
          } else {
            if (result.message == "AuthToken is Expired") {
              localStorage.setItem("token", "");
            } 
            this.setState({
              isLoaded: true,
              apistatus: result.status,
              apiStatusMessage: result.message,
              items: [],
              itemsImages: [],
              carArry: [],
              slideropen: true,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
    // Main Appointment API End
  };

  // After Rendering
  componentDidMount() {
    this.getallusercars();
    // this.onloadAPI();
  }


  tabClick = (e) => {
    const tabId = e.currentTarget.getAttribute("carid");
    $(e.currentTarget).siblings().removeClass('active');   
    $(e.currentTarget).addClass('active');

    this.setState({
        defaultcarid: tabId,
        isLoaded: false
      }, () => {
        this.onloadAPI();
      });
  }



  openDialog = (e) => {
    const servicename = e.currentTarget.getAttribute("serviceName");
    const servicepercent = e.currentTarget.getAttribute("servicepercentoff");
    const serviceprice = e.currentTarget.getAttribute("serviceprice");
    const servicedesc = e.currentTarget.getAttribute("servicedescription");
    const servicedealername = e.currentTarget.getAttribute("servicedealershipname");
    const serviceimage = e.currentTarget.getAttribute("serviceimg");
    const serviceid = e.currentTarget.getAttribute("serviceid");
    const dealershipid = e.currentTarget.getAttribute("dealershipid");
    const itemenum = e.currentTarget.getAttribute("enum");
    const itemalreadyincart = e.currentTarget.getAttribute("alreadyincart");
    this.setState( {
        isModallOpen: true,
        detailobject: {
          'name' : servicename,
          'percent' : servicepercent,
          'price' : serviceprice,
          'desc' : servicedesc,
          'dealername' : servicedealername,
          'img' : serviceimage,
          'id' : serviceid,
          'dealerid': dealershipid,
          'enum': itemenum,
          'incart': itemalreadyincart

        }
    })
    $('.special_header').addClass('blur');
    $('.special_content_wrapper').addClass('blur');
    $('.round_tabs').addClass('blur');
    $('.top_iconbar').addClass('blur');


  }


  

  // Add To cart 
  AddToCart = e => {
      
    let item = e.currentTarget.getAttribute("itemID");
    let CarID = this.state.defaultcarid;
    let DealershipID = e.currentTarget.getAttribute("dealerId");;
    let CartItemModels = "";
    let PromoCode = "";
    let Notes = "";
    let Enum = e.currentTarget.getAttribute("enum");
    var priceval = e.currentTarget.getAttribute('price');

    let val = [{   
        "CartItemType": parseInt(Enum),
        "ItemId": parseInt(item),
        "isBeneift": false,
        "Price" : priceval,
        "ItemTypeAddToCondition": 3
    }] 
    let data = {
        "DealerShipId": DealershipID,
        "CarId": parseInt(CarID),
        "CartItemModels" : val,
        "PromoCode": PromoCode,
        "Notes": Notes
    }
    this.setState(
        { 
            AddItemToCart : data, 
            additemapiisloaded : true,
            isRighpanelOpen: false
        }, () => {
            // Add To Cart API Hit Function
            this.AddToCartApiFunction(this.state.AddItemToCart);
            $('.special_header').removeClass('blur');
            $('.special_content_wrapper').removeClass('blur');
            $('.round_tabs').removeClass('blur');
            $('.top_iconbar').removeClass('blur');
        }
    )
    
  }


  // Add To Cart API Function
  AddToCartApiFunction = (ReqData) => {
    
    console.log(ReqData)   
    this.setState({
      isModallOpen: false,
    })     
    let end_point = 'Cart/AddItemToCart';
    const url = this.context.Base_url+end_point; 
    fetch(url,{
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : this.state.api_token
        },
        body: JSON.stringify(ReqData),
    })
    .then(res => res.json())
    .then((result) => {
        if(result.status) {
            this.setState({
                additemapistatus: result.status,
                additemapimessage: result.message,
            }, () => {

                this.context.setupdatecartcount(true);
                setTimeout(() => {
                    this.setState({additemapiisloaded:false})
                }, 2000);
            });
        } else {
            this.setState({
                additemapistatus: result.status,
                additemapimessage: result.message,
            });
        }
    },
    (error) => {
        this.setState({
            additemapistatus:false,
            error
        });
    }) 

  }
  // Add To Cart API Function


  EmptyCart = e => {
      let end_point = 'Cart/EmptyCartItems';
      const url = this.context.Base_url+end_point;
      fetch(url,{
          method: 'POST', 
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : this.state.api_token
          }
      })
      .then(res => res.json())
      .then((result) => {
          if(result.status) {
              this.setState({
                  emptycartapistatus: result.status
              }, () => {
                  this.setState({
                      additemapiisloaded : true
                  })
                  this.AddToCartApiFunction(this.state.AddItemToCart);
              });
          } else {
              this.setState({
                  emptycartapistatus: result.status
              });
          }
      },
      (error) => {
          this.setState({
              emptycartapistatus: false,
              error
          });
      }) 
      $('.special_header').removeClass('blur');
      $('.special_content_wrapper').removeClass('blur');
      $('.round_tabs').removeClass('blur');
      $('.top_iconbar').removeClass('blur');
  }



  render() {
    return (
      <>
        <div className="special_section">
          <div className="top_iconbar">
            <span className="top_Left_column">
              <h4 className="title_medium" id="top__title">
                {this.props.title}
              </h4>
            </span>
            <TopNav />
          </div>
          <div className="special_tabs_wrapper">
            <div className="round_tabs widthauto">
              {this.state.carArry.map((cararry) => {
                return (
                  <span
                    className={`innertab__wrapper_items`}
                    onClick={this.tabClick}
                    carid={cararry.id}>
                    {cararry.make + " " + cararry.model}
                  </span>
                );
              })}
            </div>
          </div>
          {this.state.isLoaded ? (
            this.state.itemsServiceArr.length === 0 &&
            this.state.itemsFnIArr.length === 0 ? (
              this.state.isLoaded ? (
                <div className="special_content_wrapper center">
                  <h2>No Special Found</h2>
                </div>
              ) : (
                <div className="loader-div">
                  <img alt="img" src={loader} id="belliconid"></img>
                </div>
              )
            ) : (
              <>
                {
                  <>
                    {this.state.isLoaded ? (
                      this.state.itemsImages.length != 0 ? (
                        <div className="special_header">
                          <div className="slider_banner_special">
                            <Slider {...settings}>
                              {this.state.itemsImages.map((data) => {
                                return (
                                  <div
                                    className="wrapper_mega_img_special"
                                    onClick={this.openDialog}
                                    serviceName={data.serviceName}
                                    servicepercentoff={data.percentOff}
                                    serviceprice={data.discountPrice}
                                    servicedescription={data.description}
                                    servicedealershipname={data.dealershipName}
                                    alreadyincart={
                                      data.addToCart ? "true" : "false"
                                    }
                                    serviceimg={data.imageURL}
                                    serviceid={data.serviceID}
                                    enum="0"
                                    dealershipid={data.dealershipId}>
                                    <h4 className="sptag1">
                                      {data.serviceName}
                                    </h4>
                                    <h4 className="sptag2">
                                      ${data.discountPrice}
                                    </h4>
                                    <h4 className="sptag3">
                                      {data.percentOff}% Off
                                    </h4>
                                    <img
                                      className="brokenimg"
                                      alt="Failed to load"
                                      src={data.isMegaShowImage}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        </div>
                      ) : (
                        <div className="special_header nocrsr">
                          <div className="slider_banner_special">
                            <div className="wrapper_mega_img_special">
                              <img
                                className="brokenimg"
                                alt="Failed to load"
                                src={this.state.dealershipImages}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="loader-div">
                        <img alt="img" src={loader} id="belliconid"></img>
                      </div>
                    )}
                  </>
                }
                <div className="special_content_wrapper">
                  {this.state.isLoaded ? (
                    <>
                      {this.state.itemsServiceArr.map((data) => {
                        return (
                          <div
                            className="special_box_wrappr"
                            onClick={this.openDialog}
                            serviceName={data.serviceName}
                            servicepercentoff={data.percentOff}
                            serviceprice={data.discountPrice}
                            servicedescription={data.description}
                            servicedealershipname={data.dealershipName}
                            serviceimg={data.imageURL}
                            serviceid={data.serviceID}
                            alreadyincart={data.addToCart ? "true" : "false"}
                            enum="0"
                            dealershipid={data.dealershipId}>
                            <img
                              className="brokenimg"
                              alt="Failed to load"
                              src={data.imageURL}
                            />
                            <div>
                              {console.log(this.state)}
                              <h4>{data.serviceName}</h4>
                              <h4>${data.discountPrice}</h4>
                            </div>
                            <span className="percntofftag">
                              {data.percentOff}% Off
                            </span>
                          </div>
                        );
                      })}
                      {this.state.itemsFnIArr.map((data) => {
                        return (
                          <div
                            className="special_box_wrappr"
                            onClick={this.openDialog}
                            serviceName={data.serviceName}
                            servicepercentoff={data.percentOff}
                            serviceprice={data.discountPrice}
                            servicedescription={data.description}
                            servicedealershipname={data.dealershipName}
                            serviceimg={data.imageURL}
                            serviceid={data.serviceID}
                            alreadyincart={data.addToCart ? "true" : "false"}
                            enum="2"
                            dealershipid={data.dealershipId}>
                            <img
                              className="brokenimg"
                              alt="Failed to load"
                              src={data.imageURL}
                            />
                            <div>
                              <h4>{data.serviceName}</h4>
                              <h4>${data.discountPrice}</h4>
                            </div>
                            <span className="percntofftag">
                              {data.percentOff}% Off
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="loader-div">
                      <img alt="img" src={loader} id="belliconid"></img>
                    </div>
                  )}
                </div>
              </>
            )
          ) : (
            <div className="special_content_wrapper center">
              <div className="loader-div">
                <img alt="img" src={loader} id="belliconid"></img>
              </div>
            </div>
          )}
          {this.state.isModallOpen && (
            <div className="carinfo_cards_box_right_panel modalcenter active">
              <div
                className="closedetailsection"
                onClick={() => {
                  this.setState({
                    isModallOpen: false,
                  });
                  $(".special_header").removeClass("blur");
                  $(".special_content_wrapper").removeClass("blur");
                  $(".round_tabs").removeClass("blur");
                  $(".top_iconbar").removeClass("blur");
                }}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              {
                <>
                  <div className="right-panel_image_wrapper">
                    <img
                      className="brokenimg"
                      alt="img"
                      src={this.state.detailobject.img}
                      id="belliconid"></img>
                  </div>
                  <div className="right-panel_title med-title mt-2">
                    <div>
                      <h3>{this.state.detailobject.name}</h3>
                      <h3>${this.state.detailobject.price}</h3>
                    </div>
                    <span className="percntofftag">
                      {this.state.detailobject.percent}%Off
                    </span>
                  </div>
                  <div className="right-panel_title dir-col mt-1">
                    <h2>
                      <strong>Description</strong>
                    </h2>
                    <p>{this.state.detailobject.desc}</p>
                  </div>
                  <div className="right-panel_title dir-col mt-1">
                    <h3>{this.state.detailobject.dealername}</h3>
                  </div>
                  <br></br>
                  {this.state.detailobject.incart == "true" ? (
                    <div className="btn_theme fullwidth">
                      <i className="fa-solid fa-cart-shopping mr-1"></i>
                      Already in Cart
                    </div>
                  ) : (
                    <div
                      className="btn_theme fullwidth mt-1"
                      onClick={this.AddToCart}
                      itemID={this.state.detailobject.id}
                      enum={this.state.detailobject.enum}
                      dealerId={this.state.detailobject.dealerid}
                      price={this.state.detailobject.price}>
                      Add to Cart
                    </div>
                  )}
                </>
              }
            </div>
          )}
        </div>
        {this.state.additemapiisloaded
          ? this.state.additemapistatus && (
              <div className="loader-div succesmodal">
                <Overlay
                  title="additem"
                  message={this.state.additemapimessage}
                />
              </div>
            )
          : ""}
        {this.state.additemapiisloaded
          ? this.state.additemapistatus === false && (
              <div className="loader-div succesmodal">
                <h3 className="title_medium"></h3>
                <div className="confirm_dialog">
                  <h4>{this.state.additemapimessage}</h4>
                  <div className="flex">
                    {this.state.additemapimessage ===
                    "Item Already Exist in the Cart." ? (
                      <div
                        className={`btn_theme small`}
                        onClick={() => {
                          this.setState({
                            additemapiisloaded: false,
                          });
                        }}>
                        Okay
                      </div>
                    ) : (
                      <>
                        <div
                          className={`btn_theme small light`}
                          onClick={this.EmptyCart}>
                          Empty Cart
                        </div>
                        <div
                          className={`btn_theme small`}
                          onClick={() => {
                            this.setState({
                              additemapiisloaded: false,
                            });
                          }}>
                          Cancel
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="confirm_dialog_layer"></div>
              </div>
            )
          : ""}
      </>
    );
  }
}
export default Specials;
